import React, {useEffect, useState} from "react"
import {useLocalization} from "../../../../hooks/useLocalization";
import {useNavigate} from "react-router-dom";
import {url} from "../../../../routes/utility";
import {GetPossibleWinRewards} from "../../../../services/Profile/ProfileService";
import {Loading} from "../../../../components";
import {useSelector} from "react-redux";
import {getLanguageIdByLanguageKey} from "../../../../components/Utility";
import {AwardsRow} from "../index";
import useAnalytics from "../../../../hooks/useAnalytics";
import {EventLogs} from "../../../../components/Constants";

const Awards = ({ memberId }) => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const analytics = useAnalytics();
    const language = useSelector(state => state.localizationStore.language);

    const [awardsData, setAwardsData] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleClickAllRewardsButton = () => {
        navigate(url("profile.streakawards", { memberId: memberId }));
        analytics
            .useAnalytics(EventLogs.STREAK_ALL_REWARDS_BUTTON_CLICKED, undefined, false, true);
    }

    useEffect(() => {
        memberId &&
            GetPossibleWinRewards(memberId, getLanguageIdByLanguageKey(language))
                .then(result => {
                    if (result.status === 200)
                        setAwardsData(JSON.parse(result.content));

                    setLoading(false);
                })
                .catch()
    }, [memberId])

    return (
        <div className="mt-5">
            <div className="flex justify-between">
                <p className="text-[16px] font-bold text-primary">
                    {strings.profile.awards.awards_title}
                </p>
                <button
                   type="button"
                   className="text-[16px] font-bold text-primary cursor-pointer hover:text-secondary"
                   onClick={handleClickAllRewardsButton}
                >
                    {strings.profile.awards.all}
                </button>
            </div>
            <div className="flex-inline mt-1.5">
                {loading && <Loading />}

                {(!loading && awardsData.length > 0) && (
                    awardsData.map((item, index) => (
                        <AwardsRow
                            key={index}
                            data={item}
                        />
                    ))
                )}
            </div>
        </div>
    )
}

export default Awards