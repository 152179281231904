import React, {useEffect} from "react";
import {Dropdown} from "../../../components";
import {useLocalization} from "../../../hooks/useLocalization";
import useAuth from "../../../hooks/useAuth";
import {addTimeZone, getTimeZone} from "../../../components/Utility";
import useTeacherWorkingTimes from "../../../hooks/useTeacherWorkingTimes";

const TeacherTimeRangePicker = (props) => {

    const {  selectedStartTime, setSelectedStartTime, selectedEndTime, setSelectedEndTime,
        firstSelectedStartTime, firstSelectedEndTime
    } = props;

    const strings = useLocalization();
    const { beginTimes, endTimes, times} = useTeacherWorkingTimes(true);
    const { member } = useAuth();
    const timeZone = getTimeZone(member.Timezone);

    const selectedEndTimeIndex =  endTimes.findIndex(item => item.id === selectedEndTime.id);

    // updateSelectedEndTime fonksiyonu, seçilen başlangıç zamanına göre seçilen bitiş zamanını günceller.
    const updateSelectedEndTime = (selectedStartTime) => {
        // selectedStartTime.id'nin times dizisindeki indeksini bulur.
        let index = times.indexOf(selectedStartTime.id.slice(0, 5));
        // times dizisinin uzunluğunu alır.
        let length = times.length;

        // Eğer times dizisi boş değilse, işlemler devam eder.
        if(length > 0) {
            // Eğer selectedStartTime.id -1 ise, ilk seçilen bitiş zamanını ayarlar.
            if (selectedStartTime.id === -1) {
                setSelectedEndTime(firstSelectedEndTime);
            }
            // Eğer seçilen başlangıç zamanının indeksi ile 2 eklenmiş değer times dizisinin uzunluğundan küçükse, bir sonraki zamanı seçilen bitiş zamanı olarak ayarlar.
            else if (index + 2 < length) {
                setSelectedEndTime({
                    id: times[index + 1],
                    name: addTimeZone(times[index + 1], timeZone),
                });
            }
            // Eğer yukarıdaki koşullar sağlanmazsa, son zamanı seçilen bitiş zamanı olarak ayarlar.
            else {
                setSelectedEndTime({
                    id: times[length - 1],
                    name: addTimeZone(times[length - 1], timeZone),
                });
            }
        }
    }

    // selectedStartTime.id değiştiğinde, updateSelectedEndTime fonksiyonunu çağırır.
    useEffect(() => {
        // Eğer selectedStartTime.id -1 değilse, updateSelectedEndTime fonksiyonunu çağırır.
        if (selectedStartTime.id !== -1) {
            updateSelectedEndTime(selectedStartTime);
        }
        // Eğer selectedStartTime.id -1 ise ve ilk seçilen başlangıç zamanının id'si ile aynıysa, ilk seçilen başlangıç ve bitiş zamanlarını ayarlar.
        else if (selectedStartTime.id === firstSelectedStartTime.id) {
            setSelectedStartTime(firstSelectedStartTime);
            setSelectedEndTime(firstSelectedEndTime);
        }
    }, [selectedStartTime.id]);

    // selectedEndTime.id değiştiğinde, selectedStartTime.id -1 değilse ve ilk seçilen başlangıç zamanının id'si ile aynıysa, ilk seçilen başlangıç ve bitiş zamanlarını ayarlar.
    useEffect(() => {
        if (selectedEndTime.id === -1 && selectedStartTime.id !== -1) {
            setSelectedStartTime(firstSelectedStartTime);
            setSelectedEndTime(firstSelectedEndTime);
        }
    }, [selectedEndTime.id]);

    return (
        <>
            <label className="text-[14px] font-bold">{strings.teachers.filter.lesson_time}</label>
            <div className="flex items-center gap-3">
                <Dropdown
                    data={beginTimes}
                    selected={selectedStartTime}
                    setSelected={setSelectedStartTime}
                />
                <p>{strings.teachers.filter.and}</p>
                <Dropdown
                    data={endTimes.slice(selectedEndTimeIndex)}
                    selected={selectedEndTime}
                    setSelected={setSelectedEndTime}
                    isDisabled={selectedStartTime.id === -1 || endTimes.slice(selectedEndTimeIndex).length === 1}
                />
            </div>
        </>
    );
}

export default TeacherTimeRangePicker;