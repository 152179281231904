import React, {useEffect, useRef, useState} from "react";
import {useLocalization} from "../../hooks/useLocalization";
import {ToggleButton} from "../../components/Buttons";
import useAuth from "../../hooks/useAuth";
import {AddMemberContactPermission, AddMemberGeneralPermission, GetMemberContactPermissions, GetMemberGeneralPermissions} from "../../services/Permission/PermissionService";
import {ContactTypes, EventLogs, PermissionTypes} from "../../components/Constants";
import {closeModal, ModalOneButton, ModalOverlay, openModal} from "../../components";
import useAnalytics from "../../hooks/useAnalytics";

const Permission = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const {member} = useAuth();

    const modalOverlayRef = useRef();
    const modalRef = useRef();

    const [isApprovedWhatsapp, setIsApprovedWhatsapp] = useState(false);
    const [isApprovedRecord, setIsApprovedRecord] = useState(true);
    const [permissions, setPermissions] = useState([]);
    // Başlangıçta ders kaydı izni default olarak verilmiş olması için state'in başlangıç değerine bunu ekliyoruz
    const [generalPermissions, setGeneralPermissions] = useState([PermissionTypes.PHONEBOOK_ACCESS]);

    const getMemberContactPermissions = async (memberId) => {
        const result = await GetMemberContactPermissions(memberId);

        if (result.statusCode === 200) {
            let resultContent = JSON.parse(result.message);
            let tmpIsApprovedWhatsapp = resultContent.filter(contactType => contactType.contactTypeId === ContactTypes.WhatsApp).length > 0;

            let tmpPermissionsArr = [];

            resultContent?.map((contactType) => {
                if (contactType.contactTypeId !== ContactTypes.WhatsApp)
                    tmpPermissionsArr.push(contactType.contactTypeId);
            })

            setIsApprovedWhatsapp(isApprovedWhatsapp => !tmpIsApprovedWhatsapp);
            setPermissions(permissions => tmpPermissionsArr);
        }
    }



    const getMemberGeneralPermissions = async (memberId) => {
        const result = await GetMemberGeneralPermissions(memberId);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            let tmpIsApprovedRecord = resultContent.filter(permissionType => permissionType === PermissionTypes.LESSON_RECORD).length > 0;
            let tmpPermissionsArr = [];

            resultContent?.map((permissionType) => {
                if (permissionType !== PermissionTypes.LESSON_RECORD)
                    tmpPermissionsArr.push(permissionType);
            })
            setGeneralPermissions(generalPermissions => tmpPermissionsArr);

            setIsApprovedRecord(isApprovedRecord => !tmpIsApprovedRecord);
        }
    }

    const addMemberContactPermissions = async (memberId, isApprovedWhatsapp) => {

        let tmpPermissions = [];
        if (!isApprovedWhatsapp) {
            tmpPermissions = [...permissions, ContactTypes.WhatsApp]
        } else {
            tmpPermissions = [...permissions]
        }

        let model = {
            memberId: memberId,
            contactTypeIds: tmpPermissions
        }

        const result = await AddMemberContactPermission(model);

        if (result.statusCode === 200) {
            setIsApprovedWhatsapp(isApprovedWhatsapp => !isApprovedWhatsapp);
        } else {
            openModal(modalOverlayRef, modalRef);
        }
    }

    const addMemberGeneralPermission = async (memberId, isApprovedRecord) => {

        let tmpPermissions = [];
        if (!isApprovedRecord) {
            tmpPermissions = [...generalPermissions, PermissionTypes.LESSON_RECORD]
        } else {
            tmpPermissions = [...generalPermissions]
        }

        let model = {
            memberId: memberId,
            permissionTypeIds: tmpPermissions
        }

        const result = await AddMemberGeneralPermission(model);

        if (result.status === 200) {
            //LOGGED
            isApprovedRecord
                ? analytics.useAnalytics(EventLogs.LESSON_RECORD_PERMISSION_APPROVED)
                : analytics.useAnalytics(EventLogs.LESSON_RECORD_PERMISSION_REJECTED)
            setIsApprovedRecord(isApprovedRecord => !isApprovedRecord);
        } else {
            openModal(modalOverlayRef, modalRef);
        }
    }

    useEffect(() => {
        if(member) {
            getMemberContactPermissions(member.MemberId);
            getMemberGeneralPermissions(member.MemberId);
        }
    }, []);

    return (
        <>
            <div className="main-content">
                <div className="left-page">
                    <div className="mt-5 gray-section">
                    <p className="font-bold relative pb-2 text-md text-primary">
                            {strings.permissions.description}
                        </p>
                        <div className="flex justify-between mx-auto max-w-full w-[550px] my-4">
                            <p className="text-start">{strings.permissions.whatsapp}</p>
                            <ToggleButton
                                checked={isApprovedWhatsapp}
                                onChange={(e) => {
                                    addMemberContactPermissions(member.MemberId, e.target.checked);
                                }}
                            />
                        </div>
                        <p className="font-bold relative pb-2 text-md text-primary">
                            {strings.permissions.lesson_record_title}
                        </p>
                        <div className="flex justify-between mx-auto max-w-full w-[550px] my-4">
                            <p className="text-start">{strings.permissions.approve_lesson_record}</p>
                            <ToggleButton
                                checked={isApprovedRecord}
                                id="isActiveRecord"
                                onChange={(e) => {
                                    addMemberGeneralPermission(member.MemberId, e.target.checked);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <ModalOverlay ref={modalOverlayRef}/>
            <ModalOneButton
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.general_information_messages.an_error_occured}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => {
                    closeModal(modalOverlayRef, modalRef);
                }}
            />
        </>
    )
}

export default Permission;