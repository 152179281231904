import React, {useEffect, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {GetAllTeachersWithoutSubstituteAndVirtual} from "../../../services/Teacher/TeacherService";
import {Default_Teacher} from "../../../assets/svg";
import {useSelector} from "react-redux";
import classNames from "classnames";
import {getApplicationDirection} from "../../../components/Utility";

const TeacherSlotAnimation = ({ selectedTeacherIdAfterAnimation }) => {

    const language = useSelector(state => state.localizationStore.language);

    const [currentTeacher, setCurrentTeacher] = useState(0);
    const [teachers, setTeachers] = useState([]);

    useEffect(() => {
        GetAllTeachersWithoutSubstituteAndVirtual()
            .then(result => {
                if(result.status === 200)
                    setTeachers(JSON.parse(result.content));
            })
            .catch()

    }, [])

    useEffect(() => {
        let interval;
        let timer;

        if(teachers.length > 0) {
            const selectedTeacherIndex =
                teachers.findIndex(t => t.id === selectedTeacherIdAfterAnimation);

            interval = setInterval(() => {
                setCurrentTeacher((prev) => (prev + 1) % teachers.length);
            }, 200);

            timer = setTimeout(() => {
                clearInterval(interval);
                setCurrentTeacher(selectedTeacherIndex);
            }, 2500);
        }

        return () => {
            clearInterval(interval);
            clearTimeout(timer);
        };
    }, [teachers.length]);

    return (
        teachers.length > 0 &&
            <div className="overflow-hidden h-[80px] w-full relative mt-8">
                <AnimatePresence initial={false}>
                    <motion.p
                        key={currentTeacher}
                        initial={{y: -50, opacity: 0}}
                        animate={{y: 0, opacity: 1}}
                        exit={{y: 50, opacity: 0}}
                        transition={{duration: 0.3}}
                        className="flex items-center justify-center absolute inset-0"
                    >
                        <img
                            src={teachers[currentTeacher]?.profilePictureUrl?.length === 0 || teachers[currentTeacher]?.profilePictureUrl === null
                                ? Default_Teacher ?? ""
                                : teachers[currentTeacher]?.profilePictureUrl
                            }
                            alt="icon"
                            className={classNames("h-[60px] w-[60px] rounded-full", {
                                "ml-2" : getApplicationDirection(language),
                                "mr-2" : !getApplicationDirection(language)
                            })}
                        />
                        <span
                            className="font-bold text-[18px] sm:min-w-max"
                        >
                            {teachers[currentTeacher].fullName}
                        </span>
                    </motion.p>
                </AnimatePresence>
            </div>
    );
}

export default TeacherSlotAnimation;