import React from "react";
import { useSelector } from "react-redux";
import { getCountryNameByCountryCode } from "../../components/Utility";
import { useLocalization } from "../../hooks/useLocalization";
import CandidateAndInactiveStudentCommon from "./components/CandidateAndInactiveStudentCommon";
import useAuth from "../../hooks/useAuth";

const DashboardCandidate = () => {
  const strings = useLocalization();
  const { member } = useAuth();
  const language = useSelector(state => state.localizationStore.language);
  const userCountryCode = navigator.language.split('-')[1];

  return (
    <>
      <div className="left-page">
        <div className="pt-6">
          <p className="font-bold text-xl">{`${strings.dashboard.none_user.title_1} ${member?.FullName == undefined ? '' : member?.FullName},`}</p>
          <p className="text-lg" dangerouslySetInnerHTML={{ __html: getCountryNameByCountryCode(userCountryCode, language) !== '' ? strings.dashboard.none_user.title_2.replace(/#country#/g, getCountryNameByCountryCode(userCountryCode, language)) : strings.auth.layout.logo_subtitle_general }} />
        </div>

        <CandidateAndInactiveStudentCommon />
      </div>
    </>
  );
};

export default DashboardCandidate;
