import { useEffect, useState } from "react";
import { TermDropDown } from "../../components";
import ProgressReport from "../../components/ProgressReport";
import { getApplicationDirection } from "../../components/Utility";
import useAuth from "../../hooks/useAuth";
import { useLocalization } from "../../hooks/useLocalization";
import { getMemberType } from "../../services/Auth/AuthService";
import { GetProgressReportService } from "../../services/SpeakingLesson/SpeakingLessonService";
import { useSelector } from "react-redux";
import useAnalytics from "../../hooks/useAnalytics";

const ProgressReportHome = () => {
    const strings = useLocalization();

    const { member, token } = useAuth();
    const analytics = useAnalytics();
    const language = useSelector(state => state.localizationStore.language);

    const memberType = getMemberType(member.Status);
    const termId = useSelector(state => state.termStore.termId);
    const terms = memberType !== 'Candidate' && JSON.parse(member.Terms);

    const [report, setReport] = useState(false);
    const [reportLoading, setReportLoading] = useState(false);

    const getProgressReport = async (termId) => {
        setReportLoading(reportLoading => true);

        const result = await GetProgressReportService(termId, token);

        if(result.statusCode === 200) {
            setReport(report => JSON.parse(result.message))
        }

        setReportLoading(reportLoading => false);
    }

    useEffect(() => {
        let abortController = new AbortController();


        let rp = { startLevel: "", currentLevel: "", attendanceRate: "0,0" };
        setReport(report => rp);

        if(termId) {
            getProgressReport(termId);
        }

        return () => {
            abortController.abort();
        };
    }, [termId])


    return (
        <>
            <div className={`main-content ${getApplicationDirection(language) ? 'md:ml-7' : 'md:mr-7'}`}>

                {memberType === 'Candidate' ? (
                    <div className="mt-5 gray-section px-[10px] xs:px-[10px] text-sm text-center">
                        {strings.speaking_lesson.progress_report.you_are_a_candidate}
                    </div>
                ) : (
                    <div className="mt-5 gray-section px-[10px] xs:px-[10px] text-start">
                        <TermDropDown terms={terms} />
                    </div>
                )}

                <ProgressReport report={report} reportLoading={reportLoading} />

                <div className="left-margin-bottom"></div>
            </div>
        </>
    )
}

export default ProgressReportHome