import { useSelector } from "react-redux";
import { Under_Construction } from "../../assets/svg"
import { useLocalization } from "../../hooks/useLocalization"
import { getApplicationDirection } from "../../components/Utility";

const BuyChangeTeacherTime = () => {
    const strings = useLocalization();
    const language = useSelector(state => state.localizationStore.language);

    return (
        <div className={`main-content ${getApplicationDirection(language) ? 'md:ml-7' : 'md:mr-7'}`}>
            <div className="left-page">
                <div className="mx-auto text-center mt-7 text-sm px-4">
                    <img
                        className="inline-block"
                        src={Under_Construction}
                        alt=""
                        width="256"
                        height="256"
                    />
                    <p className="font-bold mt-7 text-base">{strings.general_information_messages.under_construction_title}</p>
                    <p className="mt-5">{strings.general_information_messages.under_construction_content}</p>
                </div>

                <div className="left-margin-bottom"></div>
            </div>
        </div>
    )
}

export default BuyChangeTeacherTime