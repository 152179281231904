import { parsePhoneNumber } from 'libphonenumber-js';
import { fetchApi } from '../index';

export const LoginService = async (phoneNumber, password, deviceName) => {

    const url = '/auth/login/';

    const payload = {
        brandId: parseInt(process.env.REACT_APP_BRAND_ID),
        phoneNumber: phoneNumber,
        password: password,
        deviceName: deviceName
    };

    return await fetchApi(url, payload);
}

export const AutoLoginService = async (token) => {

    const url = '/auth/autologin/';

    const payload = {
        payload: {
            token: token
        }
    };

    return await fetchApi(url, payload);
}

export const GetCandidateSurveyService = async (memberId) => {
    const url = '/_services/member/v3/survey/get_onboarding_answers/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const UpdateMemberReasonToLearnEnglish = async (model) => {
    const url = '/_services/member/v1/member_reason_to_learn_english/create_or_update_member_reasons/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const UpdateSurveyProfessionService = async (memberId, profession, token) => {
    const url = '/auth/set_survey_profession/';

    const payload = {
        payload: {
            memberId: memberId,
            professionId: profession
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}


export const RegisterService = async (fullName, phoneNumber, password, timeZoneOffset, utm_source = '', utm_content = '', utm_term = '', utm_medium = '', utm_campaign = '', utm_label = '', referenceCode='') => {

    const url = '/auth/register/';

    const payload = {
        brandId: parseInt(process.env.REACT_APP_BRAND_ID),
        phoneNumber: phoneNumber,
        password: password,
        landingPage: 'student-web',
        registerType: 3,
        sourceType: 1,
        utmSource: utm_source,
        utmContent: utm_content,
        utmTerm: utm_term,
        utmMedium: utm_medium,
        utmCampaign: utm_campaign,
        utmLabel: utm_label,
        fullName: fullName,
        email: '',
        timezone: timeZoneOffset,
        referenceCode: referenceCode,
        countryIso2: parsePhoneNumber(phoneNumber).country
    };

    return await fetchApi(url, payload);

}

export const SendVerificationCodeServiceV2 = async (phoneNumber) => {
    const url = '/_services/member/v2/member_account/send_verification_code/';

    const payload = {
        phoneNumber: phoneNumber,
        brandId: parseInt(process.env.REACT_APP_BRAND_ID),
        sourceType: 1,
        userType: 0 // selected as member by default
    };

    return await fetchApi(url, payload);
}

export const VerifyCodeServiceV2 = async (memberId, code) => {
    const url = '/_services/member/v2/member_account/verify_code/';

    const payload = {
        memberId: memberId,
        enteredCode: code,
        sourceType: 1,
        userType: 0 // selected as member by default
    };

    return await fetchApi(url, payload);
}

export const UpdatePasswordV2 = async (memberId, newPassword) => {
    const url = '/_services/member/v2/member_account/update_password/';

    const payload = {
        memberId: memberId,
        newPassword: newPassword,
        sourceType: 1,
        userType: 0 // selected as member by default
    };

    return await fetchApi(url, payload);
}

export const GenerateTokenService = async (memberId) => {
    const url = '/auth/generate_token/';

    const payload = {
        payload: {
            memberId: memberId
        },
        lang: process.env.REACT_APP_LANG,
    };

    return await fetchApi(url, payload);
}

export const VerifyMagicLink = async (magicLink) => {
    const url = '/_services/magic_link/verify_magic_link/';

    const payload = {
        magicLink: magicLink
    }

    return await fetchApi(url, payload);
}

export const AddMemberLevelCollection = async (memberId, levelDefinitionType, levelCollectionId) => {
    const url = '/_services/member/v1/member_level_collection/add_member_level_collection/';

    const payload = {
        memberId: memberId,
        levelDefinitionType: levelDefinitionType,
        levelCollectionId: levelCollectionId
    }

    return await fetchApi(url, payload);
}

export const GetMemberNameByReferenceCode = async (referenceCode) => {
    const url = '/_services/member/v1/member/get_member_name_by_reference_code/';

    const payload = {
        referenceCode: referenceCode
    }

    return await fetchApi(url, payload);
}

//this function provides to return member type
export const getMemberType = memberStatus => {
    if (memberStatus === '2') return 'Student';
    if (memberStatus === '3') return 'InactiveStudent';
    return 'Candidate';
}