import classNames from "classnames";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Logo_Square } from "../../../assets/img";
import { Default_Teacher } from "../../../assets/svg";
import { TermDropDown } from "../../../components";
import { DemoStatusType, EventLogs } from "../../../components/Constants";
import useAnalytics from "../../../hooks/useAnalytics";
import useAuth from "../../../hooks/useAuth";
import { useLocalization } from "../../../hooks/useLocalization";
import { url } from "../../../routes/utility";
import { addTimeZone, checkMicrophoneAllowed, checkNotificationAllowed, formatNoneZoreDateToZeroDateForDot, getBrowserName, getTimeZone, getyyyyMMddDate2, showDateWithFormat } from "../../../components/Utility";
import { replaceTextWithButton } from "../../../utils/Helpers";

const DemoTermContent = (props) => {

    const { lessonData, lessonInfo, terms } = props;

    const strings = useLocalization();
    const navigate = useNavigate();
    const analytics = useAnalytics();
    const { member } = useAuth();

    const [selectedTermStatus, setSelectedTermStatus] = useState(null);
    const [isNotificationAllowed, setIsNotificationAllowed] = useState(null);
    const [isMicrophoneAllowed, setIsMicrophoneAllowed] = useState(null);
    const [browser, setBrowser] = useState(null);

    const isPermissionsGiven = isMicrophoneAllowed === "granted" && isNotificationAllowed === "granted"
    const timeZone = getTimeZone(member.Timezone);

    const changeTerm = (term) => {
        setSelectedTermStatus(term.Status)
    };

    const handleDemoLessonTimeChangeButton = () => {
        if (lessonData.demoStatus === DemoStatusType.NormalDemo) {
            navigate(url("support.changeteachertime"))
        } else if (lessonData.demoStatus === DemoStatusType.AutoDemo) {
            navigate(url("demo"), { state: {"source": "dashboard"}});
        }
    }

    // Mikrofon izni isteyen fonksiyon
    const getMicrophonePermission = () => {
        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(function (stream) {
                    setIsMicrophoneAllowed(isMicrophoneAllowed => true);
                    analytics.useAnalytics(EventLogs.MICROPHONE_PERMISSION_APPROVED);
                })
                .catch((e) => {
                    //Eğer mikrofon izni daha önce reddedilmiş ya da verilip kaldırılmışsa hataya düşüyor
                    //böyle bir durum yaşanırsa kullanıcıyı bilgilendirmek için modal açıyoruz
                });
        }
    }

    // Bildirim izni isteyen fonksiyon
    const getNotificationRequest = async () => {
        if (!("Notification" in window))
            alert("This browser does not support Desktop notifications");

        if (Notification.permission !== "granted") {
            await Notification.requestPermission((permission) => {
                if (permission === "granted") {
                    setIsNotificationAllowed(isNotificationAllowed => true);
                    analytics.useAnalytics(EventLogs.NOTIFICATION_PERMISSION_APPROVED);
                    callNotify("Success", "Allowed notification!", Logo_Square, '/sound/success.mp3');
                    //Eğer chrome ya da edge tarayıcı ise mikrofon iznini de almadan sayfayı yenilemiyoruz
                    if (browser === "chrome" || browser === "edge") {
                        checkMicrophoneAllowed().then(status => {
                            if (status === "granted") {
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                            }
                        });
                    } else {
                        //Eğer yukarıdaki 2 tarayıcıdan birisi değilse direkt sayfayı yeniliyoruz
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500);
                    }

                }
            });
        }
    }

    // Kullanıcıdan bildirim ve mikrofon izni vermesini istediğimizde bu fonksiyon çağırılıyor
    const callNotify = (title, msg, icon, sound) => {
        new Notification(title, { body: msg, icon: icon });
        new Audio(sound).play();
    }
    
    useEffect(() => {
        //Tarayıcı ismi alınıyor ve ilgili değişkene atanıyor
        setBrowser(getBrowserName());

        checkMicrophoneAllowed().then(status => (
            setIsMicrophoneAllowed(status)
        ));
        checkNotificationAllowed().then(status => (
            setIsNotificationAllowed(status)
        ));
    }, [])

    return (
        <div className="my-5 gray-section">
            <h5 className="text-primary mb-2">{strings.dashboard.demo_student.demo_lesson_reserved}</h5>
            {terms?.length > 1 && <TermDropDown terms={terms} changeTerm={changeTerm} /> }

            <div className="text-sm mt-4 pb-2 max-w-lg mx-auto">
                {selectedTermStatus !== 0 ? (
                    <>
                        <div className="flex flex-col sm:flex-row mb-3">
                            <div className="flex flex-col items-center sm:block sm:w-[30%] mb-3 sm:mb-0">
                                <div className='sm:hidden mb-3' dangerouslySetInnerHTML={{ __html: lessonInfo.text }} />
                                {lessonInfo.teacherName ?
                                    (
                                        <>
                                            <img
                                                className={classNames("rounded-full w-[100px] mx-auto", {
                                                    "cursor-pointer": !(lessonData?.teacherModel?.id === 1320 || lessonData?.teacherModel?.id === 1500 || lessonData?.teacherModel?.id === 1501)
                                                })}
                                                onClick={() => !(lessonData?.teacherModel?.id === 1320 || lessonData?.teacherModel?.id === 1500 || lessonData?.teacherModel?.id === 1501) && navigate(url("teachers.detail", { teacherId: lessonData?.teacherModel?.id }))}
                                                src={lessonInfo.teacherImg === null ? Default_Teacher : lessonInfo.teacherImg}
                                                alt=""
                                            />
                                            <p className='font-bold'>{lessonInfo.teacherName}</p>
                                            <p>{formatNoneZoreDateToZeroDateForDot(lessonData.lessonDate)} - {addTimeZone(lessonData.lessonTime, timeZone)}</p>
                                            <p className="text-[13px] leading-4 text-base-text-light">{replaceTextWithButton(strings.dashboard.demo_student.change_demo_lesson_time, handleDemoLessonTimeChangeButton)}</p>
                                        </>
                                    ) : (
                                        <>
                                            {strings.dashboard.student.no_teacher_info}
                                        </>
                                    )
                                }
                            </div>
                            <div className="sm:w-[70%]">
                                <div className='max-sm:hidden' dangerouslySetInnerHTML={{ __html: lessonInfo.text }} />
                                <div className="flex flex-col items-center mt-3 sm:mt-4">
                                    <Link
                                        to={url("speakinglesson.dailylesson")}
                                        className="button w-[95%] max-w-[330px] primary-button"
                                        onClick={() => {
                                            analytics.useAnalytics(
                                                "Dashboard_Ders_Kitabini_Incele_Click"
                                            );
                                            localStorage.setItem("isLessonBookClicked", true);
                                            lessonData.lessonDate && localStorage.setItem("selectedDate", getyyyyMMddDate2(showDateWithFormat(lessonData.lessonDate)));
                                        }}
                                    >
                                        {strings.dashboard.student.view_the_lesson_book}
                                    </Link>
                                    <a
                                        className="button w-[95%] max-w-[330px] secondary-button mt-3"
                                        href={process.env.REACT_APP_DOWNLOAD_APP_URL}
                                        target="_blank" rel="noreferrer"
                                    >
                                        {strings.dashboard.demo_student.download_mobil_app}
                                    </a>
                                    { !isPermissionsGiven && 
                                        <button className='button w-[95%] max-w-[330px] secondary-button mt-3'
                                            onClick={() => {
                                                isNotificationAllowed !== "granted" && getNotificationRequest();
                                                isNotificationAllowed === "granted" && isMicrophoneAllowed !== "granted" && getMicrophonePermission();
                                            }}>
                                            {isNotificationAllowed  !== "granted" && strings.dashboard.demo_student.give_notification_permission }
                                            {isNotificationAllowed === "granted" &&  isMicrophoneAllowed  !== "granted" && strings.dashboard.demo_student.give_microphone_permission }
                                        </button>
                                    }
                                </div>
                            </div>

                        </div>
                        {!isPermissionsGiven && 
                            <p className="text-[13px] leading-4 text-base-text-light"><span className="font-bold">{strings.ai_chatbot.note}: </span> {strings.dashboard.demo_student.microphone_permission}</p>
                        }
                    </>
                ) : (
                    <>
                        <p>{strings.dashboard.student.dashboard_passive_lesson_error}</p>
                        <Link
                            to={url("speakinglesson.dailylesson")}
                            className="button w-[95%] max-w-[330px] primary-button mb-2 mt-3 mx-auto"
                            onClick={() => {
                                analytics.useAnalytics(
                                    "Dashboard_Ders_Kitabini_Incele_Click"
                                );
                            }}
                        >
                            {strings.dashboard.student.click_for_previous_lessons}
                        </Link>
                    </>
                )}

            </div>
        </div>
    )
}

export default DemoTermContent;