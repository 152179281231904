import React, {useEffect, useState} from 'react'
import {useLocalization} from "../../../hooks/useLocalization";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {EducationProgramSubCategoryListService} from "../../../services/Market/MarketEducationPackageService";
import {useSelector} from "react-redux";
import {Loading} from "../../../components";
import {ProgramBadge} from "../components";
import {url} from "../../../routes/utility";
import {getLanguageIdByLanguageKey} from "../../../components/Utility";
import useAnalytics from "../../../hooks/useAnalytics";
import {EventLogs} from "../../../components/Constants";

const SubCategory = () => {

  const strings = useLocalization();
  const { programId } = useParams();
  const navigate = useNavigate();
  const language = useSelector(state => state.localizationStore.language);
  const location = useLocation();
  const IS_MOBILE = location.pathname.includes("/mobile_market_sub_category");
  // Landing page'lerde bu sayfayı embed edip kullanıldığında bu değer true oluyor.
  const IS_EMBEDDED_URL = !location.pathname.includes("market");
  const analytics = useAnalytics();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const handleButton = (programId) => {
      let redirectUrl = 
        IS_EMBEDDED_URL 
            ? 'educationpackages.detail' 
                : IS_MOBILE 
                    ? 'mobile_market_education_packages_detail' 
                    : 'market.educationpackages.detail';

      navigate(url(redirectUrl, { programId }));
  }

  useEffect(() => {
      EducationProgramSubCategoryListService(programId, getLanguageIdByLanguageKey(language))
          .then(result => {
              if(result.status === 200){
                  let resultContent = JSON.parse(result.content);
                  setData(subCategoryList => resultContent);
              }

              setLoading(loading => false);
          })
          .catch(e => {})
  }, [language])

  useEffect(() => {
      if (IS_MOBILE) {
          const page = {
              "second_page": true
          }
          window.ReactNativeWebView.postMessage(JSON.stringify(page));
      }
  }, [])

  useEffect(() => {
      if(Object.keys(data).length > 0)
            !IS_EMBEDDED_URL && analytics.useAnalytics(
                EventLogs.EDUCATION_PROGRAM_MARKET_DETAIL_LIST,
                { program_name: data.programTitle }
            )
  }, [data]);

  return (
      <div className={` ${!IS_EMBEDDED_URL ? `${IS_MOBILE ? "main-content-mobile" : "main-content"}` : "mx-5"}`}>
          <div className="left-page">

              {loading && <Loading/>}

              {!loading && (
                  <div className="transparent-section mt-5 text-sm text-start">
                      <h5 className="text-secondary">{strings.market.education_packages.title}</h5>
                      <p className="mt-3 text-center">{strings.market.education_packages.description}</p>

                      {Object.keys(data).length > 0 &&
                          data?.categoryList.map((subCategory, index) => (
                              <ProgramBadge
                                  key={index}
                                  title={subCategory.title}
                                  subtitle={subCategory.description}
                                  lessonCount={subCategory.totalLessonCount}
                                  onClick={() => handleButton(subCategory.programId)}
                              />
                          ))
                      }
                  </div>
              )}

              <div className="left-margin-bottom"></div>
          </div>
      </div>
  )
}

export default SubCategory