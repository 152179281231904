import useAuth from "../../hooks/useAuth";
import {useEffect, useState} from "react";
import {LevelCollection, MemberStatus, OnboardingExercise} from "../../components/Constants";
import {
    OnboardingCongratulate,
    OnboardingExerciseDialog,
    OnboardingExerciseGrammar,
    OnboardingExerciseSentence,
    OnboardingExerciseWords
} from "./components";
import {useNavigate, useParams} from "react-router-dom";
import {GetMemberExercise} from "../../services/OnboardingExercise/OnboardingExerciseService";
import {url} from "../../routes/utility";
import {useSelector} from "react-redux";

const OnboardingExerciseHome = () => {

    const { member } = useAuth();
    const navigate = useNavigate();
    const { dayIndex } = useParams();

    const [exerciseData, setExerciseData] = useState({});
    const [step, setStep] = useState(OnboardingExercise.DIALOG);
    const [loading, setLoading] = useState(true);

    const mistakeQuestionIds = useSelector(state => state.exerciseReportStore.mistakeIds);
    const correctQuestionIds = useSelector(state => state.exerciseReportStore.correctIds);

    const [report, setReport] = useState({
       memberId: member?.MemberId,
       day: +dayIndex,
       result: {
           dialog: "",
           words: [],
           hint: "",
           grammar: "",
           totalQuestion: 0,
           correct: 0,
           wrong: 0,
           mistakeQuestions: []
       }
    });

    const getExercise = async () => {
        try {

            let model = {
                memberStatus: +member?.Status,
                day: +dayIndex,
                useCaseId: +member?.ReasonToLearnEnglishId,
                //Level seçimi 1(No English) olanlar için default olarak level 2(Starter) olarak gönderiliyor
                levelId: member?.MemberLevelCollectionId === ""
                    ? LevelCollection.BEGINNER
                    : +member?.MemberLevelCollectionId === LevelCollection.NO_ENGLISH
                        ? LevelCollection.STARTER
                        : +member?.LevelCollectionId
            }
            const result = await GetMemberExercise(model);

            setExerciseData(exerciseData => result);
            setLoading(loading => false);
        } catch (e) { }
    }

    //Report'u değiştirmek için kullanılan fonksiyon.
    const changeReport = (values) => {
        setReport({ ...report, ...values });
    }

    useEffect(() => {
        if(Object.keys(exerciseData).length > 0){
            //Egzersiz adımlarının ID'leri rapora eklenmek için alınıyor.
            let dialogId = exerciseData.dialog.id;
            let hintId = exerciseData.hint.id;
            let grammarId = exerciseData.grammar.id;
            let wordIds = exerciseData.words.word.filter(w => w.id).map((word) => word.id);

            //Egzersiz adımlarının soru sayıları rapora eklenmek için alınıyor.
            let dialogQuestionLength = exerciseData.dialog?.questions.length;
            let wordQuestionLength = exerciseData.words?.questions.length;
            let hintQuestionLength = exerciseData.hint?.questions.length;
            let grammarQuestionLength = exerciseData.grammar?.questions.length;

            let totalQuestion = dialogQuestionLength + wordQuestionLength + hintQuestionLength + grammarQuestionLength;

            changeReport({
                result: {
                    dialog: dialogId,
                    words : wordIds,
                    hint: hintId,
                    grammar: grammarId,
                    totalQuestion: totalQuestion,
                    correct: correctQuestionIds.length,
                    wrong: mistakeQuestionIds.length,
                    mistakeQuestions: mistakeQuestionIds
                }
            })
        }
    }, [exerciseData, mistakeQuestionIds, correctQuestionIds])

    /*
        Sayfa yenilendiğinde storage'da tutulan step değeri temizleniyor.
    */
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            localStorage.removeItem("step");
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        getExercise();
    }, [])

    // Öğrenci durumu aday olmayanlar dışında sayfaya giriş yapmaya çalışılırsa dashboard'a yönlendiriliyor.
    useEffect(() => {
        if(+member?.Status === MemberStatus.Active)
            navigate(url("dashboard"))
    }, [])

    return (
        <>
            {step === OnboardingExercise.DIALOG &&
                <OnboardingExerciseDialog
                    setStep={setStep}
                    data={exerciseData?.dialog}
                    day={+dayIndex + 1}
                    loading={loading}
                />
            }
            {step === OnboardingExercise.WORDS &&
                <OnboardingExerciseWords
                    setStep={setStep}
                    data={exerciseData?.words}
                    day={+dayIndex + 1}
                />
            }
            {step === OnboardingExercise.SENTENCES &&
                <OnboardingExerciseSentence
                    setStep={setStep}
                    data={exerciseData?.hint}
                    day={+dayIndex + 1}
                />
            }
            {step === OnboardingExercise.GRAMMAR &&
                <OnboardingExerciseGrammar
                    setStep={setStep}
                    data={exerciseData?.grammar}
                    day={+dayIndex + 1}
                />
            }
            {step === OnboardingExercise.CONGRATULATE &&
                <OnboardingCongratulate
                    report={report}
                    day={+dayIndex + 1}
                />
            }
        </>
    )
}

export default OnboardingExerciseHome;