import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {wordsAndIdioms} from '../../assets/data/wordsAndIdioms';
import {TermDropDown} from '../../components/TermDropDown';
import {
    addTimeZone,
    formatNoneZoreDateToZeroDateForDot,
    getTimeDiff,
    getTimeZone,
    getyyyyMMddDate2,
    showDateWithFormat
} from '../../components/Utility';
import useAnalytics from '../../hooks/useAnalytics';
import useAuth from '../../hooks/useAuth';
import {useLocalization} from '../../hooks/useLocalization';
import {url} from '../../routes/utility';
import {GetDashboardLessonData} from '../../services/Dashboard/DashboardService';
import {memberLogout} from '../../store/MemberSlice';
import IdiomsOfTheDay from './components/IdiomsOfTheDay';
import {Default_Teacher} from '../../assets/svg';
import {setTermId} from "../../store/TermSlice";
import {TermStatusTypes} from "../../components/Constants";
import classNames from 'classnames';

const DashboardStudent = () => {
    const strings = useLocalization();
    const analytics = useAnalytics();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { member, token } = useAuth();
    const language = useSelector(state => state.localizationStore.language);

    const terms = member.Terms ? JSON.parse(member.Terms) : dispatch(memberLogout());
    const timeZone = getTimeZone(member.Timezone);

    const [lessonData, setLessonData] = useState(false);
    const [postponeDate, setLPostponeDate] = useState(false);
    const [selectedTermStatus, setSelectedTermStatus] = useState(null);
    const [lessonInfo, setLessonInfo] = useState({ "text": "", "teacherName": "", "teacherImg": "" });
    const [isLessonTimePassed, setIsLessonTimePassed] = useState(false)
    const selectedTermId = useSelector(state => state.termStore.termId);
    const selectedTerm = terms.find(term => term.TermId === selectedTermId);

    // get day, word and idioms
    const dayIndex = (new Date().getDate() - 1);
    const todaysWord = wordsAndIdioms[dayIndex].word;
    const todaysIdioms = wordsAndIdioms[dayIndex].idioms;

    //from child dropdown
    const changeTerm = term => {
        setSelectedTermStatus(selectedTermStatus => term.Status)
    };

    const checkDotForDate = dt => {
        return dt.includes(".");
    }

    const createDateFromDotDate = dt => {
        let dateArr = dt.split(".");
        let date = dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
        return date;
    }

    const createDateFromDotDateToDashDateWithTime = (date, lessonTime) => {
        let [day, month, year] = date.split('.');
        let [hour, minute] = lessonTime.split(':');

        // Eğer "day" değeri 10'dan küçükse başına sıfır ekleniyor
        // Eğer "day" değeri 10'dan küçükse ve tek haneli olarak geliyorsa başına sıfır ekleniyor
        if (parseInt(day) < 10 && day.length === 1) {
            day = `0${day}`;
        }

        // Eğer "month" değeri 10'dan küçükse ve tek haneli olarak geliyorsa başına sıfır ekleniyor
        if (parseInt(month) < 10 && month.length === 1) {
            month = `0${month}`;
        }

        return new Date(`${year}-${month}-${day}T${hour}:${minute}:00`);
    }

    const getDashboardLessonData = async (termId) => {

        const result = await GetDashboardLessonData(termId)

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setLessonData(resultContent)
        }
    }

    //when termId change get term info
    useEffect(() => {
        let abortController = new AbortController();

        if (selectedTermId) {
            getDashboardLessonData(selectedTermId)
        }

        return () => {
            abortController.abort();
        };
    }, [selectedTermId])

    useEffect(() => {
        if (selectedTermId.length > 0 && terms.length > 0) {
            let tmpSelectedTermStatus = terms.find(term => term.TermId === selectedTermId)?.Status;
            setSelectedTermStatus(selectedTermStatus => tmpSelectedTermStatus);
        }
    }, [token, terms, selectedTermId]);

    //update lesson dates
    useEffect(() => {

        //if there is data for lesson
        if (lessonData) {

            //if lesson data has a date
            if (lessonData.lessonDate !== undefined) {
                let lessonDate = formatNoneZoreDateToZeroDateForDot(lessonData.lessonDate);

                if (checkDotForDate(lessonData.lessonDate)) {
                    lessonDate = createDateFromDotDate(lessonDate);
                }

                //set lessondate for postpone page
                setLPostponeDate(postponeDate => lessonDate);

                //get time difference for next lesson
                const counterLesson = getTimeDiff(new Date(`${lessonDate} ${addTimeZone(lessonData.lessonTime, timeZone)}`), new Date());

                //create an object for lesson info content
                let info = { "text": "", "teacherName": "", "teacherImg": "" };
                let lessonMessage = "";

                if (createDateFromDotDateToDashDateWithTime(lessonData.lessonDate, addTimeZone(lessonData.lessonTime, timeZone)) >= new Date()) {
                    //change values for current language
                    lessonMessage = strings.dashboard.student.lesson_info;
                    lessonMessage = lessonMessage.replace(/#counter#/g, counterLesson);
                    lessonMessage = lessonMessage
                        .replace(/#name#/g, selectedTerm?.StudentName || member.FullName)
                        .replace(/#date#/g, showDateWithFormat(lessonData.lessonDate))
                        .replace(/#time#/g, addTimeZone(lessonData.lessonTime, timeZone))
                        .replace(/#day#/g, strings.dashboard.student.day)
                        .replace(/#hour#/g, strings.dashboard.student.hour)
                        .replace(/#minute#/g, strings.dashboard.student.minute);
                    setIsLessonTimePassed(isLessonTimePassed => false)

                } else {
                    lessonMessage = strings.dashboard.student.in_lesson_time_message;
                    lessonMessage = lessonMessage.replace(/#name#/g, member.FullName ?? "");
                    setIsLessonTimePassed(isLessonTimePassed => true)
                }

                //update info object
                info.text = lessonMessage;
                info.teacherName = lessonData.teacherModel?.fullName.replace(" (Sanal Egitmen)", "");
                info.teacherImg = lessonData.teacherModel?.profilePicture;

                //set lesson info for render
                setLessonInfo(info);
            }

        }
    }, [lessonData, language])

    //Kullanıcının bir aktif paketi varsa ve seçili paketi aktif paket değilse otomatik olarak aktif pakete set ediyoruz.
    useEffect(() => {
        if (terms) {
            let activeTerms = terms.filter(t => t.Status === TermStatusTypes.Active);

            if (activeTerms.length === 1 && selectedTerm?.Status !== TermStatusTypes.Active) {
                let activeTermId = activeTerms[0].TermId;
                dispatch(setTermId(activeTermId));
            }
        }
    }, []);

    return (
        <>

            <div className="mt-4 gray-section">
                <h5 className="text-primary mb-2">{strings.dashboard.student.title}</h5>
                { terms?.length > 1 && <TermDropDown terms={terms} changeTerm={changeTerm} /> }

                <div className="text-sm mt-4 pb-2 max-w-lg mx-auto">
                    {selectedTermStatus !== 0 ? (
                        <div className="flex flex-col sm:flex-row mb-3">
                            <div className="flex flex-col items-center sm:block sm:w-[30%] mb-3 sm:mb-0">
                                {lessonInfo.teacherName ?
                                    (<>
                                        <img
                                            className={classNames("rounded-full w-[100px] mx-auto", {
                                                "cursor-pointer": !(lessonData?.teacherModel?.id === 1320 || lessonData?.teacherModel?.id === 1500 || lessonData?.teacherModel?.id === 1501)
                                            })}
                                            onClick={() => !(lessonData?.teacherModel?.id === 1320 || lessonData?.teacherModel?.id === 1500 || lessonData?.teacherModel?.id === 1501) && navigate(url("teachers.detail", { teacherId: lessonData?.teacherModel?.id }))}
                                            src={lessonInfo.teacherImg === null ? Default_Teacher : lessonInfo.teacherImg}
                                            alt=""
                                        />
                                        <p>{strings.dashboard.student.your_teacher}<br className='hidden sm:block' /> <span className="font-bold">{lessonInfo.teacherName}</span></p>
                                    </>
                                    ) : (<>{strings.dashboard.student.no_teacher_info}</>)}
                            </div>
                            <div className="sm:w-[70%] mb-5 sm:mb-3">
                                <div dangerouslySetInnerHTML={{ __html: lessonInfo.text }} />
                                <div className="flex flex-col items-center mt-3 sm:mt-4">
                                    <Link
                                        to={url("speakinglesson.dailylesson")}
                                        className="button w-[95%] xs:w-[330px] primary-button mb-2"
                                        onClick={() => {
                                            analytics.useAnalytics(
                                                "Dashboard_Ders_Kitabini_Incele_Click"
                                            );
                                            localStorage.setItem("isLessonBookClicked", true);
                                            lessonData.lessonDate && localStorage.setItem("selectedDate", getyyyyMMddDate2(showDateWithFormat(lessonData.lessonDate)));
                                        }}
                                    >
                                        {strings.dashboard.student.view_the_lesson_book}
                                    </Link>
                                    {
                                        !isLessonTimePassed && <Link to={url("support.postpone")} state={{ date: postponeDate }} className="text-button" onClick={() => analytics.useAnalytics('Dashboard_Ders_Erteleme_Click')}>
                                            {strings.dashboard.student.want_to_postpone_class}
                                        </Link>
                                    }
                                </div>
                            </div>

                        </div>
                    ) : (
                        <>
                            <p>{strings.dashboard.student.dashboard_passive_lesson_error}</p>
                            <Link
                                to={url("speakinglesson.dailylesson")}
                                className="button w-[95%] xs:w-[330px] primary-button mb-2 mt-3 mx-auto"
                                onClick={() => {
                                    analytics.useAnalytics(
                                        "Dashboard_Ders_Kitabini_Incele_Click"
                                    );
                                }}
                            >
                                {strings.dashboard.student.click_for_previous_lessons}
                            </Link>
                        </>
                    )}

                </div>

            </div>
            <div className="transparent-section mt-5">
                <h5 className="text-secondary">{strings.dashboard.student.word_of_the_day}</h5>
                <p className="mt-[27px] font-bold" dir='ltr'>{todaysWord[0]}</p>
                <p className="mt-[27px]">{todaysWord[1]}</p>
                <p className="mt-[27px] font-bold" dir='ltr'>{todaysWord[2]}</p>
                <p className="mt-[27px]">{todaysWord[3]}</p>
            </div>

            <div className="transparent-section mt-5">
                <h5 className="text-secondary">{strings.dashboard.student.idioms_of_the_day}</h5>

                <IdiomsOfTheDay idioms={todaysIdioms} />

                {/* AL'de kaldırıldığı için yorum satırına aldık */}
                {/* <div className="btn-outer-div mb-5 mt-[27px]">
                    <Link to={url("resources")} className="button primary-button" onClick={() => analytics.useAnalytics('Dashboard_Tum_Kaynaklar_Click')}>
                        {strings.dashboard.student.all_sources}
                    </Link>
                </div> */}
            </div>
            <div className="left-margin-bottom"></div>

        </>
    )
}

export default DashboardStudent