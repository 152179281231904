import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, closeModal, Loading, ModalOneButton, ModalOverlay, ModalTwoButtons, openModal } from "../../../../components";
import { ServiceErrorMessages } from "../../../../components/Constants";
import { addTimeZone, getTimeZone } from "../../../../components/Utility";
import useAnalytics from "../../../../hooks/useAnalytics";
import useAuth from "../../../../hooks/useAuth";
import { useLocalization } from "../../../../hooks/useLocalization";
import useToken from "../../../../hooks/useToken";
import { url } from "../../../../routes/utility";
import { UpdateTeacherTimeServiceV2 } from "../../../../services/Teacher/TeacherService";

const ChangeTeacherTimeList = ({ data, loadingTeacher, showTitle = true }) => {
    const strings = useLocalization();
    const analytics = useAnalytics();
    const navigate = useNavigate();

    const tokenHook = useToken();

    const { member, token } = useAuth();
    const timeZone = getTimeZone(member.Timezone);
    const terms = JSON.parse(member.Terms);
    const selectedTermId = useSelector(state => state.termStore.termId);
    const selectedTerm = terms.find(term => term.TermId === selectedTermId);
    const termInfo = `${selectedTerm.ProductName} / ${addTimeZone(selectedTerm.LessonTime, timeZone)} / ${selectedTerm.LessonDuration} ${strings.general_information_messages.minute}`

    const [modalText, setModalText] = useState('');
    const [teacherId, setTeacherId] = useState(0);
    const [lessonTime, setLessonTime] = useState('');
    const [error, setError] = useState(false);

    const modalRef = useRef();
    const modalOverlayRef = useRef();
    const secondModalRef = useRef();

    const setVariables = (teacher, time) => {
        setTeacherId(teacherId => teacher);
        setLessonTime(lessonTime => time);
    }

    const resetVariables = () => {
        setTeacherId(teacherId => 0);
        setLessonTime(lessonTime => '');
    }

    const teacherTimeChange = async (teacherId, lessonTime, termId, member) => {
        try {
            const result = await UpdateTeacherTimeServiceV2(
                teacherId,
                lessonTime,
                termId,
                member.MemberId,
                member.MemberId,
                token
            );
            if (result.statusCode !== 200) {
                if (result.message) {
                    analytics.useAnalytics('Error_Occured', { error: `${result.message}` });

                    if (result.message === ServiceErrorMessages.ESD_TWO_HOURS_ERROR)
                        setModalText(strings.support.teacher_time_change.esd_two_hour_error);
                    else if (result.message === ServiceErrorMessages.ESD_TIME_FRAME_ERROR)
                        setModalText(strings.support.teacher_time_change.esd_time_frame_error);
                    else
                        setModalText(strings.general_information_messages.an_error_occured);
                }
                else {
                    analytics.useAnalytics('Error_Occured', {
                        error: `An unexpected error occurred.`,
                    });

                    setModalText(strings.general_information_messages.an_error_occured);
                }
                setError(error => true);
                return;
            } else {
                tokenHook.generateToken(member.MemberId);
                setError(error => false);
                analytics.useAnalytics('Egitmen_Saat_Degisikligi_Approved');
                setModalText(strings.support.teacher_time_change.teacher_change_success);
            }
            resetVariables();
        } catch (error) {
            setError(error => true);
            setModalText(strings.general_information_messages.an_error_occured);
        }
    };

    return (
        <>

            <div className="transparent-section mt-5 text-sm text-center">
                {showTitle &&
                    <h5 className="text-secondary mb-3">{strings.support.teacher_time_change_title}</h5>
                }
                <p className="font-bold">{strings.support.teacher_time_change.teacher_change_information}</p>
                <hr />
                <p>
                    {strings.support.teacher_time_change.process} <strong className="text-base">{termInfo}</strong> {strings.support.teacher_time_change.process_continue}
                </p>
                <div className="mt-5">
                    <div className="font-bold flex justify-between">
                        <p className="w-2/4 text-start">{strings.support.teacher_list.teacher_name}</p>
                        <p className="w-1/4 text-start">{strings.support.teacher_list.lesson_time}</p>
                        <p className="w-1/4"> </p>
                    </div>
                    {loadingTeacher && (
                        <div className="my-5">
                            <Loading />
                        </div>
                    )}

                    {!loadingTeacher && (
                        <>
                            {data.length === 0 && (
                                <div>
                                    <hr className="my-2" />
                                    <div className="flex justify-center">
                                        <p className="my-5">{strings.teachers.filter.avaliable_time_not_found}</p>
                                    </div>
                                </div>
                            )}

                            {data.length > 0 && data.map((item, index) => (
                                <div key={index}>
                                    <hr className="my-2" />
                                    <div className="flex justify-between">
                                        <p className="w-2/4 text-start">{item.teacherName}</p>
                                        <p className="w-1/4 text-start">{addTimeZone(item?.lessonTime?.slice(0, 5), timeZone)}</p>
                                        <p className="w-1/4">
                                            <Button
                                                classnames="button primary-button-outline max-w-max px-2 h-[25px] text-sm mt-0"
                                                action={() => {
                                                    analytics.useAnalytics('Egitmen_Saat_Degisikligi_Sec');
                                                    setVariables(item.teacherId, item.lessonTime);
                                                    setModalText(modalText => `${item.teacherName} - ${addTimeZone(item?.lessonTime?.slice(0, 5), timeZone)}`);
                                                    openModal(modalOverlayRef, modalRef);
                                                }}
                                                text={strings.support.teacher_list.choose}
                                            />
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>

            {/* Modal */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalTwoButtons
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.modal.transaction_information}
                message={strings.support.teacher_time_change.lesson_time_change_dialog
                    .replace(/#lesson#/g, modalText)}
                buttonText1={strings.modal.approve}
                buttonText2={strings.modal.dont_approve}
                buttonClick1={() => {
                    teacherTimeChange(teacherId, lessonTime, selectedTermId, member);
                    closeModal(modalOverlayRef, modalRef);
                    openModal(modalOverlayRef, secondModalRef);
                }}
                buttonClick2={() => {
                    resetVariables();
                    closeModal(modalOverlayRef, modalRef);
                }}
            />
            <ModalOneButton
                ref={secondModalRef}
                title={strings.modal.information_message}
                overlayRef={modalOverlayRef}
                message={modalText}
                buttonText={strings.modal.okey}
                buttonClick={() => {
                    error
                        ?
                        closeModal(modalOverlayRef, secondModalRef)
                        :
                        navigate(url("dashboard"));
                    setError(error => false);
                }}
                closeClick={() => {
                    error
                        ?
                        closeModal(modalOverlayRef, secondModalRef)
                        :
                        navigate(url("dashboard"));
                    setError(error => false);
                }}
            />

        </>
    )
}

export default ChangeTeacherTimeList