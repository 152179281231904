import React, { useState, useEffect } from "react"
import { Streak } from "../../../../assets/svg";
import { useLocalization } from "../../../../hooks/useLocalization";
import useMemberStreak from "../../../../hooks/useMemberStreak";
import ShareStreak from "./ShareStreak";
import useAnalytics from "../../../../hooks/useAnalytics";
import { EventLogs } from "../../../../components/Constants";
import { useNavigate } from "react-router-dom";
import useMemberUsername from "../../../../hooks/useMemberUsername";
import { url } from "../../../../routes/utility";
import classNames from "classnames";

const StreakDetail = ({ memberId, modalStatus = false, source }) => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const navigate = useNavigate();
    const memberStreak = useMemberStreak(memberId);
    const memberUsername = useMemberUsername(memberId);

    const [openShareModal, setOpenShareModal] = useState(modalStatus);

    const handleClickShareButton = () => {
        setOpenShareModal(true);
        analytics.useAnalytics(EventLogs.STREAK_SHARED_BUTTON_CLICKED, undefined, false, true);
    }

    useEffect(() => {
        if (modalStatus && !openShareModal) {
            setOpenShareModal(true);
            navigate(`/${source}`);
        }
    }, [openShareModal])

    return (
        <>
            {!modalStatus && (
                <div className="mt-5">
                    <div className="flex items-center justify-center gap-2">
                        <img
                            src={Streak ?? ""}
                            width="60"
                            height="60"
                            alt="streak"
                        />
                        <div className="flex flex-col">
                            <p className="font-bold text-[32px]">{memberStreak}</p>
                            <p className="text-[16px] -mt-1">
                                {strings.profile.statistics.weekly_streak}
                            </p>
                        </div>
                    </div>
                    {+memberStreak > 0 &&
                        <button
                           type="button"
                           className="button primary-button max-w-[324px] mx-auto my-5"
                           onClick={handleClickShareButton}
                        >
                           {strings.video_practice.word_card.share}
                        </button>
                    }
                    <button
                        type="button"
                        className={classNames("button text-button font-normal mb-4", {
                            "-mt-3": +memberStreak > 0,
                            "mt-4": +memberStreak === 0
                        })}
                        onClick={() => navigate(url("profile.profilesub", { username: memberUsername }))}
                    >
                        {strings.profile.go_to_profile}
                    </button>
                    <p className="text-center">
                        {strings.profile.awards.award_description}
                    </p>
                </div>
            )}

            {openShareModal &&
                <ShareStreak
                    setOpenShareModal={setOpenShareModal}
                    streak={memberStreak}
                />
            }
        </>
    )
}

export default StreakDetail