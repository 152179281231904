import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { MentorCard } from '../../assets/svg';
import { Button, closeModal, decodeToken, Loading, ModalOneButton, ModalOverlay, openModal } from '../../components';
import { EventLogs } from '../../components/Constants';
import {getApplicationDirection, maskPhoneNumber} from '../../components/Utility';
import useAnalytics from '../../hooks/useAnalytics';
import { useLocalization } from '../../hooks/useLocalization';
import { GetMentorInformation } from '../../services/Dashboard/DashboardService';
import classNames from "classnames";

const MentorDetail = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const language = useSelector(state => state.localizationStore.language);

    const modalOverlayRef = useRef();
    const modalRef = useRef();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    const token = useSelector(state => state.memberStore.token);
    const member = token && decodeToken(token);
    const memberId = member?.MemberId;
    const isMentorDefault = !data.fullName || data.fullName.includes("Varsayılan")
    const whatsappApiUrl = !isMentorDefault ? process.env.REACT_APP_WHATSAPP_API_URL + `?phone=${data.phoneNumber}&text=${strings.mentor.hello},` : false

    const getMentorInformation = async (memberId) => {
        const result = await GetMentorInformation(memberId);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setData(data => resultContent)
        } else {
            openModal(modalOverlayRef, modalRef);
        }
        setLoading(loading => false);
    }

    useEffect(() => {
        if (memberId) {
            getMentorInformation(memberId);
        }
    }, [memberId])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }, [])

    return (
        <div className="main-content">
            <div className="left-page">
                {loading && (
                    <Loading />
                )}
                {!loading && (
                    <>
                        <div className="transparent-section mt-4">
                            <h5 className="text-secondary">{strings.mentor.title}</h5>
                            <div className="w-[100px] sm:w-[110px] h-[100px] sm:h-[110px] bg-box border border-onboarding-border rounded-full mx-auto flex items-center justify-center mt-4">
                                <img src={MentorCard} alt="" />
                            </div>
                            <div className='mt-4 text-start flex flex-col gap-3'>
                                <div className='flex flex-col gap-1'>
                                    <p className='font-bold text-primary'>{strings.mentor.mentor_informations}</p>
                                    <p>
                                        <span className='font-bold'>{strings.mentor.name} : </span>
                                        {isMentorDefault ? strings.mentor.default_mentor : data.fullName}
                                    </p>
                                    <p>
                                        <span className='font-bold'>{strings.mentor.phone} : </span>
                                        {isMentorDefault ? "-" : <a className="text-primary underline" href={`tel:${data.phoneNumber}`}>{maskPhoneNumber(data.phoneNumber)}</a>}
                                    </p>
                                    <p>
                                        <span className='font-bold'>{strings.mentor.mail} : </span>
                                        {isMentorDefault ? "-" : <a className="text-primary underline" href={`mailto:${data.email}`}>{data.email}</a>}
                                    </p>
                                    <p>
                                        <span className='font-bold'>{strings.mentor.working_hour} : </span>
                                        <span>{strings.mentor.weekday} 09:00 - 18:30</span>
                                    </p>
                                </div>
                                <div className='flex flex-col gap-1'>
                                    <p className='font-bold text-primary'>{strings.mentor.what_is_mentor}</p>
                                    <ul className={classNames('flex flex-col gap-1 list-disc', {
                                        "ml-5": !getApplicationDirection(language),
                                        "mr-5": getApplicationDirection(language)
                                    })}>
                                        <li>{strings.mentor.mentor_content1}</li>
                                        <li>{strings.mentor.mentor_content2}</li>
                                        <li>{strings.mentor.mentor_content3}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {!isMentorDefault && (
                            <div className="btn-outer-div my-5">
                                <Button
                                    classnames="button primary-button"
                                    action={() => {
                                        analytics.useAnalytics(EventLogs.MENTOR_WHATSAPP_MESSAGE_CLICK)
                                        window.open(whatsappApiUrl)
                                    }}
                                    text={strings.mentor.write_whatsapp}
                                    targetBlank={true}
                                />
                            </div>
                        )}
                    </>
                )}
                <ModalOverlay ref={modalOverlayRef} />
                <ModalOneButton
                    ref={modalRef}
                    overlayRef={modalOverlayRef}
                    title={strings.auth.information_messages.modal_title}
                    message={strings.general_information_messages.an_error_occured}
                    buttonText={strings.auth.form.okay_button}
                    buttonClick={() => {
                        closeModal(modalOverlayRef, modalRef);
                    }}
                />
            </div>
        </div>
    )
}

export default MentorDetail