import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { useSelector } from 'react-redux';
import { LogService } from "../services/Log/LogService";
import { useContext } from "react";
import { FirebaseContext } from "../store/FirebaseProvider";
import { decodeToken } from "../components/Utility";

const useAnalytics = () => {

    const firebase = useContext(FirebaseContext).firebase;
    
    const analytics = getAnalytics(firebase);

    const token = useSelector(state => state.memberStore.token);
    const member = token && decodeToken(token);
    const memberId = member?.MemberId;
    
    const termId = useSelector(state => state.termStore.termId);

    const useAnalytics = (event, param, sendAnalytics = true, sendToBackend = true) => {

        if(sendAnalytics)
            logEvent(analytics, event, param);

        if (event !== "screen_view") {

            if(sendAnalytics)
                window.customDataLayer.push({ event: event });

            if(sendToBackend)
                LogService(event, memberId, termId)
                    .then(e => { })
                    .catch(error => { });
        }
    };

    // key value ("user_type", "student")
    const useCustomAnalytics = (key, value, isObject = false) => {
        const property = {};
        property[key] = value;
        setUserProperties(analytics, isObject ? value : property);
    };
    return { useAnalytics, useCustomAnalytics };
}

export default useAnalytics