import {Outlet, useLocation, useParams} from "react-router-dom";
import {ExerciseStep} from "../Exercises/components";
import {VideoPracticeSteps} from "../../components/Constants";
import React, {useEffect, useState} from "react";
import {getApplicationDirection} from "../../components/Utility";
import {useSelector} from "react-redux";

const VideoFlowLayout = () => {

    const location = useLocation();
    const { videoId} = useParams();
    const language = useSelector(state => state.localizationStore.language);

    const [currentStep, setCurrentStep] = useState(-1);

    const getStepByPathName = (path) => {
        if (path.includes("/video/")) {
            return 1;
        } else if (path.includes("/word/")) {
            return 2;
        } else if (path.includes("/dialog/")) {
            return 3;
        } else if (path.includes("/grammar/")) {
            return 4;
        } else if (path.includes("/ai")) {
            return 5;
        }
    }

    useEffect(() => {
        setCurrentStep(getStepByPathName(location.pathname))
    }, [location.pathname]);

    return (
        <div className={`main-content ${getApplicationDirection(language) ? 'md:ml-7' : 'md:mr-7'}`}>

            <ExerciseStep
                data={VideoPracticeSteps}
                step={currentStep}
                routingId={videoId}
            />

            <Outlet/>

            <div className="left-margin-bottom"></div>
        </div>
    )
}

export default VideoFlowLayout;