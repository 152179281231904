import React from 'react'
import { Error_404 } from '../../assets/svg';
import { Menu, SideBar } from '../../components';
import { useLocalization } from '../../hooks/useLocalization';
import { useSelector } from 'react-redux';
import { getApplicationDirection } from '../../components/Utility';

const Error404Layout = () => {
    const strings = useLocalization();
    const language = useSelector(state => state.localizationStore.language); 

    return (
        <>
            <Menu title={strings.error404.title} />

            <section className="container">

                <div className={`main-content ${getApplicationDirection(language) ? 'md:ml-7' : 'md:mr-7'}`}>

                    <div className="mx-auto text-center mt-7 text-sm px-4">
                        <img
                            className="inline-block"
                            src={Error_404}
                            alt=""
                            width="256"
                            height="256"
                        />
                        <p className="font-bold mt-7 text-base">{strings.general_information_messages.error404_title}</p>
                        <p className="mt-5">{strings.general_information_messages.error404_content}</p>
                    </div>

                    <div className="left-margin-bottom"></div>
                </div>

                <SideBar />

            </section>
        </>
    )
}

export default Error404Layout