import {Empty} from "../../../../assets/svg";
import {getApplicationDirection} from "../../../../components/Utility";
import {useSelector} from "react-redux";

const AIButton = (props) => {

    const { icon, title, action, isVisible } = props;
    const language = useSelector(state => state.localizationStore.language);

    return (
        isVisible && (
            <div
                 className={`onboarding-button flex items-center cursor-pointer ${getApplicationDirection(language) ? 'pr-[70px] pl-7' : 'pl-[70px] pr-7'}`}
                 onClick={action}
            >
                <div className={`onboarding-button-left ${getApplicationDirection(language) ? 'border-l' : 'border-r'}`}>
                    <img src={icon || Empty} alt="" width="32" height="32" />
                </div>
                <p className="text-start">{title}</p>
            </div>
        )
    );
};

export default AIButton;
