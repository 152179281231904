import {Link} from "react-router-dom";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {getApplicationDirection} from "./Utility";
import { Check } from "../assets/svg";

const HorizontalMenuButton = (props) => {

    const {
        showLink = false,
        showOnclick = true,
        onclick,
        icon,
        title,
        link = "",
        classnames = "",
        isSelected = false
    } = props;

    const language = useSelector(state => state.localizationStore.language);

    return (
        <>
            {showLink &&
                <Link
                    to={link}
                    className={classNames("", {
                        [classnames] : [!!classnames]
                    })}
                >
                    <div
                        className={classNames("onboarding-button flex items-center", {
                            "pr-[70px] pl-7" : getApplicationDirection(language),
                            "pl-[70px] pr-7" : !getApplicationDirection(language)
                        })}
                    >
                        <div
                            className={classNames("onboarding-button-left", {
                                "border-l" : getApplicationDirection(language),
                                "border-r" : !getApplicationDirection(language),
                            })}
                        >
                            <img src={icon} alt="" width="32" height="32" />
                        </div>
                        <p className="text-start">{title}</p>
                    </div>
                </Link>
            }
            {showOnclick &&
                <div
                    onClick={onclick}
                    className={classNames("onboarding-button flex items-center cursor-pointer", {
                        [classnames] : [!!classnames],
                        "pr-[70px] pl-7" : getApplicationDirection(language),
                        "pl-[70px] pr-7" : !getApplicationDirection(language)
                    })}
                >
                    {isSelected && 
                        <div
                            className={classNames("onboarding-check flex", {
                                "ml-[5px] md:ml-[20px]" : getApplicationDirection(language),
                                "mr-[5px] md:mr-[20px]" : !getApplicationDirection(language),
                            })}
                        >
                            <img src={Check} alt="check" width="16" height="16"/>
                        </div>
                    }
                    <div
                        className={classNames("onboarding-button-left", {
                            "border-l" : getApplicationDirection(language),
                            "border-r" : !getApplicationDirection(language),
                        })}
                    >
                        <img src={icon} alt="" width="32" height="32" />
                    </div>
                    <p className="text-start">{title}</p>
                </div>
            }
        </>
    )
}

export default HorizontalMenuButton;