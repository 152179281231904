import React, {useContext} from 'react'
import {Outlet} from "react-router-dom";
import {Menu, SideBar} from '../../components';
import {useLocalization} from '../../hooks/useLocalization';
import CampaignBar from "./components/CampaignBar";
import {FirebaseContext} from "../../store/FirebaseProvider";
import useAuth from '../../hooks/useAuth';
import { MemberStatus } from '../../components/Constants';
import YearInReviewCard from './components/YearInReviewCard';

const DashboardLayout = () => {

  const strings = useLocalization();
  const campaignStickyBar = useContext(FirebaseContext)?.campaignStickyBar;
  const { member } = useAuth();

  return (
    <>
      <Menu title={strings.dashboard.title} />

      <section className="container">

        <Outlet />

        {member?.MemberStatus === MemberStatus.ACTIVE && <YearInReviewCard memberId={member?.MemberId} />}

        <SideBar />
        
      </section>

      {campaignStickyBar && <CampaignBar campaignStickyBar={campaignStickyBar} />}
    </>
  )
}

export default DashboardLayout