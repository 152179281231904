import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import '../../assets/css/logo-text-outline.css';
import { Logo } from "../../assets/svg";
import { ChangeLanguage, TopStickBar } from '../../components';
import { getApplicationDirection, getCountryNameByCountryCode } from '../../components/Utility';
import { useLocalization } from '../../hooks/useLocalization';
import { getQueryParam } from '../../utils/Static';
import { GetFamilyManagerNameByFamilyCode } from '../../services/LearnTogether/LearnTogetherService';
import { GetMemberNameByReferenceCode } from '../../services/Auth/AuthService';

const AuthLayout = () => {

    const strings = useLocalization();
    const language = useSelector(state => state.localizationStore.language);
    const userCountryCode = navigator.language.split('-')[1];

    const location = useLocation();

    // familyCode varsa, değerini döndürüyoruz, yoksa null döndürüyoruz
    const familyCode = getQueryParam('familyCode', location);

    // ref varsa, değerini döndürüyoruz, yoksa null döndürüyoruz
    const referenceCode = getQueryParam('ref', location);

    const [inviteName, setInviteName] = useState("");
    const  [referencerName, setReferencerName] = useState("");

    const getFamilyManagerNameByFamilyCode = async (familyCode) => {
        
        const result = await GetFamilyManagerNameByFamilyCode(familyCode);
        if (result.status === 200) {
            setInviteName(inviteName =>result.content)
        }
    }

    const getMemberNameByReferenceCode = async (referenceCode) => {
        
        const result = await GetMemberNameByReferenceCode(referenceCode);
        if (result.status === 200) {
            setReferencerName(inviteName =>result.content)
        }
    }

    useEffect(() => {
        familyCode && getFamilyManagerNameByFamilyCode(familyCode)
    }, [familyCode])

    useEffect(() => {
        referenceCode && getMemberNameByReferenceCode(referenceCode)
    }, [referenceCode])

    return (
        <section className={`min-h-full flex relative max-md:overflow-hidden ${getApplicationDirection(language) ? 'auth-rtl' : 'auth-ltr'}`}>
            {(location.pathname.includes("/register") || location.pathname.includes("/login")) && familyCode && inviteName &&
                <TopStickBar>
                    <div className="px-3 pr-7 sm:px-7">
                        <div className='flex gap-2 items-center h-full'>
                            <span className="material-symbols-outlined text-white">notifications</span>
                            <h1 className="text-white text-[11px] sm:text-[16px]" dangerouslySetInnerHTML={{ __html: strings.learn_together.invite_message.replace("#NAME#", inviteName) }} />
                        </div>
                    </div>
                </TopStickBar>
            }
            {(location.pathname.includes("/register") || location.pathname.includes("/login")) && referenceCode && referencerName &&
                <TopStickBar>
                    <div className="px-3 pr-7 sm:px-7">
                        <div className='flex gap-2 items-center h-full'>
                            <span className="material-symbols-outlined text-white">notifications</span>
                            <h1 className="text-white text-[11px] sm:text-[16px]" dangerouslySetInnerHTML={{ __html: strings.reference.reference_message.replace("#NAME#", referencerName) }} />
                        </div>
                    </div>
                </TopStickBar>
            }
            {/* Left */}
            <div className="left-login">
                <div className={`absolute top-4 ${getApplicationDirection(language) ? 'left-0 md:left-1/2' : 'right-0 md:right-1/2'}`}>
                    <ChangeLanguage theme="dark" />
                </div>
                <div className="flex flex-col items-center">
                    <img src={Logo} className="w-[40%] mt-5 md:mt-0" />
                    <p className="font-bold text-2xl my-2">{strings.brand_name}</p>
                    <p className="text-sm w-[240px] text-center mb-6" dangerouslySetInnerHTML={{ __html: getCountryNameByCountryCode(userCountryCode, language) !== '' ? strings.auth.layout.logo_subtitle.replace(/#country#/g, getCountryNameByCountryCode(userCountryCode, language)) : strings.auth.layout.logo_subtitle_general }} />
                </div>
                <div className="btn-outer-div mt-10 md:mt-8 mb-2">
                    <Outlet />
                </div>
            </div>

            {/* <!-- Right --> */}
            <div className="right-login">
                <p className="text-login-right" dangerouslySetInnerHTML={{ __html: getCountryNameByCountryCode(userCountryCode, language) !== '' ? strings.auth.layout.right_subtitle_1.replace(/#country#/g, getCountryNameByCountryCode(userCountryCode, language)) : strings.auth.layout.right_subtitle_1_general }} />
                <p className="text-login-right mt-32">{strings.auth.layout.right_subtitle_2}</p>
            </div>

            {/* <!-- For mobile view --> */}
            <div id="white_section" className="mobile-white"> </div>
            <div id="shadow_section" className="mobile-shadow"> </div>

        </section>
    )
}

export default AuthLayout