import React, {useEffect, useRef, useState} from "react";
import {Loading, ModalFullScreen, ModalOneButton, ModalOverlay} from "../../../../components";
import {
    Add,
    Edit,
    MicrophoneCircle,
    Reading,
    SpeakerWhite
} from "../../../../assets/svg";
import {EventLogs, LanguageTitleType, ServiceErrorMessages, WordStoreType} from "../../../../components/Constants";
import {AddMemberWordStore, GetWordDetails} from "../../../../services/VideoPractice/VideoPracticeService";
import {
    closeModal,
    getApplicationDirection,
    openModal,
    sendEventByDefaultParameters
} from "../../../../components/Utility";
import useSpeaker from "../../../../hooks/useSpeaker";
import {useLocalization} from "../../../../hooks/useLocalization";
import useAuth from "../../../../hooks/useAuth";
import classNames from "classnames";
import {OriginModal, ShareModal, WritingExerciseModal} from "../index";
import PronunciationModal from "./PronunciationModal";
import useAnalytics from "../../../../hooks/useAnalytics";
import {useSelector} from "react-redux";
import useTts from "../../../../hooks/useTts";

const WordModal = (props) => {

    const {
        wordId = "",
        handleClose = () => {},
        source,
        contentName
    } = props;

    const strings = useLocalization();
    const { member } = useAuth()
    const {speakSentence, SpeakerComponent} = useTts();
    const analytics = useAnalytics();
    const language = useSelector(state => state.localizationStore.language);
    const videoInfo = JSON.parse(localStorage.getItem("videoInfo")) || false;

    const modalOverlayRef = useRef();
    const modalRef = useRef();

    // Sayfa yüklenmesini kontrol eden state
    const [loading, setLoading] = useState(false);
    // Kelime bilgilerinin tutulduğu state
    const [data, setData] = useState({});
    // Seçili image url'i tutan fonksiyon
    const [selectedImageUrl, setSelectedImageUrl] = useState("");
    // Farklı kelime anlamı fotoğraflarının değerlerini tutan state
    const [differentImageIndexes, setDifferentImageIndexes] = useState([]);
    // Kelime haznesinden gelen hata mesajlarını kullanıcıya gösteren state
    const [modalMessage, setModalMessage] = useState("");

    // Alt butonlardaki pencereleri kontrol eden stateler
    const [openPronunciationModal, setOpenPronunciationModal] = useState(false);
    const [openOriginModal, setOpenOriginModal] = useState(false);
    const [openWritingExerciseModal, setOpenWritingExerciseModal] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false);

    // base event fonksiyonu
    const sendEvent = (event) => {
        sendEventByDefaultParameters(
            event,
            analytics,
            videoInfo?.videoTags,
            {
                video_name: contentName,
                word: data?.word,
                is_selected: data?.isInMemberWordStore
            },
            { seviye: '', ilgi_alanlari: '' }
        );
    }

    // Modal'ın kapanmasını sağlayan fonskiyon
    const handleCloseClick = () => {
        document.body.style.overflow = 'auto';
        handleClose();

        if(source === "video")
            sendEvent(EventLogs.VIDEO_EXERCISE_WORD_CART_CLOSED); //LOGGED
    }

    // Kelime seslendirmesini yapan fonksiyon
    const handleSpeakWord = () => {
        speakSentence(data.word, process.env.REACT_APP_LANG_EN_CODE, wordId);

        if(source === "video")
            sendEvent(EventLogs.VIDEO_EXERCISE_WORD_CART_LISTEN); //LOGGED
    }

    // Paylaş butonu işlemlerini yapan fonksiyon
    const handleShareButton = () => {
        setOpenShareModal(true);

        if(source === "video")
            sendEvent(EventLogs.VIDEO_EXERCISE_WORD_CART_SHARE); //LOGGED
    }

    // Telaffuz butonu işlemlerini yapan fonksiyon
    const handleOpenPronunciationModal = () => {
        setOpenPronunciationModal(true);

        if(source === "video")
            sendEvent(EventLogs.VIDEO_EXERCISE_WORD_CART_PRACTICE_CLICK); //LOGGED
    }

    // Kelime Kökeni butonu işlemlerini yapan fonksiyon
    const handleOpenOriginModal = () => {
        setOpenOriginModal(true);

        if(source === "video")
            sendEvent(EventLogs.VIDEO_EXERCISE_WORD_CART_DETAILED_INFO_CLICK); //LOGGED
    }

    // Kelime Kökeni butonu işlemlerini yapan fonksiyon
    const handleOpenWritingExerciseModal = () => {
        setOpenWritingExerciseModal(true);

        if(source === "video")
            sendEvent(EventLogs.VIDEO_EXERCISE_WORD_CARD_WRITE_CLICK); //LOGGED
    }

    // Kelime haznesine ekleme yapan servis
    const addMemberWordStore = () => {
        const model = {
            memberId: member?.MemberId,
            wordId: wordId,
            wordStoreType: WordStoreType.VIDEO
        };

        if(source === "video")
            sendEvent(EventLogs.VIDEO_EXERCISE_WORD_CART_ADD_TO_LIST); //LOGGED

        AddMemberWordStore(model)
            .then(result => {
                if(result.status === 200) {
                    setModalMessage(strings.video_practice.word_card.add_member_word_store_success_message
                        .replace("#word#", data?.word)
                    );
                } else {
                    if(result.content === ServiceErrorMessages.MEMBER_WORD_ALREADY_EXITS) {
                        setModalMessage(strings.video_practice.word_card.add_member_word_store_error_message
                            .replace("#word#", data?.word)
                        );
                    } else {
                        setModalMessage(strings.onboarding.information_messages.an_error_occured);
                    }
                }

                openModal(modalOverlayRef, modalRef);
            })
            .catch();
    }

    // Servisten kelime detayları getiriliyor
    useEffect(() => {
        if(wordId.length > 0){
            setLoading(true);

            GetWordDetails(wordId, member?.MemberId, LanguageTitleType.ARABIC)
                .then(result => {
                    if(result.status === 200)
                        setData(JSON.parse(result.content));

                    setLoading(false);
                })
                .catch()
        }
    }, [wordId]);

    // Kelime anlamları array'i içerisinden farklı image url içeren indexlerin değerlerini bulan fonksiyon
    const findIndexesByDifferentImageUrl = (data) =>  {
        let firstIndex = -1;

        for (let i = 0; i < data.length; i++) {
            if (firstIndex === -1) {
                firstIndex = i;
            } else if (data[i].imageUrl !== data[firstIndex].imageUrl) {
                return [firstIndex, i];
            }
        }

        return [];
    }

    // Default seçili olacak ImageUrl belirleniyor
    useEffect(() => {
        if(Object.keys(data).length > 0) {
             setSelectedImageUrl(data?.meanings[0]?.imageUrl);
             const differentIndexes = findIndexesByDifferentImageUrl(data?.meanings);
             setDifferentImageIndexes(differentIndexes);
        }
    }, [data]);

    return (
        <>
            <SpeakerComponent />
            <ModalFullScreen
                handleCloseClick={handleCloseClick}
                children={
                    <>
                        {loading && <Loading classnames="text-white" />}
                        {(Object.keys(data).length > 0 && !loading) &&
                            <div id="word-modal-content" className="flex flex-col items-center py-4 px-5">
                                <div className="flex flex-col max-w-[500px] relative ">
                                    <div className="flex flex-col gap-0.5">
                                        <img
                                            src={selectedImageUrl}
                                            alt="word-modal"
                                            className="mb-2 mt-12 md:mt-0 w-full max-w-max md:max-w-max mx-auto aspect-video object-cover rounded-md"
                                        />
                                        <button
                                            type="button"
                                            className={classNames("mb-2 group relative", {
                                                "mr-auto": getApplicationDirection(language),
                                                "ml-auto": !getApplicationDirection(language),
                                            })}
                                            onClick={handleShareButton}
                                        >
                                            <span className="material-symbols-outlined text-white cursor-pointer">share</span>
                                            <div className="tooltip-top -top-[38px]">{strings.video_practice.word_card.share}</div>
                                        </button>
                                    </div>

                                    <div
                                        className={classNames("flex flex-col gap-3 h-[calc(100vh-430px)] overflow-y-auto", {
                                            "pl-2" : getApplicationDirection(language),
                                            "pr-2" : !getApplicationDirection(language),
                                        })}
                                    >
                                        <div className="flex text-white items-start justify-start">
                                            <button type="button" onClick={handleSpeakWord} className="flex gap-1">
                                                <p className="hover:text-white/70 font-bold">{data.word}</p>
                                                <div className="flex hover:text-white/70">
                                                    <img src={SpeakerWhite} width={20} height={20} alt="speaker"/>
                                                    <span className="mr-1.5">:</span>
                                                </div>
                                            </button>
                                            {data?.meanings.length > 0 &&
                                                <div className="flex gap-0.5">
                                                    {data?.meanings.map((item, index) =>  {
                                                        const isClickable = differentImageIndexes.includes(index);

                                                        return (
                                                            <span
                                                                key={index}
                                                                type="button"
                                                                className={classNames("", {
                                                                    "underline font-bold text-primary cursor-pointer" : isClickable
                                                                })}
                                                                onClick={() => {
                                                                    isClickable && setSelectedImageUrl(item.imageUrl);
                                                                }}
                                                            >
                                                                {item.title}
                                                                {index === 0 || index === data?.meanings?.length - 1
                                                                    ? ""
                                                                    : ","
                                                                }
                                                            </span>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </div>

                                        <div className="flex gap-1.5 text-white items-start justify-start">
                                            <p className="font-bold">{strings.video_practice.word_card.phonetics}:</p>
                                            {data?.phonetics?.length > 0 &&
                                                <div className="flex flex-col">
                                                    {data.phonetics.map((item, index) =>  (
                                                        <span key={index}>
                                                            {item}
                                                            {index === 0 || index === data.phonetics.length - 1
                                                                ? ""
                                                                : ","
                                                            }
                                                        </span>
                                                    ))}
                                                </div>
                                            }
                                        </div>


                                        <div className="flex flex-col text-white items-start justify-start">
                                            <p className="font-bold">{strings.video_practice.word_card.synonyms}:</p>
                                            <div className="flex flex-col">
                                                {data?.differentMeanings.map((meaning, index) => (
                                                    <div key={index} className="flex gap-1.5">
                                                        <span>{meaning}</span>
                                                        <button type="button" onClick={() => speakSentence(meaning)}>
                                                            <img src={SpeakerWhite} width={20} height={20} alt="speaker"/>
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="flex flex-col text-white items-start justify-start">
                                            <p className="font-bold">{strings.video_practice.word_card.english_description}:</p>
                                            <p>{data?.description}</p>
                                        </div>

                                        {data?.wordSentences?.map((item, index) => (
                                            <div key={index} className="flex flex-col text-white items-start justify-start gap-4">
                                                <div className="flex flex-col">
                                                    <p className="font-bold">{strings.video_practice.word_card.sample_sentence}:</p>
                                                    <p dir="ltr">{item.sentence}</p>
                                                </div>

                                                <div className="flex flex-col">
                                                    <p className="font-bold">{strings.video_practice.word_card.sample_sentence_translation}:</p>
                                                    <p>{item.sentenceTranslation}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="text-white flex gap-5 mt-4">
                                        {!data?.isInMemberWordStore &&
                                            <div className="flex flex-col items-center gap-0.5">
                                                <button type="button" onClick={addMemberWordStore}>
                                                    <img src={Add} width={24} height={24} alt="add"/>
                                                </button>
                                                <p className="text-[12px] text-center max-w-[50px] leading-[14px]">
                                                    {strings.video_practice.word_card.add_learn_word_list}
                                                </p>
                                            </div>
                                        }
                                        <div className="flex flex-col items-center gap-0.5">
                                            <button type="button" onClick={handleOpenPronunciationModal}>
                                                <img src={MicrophoneCircle} width={24} height={24} alt="microphone"/>
                                            </button>
                                            <p className="text-[12px] text-center max-w-[50px] leading-[14px]">
                                                {strings.video_practice.word_card.word_pronunciation}
                                            </p>
                                        </div>

                                        <div className="flex flex-col items-center gap-0.5">
                                            <button type="button" onClick={handleOpenOriginModal}>
                                                <img src={Reading} width={24} height={24} alt="reading"/>
                                            </button>
                                            <p className="text-[12px] text-center max-w-[50px] leading-[14px]">
                                                {strings.video_practice.word_card.word_origin}
                                            </p>
                                        </div>

                                        {data?.wordExerciseSentence?.length > 0 &&
                                            <div className="flex flex-col items-center gap-0.5">
                                                <button type="button" onClick={handleOpenWritingExerciseModal}>
                                                    <img src={Edit} width={24} height={24} alt="edit"/>
                                                </button>
                                                <p className="text-[12px] text-center max-w-[50px] leading-[14px]">
                                                    {strings.video_practice.word_card.writing_exercise}
                                                </p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
            />

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalOneButton
                overlayRef={modalOverlayRef}
                ref={modalRef}
                title={strings.auth.information_messages.modal_title}
                message={modalMessage}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => {
                    closeModal(modalOverlayRef, modalRef);
                }}
            />

            {openPronunciationModal &&
                <PronunciationModal
                    pronunciationInfo={{
                        pronunciationId: wordId,
                        content: data?.word,
                        phonetic: data?.phonetics[0],
                        source: 'word-card',
                        logParameters: {
                            video_name: contentName,
                            word: data?.word,
                            is_selected: data?.isInMemberWordStore
                        }
                    }}
                    setOpenPronunciationModal={setOpenPronunciationModal}
                />
            }

            {openOriginModal &&
                <OriginModal
                    wordInfo={{
                        wordId: wordId,
                        originDescription: data?.originDescription,
                        originDescriptionEnglish: data?.originDescriptionEnglish,
                        wordType: data?.meanings[0]?.wordTypeId,
                        logParameters: {
                            video_name: contentName,
                            word: data?.word,
                            is_selected: data?.isInMemberWordStore
                        }
                    }}
                    setOpenOriginModal={setOpenOriginModal}
                />
            }

            {openWritingExerciseModal &&
                <WritingExerciseModal
                    writingExerciseInfo={{
                        word: data?.word,
                        sentence: data?.wordExerciseSentence,
                        logParameters: {
                            video_name: contentName,
                            word: data?.word,
                            is_selected: data?.isInMemberWordStore
                        }
                    }}
                    setOpenWritingExerciseModal={setOpenWritingExerciseModal}
                />
            }

            {openShareModal &&
                <ShareModal
                    shareModalInfo={{
                        wordId: wordId,
                        wordType: data?.meanings?.[0].wordTypeId,
                        shareType: 'word',
                        logParameters: {
                            video_name: contentName,
                            word: data?.word,
                            is_selected: data?.isInMemberWordStore
                        }
                    }}
                    setOpenShareModal={setOpenShareModal}
                />
            }
        </>
    )
}

export default WordModal;