import { fetchApi } from "../index";

export const GetTeacherFilterService = async (model) => {
    const url = '/_services/teacher/v3/teacher/list_to_student/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetTeacherDetailsService = async (Id, token) => {
    const url = '/teacher/get_teacher_details/';

    const payload = {
        payload: {
            Id: Id
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const GetTeacherFreeTimeByTeacherId = async (model) => {
    const url = '/_services/member/v1/support/teacher_free_time_by_range/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const UpdateTeacherTime = async (model) => {
    const url = '/_services/member/v2/support/teacher_lesson_time_change/';

    const payload = {
        ...model
    }

    return await fetchApi(url, payload);
}

export const UpdateTeacherTimeServiceV2 = async (teacherId, lessonTime, termId, memberId, createUserId, token) => {
    const url = '/teacher/update_teacher_time_v2/';

    const payload = {
        payload: {
            teacherId: teacherId,
            lessonTime: lessonTime,
            termId: termId,
            memberId: memberId,
            createUserId: createUserId
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const AssignTermTeacher = async (memberId) => {

    const url = '/_services/member/v2/term/assign_term_teacher/';

    const payload = {
        memberId: memberId
    }

    return await fetchApi(url, payload);
}

export const AssignTermTeacherToSandy = async (memberId) => {
    const url = '/_services/member/v1/term/assign_term_teacher_to_sandy/';

    const payload = {
        memberId: memberId
    }

    return await fetchApi(url, payload);
}

export const GetAllTeachersWithoutSubstituteAndVirtual = async () => {
    const url = '/_services/teacher/v1/teacher/get_all_teachers_without_substitute_and_virtual/'

    const payload = {}

    return await fetchApi(url, payload);
}

export const GetAllHumanTeachers = async () => {
    const url = '/_services/teacher/v1/teacher/get_all_human_teachers/';

    const payload = {};

    return await fetchApi(url, payload);
}