export const Button = (props) => {
    return (
        <button type={props.type} className={props.classnames} onClick={props.action}>{props.text}</button>
    );
}

export const ToggleButton = ({ checked, onChange, id = "isActive" }) => {
    return (
        <div className="w-full md:w-[246px] flex items-center max-w-max">
            <input
                id={id}
                type="checkbox"
                className="hidden peer"
                checked={checked}
                onChange={onChange}
            />
            <label
                htmlFor={id}
                id="slider"
                className="relative mr-2 w-12 h-6 rounded-full bg-red peer-checked:bg-green cursor-pointer duration-500 before:switch-circle"
            ></label>
        </div>
    );
}