import { fetchApi } from '../index';

export const GetMemberContactPermissions = async (memberId) => {

    const url = '/permission/get_member_contact_permissions/';

    const payload = {
        payload: {
            memberId: memberId
        }
    };

    return await fetchApi(url, payload);
}

export const AddMemberContactPermission = async (model) => {

    const url = '/permission/add_member_contact_permission/';

    const payload = {
        payload: {
            ...model
        }
    };

    return await fetchApi(url, payload);
}

export const GetMemberGeneralPermissions = async (memberId) => {
    const url = '/_services/member/v1/member_general_permission/get_member_general_permissions/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const AddMemberGeneralPermission = async (model) => {
    const url = '/_services/member/v1/member_general_permission/add_member_general_permission/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const AddMemberNotificationPermission = async (model) => {
    const url = '/_services/member/v1/member_notification_permission/add_member_notification_permission/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}
