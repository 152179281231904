import {useEffect, useState} from "react";
import {GetTeacherWorkingTimesService} from "../services/Support/SupportService";
import {addTimeZone, getTimeZone} from "../components/Utility";
import {useLocalization} from "./useLocalization";

const useTeacherWorkingTimes = (includeStartAndEndTime) => {

    const strings = useLocalization();

    let firstSelectedStartTime = {id: -1, name: strings.teachers.filter.start_time};
    let firstSelectedEndTime = {id: -1, name: strings.teachers.filter.end_time}

    const timeZone = getTimeZone();

    const [times, setTimes] = useState([]);
    const [beginTimes, setBeginTimes] = useState([]);
    const [endTimes, setEndTimes] = useState([]);

    //Filtreleme yapılacak saat aralıkları servisten getiriliyor.
    const getWorkingTimes = async () => {
        const result = await GetTeacherWorkingTimesService();

        if (result.statusCode === 200) {
            let resultContent = JSON.parse(result.message);
            let timeList = [];

            resultContent?.map((item) => {
                timeList.push({ id: item, name: addTimeZone(item, timeZone) });
            });

            /*
                Başlangıç saatinden en son indexteki saat çıkarılıyor. Bu sayede,
                00.00 - 00.00 seçimi engellenmiş oluyor.
            */
            let tmpBeginTimes;
            let tmpEndTimes;

            if(includeStartAndEndTime) {
                tmpBeginTimes = [firstSelectedStartTime, ...timeList];
                tmpEndTimes = [firstSelectedEndTime, ...timeList];
            } else {
                tmpBeginTimes = [...timeList];
                tmpEndTimes = [...timeList];
            }

            tmpBeginTimes.pop();

            setTimes(times => resultContent);
            setBeginTimes(beginTimes => tmpBeginTimes);
            setEndTimes(endTimes => tmpEndTimes);
        }
    };

    useEffect(() => {
        getWorkingTimes();
    }, [])

    return { beginTimes, endTimes, times };
}

export default useTeacherWorkingTimes;