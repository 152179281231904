import { Accordion } from "../../../../../../components";
import { LessonResultAccordionType } from "../../../../../../components/Constants";
import useAccordionActivity from "../../../../../../hooks/useAccordionActivity";
import useAuth from "../../../../../../hooks/useAuth";
import { useLocalization } from "../../../../../../hooks/useLocalization";
import { getTimeZone, addTimeZone } from "../../../../../../components/Utility";

const LessonInfoNoAnswer = ({ data, lessonResultAccordionActivities, lessonResultId }) => {

    const strings = useLocalization();
    const { member } = useAuth();
    const timeZone = getTimeZone(member.Timezone);

    const { activities, addAccordionActivity } = useAccordionActivity(lessonResultId, lessonResultAccordionActivities);

    return (
        <Accordion
            title={strings.speaking_lesson.daily_lesson.lesson_information}
            disableOutsideClick={true}
            isScrollable={false}
            isDownArrowIcon={false}
            openCloseCheckboxStatus={
                activities
                    ?.find(l => LessonResultAccordionType.LESSON_INFO === l.lessonResultAccordionType)?.isOpened
                || false
            }
            action={() => addAccordionActivity(LessonResultAccordionType.LESSON_INFO)}
        >
            <div className="max-w-lg mt-4 pb-6 mx-auto">
                    <div className="flex flex-col sm:flex-row sm:justify-between mx-auto text-start">
                        <div className="text-1xs">
                        <div className="font-bold w-[225px] relative pb-2 text-sm">{strings.speaking_lesson.no_answer.comment_title}
                            <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-primary rounded"></div>
                        </div>
                        <p className="mt-1">{strings.speaking_lesson.no_answer.comment_content}</p>
                    </div>
                    <div className="text-1xs">
                        <div className="font-bold w-[225px] relative pb-2 text-sm mt-4 sm:mt-0">{strings.speaking_lesson.no_answer.call_log_title}
                            <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-primary rounded"></div>
                        </div>
                        <div className='max-h-[300px] overflow-auto mt-1'>
                            {data.map((item, index) => (
                                <p key={index} className="mt-1">{addTimeZone(item, timeZone)}</p>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Accordion>
    );
};

export default LessonInfoNoAnswer;