import React, {useEffect, useState} from "react";
import useAnalytics from "../../hooks/useAnalytics";
import {useLocalization} from "../../hooks/useLocalization";
import {TeacherCard, TeacherDetailSearch} from "./components";
import {Loading, NoRecordsFound, Pagination} from "../../components";
import {getApplicationDirection} from "../../components/Utility";
import {GetTeacherFilterService} from "../../services/Teacher/TeacherService";
import {useSelector} from "react-redux";
import useAuth from "../../hooks/useAuth";
import {MemberStatus} from "../../components/Constants";

const TeacherList = () => {

  const strings = useLocalization();
  const analytics = useAnalytics();
  const language = useSelector(state => state.localizationStore.language);
  const { member } = useAuth();

  const limit = 10;
  const localStorageFilterData = JSON.parse(localStorage.getItem("teacherFilter")) || false;
  const selectedTermId = useSelector(state => state.termStore.termId);

  const [teacherList, setTeacherList] = useState([]);
  const [start, setStart] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(200);
  const [currentRecord, setCurrentRecord] = useState(0);
  const [loading, setLoading] = useState(true);

  const [sendToEndpoint, setSendToEndpoint] = useState(false);
  const [detailSearch, setDetailSearch] = useState({
    startTime: localStorageFilterData?.startTime ? localStorageFilterData.startTime : -1,
    endTime: localStorageFilterData?.endTime ? localStorageFilterData.endTime : -1,
    instructors: localStorageFilterData?.instructors ? localStorageFilterData.instructors : []
  });

  const getTeacherList = async (detailSearch) => {
    setLoading(loading => true);

    if(!localStorage.getItem("getAll")) {
      localStorage.setItem("getAll", JSON.stringify(true));
    }
    let storageDetailSearch = JSON.parse(localStorage?.getItem("teacherFilter")) ?? detailSearch;
    let storageGetAll = JSON.parse(localStorage.getItem("getAll"));

    let model = {
      start: start,
      length: limit,
      startTime: storageDetailSearch.startTime === -1 ? null : storageDetailSearch.startTime + ":00",
      endTime: storageDetailSearch.endTime === -1 ? null : storageDetailSearch.endTime + ":00",
      instructors: storageDetailSearch.instructors,
      termId: (+member?.Status === MemberStatus.Active && !storageGetAll) ? selectedTermId : null
    }

    const result = await GetTeacherFilterService(model);
    let resultContent = JSON.parse(result.content);

    if (result.status === 200) {
      setTeacherList(resultContent.data);

      setCurrentRecord(currentRecord => resultContent.data.length);
      setTotalRecord(totalRecord => resultContent.recordsTotal);
      setSendToEndpoint(sendToEndpoint => false);
    } else {
      if (resultContent.length > 0)
        analytics.useAnalytics('Error_Occured', { error: `${resultContent}` });
      else {
        analytics.useAnalytics('Error_Occured', {
          error: `An unexpected error occurred.`,
        });
      }
    }

    setLoading(loading => false);
  };

  //select değişiklikleri bu alandan yönetiliyor
  const changeDetail = (values) => {
    setDetailSearch({ ...detailSearch, ...values });
  }

  //sayfa değiştikçe bilgileri yeniden çağırıyoruz
  useEffect(() => {
    if (totalRecord !== 0) {
      getTeacherList(detailSearch);
    }
  }, [start])

  //sendToEndpoint state'i değiştikçe servise gidiliyor. (Eğitmenleri listele butonu)
  useEffect(() => {
    if(sendToEndpoint) {
      getTeacherList(detailSearch);
    }
  }, [sendToEndpoint])

  /*
    Detail search değiştiğinde değişen veriler localStorage'a atılıyor.
    Bunun nedeni kullanıcının eğitmen detay sayfasına girip geri geldiğinde filtrenin korunmasının sağlanmasıdır.
  */
  useEffect(() => {
    if(
        (detailSearch.startTime !== -1 &&
            detailSearch.endTime !== -1) ||
        detailSearch.instructors.length !== 0
    ) {
      localStorage.setItem("teacherFilter", JSON.stringify(detailSearch));
    } else {
      localStorage.removeItem("teacherFilter");
    }
  }, [detailSearch.startTime, detailSearch.endTime, detailSearch.instructors.length]);

  /*
    Sayfa yenilendiğinde filtre temizleniyor.
  */
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      localStorage.removeItem("teacherFilter");
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  //sayfaya ilk girildiğinde servise gidiliyor
  useEffect(() => {

    const controller = new AbortController();
    getTeacherList(detailSearch);

    return () => {
      controller.abort();
    }
  }, []);

  return (
    <>
      <div className={`main-content ${getApplicationDirection(language) ? 'md:ml-7' : 'md:mr-7'}`}>
        <TeacherDetailSearch
            changeDetail={changeDetail}
            loading={loading}
            setSendToEndpoint={setSendToEndpoint}
        />
        <div className="left-page">
          {loading && <Loading />}

          {!loading && (
              <>
                <div className="mt-5 box-section px-[10px] xs:px-[10px] text-start text-sm">
                  <p className="mt-0 font-bold text-center max-w-lg mx-auto px-5">
                    {strings.teachers.teachers_list.page_title
                        .replace("#total#", totalRecord)
                        .replace("#currentCount#", currentRecord)
                    }
                  </p>
                </div>

                <div className="gap-3 text-center">
                  {teacherList.length > 0 && (
                      teacherList?.map((teacher, index) => {
                        let teacherInformation = teacher.university !== null ? teacher.university : '';
                        if (teacher.department !== null) teacherInformation = teacherInformation + " - " + teacher.department;
                        return (
                            <TeacherCard
                                key={index}
                                detailSearch={detailSearch}
                                buttonText={strings.teachers.teachers_list.detail_information}
                                information={teacherInformation}
                                name={teacher.teacherName.replace(" (Sanal Egitmen)", "")}
                                age={teacher.age}
                                ageContent={strings.teachers.teachers_list.age}
                                image={teacher.profilePictureUrl}
                                teacherId={teacher.teacherId}
                            />
                        )
                      })
                  )}
                  {teacherList.length === 0 && <NoRecordsFound/>}
                </div>
                <p className="text-center mt-5 -mb-4">
                  {strings.teachers.teachers_list.current_teacher_text
                      .replace("#total#", totalRecord)
                      .replace("#currentCount#", currentRecord)
                  }
                </p>
                <Pagination
                    totalCount={totalRecord}
                    limit={limit}
                    start={start}
                    setStart={setStart}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setLoading={setLoading}
                    loadScreen={true}
                />
              </>
          )}

          <div className="left-margin-bottom"></div>
        </div>
      </div>
    </>
  );
};

export default TeacherList;
