import useAiRouting from "../../hooks/useAiRouting";
import {AIKeys, EventLogs} from "../../components/Constants";
import React, {useEffect, useRef, useState} from "react";
import {useLocalization} from "../../hooks/useLocalization";
import {GetVideoDescription} from "../../services/VideoPractice/VideoPracticeService";
import {useNavigate, useParams} from "react-router-dom";
import {GetAiUseCaseById} from "../../services/AIUseCase/AIUseCaseService";
import {Button, Loading} from "../../components";
import {AIChat} from "../SpeakingLesson/components";
import {url} from "../../routes/utility";
import useAnalytics from "../../hooks/useAnalytics";
import {sendEventByDefaultParameters} from "../../components/Utility";

const AI = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const analytics = useAnalytics();
    const { videoId } = useParams();
    const useCaseId = useAiRouting(AIKeys.VIDEO_EXERCISE);
    const videoInfo = JSON.parse(localStorage.getItem("videoInfo")) || false;

    const audioPlayerRef = useRef();

    const [loading, setLoading] = useState(true);
    const [selectedPractice, setSelectedPractice] = useState({});
    const [videoDescription, setVideoDescription] = useState("");

    // base event fonksiyonu
    const sendEvent = (event) => {
        sendEventByDefaultParameters(
            event,
            analytics,
            videoInfo?.videoTags,
            {
                video_name: videoInfo?.videoName,
            },
            { seviye: '', ilgi_alanlari: '' }
        );
    }

    const handleBackButton = () => {
        navigate(url("videopractice.videoflow.grammar", {videoId: videoId}));
        sendEvent(EventLogs.VIDEO_EXERCISE_AI_PRACTICE_BACK_CLICKED);
    }

    const handleCompleteButton = () => {
        navigate(url("videopractice", {videoId: videoId}));
        sendEvent(EventLogs.VIDEO_EXERCISE_AI_PRACTICE_COMPLETE_CLICKED);
    }

    useEffect(() => {
        // Video açıklaması getiriliyor
        GetVideoDescription(videoId)
            .then(result => {
                if(result.status === 200) {
                    setVideoDescription(result.content);
                }
            })
            .catch();
    }, [])

    useEffect(() => {
        if(videoDescription.length > 0) {
            // AI Use Case bilgileri getiriliyor
            GetAiUseCaseById(useCaseId)
                .then(result => {
                    if(result.status === 200) {
                        let resultContent = JSON.parse(result.content);
                        resultContent.aiUseCasePrompt =
                            resultContent.aiUseCasePrompt
                                .replace("#videoDescription#", videoDescription);

                        setSelectedPractice(resultContent);
                    }

                    setLoading(false);
                })
                .catch()
        }
    }, [videoDescription])

    return (
        <>
            {loading &&
                <div className="transparent-section mt-10">
                    <Loading classnames="mb-4" />
                </div>
            }

            {!loading &&
                <>
                    <div className="mt-10">
                        <AIChat
                            ref={audioPlayerRef}
                            selectedPractice={selectedPractice}
                            backButtonAction={() => navigate(url("videopractice.videoflow.grammar", {videoId: videoId}))}
                            logParameters={{
                                ...videoInfo.videoTags,
                                video_name: videoInfo?.videoName,
                            }}
                        />
                    </div>

                    <div className="flex gap-8 my-7 justify-center">
                        <Button
                            type="button"
                            classnames="button primary-button-outline max-w-[150px]"
                            action={handleBackButton}
                            text={strings.pagination.previous}
                        />
                        <Button
                            type="button"
                            classnames="button primary-button max-w-[150px]"
                            action={handleCompleteButton}
                            text={strings.video_practice.complete}
                        />
                    </div>
                </>
            }
        </>
    )
}

export default AI;