import {useLocalization} from "../../hooks/useLocalization";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {url} from "../../routes/utility";
import {MarketSourceTypes} from "../../components/Constants";
import {decodeToBase64AndUri} from "../../components/Utility";
import { Successful as SuccessIcon } from "../../assets/svg"

const Successful = () => {

    const strings = useLocalization();
    const location = useLocation();
    const navigate = useNavigate();

    const packageInfo = sessionStorage.getItem("p") ? decodeToBase64AndUri(sessionStorage.getItem("p")) : false;
    const errorCode = location.state?.errorCode;
    const source = packageInfo?.source;

    const goToHome = () => {
        navigate(url("home"));
    }

    useEffect(() => {
        window.history.pushState(null, null, window.location.href);

        window.addEventListener('popstate', goToHome);

        return () => { window.removeEventListener('popstate', goToHome); }
    }, [])

    return (
        <div className="main-content">
            <div className="mx-auto text-center mt-7 text-sm px-4">
                <img
                    className="inline-block"
                    src={SuccessIcon}
                    alt=""
                    width="100"
                    height="100"
                />
                <p className="font-bold mt-7 text-base">{strings.market.successful.title}</p>
                <p className="mt-5">
                    {MarketSourceTypes.SPEAKING_LESSON === source && (
                        strings.market.successful.content
                    )}
                    {MarketSourceTypes.POSTPONE_RIGHT === source && (
                        strings.market.successful.postpone_content
                    )}
                </p>
                {errorCode && <p className="mt-5 font-bold text-primary">{strings.market.successful.term_error}</p>}
            </div>

            <div className="btn-outer-div mt-10">
                <Link className="button primary-button" to={url("home")}>
                    {strings.market.successful.button}
                </Link>
            </div>

            <div className="left-margin-bottom"></div>
        </div>
    )
}

export default Successful;