import {generateTimes} from "../../../components/Utility";
import React, {useEffect, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";

const TimeSlotAnimation = ({ selectedTimeAfterAnimation }) => {

    const times = generateTimes(9, 24, 10);

    const [currentTime, setCurrentTime] = useState(0);

    useEffect(() => {
        const getRandomIndex = () => Math.floor(Math.random() * times.length);
        const selectedTimeIndex = times.findIndex(t => t === selectedTimeAfterAnimation);

        const interval = setInterval(() => {
            setCurrentTime(getRandomIndex());
        }, 200);

        const timer = setTimeout(() => {
            clearInterval(interval);
            setCurrentTime(selectedTimeIndex);
        }, 2500);

        return () => {
            clearInterval(interval);
            clearTimeout(timer);
        };
    }, [times.length]);

    return (
        <div className="overflow-hidden h-[40px] w-full relative mt-[53px]">
            <AnimatePresence initial={false} className="">
                <motion.p
                    key={currentTime}
                    initial={{y: -50, opacity: 0}}
                    animate={{y: 0, opacity: 1}}
                    exit={{y: 50, opacity: 0}}
                    transition={{duration: 0.3}}
                    className="flex items-center justify-center absolute inset-0"
                >
                    <span className="material-symbols-outlined mr-0.5 mt-0.5">schedule</span>
                    <span className="font-bold text-[18px]">
                        {times[currentTime]}
                    </span>
                </motion.p>
            </AnimatePresence>
        </div>
    );
}

export default TimeSlotAnimation;