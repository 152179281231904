import {useEffect, useState} from "react";
import {getCookie} from "ko-basic-cookie";
import {cookieName} from "../components/Constants";
import {GetCandidateSurveyService} from "../services/Auth/AuthService";
import {decodeToken} from "../components";

const useMemberSurvey = () => {

    const token = getCookie(cookieName);
    const member = token ? decodeToken(token) : false;

    const [surveyLoading, setSurveyLoading] = useState(true);
    const [survey, setSurvey] = useState(null);

    const getCandidateSurveyService = async (memberId, token) => {
        const result = await GetCandidateSurveyService(memberId, token);

        if(result.status === 200){
            let resultContent = JSON.parse(result.content);
            setSurvey(resultContent);
        }

        setSurveyLoading(surveyLoading => false);
    }

    useEffect(() => {
        if(member){
            getCandidateSurveyService(member?.MemberId, token);
        }
    }, []);

    return { survey, surveyLoading };
}

export default useMemberSurvey;