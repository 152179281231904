import React from "react";
import { useSelector } from "react-redux";
import { getApplicationDirection } from "../../../../components/Utility";
import classNames from "classnames";

const ExerciseQuestionStep = (props) => {

    const {
        currentQuestion,
        totalQuestionCount,
        closeButtonAction = () => {}
    } = props;

    const language = useSelector(state => state.localizationStore.language);

    const direction = getApplicationDirection(language) ? 'right' : 'left';

    return (
        <div className="relative flex items-center gap-1 mt-5">
            <div className="flex items-baseline min-w-[35px]">
                <span className="text-xl font-bold">{currentQuestion}</span>
                <span className="text-sm text-base-text/70 ml-0.5">/{totalQuestionCount}</span>
            </div>
            <div className="relative h-2 flex-grow">
                {[...Array(totalQuestionCount)].map((_, index) => (
                    <div
                        key={index}
                        className={`absolute top-0 bottom-0 left-0 rounded-full overflow-hidden ${
                            index < currentQuestion ? 'bg-box' : 'bg-box'
                        }`}
                        style={{
                            [direction]: `calc(${(index / totalQuestionCount) * 100}% + 1px)`,
                            width: `calc(${100 / totalQuestionCount}% - ${(totalQuestionCount - 1) / totalQuestionCount}px)`,
                        }}
                    >
                        <div 
                            className={`h-full bg-green transition-all duration-500 ease-out ${
                                index < currentQuestion ? 'w-full' : 'w-0'
                            }`}
                        ></div>
                    </div>
                ))}
            </div>
            <button
                type="button"
                className="flex items-center"
                onClick={closeButtonAction}
            >
                <span className="material-symbols-outlined cursor-pointer hover:text-base-text/90">close</span>
            </button>
        </div>
    );
};

export default ExerciseQuestionStep;