import { useSelector } from "react-redux";
import { Accordion } from "../../../../../../components";
import { TermLessonAccordionType } from "../../../../../../components/Constants";
import useAnalytics from "../../../../../../hooks/useAnalytics";
import { useLocalization } from "../../../../../../hooks/useLocalization";
import useTermAccordionActivity from "../../../../../../hooks/useTermAccordionActivity";
import { LessonBook, LessonBookAudio, LessonVideo, StudySubjects, Words } from "../../components";
import { useEffect } from "react";

const LessonContentPending = ({ data, convertedData, selectedDate, termLessonAccordionActivities, isViewLessonBookClicked }) => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const { activities, addTermAccordionActivity } = useTermAccordionActivity(termLessonAccordionActivities);

    const selectedTermId = useSelector(state => state.termStore.termId) || false;

    // Bu useEffect, ders bilgisi akordiyonunun otomatik olarak açılmasını sağlar
    useEffect(() => {
        // Eğer aktiviteler varsa ve ders bilgisi akordiyonu henüz açılmamışsa, otomatik olarak açılır
        if(isViewLessonBookClicked && activities.length > 0 && !activities?.find(l => TermLessonAccordionType.LESSON_MATERIALS === l.termLessonAccordionType)?.isOpened) { 
            // Ders bilgisini servise göndermek için addTermAccordionActivity fonksiyonu çağrılır
            addTermAccordionActivity(TermLessonAccordionType.LESSON_MATERIALS, selectedTermId, selectedDate);
        }
    }, [activities, isViewLessonBookClicked]);

    return (
        <Accordion
            title={strings.speaking_lesson.daily_lesson.lesson_materials}
            disableOutsideClick={true}
            isScrollable={false}
            isDownArrowIcon={false}
            isOpen={isViewLessonBookClicked}
            openCloseCheckboxStatus={
                activities
                    ?.find(l => TermLessonAccordionType.LESSON_MATERIALS === l.termLessonAccordionType)?.isOpened
                || false
            }
            action={() => addTermAccordionActivity(TermLessonAccordionType.LESSON_MATERIALS, selectedTermId, selectedDate)}
        >
            <div className="pb-6">
                {convertedData && convertedData[3][0] && (
                    <LessonBook data={data.book} convertedData={convertedData} events={['Book_Preview', 'Book_Section_Click']} />
                )}

                {convertedData && convertedData[1][0] && (
                    <StudySubjects convertedData={convertedData} events={'Topic_Quesion_Section_Click'} />
                )}

                {convertedData && convertedData[7][0] && (
                    <LessonBookAudio
                        src={convertedData[7]}
                        title={strings.speaking_lesson.components.audio.book_voiceover_title}
                        onplay={() => {
                            //DataLayer
                            window.dataLayer.push({
                                'event' : 'bookPlayer',
                                'action' : 'play'
                            });
                            //Event
                            analytics.useAnalytics('Book_Listen');
                        }}
                        onpause={() => {
                            //DataLayer
                            window.dataLayer.push({
                                'event' : 'bookPlayer',
                                'action' : 'pause'
                            });
                            //Event
                            analytics.useAnalytics('Book_Pause');
                        }}
                    />
                )}

                {convertedData && convertedData[6][0] && (
                    <LessonVideo src={convertedData[6][0]} title={strings.speaking_lesson.components.video.title} events={'Video_Watch'} />
                )}

                {data.book.words[0] && (
                    <Words
                        title={strings.speaking_lesson.components.words.title}
                        data={data.book.words}
                        events={['Word_Detail_Click', 'Word_Listen']}
                    />
                )}
            </div>
        </Accordion>
    )
}

export default LessonContentPending;