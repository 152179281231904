import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactIntlTelInput from 'react-intl-tel-input-v2';
import '../../../../node_modules/intl-tel-input/build/css/intlTelInput.css';
import '../../../assets/css/login.css';
import { ModalOneButton, ModalOverlay } from "../../../components";
import { allowLatinCharacters, allowPasteLatinCharacters, closeModal, getApplicationDirection, openModal } from "../../../components/Utility";
import { useLocalization } from "../../../hooks/useLocalization";
import { clearError, showError, validPhoneNumber } from "../../../services/Auth/AuthValidation";
import { AddMemberFamilyWithInvite } from "../../../services/LearnTogether/LearnTogetherService";
import { InviteReferenceWithPhone } from "../../../services/Reference/ReferenceService";

const AddMemberWithPhone = ({ memberId, source = "family" }) => {

    const strings = useLocalization();
    const language = useSelector(state => state.localizationStore.language);

    const modalRef = useRef();
    const modalOverlayRef = useRef();
    const fullnameRef = useRef();
    const fullnameErrorRef = useRef();
    const phoneInputRef = useRef();
    const phoneErrorRef = useRef();

    const [phoneNumberValue, setPhoneNumberValue] = useState({ iso2: 'tr', dialCode: '90', phone: '' });
    const [fullname, setFullname] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('+');
    const [modalMessage, setModalMessage] = useState("");
    const [buttonLoading, setButtonLoading] = useState(false);

    let inputProps = { placeholder: phoneNumberValue.iso2 === 'tr' ? '5XX XXX XX XX' : strings.auth.form.enter_your_phone_number };
    const intlTelOpts = { preferredCountries: ['tr', 'az', 'de'] };

    const phoneNumberHandle = val => {
        if (val.phone.startsWith('0')) {
            val.phone = val.phone.slice(1);
        }
        if (val.dialCode !== phoneNumberValue.dialCode) {
            setPhoneNumberValue({ iso2: val.iso2, dialCode: val.dialCode, phone: '' });
        } else {
            setPhoneNumberValue(val);
        }
    };

    const submitHandle = (e) => {
        e.preventDefault();
        clearError(fullnameErrorRef);
        clearError(phoneErrorRef);

        if (validPhoneNumber(phoneNumber) && fullname.length >= 2) {
            setButtonLoading(true);

            const model = {
                name: fullname,
                phoneNumber: phoneNumber,
                memberId: memberId
            }

            if(source === "family")
                AddMemberFamilyWithInvite(model)
                    .then(result => {
                        if(result.status === 200)
                            setModalMessage(strings.learn_together.add_member_success_message);
                        else
                            setModalMessage(strings.learn_together.add_member_error_message);

                        setButtonLoading(false);
                        openModal(modalOverlayRef, modalRef);
                    })  
                    .catch()

            if(source === "reference")
                InviteReferenceWithPhone(model)
                    .then(result => {
                        if(result.status === 200)
                            setModalMessage(strings.reference.invite_reference_success_message);
                        else
                            setModalMessage(strings.reference.invite_reference_error_message);

                        setButtonLoading(false);
                        openModal(modalOverlayRef, modalRef);
                    })
                    .catch()

        } else {
            showError(validPhoneNumber(phoneNumber), phoneErrorRef);
            showError((fullname.length >= 2), fullnameErrorRef);
        }
    };

    useEffect(() => {
        let phoneNum = '+' + phoneNumberValue.dialCode + phoneNumberValue.phone;
        setPhoneNumber(phoneNum);
    }, [phoneNumberValue])

    return (
        <>
            <div className={`mt-7 gray-section ${getApplicationDirection(language) ? 'auth-rtl' : 'auth-ltr'}`}>
                <h5 className="text-primary mb-2">
                    {source === "family" 
                        ? strings.learn_together.add_member_with_phone 
                        : strings.reference.invite_reference_with_phone
                    }
                </h5>
                <p className="mt-2">
                    {source === "family" 
                        ? strings.learn_together.add_member_info 
                        : strings.reference.invite_reference_add_info
                    }
                </p>

                <form className="btn-outer-div mt-5" onSubmit={submitHandle}>
                    <div className="mb-[6px]">
                        <label className="block text-start" htmlFor="name">
                            {strings.auth.form.your_name}
                        </label>
                        <input
                            ref={fullnameRef}
                            value={fullname ?? null}
                            className="keypress input-area w-full block" id="name" type="name" name="name"
                            placeholder={strings.auth.form.create_your_name}
                            onChange={e => setFullname(e.target.value)}
                            onPaste={allowPasteLatinCharacters}
                            onKeyDown={allowLatinCharacters}
                        />
                    </div>
                    <p ref={fullnameErrorRef} className="text-[11px] text-[#FF0000] text-start ml-2 hidden font-bold" >{strings.auth.validation_messages.invalid_name}</p>

                    <div className="mb-[6px]" ref={phoneInputRef}>
                        <label className="block text-start text-base-text-light" htmlFor="phone">
                            {strings.auth.form.phone_number}
                        </label>
                        
                        <ReactIntlTelInput 
                            className="keypress input-area w-full text-start bg-white"
                            inputProps={inputProps}
                            intlTelOpts={intlTelOpts}
                            value={phoneNumberValue}
                            onChange={phoneNumberHandle}
                        />
                    </div>
                    <p ref={phoneErrorRef} className="mb-2 text-[11px] text-[#FF0000] text-start ml-2 hidden font-bold">{strings.auth.validation_messages.invalid_phone}</p>

                    <button
                        type="submit"
                        className={classNames("button primary-button max-w-[330px] mx-auto w-full mt-4 mb-3", {
                            "opacity-70 pointer-events-none" : buttonLoading
                        })}
                        disabled={buttonLoading}
                    >
                        {strings.learn_together.add_member}
                    </button>
                </form>
            </div>

            <ModalOverlay ref={modalOverlayRef}/>
            <ModalOneButton
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={modalMessage}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => {
                    setFullname("");
                    setPhoneNumberValue({ iso2: 'tr', dialCode: '90', phone: '' });
                    setPhoneNumber('+');
                    closeModal(modalOverlayRef, modalRef);
                }}
            />

        </>
    )
}

export default AddMemberWithPhone;