import { fetchApi } from "../index";
import {fetchOutsourceApi} from "../FetchApi";

export const GetTagFilterList = async (languageTitleType) => {
    const url = '/_services/video_pool/v1/tag_category/get_tag_filter_list/';

    const payload = {
        languageTitleType: languageTitleType
    };

    return await fetchApi(url, payload);
}

export const GetAllVideosFilter = async (model) => {
    const url = '/_services/video_pool/v2/video/get_all_videos_filter/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetVideoDetails = async (videoId,  languageTitleType) => {
    const url = '/_services/video_pool/v1/video/get_video_details_by_id/';

    const payload = {
        videoId: videoId,
        languageTitleType: languageTitleType
    };

    return await fetchApi(url, payload);
}

export const GetWordDetails = async (wordId, memberId, languageTitleType) => {
    const url = '/_services/word_pool/v1/word/get_word_details/';

    const payload = {
        wordId: wordId,
        memberId: memberId,
        languageTitleType: languageTitleType
    };

    return await fetchApi(url, payload);
}

export const AddMemberWordStore = async (model) => {
    const url = '/_services/member/v1/member_word_store/add_member_word_store/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetShareImage = async (model) => {

    const url =
        process.env.REACT_APP_IMAGE_API +
        `/word_pool/share.php?wordId=${model.wordId}&languageId=${model.languageTitleType}&wordType=${model.wordType}&brandId=${model.brandId}&shareType=${model.shareType}`;

    const payload = {};

    return await fetchOutsourceApi(url, payload);
}

export const GetSpeechReport = async (formData) => {

    const url = process.env.REACT_APP_PYTHON_API_URL + '/ai/speech_report';

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
        });

        if (response.status === 200) {
            return await response.text();
        } else {
            return "Failed to reach the speech API. Status code: " + response.status;
        }
    } catch (error) {
        console.error('An error occurred:', error);
    }
}

export const GetVideoWords = async (model) => {

    const url = '/_services/word_pool/v1/word/get_video_words/';

    const payload = {
        ...model
    }

    return await fetchApi(url, payload);
}

export const GetVideoDialogs = async (videoId, memberId) => {

    const url = '/_services/video_pool/v1/video_subtitle/get_video_dialogs/';

    const payload = {
        videoId: videoId,
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetVideoGrammar = async (videoId, languageTitleType) => {

    const url = '/_services/grammar_pool/v1/grammar_category/get_grammar_category_details/';

    const payload = {
        videoId: videoId,
        languageTitleType: languageTitleType,
    };

    return await fetchApi(url, payload);
}

export const GetVideoDescription = async (videoId) => {

    const url = '/_services/video_pool/v1/video_subtitle/get_video_description_by_video_id/';

    const payload = {
        videoId: videoId,
    };

    return await fetchApi(url, payload);
}

export const AddMemberPronunciationResult = async (model) => {

    const url = '/_services/member/v1/member_pronunciation_result/add_member_pronunciation_result/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}