import {useLocalization} from "../../../../hooks/useLocalization";
import useAuth from "../../../../hooks/useAuth";
import {ReportMember, UnfollowFriend} from "../../../../assets/svg";
import React, {useRef, useState} from "react";
import {ModalOneButton, ModalOverlay, ModalTwoButtons} from "../../../../components";
import {closeModal, openModal} from "../../../../components";
import {AddMemberAbuseReport} from "../../../../services/Profile/ProfileService";
import useFollowUnfollow from "../../../../hooks/useFollowUnfollow";
import useAnalytics from "../../../../hooks/useAnalytics";
import {EventLogs} from "../../../../components/Constants";

const ProfileOperations = (props) => {

    const {
        data,
        getMemberProfile,
        updateIsReportedStatus
    } = props;

    const strings = useLocalization();
    const { member } = useAuth();
    const { unfollow } = useFollowUnfollow();
    const analytics = useAnalytics();

    const modalRef = useRef();
    const modalResultRef = useRef();
    const modalOverlayRef = useRef();

    const [modalInfo, setModalInfo] = useState({ modal: "", message: "" });
    const [modalResultMessage, setModalResultMessage] = useState("");

    const addMemberAbuseReport = (reporterMemberId, reportedMemberId) => {
        AddMemberAbuseReport(reporterMemberId, reportedMemberId)
            .then(result => {
                if(result.status === 200) {
                    setModalResultMessage(strings.profile.profile_operations.report_member_success_text
                        ?.replace("#name#", data?.memberName)
                    );
                    updateIsReportedStatus();
                } else {
                    setModalResultMessage(strings.general_information_messages.an_error_occured);
                }

                openModal(modalOverlayRef, modalResultRef);
            })
            .catch()
    }

    const handleUnfollow = () => {
        setModalResultMessage(strings.profile.profile_operations.unfollow_member_success_text
            ?.replace("#name#", data?.memberName)
        );

        getMemberProfile();
        openModal(modalOverlayRef, modalResultRef);
    }

    const handleClickReportMember = () => {
        setModalInfo({
            modal: "reportMember",
            message: strings.profile.profile_operations.report_member_approve_text
                .replace("#name#", data?.memberName)
        });

        openModal(modalOverlayRef, modalRef);
        analytics.useAnalytics(EventLogs.REPORT_USER_CLICKED, undefined, false, true);
    }

    const handleClickUnfollowMember = () => {
        setModalInfo({
            modal: "unfollow",
            message: strings.profile.profile_operations.unfollow_member_approve_text
                .replace("#name#", data?.memberName)
        });

        openModal(modalOverlayRef, modalRef);
    }

    return (
        Object.keys(data).length > 0 &&
            <>
                <div className="flex flex-col items-center gap-1 mt-20 text-light-gray">
                    {!data?.isSelfProfile && !data?.isReported &&
                        <button
                            type="button"
                            className="flex gap-1 group"
                            onClick={handleClickReportMember}
                        >
                            <img
                                src={ReportMember ?? ""}
                                width="16"
                                height="16"
                                alt="report"
                            />
                            <p className="font-bold text-[12px] group-hover:underline">
                                {strings.profile.profile_operations.report_member}
                            </p>
                        </button>
                    }

                    {data?.isFollow && !data?.isSelfProfile &&
                        <button
                            type="button"
                            className="flex gap-1 group"
                            onClick={handleClickUnfollowMember}
                        >
                            <img
                                src={UnfollowFriend ?? ""}
                                width="16"
                                height="16"
                                alt="report"
                            />
                            <p className="font-bold text-[12px] group-hover:underline">
                                {strings.profile.profile_operations.unfollow}
                            </p>
                        </button>
                    }
                </div>

                <ModalOverlay ref={modalOverlayRef}/>
                <ModalTwoButtons
                    ref={modalRef}
                    overlayRef={modalOverlayRef}
                    title={strings.modal.transaction_information}
                    message={modalInfo.message}
                    buttonText1={strings.modal.approve}
                    buttonClick1={() => {
                        closeModal(modalOverlayRef, modalRef);
                        modalInfo.modal === "reportMember"
                            ? addMemberAbuseReport(member?.MemberId, data?.memberId)
                            : unfollow(
                                member?.MemberId,
                                data?.memberId,
                                handleUnfollow,
                                () => {
                                    setModalResultMessage(strings.general_information_messages.an_error_occured);
                                    openModal(modalOverlayRef, modalResultRef);
                                }
                            )
                    }}
                    buttonText2={strings.modal.dont_approve}
                    buttonClick2={() => closeModal(modalOverlayRef, modalRef)}
                />
                <ModalOneButton
                    ref={modalResultRef}
                    overlayRef={modalOverlayRef}
                    title={strings.auth.information_messages.modal_title}
                    message={modalResultMessage}
                    buttonText={strings.auth.form.okay_button}
                    buttonClick={() => {
                        closeModal(modalOverlayRef, modalResultRef);
                    }}
                />
            </>
    )
}

export default ProfileOperations;