
export const staticData = {
    "studentQuestions": [
        {
            "title": {
                "tr": "Yeni öğrenen birisi olarak, Amerikalı eğitmenlerle nasıl konuşacağım?",
                "en": "How can I communicate with American teachers as a new learner?",
                "al": "انا كمتعلم جديد ، كيف أتحدث مع المدربين الأمريكيين؟"
            },
            "text": {
                "tr": "Eğitmenlerimiz sıfırdan başlayan, çekingen ve endişeli öğrencilerimize o kadar alıştılar ki! Bu yüzden yavaş yavaş ve tane tane başlıyoruz. Kitaplarınız da nispeten kolay oluyor. Ta ki siz alışana kadar. Alıştıktan sonra hızınız yavaş yavaş artıyor. Yüzme öğrenmek gibi düşünün. Suya girmeden yüzemezsiniz değil mi?",
                "en": "Our teachers are so accustomed to beginners, shy, and anxious students! That's why we start slowly and clearly. Your books are also relatively easy. Until you get used to it. After getting used to it, your pace gradually increases. Think of it like learning to swim. Can't swim without getting into the water, right?",
                "al": "جميع مدرسينا معتادون جدًا على طلابنا الخجولين والقلقين الذين يبدأون من الصفر!  لذلك بدأنا معكم ببطء وخطوة بخطوة.  منهاجٍ سهل بسيط نسبياً. لكي تعتاد على ذلك.  وبمجرد أن تعتاد عليها ، ستزداد سرعتك تدريجياً.  فكرِّ في الأمر مثل تعلم السباحة.  لا يمكنك السباحة دون الدخول إلى الماء ، أليس كذلك؟!!"
            }
        },
        {
            "title": {
                "tr": "10, 20 veya 30 dakika yeterli mi?",
                "en": "Are 10, 20, or 30 minutes enough?",
                "al": " هل 10 أو 20 أو 30 دقيقة كافية؟!!"
            },
            "text": {
                "tr": "Günlük 20 dakika en çok tercih edilen programlarımızdan biri. Bu örnek üzerinden gidersek, ayda 440 dakika yapıyor (22 iş günü üzerinden hesaplarsak), bu da 7 saatten fazla ders süresine denk geliyor. Size verilen ödevleri ve diğer materyalleri düşündüğünüzde, oldukça yeterli bir süre. Fazlasına ihtiyaç duyarsanız her zaman dakikalarınızı arttırabilirsiniz.",
                "en": "Daily 20 minutes is one of our most preferred programs. If we calculate based on this example, it adds up to 440 minutes per month (considering 22 workdays), which is more than 7 hours of lesson time. When you consider the assignments and other materials provided to you, it's quite sufficient. If you need more, you can always increase your minutes.",
                "al": "20 دقيقة في اليوم هي واحدة من أكثر برامجنا المفضلة.  من خلال هذا المثال ، يقوم بـ 440 دقيقة شهريًا (محسوبة على 22 يوم عمل) ، أي أكثر من 7 ساعات من وقت المحاضرة.  هذا وقت طويل جدًا ، عندما تفكر في المهام والمواد الأخرى المخصصة لك.  يمكنك دائمًا زيادة الدقائق إذا كنت بحاجة إلى المزيد."
            }
        },
        {
            "title": {
                "tr": "Eğitmenleriniz gerçekten Amerikalı mı?",
                "en": "Are your teachers really American?",
                "al": " هل معلموك أمريكيون حقًا؟"
            },
            "text": {
                "tr": "Eğitmenlerimiz Memphis/Tennessee'de bulunan ofisimizde çalışmaktadır. Eğitmenlerimiz ofisimizde çalışmaya başlamadan önce ciddi bir elemeden geçerler. Vatandaşlık durumu, İngilizce eğitim kapasitesi gibi durumlar ciddi bir şekilde değerlendirilir. Ana dili İngilizce olmayan bir eğitmenin işe alımı mümkün değildir. Bu yüzden gönlünüz rahat olabilir.",
                "en": "Our teachers work at our office in Memphis, Tennessee. Before starting to work in our office, our teachers go through a rigorous selection process. Matters such as citizenship status, English teaching capacity are seriously evaluated. Hiring a non-native English-speaking instructor is not possible. So, you can rest assured.",
                "al": "  يخضع مدربونا لفحص جاد قبل أن يبدأوا العمل في مكتبنا.  يتم تقييم مواقف مثل حالة المواطنة والقدرة على تعليم اللغة الإنجليزية بجدية.  لا يمكن تعيين مدرب لغته الأم ليست الإنجليزية.  لذلك يمكنك أن تنعم براحة البال."
            }
        },
        {
            "title": {
                "tr": "Eğitmenlerle istediğim konuları konuşabiliyor muyum?",
                "en": "Can I discuss the topics I want with the teachers?",
                "al": "  هل يمكنني التحدث عن الموضوعات التي أريدها مع المدربين؟"
            },
            "text": {
                "tr": "İngilizce durumunuza göre, konularınızı belirlerken mesleğinize göre eklemeler yapabiliyoruz. Tıp, mühendislik, iş dünyası, sağlık, güzellik, eğitim, inşaat, finans gibi konular belirleyip, programınıza ekliyoruz. Konuları istediğinizde değiştirebilirsiniz. Online İngilizce kursu olarak her şey size özel.",
                "en": "According to your English proficiency level, we can determine your topics and make additions based on your profession. We identify subjects such as medicine, engineering, business, health, beauty, education, construction, finance, and add them to your program. You can also change the topics as you wish. As an online English course, everything is personalized for you.",
                "al": " اعتمادًا على مستواك في اللغة الإنجليزية ، يمكننا إجراء إضافات وفقًا لمهنتك أثناء تحديد موادك.  نحدد مواضيع مثل الطبوالهندسة وعالم الأعمال والصحة والجمال والتعليم والبناء والتمويل ونضيفها إلى برنامجك.  يمكنك تغيير المواضيع وقتما تشاء.  بصفتك دورة لغة إنجليزية عبر الإنترنت ، فإن كل شيء مصمم خصيصًا لك"
            }
        },
        {
            "title": {
                "tr": "Yoğunluktan derse katılamazsam ne oluyor?",
                "en": "What happens if I can't attend the class due to busyness?",
                "al": "ماذا يحدث إذا لم أتمكن من حضور الفصل بسبب الضغط والإنشغال؟"
            },
            "text": {
                "tr": "Hesabınıza belirli sayıda ders erteleme hakkı tanınıyor. Ders başlamadan iki saat öncesine kadar bu haklarınızı kullanarak dersinizi erteleyebilirsiniz. Haklarınız bittiğinde ise makul bir ücret karşılığında erteleme hakkı satın alabilirsiniz. Fakat yine de tercihimiz saatlerinizi doğru ayarlayıp, derslerinize devam etmeniz.",
                "en": "You are granted a certain number of class postponement rights in your account. You can use these rights to reschedule your class until two hours before the class starts. When your rights are used up, you can purchase postponement rights for a reasonable fee. However, our preference is still for you to adjust your schedule correctly and continue with your classes.",
                "al": "يحق لحسابك تأجيل عدد معين من الدروس.  يمكنك تأجيل الدرس باستخدام هذه الحقوق حتى ساعتين  قبل بدء الدرس. عند انتهاء صلاحية حقوقك ، يمكنك شراء حق التأجيل مقابل رسوم معقولة.  ومع ذلك ، فإننا نفضل ضبط ساعات العمل بشكل صحيح ومتابعة دروسك"
            }
        },
        {
            "title": {
                "tr": "İlerleyişimi nasıl takip edebilirim?",
                "en": "How can I track my progress?",
                "al": "كيف يمكنني تتبع تقدمي؟"
            },
            "text": {
                "tr": "Derslere başladığınız zaman, belirlediğiniz kullanıcı adı ve şifre ile öğrenci panelinize giriş yapabileceksiniz. Bu panelde, eğitim içeriklerinizle birlikte ilerleme raporunuz da olacak. Burada eğitmeninizin notlarını, ilerlemenizi ve diğer içeriklerinizi görebileceksiniz.",
                "en": "When you start the classes, you will be able to log in to your student panel with the username and password you set. In this panel, you will find your progress report along with your educational content. You will be able to see your instructor's feedback, track your progress, and access other content.",
                "al": "عند بدء الدروس ، ستتمكن من تسجيل الدخول إلى لوحة الطلاب الخاصة بك باستخدام اسم المستخدم وكلمة المرور اللذين قمت بتعيينهما.  في هذه اللوحة ، سيكون لديك تقرير التقدم الخاص بك مع محتوى التدريب الخاص بك.  هنا سوف تكون قادرًا على رؤية درجات مدرسك والتقدم والمحتويات الأخرى."
            }
        },
        {
            "title": {
                "tr": "%100 İade garantisi nedir?",
                "en": "What is the %100 Refund Guarantee?",
                "al": "  ما هو ضمان استرداد الأموال بنسبة 100٪؟"
            },
            "text": {
                "tr": "%100 iade garantisi 6 ve 9+3 aylık paket alan öğrencilerimize sunmuş olduğumuz bir haktır. Derslerine en az %90 oranında devamlılık gösteren öğrencilerimiz eğitimlerinden memnun kalmadıklarında iade isteyebilir ve ödemelerinin tamamını geri iade olarak alabilirler.",
                "en": "The %100 refund guarantee is a right we offer to students who purchase the 6 and 9+3 months packages. Students who show at least 90% attendance in their classes can request a refund if they are not satisfied with their education and can receive a full refund of their payments.",
                "al": "ضمان استرداد الأموال بنسبة 100٪ هو حق نقدمه لطلابنا الذين يشترون حزم 6 و 9 + 3 أشهر.  يمكن للطلاب الذين يظهرون نسبة حضور 90٪ على الأقل في دوراتهم أن يطلبوا استرداد الأموال إذا لم يكونوا راضين عن تعليمهم ويمكنهم الحصول على مدفوعاتهم الكاملة كإسترداد."
            }
        }
    ],
    "studentComments": [
        {
            "name": "Khaled",
            "job": {
                "tr": "Mr. Anas Rafid Hazem",
                "en": "Mr. Anas Rafid Hazem",
                "al": "السيد أنس رافد حازم"
            },
            "youtubeId": "S5Ic6gL1L34"
        },
    ],
    "howToLearnEnglish": {
        "youtubeId": "qDE5mXKyQTg"
    }
}
