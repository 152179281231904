import {useLocation, useRoutes} from "react-router-dom";
import { useSelector } from "react-redux";
import useGtmHook from "./hooks/useGtmHook";
import routes from "./routes/routes";
import './assets/css/login.css';
import './index.css';
import {useEffect} from "react";
import useMember from "./hooks/useMember";

function App() {
  //for gtm
  const location = useLocation();
  const gtm = useGtmHook();
  useMember();

  useEffect(() => {
    gtm.dataLayer();
  }, [location]);

  const language = useSelector(state => state.localizationStore.language);

  return (
    <div className="h-full" dir={language === 'al' ? 'rtl' : 'ltr'}>
      {useRoutes(routes)}
    </div>
  )
}

export default App;
