import { Outlet } from "react-router-dom"
import { Menu, SideBar } from '../../components';
import { useLocalization } from "../../hooks/useLocalization"
import {useSelector} from "react-redux";
import { ReferenceBar } from "./components/DailyLesson/components";
import { useContext } from "react";
import { FirebaseContext } from "../../store/FirebaseProvider";

const SpeakingLessonLayout = () => {
    
    const strings = useLocalization();
    const attendedRenderStatus = useSelector(state => state.componentRenderStore.DailyLesson.Attended);

    const defaultStickyBar = {
        view: true,
        theme: "dark",
        buttonType: "button",
        buttonPath: "learntogether.reference",
        title: {
            al: strings.speaking_lesson.components.reference_bar.description,
            en: strings.speaking_lesson.components.reference_bar.description
        },
        buttonText: {
            al: strings.speaking_lesson.components.reference_bar.button_text,
            en: strings.speaking_lesson.components.reference_bar.button_text
        }
    };

    const attendedLessonStickyBar = useContext(FirebaseContext)?.attendedLessonStickyBar || defaultStickyBar;

    return(
        <>
            <Menu title={strings.speaking_lesson.title} />

            <section className="container">

                <Outlet />

                <SideBar />

            </section>

            {(attendedRenderStatus && attendedLessonStickyBar.view) && <ReferenceBar stickyBar={attendedLessonStickyBar} />}
        </>
    )
}

export default SpeakingLessonLayout