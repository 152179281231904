import React, {useEffect, useRef} from 'react'
import { useSelector } from "react-redux";
import { AddPerson } from '../../../assets/svg'
import useAuth from '../../../hooks/useAuth';
import { useLocalization } from '../../../hooks/useLocalization'
import {EventLogs} from "../../../components/Constants";
import useAnalytics from "../../../hooks/useAnalytics";
import { AddMemberWithPhone } from '../components';
import { getApplicationDirection } from '../../../components/Utility';

const AddFamilyMember = () => {

  const strings = useLocalization();
  const analytics = useAnalytics();
  const language = useSelector(state => state.localizationStore.langauge);

  const copiedRef = useRef();
  const { member } = useAuth();

  const FAMILY_PACKAGE_URL = process.env.REACT_APP_SITE_BASE_URL + '/mobile/joinfamily/?utm_content=family&utm_medium=family&familyCode=' + member.FamilyCode;

  const sendShareEvent = (source) => {
    analytics.useAnalytics(EventLogs.REFERENCE_FAMILY_SHARE_CLICK, { share_platform: source });
  };

  const shareWithWhatsapp = () => {
    const shareLink = `${process.env.REACT_APP_WHATSAPP_API_URL}?text=${encodeURIComponent(FAMILY_PACKAGE_URL)}`;
    window.open(shareLink, "_blank");
    sendShareEvent("whatsapp");
  }

  const sendMail = () => {
    const body = encodeURIComponent(FAMILY_PACKAGE_URL);

    const mailtoLink = `mailto:?&body=${body}`;
    window.location.href = mailtoLink;

    sendShareEvent("email");
  }

  const copyLink = () => {
    handleCopy(FAMILY_PACKAGE_URL);
    sendShareEvent("link-copy");
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);

    copiedRef.current.classList.remove("hidden");

    setTimeout(() => {
      copiedRef.current.classList.add("hidden");
    }, 1500);
  }

  const SOCIAL_LINKS = [
    {
      name: strings.social_button.whatsapp,
      action: shareWithWhatsapp
    },
    {
      name: strings.social_button.mail,
      action: sendMail
    },
    {
      name: strings.social_button.copy_link,
      action: copyLink
    },
  ];

  useEffect(() => {
    analytics.useAnalytics(EventLogs.REFERENCE_PAGE_VIEW);
  }, []);

  return (
    <div className={`main-content ${getApplicationDirection(language) ? 'md:ml-7' : 'md:mr-7'}`}>
      <div className="mx-auto text-center mt-7 px-4">
        <img
          className="inline-block"
          src={AddPerson}
          alt=""
          width="128"
          height="128"
        />
        <p className="font-bold mt-7">{strings.learn_together.family_package}</p>
        <p className="mt-2">{strings.learn_together.add_member_content}</p>
      </div>

      <div className='mt-7'>
        <p className='font-bold text-center'>{strings.learn_together.add_member_content_title_2}</p>
        <p className='text-center mt-2'>{strings.learn_together.add_member_content_2}</p>
      </div>

      <div className='mt-7'>
        <p className='font-bold text-center'>{strings.learn_together.add_member_content_title_3}</p>
        <p className='text-center mt-2'>{strings.learn_together.add_member_content_3}</p>
      </div>

      <div className="transparent-section mt-7">
        <p className="text-center">{strings.learn_together.share_text}</p>
        <div className="flex flex-col gap-3 my-4">
          {SOCIAL_LINKS.map((social, index) => (
            <div key={index} className="relative group">
              {social.name === strings.social_button.copy_link && (
                <div ref={copiedRef} className="tooltip-top-content hidden -top-[34px] duration-500">{strings.reference.copied}</div>
              )}
              <button
                className="button primary-button max-w-[330px] mx-auto w-full"
                onClick={() => {
                  social.action();
                }}
              >
                {social.name}

              </button>
            </div>
          ))}
        </div>
      </div>

      <AddMemberWithPhone 
        memberId={member?.MemberId} 
        source="family"
      />

      <div className="left-margin-bottom"></div>
    </div>
  )
}

export default AddFamilyMember