import classNames from "classnames";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Onboarding_1_1, Onboarding_1_2, Onboarding_1_3, Onboarding_1_4 } from "../../../assets/svg";
import { Button, HorizontalMenuButton, Loading } from "../../../components";
import { AIKeys, LevelDefinitionType, MemberStatus } from "../../../components/Constants";
import useAiRouting from "../../../hooks/useAiRouting";
import useAnalytics from "../../../hooks/useAnalytics";
import useAuth from "../../../hooks/useAuth";
import { useLocalization } from "../../../hooks/useLocalization";
import useMemberSurvey from "../../../hooks/useMemberSurvey";
import { url } from "../../../routes/utility";
import { AddMemberLevelCollection } from "../../../services/Auth/AuthService";
import { getQueryParam } from "../../../utils/Static";

const LevelQuestion = (props) => {

    const { currentQuestionText, handleNext } = props;

    const strings = useLocalization();
    const analytics = useAnalytics();
    const location = useLocation();
    const navigate = useNavigate();
    const { member } = useAuth();
    const { survey, surveyLoading} = useMemberSurvey();
    const useCaseId = useAiRouting(AIKeys.LEVEL_FINDER);

    const [answer, setAnswer] = useState(null);
    const [loading, setLoading] = useState(false);
    
    // familyCode varsa, değerini döndürüyoruz, yoksa null döndürüyoruz
    const familyCode = getQueryParam('familyCode', location);

    // Önce button verilerini bir array'de toplayalım
    const levelButtons = [
        {
            id: 2,
            icon: Onboarding_1_1,
            text: strings.onboarding.first.level1
        },
        {
            id: 3,
            icon: Onboarding_1_2,
            text: strings.onboarding.first.level2
        },
        {
            id: 4,
            icon: Onboarding_1_3,
            text: strings.onboarding.first.level3
        },
        {
            id: 5,
            icon: Onboarding_1_4,
            text: strings.onboarding.first.level4
        }
    ];

    const handleUpdateLevel = () => {
        setLoading(true);

        AddMemberLevelCollection(member?.MemberId, LevelDefinitionType.SELF, answer)
            .then(result => {
                if(result.status === 200) {
                    analytics.useAnalytics('Onboarding_1');
                } else {
                    analytics.useAnalytics('Error_Occured', {
                        error: `An unexpected error occurred.`,
                    });
                }

                setLoading(false);
                handleNext();
            })
            .catch()        
    }

    // Seviye Testi AI Butonuna tıklandığında yönlendirmeyi sağlayan fonksiyon.
    const handleNavigateLevelAi = () => {
        navigate(url("speakinglesson.chatbot", { useCaseId: useCaseId }));
    }

    //Kullanıcının daha önce seçtiği cevap var ise set ediliyor.
    useEffect(() => {
        if(!surveyLoading){
            setAnswer(answer => survey.second);
        }
    }, [surveyLoading]);

    useEffect(() => {
        //Eğer giriş yapan kullanıcı öğrenci ise second onboarding ekranına girerse direkt
        // olarak dashboard'a yönlendirmek için kullanılan kod
        if (+member?.Status === MemberStatus.Active) {
            navigate(url("dashboard"));
        }
    }, []);

    return (
        <>
            {surveyLoading && 
                <div className='w-full mx-auto mt-5'>
                    <Loading/>
                </div>
            }
            {!surveyLoading && (
                <>
                    <div className="text-center mx-auto">
                        <p className="text-xl">
                            <span className="font-bold">{currentQuestionText}</span>
                            {" "}{strings.onboarding.first.title}
                        </p>
                    </div>
                    <div className="onboarding-outer-div">
                        {levelButtons.map((item, index) => (
                            <HorizontalMenuButton
                                key={index}
                                title={item.text}
                                icon={item.icon}
                                showLink={false}
                                showOnclick={true}
                                isSelected={answer === item.id}
                                onclick={() => setAnswer(item.id)}
                                classnames={classNames({
                                    "bg-onboarding-bg-select md:text-md text-base": answer === item.id
                                })}
                            />
                        ))}
                    </div>
                    <div className="btn-outer-div mb-3">
                        <Button
                            type="button"
                            classnames={classNames("button primary-button", { "opacity-70 pointer-events-none": loading })}
                            text={loading ? strings.general_information_messages.processing : strings.onboarding.next_button}
                            action={handleUpdateLevel}
                        />
                    </div>
                    <div className="btn-outer-div mb-3">
                        <Button
                            type="button"
                            classnames="button primary-button-outline"
                            text={strings.ai_chatbot.level_test_ai}
                            action={handleNavigateLevelAi}
                        />
                    </div>
                </>
            )}
        </>
    );
}

export default LevelQuestion;