import { fetchApi } from '../index';
import {getLanguageNameForService} from "../../components/Utility";

export const CheckPostponeAvailabilityService = async (model) => {

    const url = '/_services/member/v1/term_right/check_term_right/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetPostponeRightProductsService = async (model) => {

    const url = '/_services/member/v1/product/get_products_for_additional/';

    const payload = {
        ...model
    }

    return await fetchApi(url, payload);
}

export const BuyPostponePackage = async (model) => {

    const url = '/_services/member/v1/term_right/buy_postpone_right_and_update/';

    const payload = {
        ...model
    }

    return await fetchApi(url, payload);
}

export const BuyPostponePackageV2 = async (model) => {

    const url = '/_services/member/v2/term_right/buy_postpone_right_and_update/';

    const payload = {
        ...model
    }

    return await fetchApi(url, payload);
}
export const GetMarketTermList = async (memberId) => {

    const url = '/_services/member/v1/term/get_market_term_list/';

    const payload = {
        memberId: memberId,
        lang: getLanguageNameForService("ar")
    }

    return await fetchApi(url, payload);
}
