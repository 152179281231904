import classNames from "classnames";
import React from "react";
import {useSelector} from "react-redux";
import {getApplicationDirection} from "./Utility";

const Radio = (props) => {

    const { id, title, state, action  } = props;
    const language = useSelector(state => state.localizationStore.language);

    return (
        <div className="flex">
            <div className="pt-1.5">
                <input
                    name={id}
                    type="radio"
                    className="hidden"
                    onChange={action}
                    checked={state}
                />
                <label
                    htmlFor={id}
                    className={classNames("rounded-full w-4 h-4 cursor-pointer duration-500 flex items-center justify-center", {
                        "border border-green text-white" : state,
                        "border border-[#aaaaaa]" : !state
                    })}
                    onClick={action}
                >
                    <span className={classNames('text-inherit h-2.5 w-2.5 rounded-full bg-green animate-fadeIn font-bold', {
                        'hidden': !state,
                    })}>
                    </span>
                </label>
            </div>
            <label
                htmlFor={id}
                className={classNames("text-[14px] text-start w-full mt-1 cursor-pointer", {
                    "mr-2" : getApplicationDirection(language),
                    "ml-2" : !getApplicationDirection(language)
                })}
                onClick={action}
            >
                {title}
            </label>
        </div>
    )
}

export default Radio;