import { Link } from "react-router-dom";
import { url } from "../../../routes/utility";
import { Default_Teacher } from "../../../assets/svg";
import { useSelector } from "react-redux";
import { getApplicationDirection } from "../../../components/Utility";

const TeacherCard = ({ name, age, ageContent, information, image, teacherId, buttonText, detailSearch }) => {

  const language = useSelector(state => state.localizationStore.language);

  return (
    <>
      <div className="teacher-card min-h-[145px]">
        <div className={`${getApplicationDirection(language) ? 'ml-2' : 'mr-2'} min-w-[90px]`}>
          <img
            className="rounded-full"
            src={(image === "" || image === null) ? Default_Teacher : image}
            width="90"
            height="90"
            alt=""
          />
        </div>
        <div className="w-full">
          <p className="font-bold text-sm">
            {name} {age !== 0 && (
                <>
                  <span className="text-secondary">- </span>
                  {age} {ageContent}
                </>
              )}
          </p>
          <hr className="my-2" />
          <p>
            {information}
          </p>
          <Link
            to={url("teachers.detail", { teacherId: teacherId })}
            state={{ teacherId: teacherId }}
            className="teacher-detail-button ml-auto mr-0 mt-2"
          >
            {buttonText}
          </Link>
        </div>
      </div>
    </>
  );
};

export default TeacherCard;
