import React, {useCallback, useEffect, useState} from "react";
import {useLocalization} from "../../../../hooks/useLocalization";
import {getLanguageIdByLanguageKey} from "../../../../components/Utility";
import useAuth from "../../../../hooks/useAuth";
import {
    GetBookUnitSentenceByMemberFilter,
    IncreaseSentenceClickCount
} from "../../../../services/SentenceBank/SentenceBankService";
import {Loading, NoRecordsFound, SentenceRow} from "../../../../components";
import usePageLeave from "../../../../hooks/usePageLeave";
import useAnalytics from "../../../../hooks/useAnalytics";
import {EventLogs} from "../../../../components/Constants";

const SentenceLearningList = () => {

    const strings = useLocalization();
    const { member } = useAuth();

    const pageLimit = 10;
    const analytics = useAnalytics();
    const { setCallback } = usePageLeave();

    const [sentences, setSentences] = useState([]);
    const [loading, setLoading] = useState(true);

    const [start, setStart] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [clickedSentenceIds, setClickedSentenceIds] = useState([]);

    const handleLoadMore = () => {
        if ((start + pageLimit) < totalRecords)
            setStart(start + pageLimit);

        analytics.useAnalytics(EventLogs.SENTENCE_BANK_LOAD_MORE_BUTTON_CLICKED, undefined, false, true);
    }

    const handleClickedSentence = (clickedSentenceId) => {
        setClickedSentenceIds(clickedSentences => [...clickedSentences, clickedSentenceId]);
    }

    const handlePageLeave = useCallback(() => {
        clickedSentenceIds.length > 0 &&
            IncreaseSentenceClickCount(clickedSentenceIds)
                .then()
                .catch()

        setClickedSentenceIds([]);
    }, [clickedSentenceIds]);

    useEffect(() => {
        clickedSentenceIds.length > 0 && setCallback(handlePageLeave);
    }, [setCallback, handlePageLeave]);

    useEffect(() => {
        if(member?.MemberId){
            const model = {
                start: start,
                length: pageLimit,
                memberId: member?.MemberId,
                languageTitleType: getLanguageIdByLanguageKey('al')
            };

            GetBookUnitSentenceByMemberFilter(model)
                .then(result => {
                    if(result.status === 200) {
                        const resultContent = JSON.parse(result.content);
                        setTotalRecords(resultContent.recordsTotal);
                        if (start === 0)
                            setSentences(resultContent.data);
                        else
                            setSentences(sentences => [...sentences, ...resultContent.data]);
                    }

                    setLoading(false);
                })
                .catch()
        }
    }, [pageLimit, start]);

    return (
        <div className="mt-4">
            <p className="text-[16px] font-bold">
                {strings.speaking_lesson.daily_lesson.sentence_bank.sentence_learning_list}
            </p>
            <div className="transparent-section mt-1">
                {loading && <Loading classnames="mb-5"/>}

                {!loading &&
                    <>
                        {sentences.length === 0 && <NoRecordsFound/>}

                        {sentences.length > 0 &&
                            <>
                                {sentences.map((item, index) => (
                                    <div key={index}>
                                        <SentenceRow
                                            data={item}
                                            showHr={sentences.length !== index + 1}
                                            handleClickedSentence={handleClickedSentence}
                                        />
                                    </div>
                                ))}
                                {totalRecords !== sentences.length &&
                                    <button
                                        className="text-center font-bold text-primary text-[14px] hover:text-secondary"
                                        onClick={handleLoadMore}
                                    >
                                        {strings.speaking_lesson.daily_lesson.sentence_bank.load_more}
                                    </button>
                                }
                            </>
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default SentenceLearningList;