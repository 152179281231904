import React, {useEffect, useState} from 'react'
import useAnalytics from '../../../hooks/useAnalytics';
import {useLocalization} from '../../../hooks/useLocalization'
import {Button, Dropdown, Loading, TermDropDown} from '../../../components';
import useAuth from '../../../hooks/useAuth';
import {
    ChangeTeacherTimeList,
    ChangeTeacherTimeNoRight,
    ChangeTeacherTimeNotAvailable,
    Frozen,
    InformationAccordion
} from '../components';
import {
    GetTeacherLessonTimeChangeListService,
    TeacherFreeLessonTimesService
} from '../../../services/Support/SupportService';
import {addTimeZone, getApplicationDirection, getTimeZone} from '../../../components/Utility';
import '../../../assets/css/dropdown_arrow.css'
import {useSelector} from 'react-redux';
import useTeacherWorkingTimes from "../../../hooks/useTeacherWorkingTimes";
import {GetAllHumanTeachers, GetTeacherFreeTimeByTeacherId} from '../../../services/Teacher/TeacherService';

const ChangeTeacherTimeHome = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const { member, token } = useAuth();
    const timeZone = getTimeZone(member.Timezone);
    const { beginTimes, endTimes, times} = useTeacherWorkingTimes(false);

    const terms = typeof(member.Terms) !== "undefined" && JSON.parse(member.Terms).filter(term => term.Status === 1);
    const language = useSelector(state => state.localizationStore.language);

    const [termId, setTermId] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingTeacher, setLoadingTeacher] = useState(false);
    const [lessonFrozen, setLessonFrozen] = useState(false);

    const [beginTime, setBeginTime] = useState({ id: "03:00", name: "06:00"});
    const [endTime, setEndTime] = useState({ id: "04:00", name: "07:00"});
    const selectedEndTimeIndex =  endTimes.findIndex(item => item.id === endTime.id);

    const [viewList, setViewList] = useState(false);
    const [lessonTimeList, setLessonTimeList] = useState([]);
    const [isAvailableToChange, setIsAvailableToChange] = useState(true);
    const [rightCount, setRightCount] = useState(0);
    const [changeHistory, setChangeHistory] = useState([]);

    const [teacherData, setTeacherData] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState({ id: -1, name: strings.support.teacher_time_change_information.select_teacher });

    const generalInformationData = [
        { id: 1, text: strings.support.teacher_time_change_information.information_one },
        { id: 2, text: strings.support.teacher_time_change_information.information_two },
        { id: 3, text: strings.support.teacher_time_change_information.information_three },
        { id: 4, text: strings.support.teacher_time_change_information.information_four },
        { id: 5, text: strings.support.teacher_time_change_information.information_five }
    ];

    //get informations for selected term
    const apiRequest = async termId => {
        try {
            const result = await GetTeacherLessonTimeChangeListService(termId, token);
            let resultMsg = JSON.parse(result.message);
            setChangeHistory(changeHistory => resultMsg?.teacherLessonTimeChangeHistory);
            //if there is an active lesson frozen
            if (resultMsg?.activeFreezeHistory !== "null") {
                setLessonFrozen(lessonFrozen => resultMsg.activeFreezeHistory);
            } else {
                setLessonFrozen(lessonFrozen => false);
            }

            if (result.statusCode !== 200) {
                if (resultMsg)
                    analytics.useAnalytics('Error_Occured', { error: `${resultMsg}` });
                else {
                    analytics.useAnalytics('Error_Occured', {
                        error: `An unexpected error occurred.`,
                    });
                }
                setLoading(loading => false)
                return;
            } else {
                setIsAvailableToChange(resultMsg?.isAvailableToChange);
                setLoading(loading => false);
                setRightCount(resultMsg.teacherLessonTimeChangeRightCount);


            }
        } catch (error) {
            setLoading(loading => false)
        }
    };

    //get teacher free times
    const timeListRequest = async (termId, beginTime, endTime) => {
        let bTime = beginTime.id + ":00";
        let eTime = endTime.id + ":00";
        try {
            if (selectedTeacher?.id !== -1){
                let model = {
                    teacherId: selectedTeacher.id,
                    termId: termId,
                    beginTime: bTime,
                    endTime: eTime
                };

                const result = await GetTeacherFreeTimeByTeacherId(model);
                const data = JSON.parse(result.content);
                if(result.status === 200 && data.teacherFreeLessonTimeItems.length > 0)
                    setLessonTimeList(data.teacherFreeLessonTimeItems);
            } else {
                const result = await TeacherFreeLessonTimesService(termId, bTime, eTime, token);
                let resultMsg = JSON.parse(result.message);
                if (result.statusCode !== 200) {
                    if (resultMsg) analytics.useAnalytics('Error_Occured', { error: `${resultMsg}` });
                    else {
                        analytics.useAnalytics('Error_Occured', {
                            error: `An unexpected error occurred.`,
                        });
                    }
                } else {
                    if (resultMsg?.teacherFreeLessonTimeItems.length > 0)
                        setLessonTimeList(resultMsg.teacherFreeLessonTimeItems);
                }
            }
        } catch (error) {
            setLoadingTeacher(loadingTeacher => false);
        } finally {
            setViewList(viewList => true);
            setLoadingTeacher(loadingTeacher => false);
        }
    };

    // updateSelectedEndTime fonksiyonu, seçilen başlangıç zamanına göre seçilen bitiş zamanını günceller.
    const updateSelectedEndTime = (beginTime) => {
        // selectedStartTime.id'nin times dizisindeki indeksini bulur.
        let index = times.indexOf(beginTime.id.slice(0, 5));
        // times dizisinin uzunluğunu alır.
        let length = times.length;

        // Eğer times dizisi boş değilse, işlemler devam eder.
        if(length > 0) {
            // Eğer seçilen başlangıç zamanının indeksi ile 2 eklenmiş değer times dizisinin uzunluğundan küçükse, bir sonraki zamanı seçilen bitiş zamanı olarak ayarlar.
            if (index + 2 < length) {
                setEndTime({
                    id: times[index + 1],
                    name: addTimeZone(times[index + 1], timeZone),
                });
            }
            // Eğer yukarıdaki koşullar sağlanmazsa, son zamanı seçilen bitiş zamanı olarak ayarlar.
            else {
                setEndTime({
                    id: times[length - 1],
                    name: addTimeZone(times[length - 1], timeZone),
                });
            }
        }
    }

    const changeTerm = term => {
        setTermId(term.TermId);
        apiRequest(term.TermId);
        setLoading(loading => true);
    };

    useEffect(() => {
        if (beginTime) {
            analytics.useAnalytics('Egitmen_Saat_Degisimi', {
                time: `${beginTime.name}`,
            });
        }
    }, [beginTime]);

    // selectedStartTime.id değiştiğinde, updateSelectedEndTime fonksiyonunu çağırır.
    useEffect(() => {
        updateSelectedEndTime(beginTime);
    }, [beginTime.id]);

    useEffect(() => {
        GetAllHumanTeachers()
            .then(result => {
                if(result.status === 200) {
                    setTeacherData(JSON.parse(result.content));
                }
            })
            .catch()
    }, [])

    return (
        <>

            <div className={`main-content ${getApplicationDirection(language) ? 'md:ml-7' : 'md:mr-7'}`}>
                <div className="mt-5 box-section px-[10px] xs:px-[10px] text-start">
                    <TermDropDown terms={terms} changeTerm={changeTerm} />
                </div>

                {loading ? (
                    <Loading />
                ) : (lessonFrozen ? (<Frozen data={lessonFrozen} history={changeHistory} />)
                    : (rightCount !== undefined && rightCount === 0 ? (
                        <ChangeTeacherTimeNoRight data={changeHistory} />
                    ) : (isAvailableToChange ? (
                        <>
                            <InformationAccordion
                                title={strings.support.teacher_time_change_information.information_title}
                                generalInformationData={generalInformationData}
                            />

                            {/* Time Select */}
                            <div className="transparent-section mt-5 text-sm text-center">
                                <h5 className="text-secondary mb-3">{strings.support.teacher_time_change_title}</h5>
                                <p>{strings.support.teacher_time_change.teacher_time_change_question}</p>
                                <div className='flex justify-center mt-2'>
                                    <Dropdown
                                        data={[{ id: -1, name: strings.support.teacher_time_change_information.select_teacher }, ...teacherData]}
                                        selected={selectedTeacher}
                                        setSelected={setSelectedTeacher}
                                        classnames="max-w-max !min-w-[340px]"
                                    />
                                </div>
                                <div className="flex items-center gap-3 justify-center mt-2">
                                    <Dropdown
                                        data={beginTimes}
                                        selected={beginTime}
                                        setSelected={setBeginTime}
                                    />
                                    <p>{strings.teachers.filter.and}</p>
                                    <Dropdown
                                        data={endTimes.slice(selectedEndTimeIndex)}
                                        selected={endTime}
                                        setSelected={setEndTime}
                                        isDisabled={endTimes.slice(selectedEndTimeIndex).length === 1}
                                    />
                                </div>

                                <div className="btn-outer-div">
                                    <Button
                                        type="submit"
                                        classnames={`button primary-button my-7 ${loadingTeacher && "disabled:opacity-25"} `}
                                        disabled={loadingTeacher}
                                        action={() => {
                                            analytics.useAnalytics(
                                                'Egitmen_Saat_Listele_Buton_Click',
                                            );
                                            setLessonTimeList(lessonTimeList => []);
                                            setLoadingTeacher(loadingTeacher => true);
                                            timeListRequest(termId, beginTime, endTime, token);
                                        }}
                                        text={loadingTeacher ? strings.general_information_messages.loading : strings.support.teacher_time_change.list_hours}
                                    />
                                </div>
                            </div>

                            {/* Time Select */}
                            {viewList && (
                                <ChangeTeacherTimeList 
                                    data={lessonTimeList}
                                    loadingTeacher={loadingTeacher}
                                    showTitle={false}
                                />
                            )}
                        </>
                    ) : (<ChangeTeacherTimeNotAvailable data={changeHistory} />)
                    )))}

                <div className="left-margin-bottom"></div>
            </div>

        </>
    )
}

export default ChangeTeacherTimeHome