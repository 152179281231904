//import jwtDecode from "jwt-decode";
import sign from 'jwt-encode';
import parsePhoneNumberFromString from "libphonenumber-js";
import {Awsome, Knowledgeable, NotStarted, Preparation} from "../assets/svg";
import {Accept, DefaultProfileAl, Female, Male, NoneGender} from "../assets/img";
import {DeviceOs, Gender} from "./Constants";

export const openModal = (overlay, modal) => {
    overlay?.current?.classList?.toggle('hidden');
    modal?.current?.classList?.toggle('hidden');
}

export const closeModal = (overlay, modal) => {
    overlay.current.classList.add('hidden');
    modal.current.classList.add('hidden');
}

export const openPopup = (modal) => {
    modal?.current?.classList?.remove('scale-0');
    modal?.current?.classList?.remove('scale-100');
}

export const closePopup = (modal) => {
    modal?.current?.classList?.add('scale-0');
    modal?.current?.classList?.remove('scale-100');
}

export const openCollapse = (collapseRef, collapseOverlayRef, language) => {
    getApplicationDirection(language) 
        ? collapseRef.current.classList.toggle("-translate-x-[250px]") 
        : collapseRef.current.classList.toggle("translate-x-[250px]") 
    collapseOverlayRef.current.classList.toggle("hidden")
}

//ISO Formatlı datetime içerikleri formatlamak için kullanılıyor
export const ISOStringToDate = (date, timeZone) => {
    let tmpDate = new Date(date);

    if (date.includes("T")) {
        const dateArr = date.split("T");
        const dateTmpTime = dateArr[1].split(":");
        tmpDate = new Date(Date.UTC(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), dateTmpTime[0], dateTmpTime[1], dateTmpTime[2].split(".")[0]));
    }

    let returnDate = tmpDate.getFullYear() + "-" +  ("0" + (tmpDate.getMonth() + 1)).slice(-2) + "-" +("0" + tmpDate.getDate()).slice(-2);
    let tmpTime = tmpDate.getUTCHours() + ":" + tmpDate.getUTCMinutes() + ":" + "00";
    return returnDate + "T" + addTimeZone(tmpTime, timeZone)
}

export const getTodayForLessonDate = () => {
    const date = new Date();
    return (date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2));
}

export const fixDateFormatForAl = (date) => {
    let formattedDate = date ? date.split("/") : "";
    let newDate = parseInt(formattedDate[0]) + "/" + parseInt(formattedDate[1]) + "/" + parseInt(formattedDate[2]);
  
    return newDate;
}

export const parseDateForLesson = date => {
    return (date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2));
}

export const parseDateForLessonTurkishCulture = date => {
    return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
}

export const getFormattedTime = (date) => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
}

export const getDayDiff = (dateOne, dateTwo) => {
    let date1 = Date.UTC(dateOne.getFullYear(), dateOne.getMonth(), dateOne.getDate());
    let date2 = Date.UTC(dateTwo.getFullYear(), dateTwo.getMonth(), dateTwo.getDate());

    return parseInt((date1 - date2) / 86400000);
}

export const getTimeDiff = (dateFuture, dateNow) => {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let difference = '';
    if (days > 0) {
        difference += days === 1 ? `${days} #day#, ` : `${days} #day#, `;
    }

    difference +=
        hours === 0 || hours === 1 ? `${hours} #hour#, ` : `${hours} #hour#, `;

    difference +=
        minutes === 0 || hours === 1 ? `${minutes} #minute#` : `${minutes} #minute#`;

    return difference;
}

export const decodeToken = token => {
    //return jwtDecode(token);
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const encodeToken = (data) => {
    const token = sign(data, process.env.REACT_APP_JWT_SECURITYKEY);
    return token;
}

export const checkNumber = e => {

    let keypressed = e.keyCode;

    if ((keypressed >= 48 && keypressed <= 57) ||
        (keypressed >= 96 && keypressed <= 105)
        || keypressed === 8
        || keypressed === 27
        || keypressed === 46
        || keypressed === 9
        || (keypressed >= 35 && keypressed <= 40)) {
        return true;
    }

    return false;
}

export const getCardYears = () => {
    const thisYear = new Date().getFullYear();
    let yearArray = [];

    for (let year = thisYear; year <= (thisYear + 14); year++) {
        const tempYearObject = {
            id: year,
            name: year.toString()
        }
        yearArray.push(tempYearObject);
    }

    return yearArray;
}

export const getCardMonths = () => {
    let monthArray = [];

    for (let month = 1; month <= 12; month++) {
        const tempMonthObject = {
            id: month,
            name: month < 10 ? "0" + month : month.toString()
        }
        monthArray.push(tempMonthObject);
    }

    return monthArray;
}

export const formatCreditCardNumber = value => {
    const v = value
        .replace(/\s+/g, "")
        .replace(/[^0-9]/gi, "")
        .substr(0, 16);
    const parts = [];

    for (let i = 0; i < v.length; i += 4) {
        parts.push(v.substr(i, 4));
    }

    return parts.length > 1 ? parts.join(" ") : value;
}

export const openDropdown = () => {
    let dropdownMenu = document.getElementById("dropdown_menu");
    dropdownMenu.classList.toggle("hidden");
};

export const openWideDropdown = () => {
    let dropdownMenu = document.getElementById('dropdown_menu');
    let bottomArrow = document.getElementsByClassName('bottom-arrow')[0];
    dropdownMenu.classList.toggle('hidden');
    bottomArrow.classList.toggle('rotate-180');
}

export const calcDiscount = (price, discount) => {
    //return parseFloat(price.replace('.', '').replace(',', '.')) * discount
    return price * discount
}


export const formatPrice = (price, symbol) => {

    const formattedOutput = new Intl.NumberFormat("en", {
        style: 'currency',
        currency: process.env.REACT_APP_CURRENCY,
        minimumFractionDigits: 2,
    });

    return formattedOutput.format(price).replace(symbol, '')
}


export const formatPriceWithoutCurrency = (price) => {

    const formattedOutput = new Intl.NumberFormat(process.env.REACT_APP_LANG, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return formattedOutput.format(price);
}

export const getInstallments = (max, strings) => {
    let installmentArray = [];

    for (let ins = 1; ins <= max; ins++) {
        if(ins === 1){
            installmentArray.push({ value: 0, option: strings.market.checkout.one_shot});
        } else {
            installmentArray.push({ value: ins, option: ins });
        }
    }

    return installmentArray;
}

export const validateEmail = mail => {
    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
    return mail.match(mailFormat) ? true : false;

}

export const addTimeZone = (tm, timeZone) => {
    let today = new Date();
    let timeArr = tm.split(":");
    let time = new Date(today.getFullYear(), today.getMonth(), today.getDay(), parseInt(timeArr[0]), parseInt(timeArr[1]));
    let returnTime = new Date(time.setMinutes(time.getMinutes() + parseInt(timeZone)));
    return ("0" + returnTime.getHours()).slice(-2) + ':' + ("0" + returnTime.getMinutes()).slice(-2);
}

// export const getTimeZone = timeZone => {
//     return timeZone ? timeZone : new Date().getTimezoneOffset();
// }

export const getTimeZone = timeZone => {
    let timeZoneInMinutes = -1 * new Date().getTimezoneOffset();
    return timeZoneInMinutes;
}

export const getyyyyMMddDate = (date) => {
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);

    let formattedDate = year + '-' + month + '-' + day;
    return formattedDate;
}

export const getyyyyMMddDate2 = (dateString) => {
    const dateSplit = dateString.split(".");

    return dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit["0"];
}

// 2023-11-8 formatında gelen tarihler 2023-11-08 olarak formatlanır
export const formatNoneZeroDateToZeroDateForDash = (date) => {
    const parts = date.split('-');

    if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];

        if (month.length === 1) {
            parts[1] = '0' + month;
        }

        if (day.length === 1) {
            parts[2] = '0' + day;
        }

        return parts.join('-');
    } else {
        return 'Invalid Date';
    }
}

// 8.11.2023 formatında gelen tarihler 08.11.2023 olarak formatlanır
export const formatNoneZoreDateToZeroDateForDot = (date) => {
    let dateParts = date.split(".");

    let day = dateParts[0];
    let month = dateParts[1];

    if (day < 10 && day.length === 1) {
        day = "0" + day;
    }

    if (month < 10 && month.length === 1) {
        month = "0" + month;
    }

    date = day + "." + month + "." + dateParts[2];
    return date;
}

//Boş gelen verileri ekrana istediğimiz metin ile yazdırmamızı sağlıyor
export const isItSpecified = (value, message) => {
    return value ? value : message;
};

export const colorForStatus = (status) => {
    switch (status) {
        case 1:
            return 'bg-active';
        case 2:
            return 'bg-attended';
        case 3:
            return 'bg-missed';
        case 4:
            return 'bg-postponed';
        case 5:
            return 'bg-frozen';
        case 6:
            return 'bg-holiday';
        case 7:
            return 'bg-weekend';
        case 8:
            return 'bg-no-lesson';
        case 9:
            return 'bg-system-postponed';
        case 10:
            return 'bg-deleted';
        default:
            return "ff0000";
    }
};

//bookTitle'larda çevirileri yazdırmak için kullanılan method
export const calendarTranslationByTitle = (bookTitle, language) => {
    switch (bookTitle) {
        case "Dersi kaçırdı":
            return language === "tr" ? "Dersi Kaçırdı" : language === "al" ? "درس فائت" : language === "en" ? 'Missed Call' : bookTitle;
        case "Erteledi":
            return language === "tr" ? "Erteledi" : language === "al" ? "مؤجل" : language === "en" ? 'Postponed' : bookTitle;
        case "Dondurdu":
            return language === "tr" ? "Dondurdu" : language === "al" ? "مُجَمَّد" : language === "en" ? 'Frozen' : bookTitle;
        case "Tatil":
            return language === "tr" ? "Tatil" : language === "al" ? "عطلة" : language === "en" ? 'Holiday' : bookTitle;
        case "Hafta sonu":
            return language === "tr" ? "Hafta Sonu" : language === "al" ? "نهاية الإسبوع " : language === "en" ? 'Weekend' : bookTitle;
        case "Ders yok":
            return language === "tr" ? "Ders Yok" : language === "al" ? "لايوجد درس" : language === "en" ? 'No Lesson' : bookTitle;
        case "Sistem erteleme":
            return language === "tr" ? "Sistem Erteleme" : language === "al" ? "تأجيل النظام" : language === "en" ? 'System Postponed' : bookTitle;
        case "Silindi":
            return language === "tr" ? "Silindi" : language === "al" ? "تم الحذف" : language === "en" ? 'Deleted' : bookTitle;
        default:
            return "";
    }
};

//get browser name
export const getBrowserName = () => {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "chrome";
    } else if (userAgent.match(/edg/i)) {
        browserName = "edge";
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
        browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
        browserName = "opera";
    } else {
        browserName = "No browser detection";
    }
    return browserName
}

export const getOS = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;

    if (/Mac/.test(platform)) {
        return 'Mac OS';
    } else if (/Win/.test(platform)) {
        return 'Windows';
    } else if (/iPhone|iPad|iPod/.test(userAgent)) {
        return 'iOS';
    } else if (/Android/.test(userAgent)) {
        return 'Android';
    } else if (/Linux/.test(platform)) {
        return 'Linux';
    }

    return null;
}

export const getPermissionTutorialVideoUrl = (operatingSystem, browser) => {
    // Default değerleri belirle
    const defaultOperatingSystem = 'Windows';
    const defaultBrowser = 'chrome';

    // Video URL'lerini tanımla
    const videoUrls = {
        Windows: {
            chrome: 'https://www.youtube.com/watch?v=AqKKauBKOhs',
            edge: 'https://www.youtube.com/watch?v=kwga4EzYMo0',
            firefox: 'https://www.youtube.com/watch?v=3pf8MR2z_As',
        },
        macOS: {
            chrome: 'https://www.youtube.com/watch?v=NXdUMEkhIWE',
            edge: 'https://www.youtube.com/watch?v=jiWBsJJhj0Y',
            firefox: 'https://www.youtube.com/watch?v=rPUHjI-mQhg',
            safari: 'https://www.youtube.com/watch?v=hwEHVXt42CQ',
        },
        default: {
            chrome: 'https://www.youtube.com/watch?v=AqKKauBKOhs',
        },
    };

    // İşletim sistemini kontrol et
    const selectedOperatingSystem = videoUrls[operatingSystem] ? operatingSystem : defaultOperatingSystem;

    // Tarayıcıyı kontrol et
    const selectedBrowser = videoUrls[selectedOperatingSystem][browser]
        ? browser
        : defaultBrowser;

    // Video URL'ini döndür
    return videoUrls[selectedOperatingSystem][selectedBrowser];
}


export const getDeviceOSName = () => {
    const userAgent = window.navigator.userAgent;

    if (userAgent.indexOf('Windows') !== -1) {
      return 'Windows';
    } else if (userAgent.indexOf('Mac') !== -1) {
      return 'macOS'
    } else if (userAgent.indexOf('Linux') !== -1) {
      if (userAgent.indexOf('Ubuntu') !== -1) {
        return 'Linux Ubuntu';
      } else if (userAgent.indexOf('Fedora') !== -1) {
        return 'Linux Fedora';
      } else if (userAgent.indexOf('Debian') !== -1) {
        return 'Linux Debian';
      } else if (userAgent.indexOf('Mint') !== -1) {
        return 'Linux Mint';
      } else {
        return 'Linux';
      }
    } else {
      return 'Bilinmeyen İşletim Sistemi';
    }
}

export const getBrowserVersion = () => {
    var ua= navigator.userAgent;
    var tem; 
    var M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
    return M.join(' ');
}

export const getLanguageNameForService = (language) => {
    if(language === 'al'){
        return process.env.REACT_APP_LANG
    } else if (language === 'en') {
        return process.env.REACT_APP_LANG_EN
    } else {
        return process.env.REACT_APP_LANG
    }
}

export const getLanguageNameForCurrentLanguage = (languageKey, currentLanguage) => {
    if(languageKey === 'al'){
        return currentLanguage === 'al' ? 'عربي' : currentLanguage === 'en' ? 'Arabic' : '';
    }
    else if(languageKey === 'en'){
        return currentLanguage === 'al' ? 'إنجليزي' : currentLanguage === 'en' ? 'English' : '';
    }
}

// Dil arapça olduğunda true başka bir dil olduğunda ise false döndürür ve buna göre uygulama yönünü ayarlarız.
export const getApplicationDirection = (language) => {
    if(language === 'al'){
        return true;
    }

    return false;
}

export const getCountryNameByCountryCode = (countryCode, language) => {
    if(countryCode === 'IQ'){
        return language === 'al' ? 'العراق' : language === 'en' ? 'Iraq' : '';
    } else if (countryCode === 'JO') {
        return language === 'al' ? 'الأردن' : language === 'en' ? 'Jordan' : '';

    } else if (countryCode === 'SA') {
        return language === 'al' ? 'السعودية' : language === 'en' ? 'Saudi Arabia' : '';
        
    } else if (countryCode === 'QA') {
        return language === 'al' ? 'قطر' : language === 'en' ? 'Qatar' : '';
        
    } else if (countryCode === 'SY') {
        return language === 'al' ? 'سورية' : language === 'en' ? 'Syria' : '';
        
    } else if (countryCode === 'KW') {
        return language === 'al' ? 'الكويت' : language === 'en' ? 'Kuwait' : '';
        
    } else if (countryCode === 'OM') {
        return language === 'al' ? 'عمان' : language === 'en' ? 'Oman' : '';
        
    } else if (countryCode === 'TR') {
        return language === 'al' ? 'ديك رومى' : language === 'en' ? 'Turkey' : '';
    } else {
        return language === 'al' ? '' : language === 'en' ? '' : '';
    }
}

export const getLanguageIdByLanguageKey = (language) => {
    switch(language){
        case 'al':
            return 3;
        case 'en':
            return 4;
        default:
            return 3;
    }
}

export const convertToTurkishUpper = (text) => {
    const turkishChars = ['ı', 'i', 'ş', 'ğ', 'ü', 'ö', 'ç', 'İ', 'I', 'Ş', 'Ğ', 'Ü', 'Ö', 'Ç'];
    const englishChars = ['I', 'I', 'S', 'G', 'U', 'O', 'C', 'I', 'I', 'S', 'G', 'U', 'O', 'C'];
  
    let converted = text;
  
    for (let i = 0; i < turkishChars.length; i++) {
      const regex = new RegExp(turkishChars[i], 'g');
      converted = converted.replace(regex, englishChars[i]);
    }
  
    converted = converted.toUpperCase();
    return converted;
};

export const getAnswerTitleByIndex = (index) => {
    switch (index) {
        case 0:
            return "a)";
        case 1:
            return "b)";
        case 2:
            return "c)";
        case 3:
            return "d)";
        default:
            return "";
    }
}

export const showDateWithFormat = dt => {
    let dateArr = dt.split(".");
    let date = ("0" + parseInt(dateArr[0])).slice(-2) + "." + ("0" + parseInt(dateArr[1])).slice(-2) + "." + dateArr[2];
    //let dateFuture = new Date(parseInt(dateArr[2]), parseInt(dateArr[1])-1)
    return date;
}

// Kopyala/yapıştır ile latin harfleri/karakterleri dışında input girilmesi engelleniyor.
export const allowPasteLatinCharacters = (e) => {
    const pastedText = e.clipboardData.getData('text');

    const latinAlphabetRegex = /^[a-zA-Z\s]*$/;

    if (!latinAlphabetRegex.test(pastedText)) {
        e.preventDefault();
        return;
    }
};

//onKeyDown sırasında latin harfleri dışında karakter girilmesi engelleniyor.
export const allowLatinCharacters = (e) => {
    const turkishVowels = /[iöüşğçİ0123456789]/i;

    if (!e.key.match(/[a-zA-Z]/) && e.key !== " " && !turkishVowels.test(e.key)) {
        e.preventDefault();
    }
};

// Dakika, saniye, salise cinsinden değerleri saniyeye çevirir.
export const convertTimeFormatToSeconds = (timeFormat) => {
    let timeParts = timeFormat.split(':');

    let hours = parseInt(timeParts[0]);
    let minutes = parseInt(timeParts[1]);
    let seconds = parseFloat(timeParts[2].replace(',', '.'));

    let totalSeconds = hours * 3600 + minutes * 60 + seconds;

    return totalSeconds;
}


//Telefon numarasını ülkeye göre maskeleyen fonksiyon.
export const maskPhoneNumber = (phoneNumber) => {
    try {
        const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
        if (parsedPhoneNumber) {
            return parsedPhoneNumber.formatInternational();
        } else {
            return "Invalid phone number";
        }
    } catch (error) {
        return "Invalid phone number";
    }
}

export const openAnswerStatus = (ref) => {
    ref?.current?.classList?.remove('scale-0');
}

export const closeAnswerStatus = (ref) => {
    ref?.current?.classList?.add('scale-0');
    ref?.current?.classList?.remove('scale-100');
}

//Boş karakter kontrolü yapan fonksiyon
export const hasNonWhitespaceCharacter = (text) =>{
    return /\S/.test(text);
}

//Soru tipleri loglarının(egzersiz) dinamik kontrol edilmesi için yardımcı fonksiyon.
export const getLogDescriptionAndParameter = (logObject) => {
    if(logObject) {
        //Log açıklaması
        let logDescription = logObject.logDescription;
        let parameterObject = {};

        logObject?.parameters.length > 0 &&
        logObject.parameters.map((item) => {
            //Default log parameter key
            let parameterName = item.name;
            //Default log parameter value
            let parameterData = item.data;

            parameterObject[parameterName] = parameterData;
        })

        return { logDescription, parameterObject };
    }

    return false;
}

export const formatDateWithTime = (inputDate, language = 'al', showHourText = false, showHour = true) => {
    const date = new Date(inputDate);

    const alMonthNames = [
        "كانون الثاني", "شباط", "آذار",
        "نيسان", "أيار", "حزيران",
        "تموز", "آب", "أيلول",
        "تشرين الأول", "تشرين الثاني", "كانون الأول"
    ];
    const trHourText = "saat";

    const enMonthNames = [
        "January", "February", "March",
        "April", "May", "June",
        "July", "August", "September",
        "October", "November", "December"
    ];
    const enHourText = "hour";

    const monthNames = (language === 'tr') ? alMonthNames : enMonthNames;
    const hourText = (language === 'tr') ? trHourText : enHourText;
    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];

    // Yıl, Ay, Gün, Saat, Dakika değerlerini alır
    const year = date.getFullYear();
    const day = date.getDate();
    // Tek haneli ise başına "0" ekler
    const hours = ('0' + date.getHours()).slice(-2);
    // Tek haneli ise başına "0" ekler
    const minutes = ('0' + date.getMinutes()).slice(-2);

    return `${day} ${monthName} ${year} ${showHourText ? hourText : ""} ${showHour ? `${hours}:${minutes}` : ""} `;
}

export const getActionIconByActionStatus = (status) => {
    switch (status) {
        case 100:
            return { icon: NotStarted, className: "border border-[#909195]" };
        case 200:
            return { icon: Preparation, className: "bg-[#B96634]"};
        case 300:
            return { icon: Accept, className: "bg-[#CB8132]" };
        case 400:
            return { icon: Knowledgeable, className: "bg-[#938EBA]" };
        case 500:
            return { icon: Awsome, className: "bg-[#55418F]" };
        default:
            return "";
    }
}

export const isJWT = (token) => {
    try {
        const tokenParts = token.split('.');
        return tokenParts.length === 3;
    } catch (error) {
        return false;
    }
}

export const parseMobileKey = (key) => {

    const decodedToken = atob(key);

    const parsedToken = new URLSearchParams(decodedToken);

    const memberId = parsedToken.get("MemberId");
    const status = parsedToken.get("Status");
    const path = parsedToken.get("Path");
    const categoryId = parsedToken.get("CategoryId");
    const day = parsedToken.get("Day");
    const minute = parsedToken.get("Minute");
    const month = parsedToken.get("Month");
    const memberSlogan = parsedToken.get("MemberSlogan");

    return {
        MemberId: memberId,
        Status: status,
        Path: path,
        CategoryId: categoryId,
        Day: day,
        Minute: minute,
        Month: month,
        MemberSlogan: memberSlogan
    };
}

export const parseMobileKeyToJson = (key) => {
    const decodedToken = atob(key);
    const uriDecodedToken = decodeURIComponent(decodedToken);
    return JSON.parse(uriDecodedToken);
}

export const parseMarketRouteKey = (key) => {

    const decodedToken = atob(key);

    const parsedToken = new URLSearchParams(decodedToken);

    const categoryId = parsedToken.get("CategoryId");
    const day = parsedToken.get("Day");
    const minute = parsedToken.get("Minute");
    const month = parsedToken.get("Month");
    const memberSlogan = parsedToken.get("MemberSlogan");

    return {
        CategoryId: categoryId,
        Day: day,
        Minute: minute,
        Month: month,
        MemberSlogan: memberSlogan
    };
}

export const encodeToBase64AndUri = (data) => {
    const jsonString = JSON.stringify(data);
    const encodedString = btoa(encodeURIComponent(jsonString));

    return encodedString;
}

export const decodeToBase64AndUri = (data) => {
    const decodedString = decodeURIComponent(atob(data));
    return JSON.parse(decodedString);
}

export const formatDateMarket = (date) => {
    return (date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2));
}

export const addDaysToDate = (date, days) => {
    const result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
}

// İki arrayi karşılaştırır ve ilk array içerisinde ikinci array elemanlarından kaç adet bulunduğunu döndürür
export const compareTwoArrays = (arrayOne, arrayTwo) => {
    const commonElements = arrayOne.filter(item1 => arrayTwo.some(item2 => item2.id === item1.id));
    return commonElements.length;
}

// Video pratik sayfası için taglar içerisinden veri bulunur
export const findTagsByIdAndCategory = (ids, data) => {
    let foundTags = [];

    // Veri üzerinde döngü
    data.forEach(function(category, index) {
        // İstenilen kategoriye mi ait olduğunu kontrol et
        // Etiketleri döngü ile kontrol et
        category.tags.forEach(function(tag) {
            // İstenilen ID'ye sahip mi kontrol et
            if (ids.includes(tag.id)) {
                // Bulunan etiketi listeye ekle
                var foundCategory = foundTags.find(entry => entry.category === (index === 0 ? "level" : "interest"));
                if (foundCategory) {
                    foundCategory.selectedCategories += ", " + tag.name;
                } else {
                    foundTags.push({ category: (index === 0 ? "level" : "interest"), selectedCategories: tag.name });
                }
            }
        });
    });

    const level = foundTags.find(l => l.category === 'level')?.selectedCategories || "";
    const interest = foundTags.find(i => i.category === 'interest')?.selectedCategories || "";

    return { level: level, interest: interest};
}

export const sendEventByDefaultParameters = (event, analytics, defaultParameters, parameters, emptyParameters) => {
    if(defaultParameters) {
        const mergedObject = Object.assign({}, defaultParameters, parameters);
        analytics.useAnalytics(event, mergedObject);
    } else {
        const mergedObject = Object.assign({}, parameters, emptyParameters);
        analytics.useAnalytics(event, mergedObject);
    }
}

export const getColorForScore = (value) => {
    if (value >= 80) return '#66bb6a'; // 80-100 arası
    if (value >= 60) return '#F16C00'; // 60-79 arası
    return '#f25961'; // 0-59 arası
};

export const timeStampToSecond = (input) => {
    const parts = input.split(':');
    const second = parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseFloat(parts[2]);
    return parseFloat(second.toFixed(1));
}

export const getLevelCollectionById = (levelCollectionId) => {
    switch (levelCollectionId) {
        case 2:
            return "Starter";
        case 3:
            return "Beginner";
        case 4:
            return "Intermediate";
        case 5:
            return "Advanced";
        default:
            return "";
    }
}

export const getUseCaseById = (useCaseId) => {
    switch (useCaseId) {
        case 1:
            return "To use in business life";
        case 2:
            return "To rise in your career";
        case 3:
            return "To use in Business Meetings";
        case 4:
            return "Participating in an interview/job interview";
        case 5:
            return "Overseas business trips";
        case 6:
            return "Overseas vacation";
        case 7:
            return "Going abroad to work";
        case 8:
            return "Communication with close relatives or friends";
        case 9:
            return "Students who will use it at school";
        default:
            return "";
    }
}

// 2024-03-01T09:02:14.1266667 -> Ocak 2022
export const formatDateWithMonthAndYear = (inputDate, language = 'al') => {
    const date = new Date(inputDate);

    const alMonthNames = [
        "كانون الثاني", "شباط", "آذار",
        "نيسان", "أيار", "حزيران",
        "تموز", "آب", "أيلول",
        "تشرين الأول", "تشرين الثاني", "كانون الأول"
    ];

    const enMonthNames = [
        "January", "February", "March",
        "April", "May", "June",
        "July", "August", "September",
        "October", "November", "December"
    ];

    const monthNames = (language === 'al') ? alMonthNames : enMonthNames;
    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];

    const year = date.getFullYear();

    return `${monthName} ${year}`;
}

export const getDefaultPhotoByGender = (gender, memberId = "") => {
    const officialMemberId = process.env.REACT_APP_OFFICIAL_MEMBER_ID;

    if (memberId === officialMemberId) {
        return DefaultProfileAl;
    }

    switch (Number(gender)) {
        case Gender.MALE:
            return Male;
        case Gender.FEMALE:
            return Female;
        case 2:
        case null:
        case undefined:
        default:
            return NoneGender;
    }
};

export const generateTimes = (startHour, endHour, intervalMinutes) => {
    const slots = [];
    let currentTime = new Date();
    currentTime.setHours(startHour, 0, 0, 0);

    const endTime = new Date();
    endTime.setHours(endHour, 0, 0, 0);

    while (currentTime <= endTime) {
        const hours = String(currentTime.getHours()).padStart(2, '0');
        const minutes = String(currentTime.getMinutes()).padStart(2, '0');
        slots.push(`${hours}:${minutes}`);

        currentTime.setMinutes(currentTime.getMinutes() + intervalMinutes);
    }

    return slots;
}

export const getOSId = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;

    if (/Mac/.test(platform)) {
        return DeviceOs.MACOS;
    } else if (/Win/.test(platform)) {
        return DeviceOs.WINDOWS;
    } else if (/iPhone|iPad|iPod/.test(userAgent)) {
        return DeviceOs.IOS;
    } else if (/Android/.test(userAgent)) {
        return DeviceOs.ANDROID;
    } else if (/Linux/.test(platform)) {
        return DeviceOs.LINUX;
    }

    return DeviceOs.NONE;
}

/**
 * '2023-11-20' formatındaki bir tarih string'ini '20231120' formatında bir integer'a dönüştürür.
 *
 * @param {string} dateString - 'YYYY-MM-DD' formatında bir tarih string'i
 * @returns {number} YYYYMMDD formatında bir integer, eğer giriş geçersizse 0 döner
 */
export const formatDateToCompactInteger = (dateString) => {
    // Gelen string'i '-' karakterine göre bölelim
    const parts = dateString.split('-');

    // Eğer doğru formatta değilse, 0 döndürelim
    if (parts.length !== 3) {
        return null;
    }

    // Yıl, ay ve günü birleştirelim ve integer'a çevirelim
    const [year, month, day] = parts;
    return parseInt(`${year}${month}${day}`);
}

export const removePunctuation = (str) => {
    return str.replace(/[^\w\s]/g, '').replace(/\s+/g, ' ');
};

/**
 * 'GG.AA.YYYY' formatındaki bir tarih stringini 'YYYY-MM-DD' formatına dönüştürür.
 * 
 * @param {string} dateString - 'GG.AA.YYYY' formatında bir tarih string'i (örn. '15.10.2024')
 * @returns {string} 'YYYY-MM-DD' formatında bir tarih string'i, eğer giriş geçersizse null döner
 */
export const formatJustDateDotToDash = (dateString) => {
    const parts = dateString.split('.');
    const [day, month, year] = parts;
    const isoDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    return isoDate;
}

// Mikrofon izin durumunu kontrol eden fonksiyon
export const checkMicrophoneAllowed = async () => {
    let microphonePermissionStatus = navigator.permissions.query({ name: 'microphone' })
        .then(permissionStatus => {
            return permissionStatus.state
        })
    return await microphonePermissionStatus
}

// Bildirim izin durumunu kontrol eden fonksiyon
export const checkNotificationAllowed = async () => {
    if (!("Notification" in window))
        return false; // Tarayıcı bildirimleri desteklemiyor

    const permission = await Notification.requestPermission();
    return permission;
};

export const findTagIdsAndCategory = (ids, data) => {
    let foundTags = [];

    // Veri üzerinde döngü
    data.forEach(function(category, index) {
        // Etiketleri döngü ile kontrol et
        category.tags.forEach(function(tag) {
            // İstenilen ID'ye sahip mi kontrol et
            if (ids.includes(tag.id)) {
                // Bulunan etiketi listeye ekle
                let foundCategory = foundTags.find(entry => entry.category === (index === 0 ? "level" : "interest"));
                if (foundCategory) {
                    foundCategory.selectedIds.push(tag.id);
                } else {
                    foundTags.push({ category: (index === 0 ? "level" : "interest"), selectedIds: [tag.id] });
                }
            }
        });
    });

    const levelIds = foundTags.find(l => l.category === 'level')?.selectedIds || [];
    const interestIds = foundTags.find(i => i.category === 'interest')?.selectedIds || [];

    return { level: levelIds, interest: interestIds };
}