import classNames from "classnames";
import React from "react";
import { useSelector } from 'react-redux';
import { getAnswerTitleByIndex, getApplicationDirection } from "../../../../components/Utility";

const QuizReportQuestions = (props) => {

    const { quizQuestions, quizReport } = props;
    const language = useSelector(state => state.localizationStore.language);

    return (
        <div className="transparent-section mt-10 text-sm text-start pb-5 mb-10">
            {quizQuestions.map((item, questionIndex) => (
                <div
                    key={questionIndex}
                    className={classNames("flex flex-col", {
                        "mt-5": questionIndex !== 0
                    })}
                >
                    <p dir="ltr" className="w-full text-center mt-2 font-bold">
                        <span>{questionIndex + 1}) </span>
                        <span dangerouslySetInnerHTML={{ __html: item.content.replace(/\n/g, '<br>') }}></span>
                    </p>
                    <div className="flex flex-col mt-4 items-center">
                        {item.questionAnswers.map((answer, index) => {

                            // Kullanıcının soruyu boş geçmediyse doğru cevabı yeşil yakıyoruz.
                            let rightAnswerButton = (answer.isCorrectAnswer) && (quizReport.questions[questionIndex].userAnswer !== -1);
                            // Kullanıcının seçtiği cevap yanlışsa seçili cevabı kırmızı yakıyoruz.
                            let wrongAnswerButton = (quizReport.questions[questionIndex].userAnswer !== quizReport.questions[questionIndex].rightAnswer && quizReport.questions[questionIndex].userAnswer === answer.id)

                            return (
                                <button
                                    key={index}
                                    type="button"
                                    className={classNames("w-full max-w-[330px] h-[50px] leading-[22px] relative overflow-hidden rounded-[10px] border border-onboarding-border mb-4 lg:mb-5 flex items-center cursor-default", {
                                        "pl-[70px] pr-7": !getApplicationDirection(language),
                                        "pr-[70px] pl-7": getApplicationDirection(language),
                                        "bg-green": rightAnswerButton,
                                        "bg-red": wrongAnswerButton,
                                    })}
                                >
                                    <div
                                        dir="ltr"
                                        className={classNames("onboarding-button-left", {
                                            "border-r": !getApplicationDirection(language),
                                            "border-l": getApplicationDirection(language),
                                            "bg-green border-white text-white": rightAnswerButton,
                                            "bg-red border-white text-white": wrongAnswerButton,
                                        })}
                                    >
                                        <span className="font-bold">{getAnswerTitleByIndex(index)}</span>
                                    </div>
                                    <p
                                        className={classNames("text-start", {
                                            "text-white": (rightAnswerButton || wrongAnswerButton),
                                            "text-[14px] leading-[17px]": answer.answer.content.length > 60,
                                        })}
                                    >
                                        {answer.answer.content}
                                    </p>
                                </button>
                            )
                        })}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default QuizReportQuestions