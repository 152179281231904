import React from 'react'
import { useState } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { languages } from "../localizations"
import { changeLanguage } from "../store/LocalizationSlice";
import { Sa_Flag, Usa_Flag } from '../assets/img';
import { getApplicationDirection, getLanguageNameForCurrentLanguage } from './Utility';

const ChangeLanguage = (props) => {

    const { theme, toTop } = props;

    const language = useSelector(state => state.localizationStore.language);
    const flag = language === 'al' ? Sa_Flag : language === 'en' ? Usa_Flag : '';
    const dispatch = useDispatch();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const dropDownRef = useRef();
    const dropDownGeneralRef = useRef();

    return (
        <div ref={dropDownGeneralRef} className={`${(toTop && getApplicationDirection(language)) ? "relative" : "flex flex-col items-end ml-2"} ${(toTop && !getApplicationDirection(language)) ? "relative" : "flex flex-col items-end mr-2"}`}>
            <button id="dropdown" className="mt-0 max-w-full" onClick={() => setIsDropdownOpen(isDropdownOpen => !isDropdownOpen)} type="button">
                <div className='flex gap-1 items-center '>
                    <img src={flag} className={`w-[34px] h-[20px] rounded transition-colour duration-300 border ${theme === "light" ? "border-onboarding-border" : "border-white"}`} />
                    <span className={`material-symbols-outlined transition-transform duration-300 ${theme === "light" ? "text-white" : "text-nav-button-border"} ${isDropdownOpen ? 'rotate-180' : ''}`}>
                        expand_more
                    </span>
                </div>
            </button>

            {/*<!-- Dropdown menu -->*/}
            <div id="dropdown_menu" 
                ref={dropDownRef} 
                className={`w-full mt-[2px] z-10 min-w-max rounded transition-all duration-300 
                    ${(toTop && getApplicationDirection(language)) ? "absolute bottom-8 left-0 origin-bottom-left" : ""} 
                    ${(toTop && !getApplicationDirection(language)) ? "absolute bottom-8 right-0 origin-bottom-right" : ""} 
                    ${theme === "light" ? "bg-white" : "bg-primary"} 
                    ${isDropdownOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-50'} 
                    ${(!toTop && getApplicationDirection(language)) ? 'origin-top-left' : ''} 
                    ${(!toTop && !getApplicationDirection(language)) ? 'origin-top-right' : ''}
                `}>
                <ul className="py-1">
                    {
                        Object.entries(languages).map(([key]) => {
                            return (
                                <li key={key}>
                                    <a onClick={() => {
                                        dispatch(changeLanguage(key));
                                        setIsDropdownOpen(isDropdownOpen => !isDropdownOpen);}} 
                                        className={`block cursor-pointer py-1 my-1 px-2 mx-[1px] text-center ${theme === "light" ? "hover:bg-primary hover:text-white" : "hover:bg-white hover:text-primary text-white"}`}
                                    >
                                        {getLanguageNameForCurrentLanguage(key, language)}
                                    </a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>

        </div>
    )
}

export default ChangeLanguage