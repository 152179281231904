import { Icon_Confused } from "../../../assets/svg";
import { useLocalization } from "../../../hooks/useLocalization";
import { Error } from "../components";

const MissedCallNoLessonResult = () => {

    const strings = useLocalization();

    return (
        <Error
            icon={Icon_Confused}
            title={strings.support.missed_call_no_lesson_result.title}
            content={strings.support.missed_call_no_lesson_result.information}
            hideButton="hidden"
        />
    )
}

export default MissedCallNoLessonResult;