import { useEffect } from "react";
import Confetti from 'react-confetti';
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import {EventLogs, SpeakingLabResultType} from "../../../../components/Constants";
import { useLocalization } from "../../../../hooks/useLocalization";
import { url } from "../../../../routes/utility";
import { AddMemberSpeakingLab } from "../../../../services/SpeakingLab/SpeakingLabService";
import useAnalytics from "../../../../hooks/useAnalytics";

const SpeakingLabCongratulate = ({ defaultOptions, exerciseData, setActiveExerciseIndex }) => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const navigate = useNavigate();

    // Kullanıcının egzersizi tamamladığını bildirmek için AddMemberSpeakingLab servisine istek gönderir
    useEffect(() => {
        setActiveExerciseIndex(0);
        
        AddMemberSpeakingLab({
            memberId: exerciseData?.memberId,
            dailyDate: exerciseData?.selectedDate,
            bookUnitId: exerciseData?.bookUnitId,
            exerciseSequence: exerciseData?.totalExerciseCount,
            speakingLabResultType: SpeakingLabResultType.COMPLETED
        })
        .then()
        .catch()

        analytics.useAnalytics(EventLogs.SPEAKING_LAB_COMPLETED);
    }, [])

    return (
        <>
            <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                gravity={0.04}
                run={true}
                numberOfPieces={500}
                recycle={false}
                initialVelocityY={{ min: 0, max: 15 }}
            />

            <div className="flex flex-col items-center justify-center h-[60vh] max-md:mt-5">
                <div className="max-md:-mt-14">
                    <Lottie 
                        animationData={defaultOptions.animationData}
                        loop={defaultOptions.loop}
                        autoplay={defaultOptions.autoplay}
                        style={{ height: 280, width: 280 }}
                    />
                </div>
                <div className="flex flex-col items-center justify-between z-10 gap-16 md:gap-40">
                    <p className="text-center font-bold">
                        {strings.speaking_lesson.speaking_lab.exercise_completed_text}
                    </p>
                    <button
                        type="button"
                        className="button w-[95%] xs:w-[330px] primary-button mb-2 mx-auto focus:!bg-primary"
                        onClick={() => navigate(url("speakinglesson.dailylesson"))}
                    >
                        {strings.video_practice.complete}
                    </button>
                </div>
            </div>
        </>
    );
}

export default SpeakingLabCongratulate;