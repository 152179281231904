import { fetchApi } from '../index';

export const GetOnboardingQuestionIdsAndSequences = async () => {

    const url = '/_services/member/v1/onboarding_question/get_onboarding_question_ids_and_sequences/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetOnboardingQuestionById = async (model) => {

    const url = '/_services/member/v1/onboarding_question/get_onboarding_question_by_id/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const UpsertMemberOnboardingAnswer = async (model) => {

    const url = '/_services/member/v1/member_onboarding_answer/upsert_member_onboarding_answer/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}