import React, {useEffect, useRef, useState} from "react";
import {Popup} from "../components";
import {CreateNpsService, CriterionDetailService} from "../services/Nps/NpsService";
import useAuth from "../hooks/useAuth";
import {Brands, Channel, EventLogs, NpsCriterionType} from "../components/Constants";
import {closePopup, getLanguageIdByLanguageKey, openPopup} from "../components/Utility";
import {useLocation} from "react-router-dom"
import {useLocalization} from "../hooks/useLocalization";
import {useSelector} from "react-redux";
import useAnalytics from "../hooks/useAnalytics";
import NpsContent from "./components/NpsContent";

const Nps = () => {

  const { member, token } = useAuth();

  const strings = useLocalization();
  const analytics = useAnalytics();
  const location = useLocation();
  const language = useSelector(state => state.localizationStore.language); 

  const npsPopupRef = useRef();
  const commentErrorRef = useRef();
  const successIconRef = useRef();

  //Popup'ın kapandığını kontrol eden state
  const [isClickedCancelButton, setIsClickedCancelButton] = useState(false);
  //İşlem başarılı olduktan sonra başarılı mesajının gösterilmesini sağlayan state
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  //Popup'ı kapatma butonunun görünürlüğünü kontrol etmek için kullanılan state
  const [showCancelButton, setShowCancelButton] = useState(true);
  //Servisten gelen criterion detaylarını tuttuğumuz state
  const [criterionDetail, setCriterionDetail] = useState({
    popupVisibility: false,
    title: "",
    criterionId: 0
  });
  //NPS oluştururken servise bu modeli gönderiyoruz
  const [model, setModel] = useState({
    memberId: "",
    criterionId: 0,
    channelId: 0,
    comment: "",
    rate: 0
  });
  // NPS Görüntülenme logunu bir defa göndermek için kullanılan state
  const [isNpsPopupOpenedLogSended, setIsNpsPopupOpenedLogSended] = useState(false);

  //Kullanıcının NPS durumunu getiriyoruz
  const getCriterionDetail = async (memberId, criterionType) => {
    const model = {
      applicationId: Brands.AL,
      memberId: memberId,
      languageId: getLanguageIdByLanguageKey(language),
      criterionType: criterionType
    }

    const result = await CriterionDetailService(model);

    if(result.status === 200) {
        const resultContent = JSON.parse(result.content);
        setCriterionDetail(criterionDetail => ({
            ...criterionDetail,
            title: resultContent.title,
            popupVisibility: resultContent.checkPageVisibility,
            criterionId: resultContent.criterionId
        }))
    }
  }

  //NPS'i servise gönderiyoruz
  const createNps = async (model) => {
    
    let valid = true;

    if(model.comment.length > 250) {
        valid = false;
        commentErrorRef.current.innerText = strings.popup.nps.comment_error_message
    }

    if(valid) {
        await CreateNpsService(model);

        // Başarılı mesajını göstermek için showSuccessMessage state'ini true'ya çekiyoruz
        setShowSuccessMessage(showSuccessMessage => true);
        setShowCancelButton(showCancelButton => false);
        //4 saniye sonra popup'ı kapatıp tutulan modeli sıfırlıyoruz
        setTimeout(() => {
            closePopup(npsPopupRef);
            clearModel();
            setShowCancelButton(showCancelButton => true);
        }, 3000)

        //Öğrencinin göndermiş olduğu NPS skoru loglanıyor.
        if(model.rate > 0)
            analytics.useAnalytics(
                EventLogs.NPS_SURVEY_REPLIED,
                {
                    score: model.rate
                }
            )
    }
  }

  //NPS oluşturma modelini sıfırlamak için kullanılan fonksiyon
  const clearModel = () => {
    setModel(model => ({
        ...model,
        memberId: "",
        criterionId: 0,
        channelId: 0,
        comment: "",
        rate: 0
    }))
  }

  //Kullanıcı dashboard'a geldiğinde NPS durumunu bize getiren servise gidilip detaylar yakalanıyor
  //Dil değişince de servise giderek başlıkları dile göre servisten tekrar getiriyoruz
  useEffect(() => {
    if((location.pathname === '/dashboard') && member?.MemberId) {
        getCriterionDetail(member?.MemberId, NpsCriterionType.NPS);
        getCriterionDetail(member?.MemberId, NpsCriterionType.MENTOR_NPS);
    }
  }, [location.pathname, language, token])

  //NPS durumunda gelen popup göster değişkeni true geldiğinde popup'ı açıyoruz
  useEffect(() => {
      if(criterionDetail.popupVisibility) {
          openPopup(npsPopupRef);
          setIsClickedCancelButton(isClickedCancelButton => false);

          //NPS görünülenme logunun bir defa gönderilmesi için kontrol yapılıyor.
          if(!isNpsPopupOpenedLogSended) {
              analytics.useAnalytics(EventLogs.NPS_SURVEY_IMPRESSION);
              setIsNpsPopupOpenedLogSended(isNpsPopupOpenedLogSended => true);
          }
      }
      else {
          closePopup(npsPopupRef);
      }

      setShowSuccessMessage(false);
      setModel(model => ({
          ...model,
          memberId: member?.MemberId,
          channelId: Channel.WebSite,
          criterionId: criterionDetail.criterionId
      }))
  }, [criterionDetail])

  useEffect(() => {
    if(isClickedCancelButton) {
        createNps(model);
        closePopup(npsPopupRef);
        clearModel();

        //NPS Popup'ı kapatılınca log atılıyor.
        analytics.useAnalytics(EventLogs.NPS_SURVEY_CLOSED);
    }
  }, [isClickedCancelButton])

   /*
      NPS ekranı arka planda hep açık olduğu için sistemden çıkış yapıldığında (auth ekranını görüntülendiğinde)
      log atılma durumu, tekrar giriş yaptığında gönderilebilmek için false'a çekiliyor.
   */
   useEffect(() => {
       if(    location.pathname.includes("/auth")
           || location.pathname.includes("/onboarding")
           || location.pathname.includes("/success"))
        setIsNpsPopupOpenedLogSended(false);
   }, [location.pathname]);

  return (
      member && (
        <Popup
            ref={npsPopupRef}
            setIsClickedCancelButton={setIsClickedCancelButton}
            showCancelButton={showCancelButton}
            children={
                <NpsContent
                    criterionDetail={criterionDetail}
                    showSuccessMessage={showSuccessMessage}
                    successIconRef={successIconRef}
                    commentErrorRef={commentErrorRef}
                    uniqueValue="nps"
                    model={model}
                    setModel={setModel}
                    action={() => createNps(model)}
                />
            }
        />
      )
  );
};

export default Nps;
