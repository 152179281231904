import React, { useState } from 'react'
import useAnalytics from '../../../../../../hooks/useAnalytics';
import { useLocalization } from '../../../../../../hooks/useLocalization'

const StudySubjects = ({ convertedData, events, analyticsPass = false }) => {

    const strings = useLocalization();
    const analytics = useAnalytics();

    const [currentTab, setCurrentTab] = useState(0);

    return (
        <>

            <div className="transparent-section mt-5">
                <h5 className="text-secondary">{strings.speaking_lesson.components.study_subjects.title}</h5>

                <div className="mx-auto mt-4 max-w-[475px]">

                    <ul id="study-tabs" className="flex justify-between mx-auto text-start">
                        {convertedData[1] && (
                            <li className="font-bold w-[130px] relative pb-1">
                                <a className="cursor-pointer" onClick={() => {
                                    setCurrentTab(currentTab => 0);
                                    !analyticsPass && analytics.useAnalytics(events, { category: 'Bölüm Soruları' });
                                }}>
                                    {strings.speaking_lesson.components.study_subjects.tab_title_1}
                                </a>
                                <div className={`absolute bottom-0 left-0 right-0 h-[3px] rounded ${currentTab === 0 ? "bg-primary" : "bg-base-text"}`}></div>
                            </li>
                        )}
                        {convertedData[2] && (
                            <li className="font-bold w-[130px] relative pb-1">
                                <a className="cursor-pointer" onClick={() => {
                                    setCurrentTab(currentTab => 1);
                                    !analyticsPass && analytics.useAnalytics(events, { category: 'Cümle Kalıpları' });
                                }}>{strings.speaking_lesson.components.study_subjects.tab_title_2}</a>
                                <div className={`absolute bottom-0 left-0 right-0 h-[3px] rounded ${currentTab === 1 ? "bg-primary" : "bg-base-text"}`}></div>
                            </li>
                        )}
                    </ul>

                    <div dir='ltr' id="study-tab-contents" className="text-start">
                        {convertedData[1] && (
                            <div id="first" className={`pt-4 ${currentTab !== 0 ? "hidden" : ""}`}>
                                {convertedData[1].map((item, index) => (
                                    <p key={index}>» {item}{' '}</p>
                                ))}
                            </div>
                        )}
                        {convertedData[2] && (
                            <div id="second" className={`pt-4 ${currentTab !== 1 ? "hidden" : ""}`}>
                                {convertedData[2].map((item, index) => (
                                    <p key={index}>» {item}{' '}</p>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </>
    )
}

export default StudySubjects