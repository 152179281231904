import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {App_Gallery, App_Store, Play_Store} from "../assets/img"
import useAuth from "../hooks/useAuth"
import {useLocalization} from "../hooks/useLocalization";
import {Loading} from "./index";
import {GetBookOfTheLastLessonAttended} from "../services/SpeakingLesson/SpeakingLessonService";
import {useNavigate} from "react-router-dom";
import useMemberUsername from "../hooks/useMemberUsername";
import {url} from "../routes/utility";
import classNames from "classnames";

const DefaultChart = () => {

    const strings = useLocalization();

    return (
        <>
            <div className="px-3 mt-3">
                <div className="flex items-end justify-center mt-14 relative">
                    <div data-level="?" className="level-block level-incompleted"></div>
                    <div data-level="?" className="level-block level-incompleted"></div>
                    <div data-level="?" className="level-block active-level"
                         style={{height: "75px", backgroundColor: "#E5E5E5"}}></div>
                    <div data-level="?" className="level-block level-incompleted"></div>
                    <div data-level="?" className="level-block level-incompleted"></div>
                </div>
                <div className="relative">
                    <hr className="h-line"/>
                </div>
            </div>

            <div className="mt-5 gray-section p-1">
                <p className="font-bold text-secondary flex items-center justify-center leading-[18px]">{strings.speaking_lesson.progress_report.same_level_title}</p>
            </div>

            <div className="px-3 mt-3">
                <div className="flex items-end justify-center mt-14 relative">
                    <div data-level="?" className="level-block level-incompleted"></div>
                    <div data-level="?" className="level-block level-incompleted"></div>
                    <div data-level="?" className="level-block level-incompleted"></div>
                    <div data-level="?" className="level-block level-incompleted"></div>
                    <div data-level="?" className="level-block active-level"
                         style={{height: "75px", backgroundColor: "#E5E5E5"}}></div>
                </div>
                <div className="relative">
                    <hr className="h-line"/>
                </div>
            </div>

            <div className="text-[11px] text-[#999999] text-center mt-5">
                {strings.speaking_lesson.progress_report.same_level_content}
            </div>
        </>
    )
}

const ProgressReport = ({report, reportLoading}) => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const { member } = useAuth();
    const memberUsername = useMemberUsername(member?.MemberId);

    const terms = typeof (member.Terms) !== "undefined" && JSON.parse(member.Terms);
    const selectedTermId = useSelector(state => state.termStore.termId);
    const selectedTerm = terms && terms.find(term => term.TermId === selectedTermId);

    const [width, setWidth] = useState(false);

    const [currentBook, setCurrentBook] = useState("")
    const [bookStatus, setBookStatus] = useState("default");
    const [bookLevel, setBookLevel] = useState(-1);
    const [bookLevelDescription, setBookLevelDescription] = useState("");

    const [endLevel, setEndLevel] = useState(0);
    const [endLevelLabel, setEndLevelLabel] = useState(false);

    const [loading, setLoading] = useState(true);

    //Öğrencinin son katıldığı dersin kitabına ait bilginin alındığı servis.
    const getLastAttendedLessonBook = async (termId) => {
        const result = await GetBookOfTheLastLessonAttended(termId);

        if (result.statusCode === 200) {
            let resultContent = JSON.parse(result.message);
            //Servisten gelen öğrencinin en son katıldığı derse ait kitap set ediliyor.
            setCurrentBook(currentBook => resultContent.bookTitle);

            if (resultContent.isLevelId) {
                /*
                    * Son katıldığı derse ait kitap seviyeli ve ClickIVO kitabı ise bu durum set ediliyor.
                    * Son katıldığı derse ait kitap seviyeli ve normal kitap ise bu durum set ediliyor.
                */
                resultContent.bookTitle.includes("ClickIVO")
                    ? setBookStatus(bookStatus => "withLevelClickIVO")
                    : setBookStatus(bookStatus => "withLevel")
            } else {
                // Son girdiği derse ait kitap seviyesiz bir kitap ise bu durum set ediliyor.
                setBookStatus(bookStatus => "withoutLevel");
            }
        }
        setLoading(loading => false);
    }

    //Öğrenci ClickIVO kitabı alıyorsa 12 ay sonra geleceği seviye hesaplanıyor.
    const setFutureLevelNumber = (startLevel) => {
        switch (startLevel) {
            case "Starter":
            case "Starter-1":
            case "Starter-2":
            case "Starter-3":
            case "Starter-4":
            case "Starter-5":
            case "Starter-6":
                setEndLevel(endLevel => 3);
                setEndLevelLabel(endLevelLabel => "Intermediate-5");
                break;
            case "Beginner-1":
            case "Beginner-2":
            case "Beginner-3":
            case "Beginner-4":
                setEndLevel(endLevel => 4);
                setEndLevelLabel(endLevelLabel => "Intermediate-7");
                break;
            case "Intermediate-1":
            case "Intermediate-2":
            case "Intermediate-3":
            case "Intermediate-4":
            case "Intermediate-5":
                setEndLevel(endLevel => 5);
                setEndLevelLabel(endLevelLabel => "Advanced-1");
                break;
            case "Intermediate-6":
            case "Intermediate-7":
            case "Intermediate-8":
            case "Intermediate-9":
                setEndLevel(endLevel => 5);
                setEndLevelLabel(endLevelLabel => "Advanced-2");
                break;
            case "Advanced-1":
            case "Advanced-2":
                setEndLevel(endLevel => 5);
                setEndLevelLabel(endLevelLabel => "Advanced-2");
                break;
            default:
                setEndLevel(startLevel => 0);
                break;
        }
    }

    useEffect(() => {
        if (selectedTermId) {
            setBookStatus(currentBook => "default");
            setBookLevel(bookLevel => -1);
            getLastAttendedLessonBook(selectedTermId);
        } else {
            setLoading(loading => false);
        }
    }, [selectedTermId]);

    useEffect(() => {
        if (report) {
            let w = parseInt(parseFloat(report.attendanceRate.replace("٫", ".").replace(",", ".")) * 100);
            setWidth(width => w);

            //check width for design
            if (w < 10) {
                setWidth(width => 10);
            } else {
                setWidth(width => w);
            }

            //Öğrencinin eğitime başladığı seviye (Yurtdışı tarafından gönderilir)
            let startLevel = report.startLevel;

            setFutureLevelNumber(startLevel, "start");
        }
    }, [report]);

    useEffect(() => {
        if (currentBook?.length > 0 && bookStatus === "withLevelClickIVO") {
            // Öğrencinin son girdiği dersteki kitabına göre seviye belirlemesi yapılıyor.
            if (currentBook.includes("Starter")) {
                setBookLevel(1);
                setBookLevelDescription(bookLevelDescription => "Starter");
            } else if (currentBook.includes("Beginner")) {
                setBookLevel(2);
                setBookLevelDescription(bookLevelDescription => currentBook.match(/Beginner-\d+/));
            } else if (
                currentBook.includes("Intermediate-1") ||
                currentBook.includes("Intermediate-2") ||
                currentBook.includes("Intermediate-3") ||
                currentBook.includes("Intermediate-4") ||
                currentBook.includes("Intermediate-5")
            ) {
                setBookLevel(3);
                setBookLevelDescription(bookLevelDescription => currentBook.match(/Intermediate-\d+/));
            } else if (
                currentBook.includes("Intermediate-6") ||
                currentBook.includes("Intermediate-7") ||
                currentBook.includes("Intermediate-8") ||
                currentBook.includes("Intermediate-9")
            ) {
                setBookLevel(4);
                setBookLevelDescription(bookLevelDescription => currentBook.match(/Intermediate-\d+/));
            } else if (currentBook.includes("Advance")) {
                setBookLevel(5);
                setBookLevelDescription(bookLevelDescription => currentBook.match(/Advance-\d+/));
            } else {
                setBookLevel(-1);
            }

        } else if (bookStatus === "withLevel") {
            // Öğrencinin son girdiği dersteki kitabına göre seviye belirlemesi yapılıyor.
            if (currentBook.includes("Starter")) {
                setBookLevel(1);
                setBookLevelDescription(bookLevelDescription => "Starter");
            } else if (currentBook.includes("Beginner")) {
                setBookLevel(2);
                setBookLevelDescription(bookLevelDescription => "Beginner");
            } else if (currentBook.includes("Intermediate")) {
                setBookLevel(3);
                setBookLevelDescription(bookLevelDescription => "Intermediate");
            } else if (currentBook.includes("Advance")) {
                setBookLevel(4);
                setBookLevelDescription(bookLevelDescription => "Advanced");
            } else {
                setBookLevel(-1);
            }
        }
    }, [currentBook, bookStatus]);

    return (
        <>
            {(loading || reportLoading) && (
                <div className="mx-auto h-[900px]">
                    <Loading/>
                </div>
            )}

            {(!loading && !reportLoading) && (
                <div className="w-full mx-auto text-center py-5 px-3">
                    <button
                        type="button"
                        className={classNames("font-bold text-lg text-primary hidden md:block mx-auto hover:text-primary/70", {
                            "pointer-events-none": memberUsername.length === 0
                        })}
                        disabled={memberUsername.length === 0}
                        onClick={() => navigate(url("profile.profilesub", {username: memberUsername}))}
                    >
                        {selectedTerm?.StudentName || member.FullName}
                    </button>

                    <div className="mt-5 gray-section p-1">
                        <p className="font-bold text-secondary py-2 leading-[18px]">{strings.speaking_lesson.progress_report.attendance_rate}</p>
                    </div>

                    <div className="w-full bg-white border border-nav-button-bg rounded-[20px] mt-3 h-[30px]">
                        {report && parseInt(parseFloat(report.attendanceRate.replace("٫", ".").replace(",", ".")) * 100) === 0 ? (
                            <div
                                className={`flex justify-start items-center h-full px-[5px] w-[50%] bg-incompleted text-white font-bold rounded-[20px] text-lg`}>
                                <span className="ml-4">?</span></div>
                        ) : (
                            <div
                                className={`flex justify-start items-center h-full px-[5px] bg-secondary text-white font-bold rounded-[20px] text-lg`}
                                style={{width: `${width && width}%`}}><span
                                className="text-sm">%</span>{report && parseInt(parseFloat(report.attendanceRate.replace("٫", ".").replace(",", ".")) * 100)}
                            </div>
                        )}
                    </div>


                    <div className="mt-5 gray-section p-1">
                        <p className="font-bold text-secondary py-2 leading-[18px]">{strings.speaking_lesson.progress_report.progress_chart}</p>
                    </div>

                    {bookStatus === "default" && (
                        <DefaultChart/>
                    )}

                    {(bookStatus === "withoutLevel" && bookStatus !== "default") && (
                        <DefaultChart/>
                    )}

                    {(bookStatus === "withLevel" && bookStatus !== "default") && (
                        <div className="px-3 mt-3">
                            <div className="flex items-end justify-center mt-14 relative">
                                <div data-level={bookLevel === 1 && bookLevelDescription}
                                     className={`level-block ${bookLevel === 1 ? "active-level" : bookLevel > 1 ? "level-completed" : "level-incompleted"}`}></div>
                                <div data-level={bookLevel === 2 && bookLevelDescription}
                                     className={`level-block ${bookLevel === 2 ? "active-level" : bookLevel > 2 ? "level-completed" : "level-incompleted"}`}></div>
                                <div data-level={bookLevel === 3 && bookLevelDescription}
                                     className={`level-block ${bookLevel === 3 ? "active-level" : bookLevel > 3 ? "level-completed" : "level-incompleted"}`}></div>
                                <div data-level={bookLevel === 4 && bookLevelDescription}
                                     className={`level-block ${bookLevel === 4 ? "active-level" : bookLevel > 4 ? "level-completed" : "level-incompleted"}`}></div>
                            </div>
                            <div className="relative">
                                <hr className="h-line"/>
                            </div>
                        </div>
                    )}

                    {(bookStatus === "withLevelClickIVO" && bookStatus !== "default") && (
                        <>
                            <div className="px-3 mt-3">
                                <div className="flex items-end justify-center mt-14 relative">
                                    <div data-level={bookLevel === 1 && bookLevelDescription}
                                         className={`level-block ${bookLevel === 1 ? "active-level" : bookLevel > 1 ? "level-completed" : "level-incompleted"}`}></div>
                                    <div data-level={bookLevel === 2 && bookLevelDescription}
                                         className={`level-block ${bookLevel === 2 ? "active-level" : bookLevel > 2 ? "level-completed" : "level-incompleted"}`}></div>
                                    <div data-level={bookLevel === 3 && bookLevelDescription}
                                         className={`level-block ${bookLevel === 3 ? "active-level" : bookLevel > 3 ? "level-completed" : "level-incompleted"}`}></div>
                                    <div data-level={bookLevel === 4 && bookLevelDescription}
                                         className={`level-block ${bookLevel === 4 ? "active-level" : bookLevel > 4 ? "level-completed" : "level-incompleted"}`}></div>
                                    <div data-level={bookLevel === 5 && bookLevelDescription}
                                         className={`level-block ${bookLevel === 5 ? "active-level" : bookLevel > 5 ? "level-completed" : "level-incompleted"}`}></div>
                                </div>
                                <div className="relative">
                                    <hr className="h-line"/>
                                </div>
                            </div>

                            <div className="mt-5 gray-section p-1">
                                <p className="font-bold text-secondary flex items-center justify-center leading-[18px]">{strings.speaking_lesson.progress_report.same_level_title}</p>
                            </div>

                            {(endLevel > 0 && endLevelLabel) ? (
                                <div className="px-3 mt-3 ">
                                    <div className="flex items-end justify-center mt-14 relative">
                                        <div data-level={endLevel === 1 && endLevelLabel}
                                             className={`level-block ${endLevel === 1 ? "active-level" : endLevel > 1 ? "level-completed" : "level-incompleted"}`}></div>
                                        <div data-level={endLevel === 2 && endLevelLabel}
                                             className={`level-block ${endLevel === 2 ? "active-level" : endLevel > 2 ? "level-completed" : "level-incompleted"}`}></div>
                                        <div data-level={endLevel === 3 && endLevelLabel}
                                             className={`level-block ${endLevel === 3 ? "active-level" : endLevel > 3 ? "level-completed" : "level-incompleted"}`}></div>
                                        <div data-level={endLevel === 4 && endLevelLabel}
                                             className={`level-block ${endLevel === 4 ? "active-level" : endLevel > 4 ? "level-completed" : "level-incompleted"}`}></div>
                                        <div data-level={endLevel === 5 && endLevelLabel}
                                             className={`level-block ${endLevel === 5 ? "active-level" : endLevel > 5 ? "level-completed" : "level-incompleted"}`}></div>
                                    </div>
                                    <div className="relative">
                                        <div
                                            className="absolute -rotate-90 left-[-38px] sm-xl:left-[-45px] bottom-[46px] font-bold text-primary text-xs">{report.startLevel}</div>
                                        <hr className="h-line"/>
                                    </div>
                                    <div className="text-[11px] text-[#999999] text-center mt-5">
                                        {strings.speaking_lesson.progress_report.same_level_content}
                                    </div>
                                </div>
                            ) : (
                                <div className="px-3 mt-3">
                                    <div className="flex items-end justify-center mt-14 relative">
                                        <div data-level="?" className="level-block level-incompleted"></div>
                                        <div data-level="?" className="level-block level-incompleted"></div>
                                        <div data-level="?" className="level-block level-incompleted"></div>
                                        <div data-level="?" className="level-block level-incompleted"></div>
                                        <div data-level="?" className="level-block active-level"
                                             style={{height: "75px", backgroundColor: "#E5E5E5"}}></div>
                                    </div>
                                    <div className="relative">
                                        <hr className="h-line"/>
                                    </div>
                                </div>
                            )}

                            <div className="text-[11px] text-[#999999] text-center mt-5">
                                {strings.speaking_lesson.progress_report.same_level_content}
                            </div>
                        </>
                    )}

                    <div className="mt-7 items-center hidden md:flex md:flex-col gap-2">
                        <a
                            href="https://apps.apple.com/us/app/alingliziah/id1216593266"
                            target="_blank" rel="noreferrer"
                        >
                            <img src={App_Store} width={225} height={80} className="border-none" alt="app_store" />
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.konusarakogren.m.konusarakogrenmobil_ar&hl=tr&gl=US"
                            target="_blank" rel="noreferrer"
                        >
                            <img src={Play_Store} width={225} height={80} className="border-none" alt="play_store" />
                        </a>
                        <a href="https://appgallery.huawei.com/app/C109543169"
                           target="_blank" rel="noreferrer"><img src={App_Gallery} width={225} height={80} className="border-none"/></a>
                    </div>
                </div>
            )}
        </>
    )
}

export default ProgressReport