export const replaceTextWithButton = (text, onClick, className = "font-bold") => {
    const parts = text.split("#button#");

    // Parçaları oluştur
    let returnedText = parts.map((part, index) => {
        if (index % 2 === 0) {
            return part;
        } else {
            return <button key={index} className={className} onClick={onClick}>{part}</button>;
        }
    });

    return returnedText;
};