import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {GetProductCategory} from "../../../../services/Market/MarketService";
import {getApplicationDirection, getLanguageNameForService} from "../../../../components/Utility";
import classNames from "classnames";
import {Loading} from "../../../../components";
import {useLocalization} from "../../../../hooks/useLocalization";
import useAnalytics from "../../../../hooks/useAnalytics";
import {EventLogs} from "../../../../components/Constants";

const PackageCategoryList = (props) => {

    const { selectedCategory, setSelectedCategory, pathInfo, packageLoading } = props;

    const strings = useLocalization();
    const language = useSelector(state => state.localizationStore.language);
    const analytics = useAnalytics();
    const campusChildId = 3;

    const dropDownRef = useRef();
    const dropDownGeneralRef = useRef();

    //Kategori listesinin tutulduğu state
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    const toggleDropdown = () => {
        dropDownRef.current.classList.toggle('hidden');
    }

    const handleSelectedCategory = (category) => {
        setSelectedCategory(selectedCategory => category);
        toggleDropdown();

        // LOGGED
        analytics.useAnalytics(EventLogs.MARKET_CATEGORY_CHANGE);
    }

    useEffect(() => {
        GetProductCategory("", getLanguageNameForService(language))
            .then(result => {
                if(result.statusCode === 200) {
                    let resultContent = JSON.parse(result.message);
                    // Kampüs Çocuk paketi kullanıcıya gösterilmemek için filtreleniyor.
                    let filteredResultContent = resultContent.filter(item => item.id !== campusChildId);

                    setCategories(categories => filteredResultContent);
                }

                setLoading(loading => false);
            })
            .catch(e => { })

    }, [language])

    useEffect(() => {
        if(categories.length > 0 && pathInfo?.CategoryId) {
            const tempSelectedCategory = categories.find(c => c.id === +pathInfo?.CategoryId);
            setSelectedCategory(selectedCategory => tempSelectedCategory);
        } else {
            setSelectedCategory(selectedCategory => categories[0]);
        }
    }, [categories]);

    return (
        <>
            {loading && <Loading />}

            {!loading && (
                <>
                    <div className="mt-5 gray-section px-[10px] xs:px-[10px] text-start" ref={dropDownGeneralRef}>
                        <div className="max-w-lg mx-auto">
                            <button
                                type="button"
                                className="button dropdown mt-0 max-w-full disabled:opacity-50 disabled:cursor-not-allowed"
                                onClick={toggleDropdown}
                                disabled={packageLoading}
                            >
                                {selectedCategory?.title}
                                <div className="bottom-arrow absolute right-3 top-[calc(50%-7.5px)]"></div>
                            </button>

                            {/*<!-- Dropdown menu -->*/}
                            <div
                                ref={dropDownRef}
                                className="w-full hidden mt-[2px] z-10 bg-white rounded transition-all"
                            >
                                <ul className="pb-1">
                                    {categories.length > 0 &&
                                        categories.map((category, index) => (
                                            <li key={index}>
                                                <button
                                                    type="button"
                                                    className={classNames("cursor-pointer py-2 px-2 hover:bg-primary hover:text-white text-start w-full", {
                                                        "bg-primary text-white": category.id === selectedCategory?.id
                                                    })}
                                                    onClick={() => handleSelectedCategory(category)}
                                                >
                                                    {category.title}
                                                </button>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    {pathInfo?.MemberSlogan && (
                        <p className="text-center mt-5">
                            <span
                                className={classNames("material-symbols-outlined text-secondary translate-y-1.5", {
                                    "ml-1" : getApplicationDirection(language),
                                    "mr-1" : !getApplicationDirection(language)
                                })}
                            >
                                notifications_active
                            </span>
                            <span className="text-secondary font-bold">{strings.market.speakingLesson.offer_title}: </span>{pathInfo.MemberSlogan}
                        </p>
                    )}

                    {/* {!pathInfo?.MemberSlogan &&
                        <p className="text-center mt-5">
                            <span className="material-symbols-outlined text-secondary translate-y-1.5 mr-1">
                                notifications_active
                            </span>
                            <span className="text-secondary font-bold">
                                {strings.market.speakingLesson.slogan}
                            </span>
                        </p>
                    } */}

                    { /*<!-- Description -->*/}
                    <div className="transparent-section mt-5 text-base">
                        {selectedCategory?.description}
                    </div>
                </>
            )}
        </>
    )
}

export default PackageCategoryList;