import { useSelector } from "react-redux";
import { getApplicationDirection } from "../../../../components/Utility";

const InformationAccordion = (props) => {

  const language = useSelector(state => state.localizationStore.language);

  return (
    <>
      <div
        className="group outline-none accordion-section text-sm mt-5"
        tabIndex="1"
      >
        <button className={`group accordion-div-main group-focus-within:border-b-0 w-full ${getApplicationDirection(language) ? 'pr-9' : ''}`}>
          <div className={`accordion-header ${getApplicationDirection(language) ? 'right-0 mr-2 ml-auto rotate-180' : 'left-0 ml-2 mr-auto rotate-0'}`}>
            <span className="material-symbols-outlined text-base-text">
              {" "}
              arrow_forward_ios{" "}
            </span>
          </div>
          <div className="accordion-text-main">
            <p className="font-bold text-start pl-5">{props.title};</p>
          </div>
        </button>
        <div className="accordion-content-main">
          {props.generalInformationData &&
            props.generalInformationData.map((item) => (
              <p className="mb-3" key={item.id}>
                <span className="font-bold">{item.id}) </span>
                {item.text}
              </p>
            ))}
        </div>
      </div>
    </>
  );
};

export default InformationAccordion;
