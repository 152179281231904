import React from "react";
import { Link } from "react-router-dom";
import useAnalytics from "../../hooks/useAnalytics";
import { useLocalization } from "../../hooks/useLocalization";
import CandidateAndInactiveStudentCommon from "./components/CandidateAndInactiveStudentCommon";
import { url } from "../../routes/utility";

const DashboardInactiveStudent = () => {
  const strings = useLocalization();
  const analytics = useAnalytics();

  return (
    <>
        <div className="pt-6">
          <p className="font-bold text-xl mb-5">
            {strings.dashboard.none_user.inactive_student_title_1}
          </p>
          <p className="text-lg mb-5">
            {strings.dashboard.none_user.inactive_student_title_2}
          </p>
          <Link to={url("market")}>
            <p
              className="text-lg mb-5"
              dangerouslySetInnerHTML={{
                __html: strings.dashboard.none_user.inactive_student_title_3,
              }}
            ></p>
          </Link>
          <Link to={url("speakinglesson.dailylesson")}>
            <p
              className="text-lg mb-5"
              dangerouslySetInnerHTML={{
                __html: strings.dashboard.none_user.inactive_student_title_4,
              }}
            ></p>
          </Link>
        </div>

        <CandidateAndInactiveStudentCommon />
    </>
  );
};

export default DashboardInactiveStudent;
