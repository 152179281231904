import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { Button_Right_Arrow, Logo } from "../../assets/svg";
import { MemberStatus, OnboardingQuestionType } from "../../components/Constants";
import { Loading } from "../../components/Loading";
import useAuth from "../../hooks/useAuth";
import { useLocalization } from "../../hooks/useLocalization";
import { url } from "../../routes/utility";
import { GetOnboardingQuestionIdsAndSequences } from "../../services/Onboarding/OnboardingService";
import { getQueryParam } from "../../utils/Static";
import { DynamicQuestions, LevelQuestion, ReasonToLearnEnglishQuestion } from "./components";
import { getApplicationDirection } from "../../components/Utility";

const Onboarding = () => {

    const strings = useLocalization();
    const { member } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const language = useSelector(state => state.localizationStore.language);
    const STORAGE_KEY = 'onboardingIndex';
    // familyCode varsa, değerini döndürüyoruz, yoksa null döndürüyoruz
    const familyCode = getQueryParam('familyCode', location);

    const [loading, setLoading] = useState(true);
    const [questionIdsAndSequences, setQuestionIdsAndSequences] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    // Bir önceki soruya geri dönülüyor.
    const handleBack = () => {
        const prevIndex = currentIndex - 1;
        
        if (prevIndex >= 0) {
            setCurrentIndex(prevIndex);
            setCurrentQuestion(questionIdsAndSequences[prevIndex]);
            localStorage.setItem(STORAGE_KEY, prevIndex.toString());
        }
    };

    // Bir sonraki soruya geçiliyor.
    const handleNext = () => {
        const nextIndex = currentIndex + 1;
        
        if (nextIndex < questionIdsAndSequences.length) {
            setCurrentIndex(nextIndex);
            setCurrentQuestion(questionIdsAndSequences[nextIndex]);
            localStorage.setItem(STORAGE_KEY, nextIndex.toString());
        } else {
            localStorage.removeItem(STORAGE_KEY); // İşlem bittiğinde localStorage'ı temizle
            if (familyCode) navigate(url("mobile.joinfamily") + window.location.search)
            else navigate(url("success"));
        }
    };

    // Şu anki soruya göre component döndürülüyor.
    const getCurrentComponent = (currentIndex, currentQuestion) => {
        if(currentQuestion) {
            const props = {
                currentQuestionText: `${currentIndex + 1}/${questionIdsAndSequences.length}`,
                currentQuestionId: currentQuestion.onboardingQuestionId,
                handleNext: handleNext
            }

            switch(currentQuestion.onboardingQuestionType) {
                case OnboardingQuestionType.WHY_DO_YOU_WANT_LEARN_ENGLISH:
                    return <ReasonToLearnEnglishQuestion {...props} />;
                case OnboardingQuestionType.ENGLISH_PROFICIENCY_LEVEL:
                    return <LevelQuestion {...props} />;
                default:
                    return <DynamicQuestions {...props} />;
            }
        }
    }

    // localStorage'dan index'i okuma ve cleanup
    useEffect(() => {
        return () => {
            localStorage.removeItem(STORAGE_KEY);
        };
    }, []);

    // Servisten onboarding soru bilgileri getiriliyor.
    useEffect(() => {
        if(member) {
            GetOnboardingQuestionIdsAndSequences()
                .then(result => {
                    if(result.status === 200) {
                        let resultContent = JSON.parse(result.content);

                        // Seviye seçimi sadece aday öğrencilerde görünür.
                        if(+member?.Status !== MemberStatus.Candidate) 
                            resultContent = resultContent.filter(item => item.onboardingQuestionType !== OnboardingQuestionType.ENGLISH_PROFICIENCY_LEVEL);
                        
                        setQuestionIdsAndSequences(resultContent);
                        
                        // localStorage'dan kayıtlı index'i al ve geçerli mi kontrol et
                        const savedIndex = parseInt(localStorage.getItem(STORAGE_KEY)) || 0;
                        const validIndex = savedIndex < resultContent.length ? savedIndex : 0;
                        
                        setCurrentIndex(validIndex);
                        setCurrentQuestion(resultContent[validIndex]);
                    }

                    setLoading(false);
                })
                .catch();
        }
    }, []);

    return (
        <section className="sm:min-h-full flex overflow-hidden flex-col lg:flex-row relative">

            {/*<!-- Left -->*/}
            <div className="left-onboarding">
                <div className="lg:h-screen flex items-center justify-end xs:justify-center lg:justify-start">
                    <p className={`${getApplicationDirection(language) ? 'mr-[20%]' : 'ml-[20%]'} z-10 xs:mr-0 max-lg:mt-6 max-lg:mb-[35px]`}>
                        {strings.onboarding.welcome_1}<br />
                        {+member.Status === MemberStatus.Candidate
                            ? strings.onboarding.welcome_2
                            : strings.onboarding.welcome_2_single}
                    </p>
                    <img className={`${getApplicationDirection(language) ? '-rotate-[46.5deg] -right-[38px] lg:-right-[75px] !top-2 lg:top-[13%]' : 'rotate-[46.5deg] -left-[38px] lg:-left-[75px] !-top-2 lg:!top-[13%]'} absolute w-[110px] lg:w-[220px]`} src={Logo} alt="logo" />
                </div>
            </div>

            {/*<!-- Right -->*/}            
            <div className="right-onboarding flex flex-col justify-center items-center lg:py-5">
                {loading && <Loading />}

                {!loading && getCurrentComponent(currentIndex, currentQuestion)}
            </div>

            {currentIndex > 0 && 
                <div className="absolute top-[10px] right-[10px]">
                    <button 
                        type="button"
                        className="navbar-btn border-[#C1C1C1]"
                        onClick={handleBack}
                    >
                        <img 
                            className="w-[10px] h-[18px]" 
                            src={Button_Right_Arrow} 
                        />
                    </button>
                </div>
            }
        </section>
    );
}

export default Onboarding;