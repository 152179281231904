import { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { EventLogs } from "../../../../../components/Constants";
import useAnalytics from "../../../../../hooks/useAnalytics";

const SpeakingLabDragDrop = (props) => {

    const { 
        wordsList, 
        onSentenceChange, 
        resetTrigger, 
        showAvailableList = true,
        isDraggable = true,
        initialSentence = null
    } = props;

    const analytics = useAnalytics();

    const [words, setWords] = useState([]);
    const [selectedWords, setSelectedWords] = useState([]);
    const [isDragging, setIsDragging] = useState(false);

    const updateSentence = useCallback(() => {
        const sentence = selectedWords.join(' ');
        onSentenceChange(sentence);
    }, [selectedWords, onSentenceChange]);

    const resetWords = useCallback(() => {
        setWords(prevWords => prevWords.map(word => ({ ...word, isSelected: false })));
        setSelectedWords([]);
    }, []);

    // Kelime seçme/seçimi kaldırma işlemini yöneten fonksiyon
    const handleWordToggle = (id) => {
        analytics.useAnalytics(EventLogs.SPEAKING_LAB_CREATE_SENTENCE_WORD_CLICK,
            undefined,
            true,
            false
        );

        setWords(prevWords => prevWords.map(word => {
            if (word.id === id) {
                if (word.isSelected) {
                    setSelectedWords(prev => prev.filter(w => w !== word.word));
                } else {
                    setSelectedWords(prev => [...prev, word.word]);
                }
                return { ...word, isSelected: !word.isSelected };
            }
            return word;
        }));
    };

    // Sürükleme başladığında çağrılan fonksiyon
    const onDragStart = () => {
        analytics.useAnalytics(EventLogs.SPEAKING_LAB_CREATE_SENTENCE_WORD_CLICK,
            undefined,
            true,
            false
        );
        setIsDragging(true);
    };

    // Sürükleme bittiğinde çağrılan fonksiyon
    const onDragEnd = (result) => {
        setIsDragging(false);
        const { source, destination } = result;

        // Eğer geçerli bir hedef yoksa
        if (!destination) {
            if (source.droppableId === 'selected') {
                const draggedWord = selectedWords[source.index];
                handleWordToggle(words.find(w => w.word === draggedWord).id);
            }
            return;
        }

        // Farklı sürükleme senaryolarını yönet
        if (source.droppableId === 'available' && destination.droppableId === 'selected') {
            // Kullanılabilir alandan seçili alana sürükleme
            const draggedWord = words[source.index];
            if (draggedWord && !draggedWord.isSelected) {
                handleWordToggle(draggedWord.id);
            }
        } else if (source.droppableId === 'selected' && destination.droppableId === 'selected') {
            // Seçili alan içinde sıralama değişikliği
            const newSelectedWords = Array.from(selectedWords);
            const [reorderedItem] = newSelectedWords.splice(source.index, 1);
            newSelectedWords.splice(destination.index, 0, reorderedItem);
            setSelectedWords(newSelectedWords);
        } else if (source.droppableId === 'selected' && destination.droppableId === 'available') {
            // Seçili alandan kullanılabilir alana sürükleme
            const draggedWord = selectedWords[source.index];
            handleWordToggle(words.find(w => w.word === draggedWord).id);
        }
        updateSentence();
    };

    // wordsList prop'u değiştiğinde çalışacak effect
    useEffect(() => {
        // Gelen kelime listesini işleyerek words state'ini güncelle
        const processedWords = wordsList
            .filter(word => /[a-zA-Z0-9]/.test(word))
            .map((word, index) => ({
                id: (index + 1).toString(),
                word: word,
                isSelected: false
            }));

        setWords(processedWords);
    }, [wordsList]);

    useEffect(() => {
        updateSentence();
    }, [selectedWords, updateSentence]);

    // resetTrigger değiştiğinde kelimeleri sıfırla
    useEffect(() => {
        resetWords();
    }, [resetTrigger, resetWords]);

    // initialSentence değiştiğinde çalışacak effect
    useEffect(() => {
        if (initialSentence) {
            const sentenceWords = initialSentence.split(' ');
            setSelectedWords(sentenceWords);
            setWords(prevWords => prevWords.map(word => ({
                ...word,
                isSelected: sentenceWords.includes(word.word)
            })));
        }
    }, [initialSentence]);

    return (
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <div className="relative w-full mb-5">
                {/* Statik çizgiler */}
                <div className="absolute top-0 left-0 right-0 flex flex-col space-y-[50px] pointer-events-none">
                    <div className="w-full h-px bg-incompleted"></div>
                    <div className="w-full h-px bg-incompleted"></div>
                    <div className="w-full h-px bg-incompleted"></div>
                </div>
                
                {/* Droppable alan */}
                {isDraggable ? (
                    <Droppable droppableId="selected" direction="horizontal" className="pointer-events-none">
                        {(provided) => (
                            <div 
                                dir="ltr"
                                {...provided.droppableProps} 
                                ref={provided.innerRef} 
                                className="flex flex-wrap justify-center gap-2 min-h-[100px] max-w-[100%] pb-2 w-full pt-2"
                            >
                                {selectedWords.map((word, index) => (
                                    <Draggable key={word} draggableId={`selected-${word}`} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={`bg-white mb-[6px] h-8 px-4 py-2 rounded-full shadow cursor-move font-bold break-words flex items-center ${snapshot.isDragging ? 'opacity-70' : ''}`}
                                                style={{
                                                    ...provided.draggableProps.style,
                                                }}
                                                onClick={() => handleWordToggle(words.find(w => w.word === word).id)}
                                            >
                                                {word}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                ) : (
                    <div dir="ltr" className="flex flex-wrap justify-center gap-2 min-h-[100px] max-w-[100%] pb-2 w-full pt-2">
                        {selectedWords.map((word) => (
                            <div
                                key={word}
                                className="bg-white mb-[6px] h-8 px-4 py-1 rounded-full shadow cursor-pointer font-bold break-words flex items-center"
                            >
                                {word}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Verilen kelime listesi */}
            {showAvailableList && 
                <div dir="ltr" className="min-h-[100px] mt-10">
                    {isDraggable ? (
                        <Droppable droppableId="available" direction="horizontal" isDropDisabled={true}>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className="flex flex-wrap justify-center gap-2 mb-5" style={{ pointerEvents: isDragging ? 'none' : 'auto' }}>
                                    {words.map((wordObj, index) => (
                                        <Draggable key={wordObj.id} draggableId={`available-${wordObj.id}`} index={index} isDragDisabled={wordObj.isSelected}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={`min-w-[40px] h-8 flex items-center justify-center px-4 py-1 rounded-full shadow cursor-pointer font-bold ${wordObj.isSelected ? 'bg-[#EAEAEA]' : 'bg-white'} ${snapshot.isDragging ? 'opacity-50' : ''}`}
                                                    onClick={() => handleWordToggle(wordObj.id)}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        width: wordObj.isSelected ? `${wordObj.word.length * 10 + 32}px` : 'auto',
                                                    }}
                                                >
                                                    {wordObj.isSelected ? '' : wordObj.word}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    ) : (
                        <div dir="ltr" className="flex flex-wrap justify-center gap-2 mb-5">
                            {words
                                .map((wordObj) => (
                                <div
                                    key={wordObj.id}
                                    className={`min-w-[40px] h-8 flex items-center justify-center px-4 py-1 rounded-full shadow cursor-pointer font-bold ${wordObj.isSelected ? 'bg-[#EAEAEA]' : 'bg-white'}`}
                                    style={{
                                        width: wordObj.isSelected ? `${wordObj.word.length * 10 + 32}px` : 'auto',
                                    }}
                                >
                                    {wordObj.isSelected ? '' : wordObj.word}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            }
        </DragDropContext>
    );
};

export default SpeakingLabDragDrop;