import { SpeakerBlue, SpeakerYou } from "../../../../../assets/svg";
import { useLocalization } from "../../../../../hooks/useLocalization";
import { useState, useEffect, useRef, useCallback } from "react";
import useAnalytics from "../../../../../hooks/useAnalytics";
import {EventLogs} from "../../../../../components/Constants";

const SpeakingLabPlaybackOptions = ({ systemPronunceButtonClick, memberAudioUrl, audioKey }) => {
    
    const strings = useLocalization();
    const analytics = useAnalytics();

    const audioRef = useRef(null);

    const [audioLength, setAudioLength] = useState(0);

    const loadAudio = useCallback(() => {
        if (memberAudioUrl) {
            const audio = new Audio(`${memberAudioUrl}?t=${Date.now()}`);
            audio.addEventListener('loadedmetadata', () => {
                setAudioLength(audio.duration);
            });
            audioRef.current = audio;
        }
    }, [memberAudioUrl]);

    useEffect(() => {
        loadAudio();
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null;
            }
        };
    }, [loadAudio, audioKey]);

    const playMemberAudio = () => {
        if (audioRef.current) {
            audioRef.current.currentTime = 0;
            audioRef.current.play()
        } else {
            loadAudio();
            setTimeout(() => {
                if (audioRef.current) {
                    audioRef.current.play()
                }
            }, 100);
        }
    };

    return (
        audioLength > 0 && (
            <div className="flex gap-4 items-center justify-center">
                <button 
                    type="button"
                    className="flex items-center justify-center gap-1"
                    onClick={() => {
                        systemPronunceButtonClick();
                        analytics.useAnalytics(EventLogs.SPEAKING_LAB_EXAMPLE_CLICKED);
                    }}
                >
                    <img 
                        src={SpeakerBlue ?? ""}
                        width={16}
                        height={16}
                        alt="speaker-you"  
                    />
                    <p className="text-[14px] font-bold text-primary hover:text-primary/70">
                        {strings.speaking_lesson.speaking_lab.sample_voice}
                    </p>
                </button>
                    <button 
                        className="flex items-center justify-center gap-1"
                        onClick={() => {
                            playMemberAudio();
                            analytics.useAnalytics(EventLogs.SPEAKING_LAB_YOUR_VOICE_CLICKED);
                        }}
                    >
                        <img 
                            src={SpeakerYou ?? ""}
                            width={16}
                            height={16}
                            alt="speaker-voice"  
                        />
                        <p className="text-[14px] font-bold text-primary hover:text-primary/70">
                            {strings.speaking_lesson.speaking_lab.your_voice}
                        </p>
                    </button>
            </div>
        )
    );
}

export default SpeakingLabPlaybackOptions;