import {useLocalization} from "../../hooks/useLocalization";
import {useLocation, useNavigate} from "react-router-dom";
import {url} from "../../routes/utility";
import useAuth from "../../hooks/useAuth";
import classNames from "classnames";
import {encodeToBase64AndUri, parseMobileKeyToJson} from "../../components/Utility";
import React, {useEffect, useState} from "react";
import {GetMarketTermList, GetPostponeRightProductsService} from "../../services/Market/MarketPostponeService";
import {Loading, TermDropDown} from "../../components";
import {useSelector} from "react-redux";
import {MarketSourceTypes, TermRightTypes} from "../../components/Constants";

const RightCatalog = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const location = useLocation();
    const { member } = useAuth();

    const isMobile = location.pathname.includes("/mobile_market_right_catalog");
    const memberInfo = sessionStorage.getItem("m") ? (parseMobileKeyToJson(sessionStorage.getItem("m"))) : member;
    const selectedTermId = useSelector(state => state.termStore.termId) || false;

    // Öğrencinin term'lerinin tutulduğu state
    const [terms, setTerms] = useState([]);
    // Öğrencinin seçili term'ünün ders süresini tutan state
    const [lessonDuration, setLessonDuration] = useState(0);
    // Ders erteleme hakkı paketlerinin tutulduğu state
    const [postponeRightPackages, setPostponeRightPackages] = useState([]);
    // Seçili ders erteleme hakkı paketinin tutulduğu state
    const [selectedPostponePackage, setSelectedPostponePackage] = useState({});
    // Servisten dönen bir hata var ise bu state true'ya çekiliyor
    const [isErrorOccured, setIsErrorOccured] = useState(false);
    // Servisi beklemek için kullanılan loading state
    const [loading, setLoading] = useState(true);

    // Satın al butonunda yapılan yönlendirme işlemleri kontrol ediliyor
    const handleBuyButton = () => {
        let redirectUrl = "market.checkout";

        if (isMobile) {
            redirectUrl = "mobile_market_checkout";
        }

        // Seçili paket bilgileri sessionStorage'da tutularak diğer sayfalara taşınıyor
        const purchasingInformations = {
            source: MarketSourceTypes.POSTPONE_RIGHT,
            selectedCategoryId: -1,
            selectedProduct: selectedPostponePackage,
            discountInformation: {},
        };

        const encodedString = encodeToBase64AndUri(purchasingInformations);
        sessionStorage.setItem("p", encodedString);

        navigate(url(redirectUrl));
    }

    // Öğrencinin aktif term'leri servisten getiriliyor
    useEffect(() => {
        GetMarketTermList(memberInfo?.MemberId)
            .then(result => {
                if(result.status === 200) {
                    setTerms(terms => JSON.parse(result.content));
                }
            })
            .catch()
    }, [])

    useEffect(() => {
        if(lessonDuration !== 0) {
            const model = {
                lessonDuration: lessonDuration,
                brandId: +process.env.REACT_APP_BRAND_ID,
                productRightTypeId: TermRightTypes.LessonPostponeRight
            }

            GetPostponeRightProductsService(model)
                .then(result => {
                    if (result.status === 200) {
                        setPostponeRightPackages(postponeRightPackages => JSON.parse(result.content));
                    } else {
                        setIsErrorOccured(isErrorOccured => true);
                    }

                    setLoading(loading => false);
                })
                .catch(e => {})
        }
    }, [lessonDuration]);

    // Seçili term'ün ders dakikası set ediliyor
    useEffect(() => {
        if (terms.length > 0 && selectedTermId) {
            const tempTermInfo = terms.find((term) => term.TermId === selectedTermId);
            setLessonDuration(lessonDuration => tempTermInfo?.LessonDuration);
        }
    }, [terms, selectedTermId]);

    // Sayfa ilk yüklendiğinde seçili gelecek ders erteleme hakkı belirleniyor
    useEffect(() => {
        if (postponeRightPackages.length > 0) {
            let selectedIndex;

            if (postponeRightPackages.length === 1)
                selectedIndex = postponeRightPackages.length - 1;
            else
                selectedIndex = postponeRightPackages.length - 2;

            setSelectedPostponePackage(selectedPostponePackage => postponeRightPackages[selectedIndex]);
        }
    }, [postponeRightPackages]);

    useEffect(() => {
        if (isMobile) {
            const page = {
                "second_page": true
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(page));
        }
    }, []);

    return (
        <div className={classNames("", {
            "main-content-mobile": isMobile,
            "main-content": !isMobile
        })}>
            {terms.length > 0 &&
                <div className="mt-5 gray-section px-[10px] xs:px-[10px] text-start">
                    <TermDropDown terms={terms} />
                </div>
            }

            {loading && (
                <Loading />
            )}

            {!loading && !isErrorOccured &&
                <>
                    <div className="transparent-section mt-5 text-base">
                        {strings.market.additional_rights.description}
                    </div>
                    <div className="transparent-section mt-5 text-sm">
                        <div className="flex flex-col">
                            <p className="font-bold mb-1 mt-2 text-start">{strings.market.additional_rights.postpone_description}</p>

                            <div className="flex flex-wrap justify-between mt-[6px]">
                                {postponeRightPackages.length > 0 && postponeRightPackages.map((postponePackage, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        className={classNames("market-button-square px-1 h-[90px] md:h-[150px] w-[90px] md:w-[150px] mr-3", {
                                           "bg-onboarding-bg-select border-primary" : selectedPostponePackage.rightCount === postponePackage.rightCount
                                        })}
                                        onClick={() => setSelectedPostponePackage(selectedPostponePackage => postponePackage)}
                                    >
                                        <p className="font-bold md:text-[14px] mt-2">
                                            {strings.market.additional_rights.postpone_right.replace("#count#", postponePackage.rightCount)}
                                        </p>
                                        <span className="text-primary md:text-[16px] xs-md:text-[12px] text-[10px] font-bold py-1 mt-2">
                                            {postponePackage?.additionalProductPriceDetails?.totalPrice} {postponePackage?.additionalProductPriceDetails?.currencySymbol}
                                        </span>
                                    </button>
                                ))}
                            </div>
                        </div>

                        <div className="btn-outer-div mt-5">
                            <div className="text-[12px] md:text-[14px]">
                                <div className="flex justify-between">
                                    <p className="font-bold">{strings.market.checkout.total_amount}</p>
                                    <p className="font-bold">{selectedPostponePackage?.additionalProductPriceDetails?.totalPrice} {selectedPostponePackage?.additionalProductPriceDetails?.currencySymbol}
                                    </p>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="button primary-button mt-5"
                                onClick={handleBuyButton}
                            >
                                {strings.market.buy_button}
                            </button>
                        </div>
                    </div>
                </>
            }

            {!loading && isErrorOccured && <p className="text-center mt-5">{strings.general_information_messages.an_error_occured}</p>}
        </div>
    )
}

export default RightCatalog;