import {useLocalization} from "../../../../hooks/useLocalization";
import {useEffect, useState} from "react";
import {GetStudentInvoiceInformation} from "../../../../services/Market/MarketService";
import {Loading} from "../../../../components";

const Invoice = (props) => {

    const { packageInfo, memberInfo, changeInvoiceInfo, invoiceInfo} = props;
    const strings = useLocalization();

    // Loading -> Servis cevabı bekler
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(packageInfo && memberInfo) {
            GetStudentInvoiceInformation(memberInfo?.MemberId)
                .then(result => {
                    if(result.status === 200) {
                        let resultContent = JSON.parse(result.content);
                        changeInvoiceInfo({
                            name: resultContent.fullName,
                            email: resultContent.email,
                            address: resultContent.invoiceAddress
                        });
                    }

                    setLoading(false);
                })
                .catch(e => {})
        }
    }, [])

    return (
        <div className="px-5 md:px-0 w-full md:w-[495px] mx-auto text-sm sm:text-base mt-5">
            <div className="font-bold w-full relative pb-2 text-sm mt-4 sm:mt-0">
                {strings.market.checkout.invoice_informations}
                <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-primary rounded"></div>
            </div>

            {loading && <Loading />}

            {!loading &&
                <div className="mt-2.5">
                    <input
                        type="text"
                        className="market-info-input h-[40px] !leading-[40px]"
                        placeholder={strings.market.information.name}
                        value={invoiceInfo.name}
                        onChange={(e) => changeInvoiceInfo({name: e.target.value})}
                    />
                    <input
                        type="text"
                        className="market-info-input h-[40px] !leading-[40px]"
                        placeholder={strings.market.information.email}
                        value={invoiceInfo.email}
                        onChange={(e) => changeInvoiceInfo({email: e.target.value})}
                    />
                    <textarea
                        id="message"
                        name="message"
                        rows="3"
                        className="market-info-input mb-1 placeholder:pt-1 pt-1 resize-none"
                        placeholder={strings.market.information.address}
                        value={invoiceInfo.address}
                        onChange={(e) => changeInvoiceInfo({address: e.target.value})}
                    />
                </div>
            }
        </div>
    )
}

export default Invoice;