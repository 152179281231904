import React from "react";
import {useLocalization} from "../../../../hooks/useLocalization";
import {Star} from "../../../../assets/svg";

const ProgramBadge = (props) => {

    const { onClick, title, subtitle=false, lessonCount=false } = props;

    const strings = useLocalization();

    return (
        <button
            className='gray-section bg-onboarding-bg/30 hover:bg-onboarding-bg/70 border mt-5 mb-3 border-transparency-border flex flex-col gap-5 w-full'
            type='button'
            onClick={onClick}
        >
            <div className='flex items-center gap-3 w-full'>
                <div className='flex-1 text-start w-[calc(100%-90px)]'>
                    <p className='font-bold'>{title}</p>
                    {subtitle && <p className="w-full overflow-hidden whitespace-nowrap overflow-ellipsis">{subtitle}</p>}
                </div>
                {
                    lessonCount &&
                    <div className='max-w-max'>
                        <img src={Star} className="w-4 h-4 mx-auto" alt="star"/>
                        <p className="mt-0.5 min-w-[70px]">{strings.market.education_packages.total_lesson_count.replace("#count#", lessonCount)}</p>
                    </div>
                }
            </div>
        </button>
    )

}

export default ProgramBadge;