import { useCallback, useEffect, useState } from 'react';
import { AddTermLessonAccordionActivity } from '../services/SpeakingLesson/SpeakingLessonService';

// Akordiyon aktivitelerini yönetmek için özel bir hook
const useTermAccordionActivity = (initialActivities) => {

  // Aktivitelerin durumunu tutan state
  const [activities, setActivities] = useState(initialActivities);

  // Yeni bir akordiyon aktivitesi eklemek için fonksiyon
  const addTermAccordionActivity = (termLessonAccordionType, termId, selectedDate) => {
    // Akordiyon açık olup olmadığını kontrol et
    const isAccordionOpened = activities.find(
      (l) => termLessonAccordionType === l.termLessonAccordionType
    )?.isOpened;

    // Eğer akordiyon açık değilse, yeni aktivite ekle
    if (!isAccordionOpened) {
      
      const model = {
        termId: termId,
        termLessonAccordionType: termLessonAccordionType,
        date: selectedDate
      };

      AddTermLessonAccordionActivity(model)
        .then((result) => {
          if (result.status === 200) {
            const resultContent = JSON.parse(result.content);
            // Aktiviteler listesini güncelle
            setActivities((prevActivities) =>
              prevActivities.map((item) =>
                item.termLessonAccordionType === termLessonAccordionType
                  ? { ...item, isOpened: resultContent }
                  : item
              )
            );
          }
        })
        .catch();
    }
  };

  // initialActivities değiştiğinde activities state'ini güncelle
  useEffect(() => {
    setActivities(initialActivities);
  }, []);

  // Hook'un dönüş değerleri
  return { activities, addTermAccordionActivity };
};

export default useTermAccordionActivity;