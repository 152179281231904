import React, { useEffect, useRef, useState } from 'react'
import useAnalytics from '../hooks/useAnalytics';
import { useDispatch, useSelector } from "react-redux"
import { setTermId } from "../store/TermSlice";
import { useLocalization } from '../hooks/useLocalization';
import "../assets/css/dropdown_arrow.css"
import useAuth from '../hooks/useAuth';
import { addTimeZone, getTimeZone } from './Utility';
import {TermStatusTypes} from "./Constants";
import classNames from "classnames";

export const TermDropDown = ({ terms, changeTerm }) => {

    const analytics = useAnalytics();
    const strings = useLocalization();
    const selectedTermId = useSelector(state => state.termStore.termId);
    const selectedTerm = terms.find(term => term.TermId === selectedTermId);
    const firstActiveTerm = terms.find(term => term.Status === 1)
    const firstPassiveTerm = terms.find(term => term);

    //Daha önce kullanıcının seçtiği bir term varsa seçili paketin bilgilerini veriyoruz. (selectedTerm)
    //Kullanıcının aktif paketi olduğunda ilk aktif paketin bilgilerini veriyoruz. (firstActiveTerm)
    //Kullanıcının seçili ve aktif bir paketi yoksa ilk pasif paketin bilgilerini veriyoruz. (firstPassiveTerm)
    const [headerData, setHeaderData] = useState(selectedTerm || firstActiveTerm || firstPassiveTerm);
    const dispatch = useDispatch();

    const { member } = useAuth();
    const timeZone = getTimeZone(member.Timezone);

    const dropDownGeneralRef = useRef();
    const dropDownRef = useRef();
    const buttonRef = useRef();

    //open selected packages dropdown
    const toggleDropDown = () => {
        dropDownRef.current.classList.toggle('hidden');
        buttonRef.current.classList.toggle('rotate-180');
    }

    ////if user clicks anywhere other than dropdown close it
    const handleDropDownClick = (event) => {
        const isClickInside = dropDownGeneralRef.current.contains(event.target);
        if (!isClickInside && dropDownGeneralRef.current.clientHeight > 70) {
            toggleDropDown();
        }
    }

    //set header for selected term
    useEffect(() => {
        //change store and parent element
        dispatch(setTermId(headerData.TermId));
        changeTerm?.(headerData);
    }, [headerData]);

    useEffect(() => {
        //if user clicks anywhere other than dropdown close it
        document.addEventListener('click', handleDropDownClick, false);

        return () => {
            document.removeEventListener('click', handleDropDownClick, false);
        };

    }, [])

    return (
            <div className="max-w-lg mx-auto" ref={dropDownGeneralRef}>
                <button id="dropdown" className="button-term dropdown" onClick={toggleDropDown} type="button">
                {headerData.ProductName} / {addTimeZone(headerData.LessonTime, timeZone)} / {headerData.LessonDuration} {strings.general_information_messages.minute}                    <div ref={buttonRef} className="bottom-arrow absolute right-3 top-[calc(50%-7.5px)]"></div>
                </button>

                {/*<!-- Dropdown menu -->*/}
                <div id="dropdown_menu" ref={dropDownRef} className="w-full hidden mt-[2px] z-10 bg-white rounded transition-all">
                    <ul className="py-1">
                        {
                            terms.map((term, index) => {
                                return (
                                    <li key={index}>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                if (term.TermId !== selectedTermId) {
                                                    setHeaderData(headerData => term);
                                                }
                                                toggleDropDown();
                                                analytics.useAnalytics("Package_Change");
                                            }}
                                            className={`"block w-full py-2 px-4 relative group ${term.TermId === selectedTermId ? "text-secondary hover:bg-primary hover:text-white" : "hover:bg-primary hover:text-white"}`}
                                        >
                                            <div className="flex items-center justify-center gap-2">
                                                <p>{term.ProductName + " / " + addTimeZone(term.LessonTime, timeZone) + " / " + term.LessonDuration} {strings.general_information_messages.minute}</p>
                                                <span
                                                    className={classNames("rounded-full h-2 min-w-[8px] group-hover:bg-white", {
                                                        "bg-red": term.Status === TermStatusTypes.Inactive,
                                                        "bg-green": term.Status === TermStatusTypes.Active,
                                                        "bg-blue": term.Status === TermStatusTypes.Future,
                                                        "bg-secondary": term.Status === TermStatusTypes.Pending
                                                    })}
                                                >
                                                </span>
                                            </div>

                                            <div className="tooltip-top -top-[34px]">
                                                {term.Status === TermStatusTypes.Inactive && (
                                                    <p>{strings.lesson_type.inactive}</p>
                                                )}
                                                {term.Status === TermStatusTypes.Active && (
                                                    <p>{strings.lesson_type.active}</p>
                                                )}
                                                {term.Status === TermStatusTypes.Future && (
                                                    <p>{strings.lesson_type.future}</p>
                                                )}
                                                {term.Status === TermStatusTypes.Pending && (
                                                    <p>{strings.lesson_type.pending}</p>
                                                )}
                                            </div>
                                        </button>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
    )
}
