import { fetchApi } from '../index';

export const CriterionDetailService = async (model) => {

    const url = '/_services/nps/v2/criterion/criterion_detail/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const CreateNpsService = async (model) => {

    const url = '/nps/create/';

    const payload = {
        payload: {
            ...model
        }
    };

    return await fetchApi(url, payload);
}

export const CheckLessonAttendStatusForNpsService = async (memberId) => {
    const url = '/_services/member/v1/term/get_attended_least_one_lesson/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetDemoSurvey = async (model) => {
    const url = '/_services/member/v1/member_demo_right/get_demo_survey/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}