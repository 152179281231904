import {useLocalization} from "../../../hooks/useLocalization";
import React, {useEffect, useState} from "react";
import {Button} from "../../../components";
import {EventLogs, OnboardingExercise, StepArray} from "../../../components/Constants";
import ExerciseQuestion from "../../Exercises/components/ExerciseQuestion";
import useAnalytics from "../../../hooks/useAnalytics";
import ExerciseStep from "../../Exercises/components/Common/ExerciseStep";

const OnboardingExerciseGrammar = (props) => {

    const { data, setStep, day } = props;

    const strings = useLocalization();
    const analytics = useAnalytics();

    const [grammarStep, setGrammarStep] = useState(1);
    let lang = 'al';

    const quizLogs = {
        leaveEmpty: {
            logDescription: EventLogs.ONBOARDING_EXERCISE_GRAMMAR_QUIZ_LEAVE_EMPTY,
            parameters: [{ name: 'day_count', data: day }]
        },
        continue: {
            logDescription: EventLogs.ONBOARDING_EXERCISE_GRAMMAR_QUIZ_CONTINUE,
            parameters: [{ name: 'day_count', data: day }]
        },
        check: {
            logDescription: EventLogs.ONBOARDING_EXERCISE_GRAMMAR_QUIZ_CHECK,
            parameters: [{ name: 'day_count', data: day }]
        },
    }

    /*
     * if: Hiç soru gelmemişse direkt "Congratulate" componentine geçiliyor.
     * else if: Soru geldiyse sorular gösteriliyor.
    */
    const handleNextButton = () => {
        if(data?.questions.length === 0) {
            setStep(OnboardingExercise.CONGRATULATE)
        } else {
            setGrammarStep(sentenceStep => 2)
        }

        //Devam Et butonunda log atılıyor.
        analytics.useAnalytics(EventLogs.ONBOARDING_EXERCISE_GRAMMAR_CONTINUE, { day_count: day });
    }

    const handlePreviousButton = () => {
        /*
            * Geri butonundan cümle componentine dönülünce localStorage'a veri atılarak cümle componentine
            başlaması gereken yer ve geri butonu ile gelindiği haber ediliyor.
        */
        let stepObject = {
            sentences: 3,
            isClickedBackButton: true
        };
        localStorage.setItem("step", JSON.stringify(stepObject));

        //Geri butonunda log atılıyor.
        analytics.useAnalytics(EventLogs.ONBOARDING_EXERCISE_GRAMMAR_BACK, { day_count: day });
        setStep(OnboardingExercise.SENTENCES);
    }

    useEffect(() => {
        //Component ilk render olduğunda log atılıyor.
        analytics.useAnalytics(EventLogs.ONBOARDING_EXERCISE_GRAMMAR_OPENED, { day_count: day });
    }, []);

    return (
       <div className="main-content">
           <div className="left-page">
                <ExerciseStep step={4} data={StepArray} />

                {grammarStep === 1 && (
                    <div className="mt-10 gray-section px-[10px] xs:px-[10px] text-center">

                        <h5>{strings.onboarding_exercises.grammar_rule}</h5>
                        <p className='mt-3' dangerouslySetInnerHTML={{ __html: data?.content[lang] }}></p>

                        <div className="flex gap-8 my-5 justify-center">
                            <Button
                                type="button"
                                classnames="button primary-button-outline max-w-[150px]"
                                action={handlePreviousButton}
                                text={strings.pagination.previous}
                            />
                            <Button
                                type="button"
                                classnames="button primary-button max-w-[150px]"
                                action={handleNextButton}
                                text={strings.speaking_lesson.quiz.continue}
                            />
                        </div>

                    </div>
                )}

                {grammarStep === 2 && (
                    <ExerciseQuestion
                        questions={data?.questions}
                        setInnerStep={() => setGrammarStep(1)}
                        setComponentStep={() => setStep(OnboardingExercise.CONGRATULATE)}
                        logs={quizLogs}
                    />
                )}

            </div>
       </div>
    )
}

export default OnboardingExerciseGrammar;