import {SentenceRow} from "../../../../../../components";
import React, {useCallback, useEffect, useState} from "react";
import useAuth from "../../../../../../hooks/useAuth";
import {getLanguageIdByLanguageKey} from "../../../../../../components/Utility";
import {
    GetPopularUnitSentences,
    IncreaseSentenceClickCount
} from "../../../../../../services/SentenceBank/SentenceBankService";
import {useLocalization} from "../../../../../../hooks/useLocalization";
import {useNavigate} from "react-router-dom";
import {url} from "../../../../../../routes/utility";
import usePageLeave from "../../../../../../hooks/usePageLeave";
import useAnalytics from "../../../../../../hooks/useAnalytics";
import {EventLogs} from "../../../../../../components/Constants";

const PopularSentences = ({ title, bookUnitId, selectedDate }) => {

    const strings = useLocalization()
    const { member } = useAuth();
    const navigate = useNavigate();
    const analytics = useAnalytics();
    const { setCallback } = usePageLeave();

    const [sentences, setSentences] = useState([]);
    const [clickedSentenceIds, setClickedSentenceIds] = useState([]);

    const handleClickedSentence = (clickedSentenceId) => {
        setClickedSentenceIds(clickedSentences => [...clickedSentences, clickedSentenceId]);
    }

    const handlePageLeave = useCallback(() => {
        clickedSentenceIds.length > 0 &&
            IncreaseSentenceClickCount(clickedSentenceIds)
                .then()
                .catch()

        setClickedSentenceIds([]);
    }, [clickedSentenceIds]);

    const handleNavigateAllSentences = () => {
        navigate(url("speakinglesson.sentencebank"), {
            state: {
                bookUnitId: bookUnitId,
                selectedDate: selectedDate
            }
        });

        analytics.useAnalytics(EventLogs.SENTENCE_BANK_ALL_SENTENCES_BUTTON_CLICKED, undefined, false, true);
    }

    useEffect(() => {
        setCallback(handlePageLeave);
    }, [setCallback, handlePageLeave]);

    useEffect(() => {
        if(member?.MemberId){
            const model = {
                memberId: member?.MemberId,
                bookUnitId: bookUnitId,
                dailyDate: +selectedDate.replaceAll("-", ""),
                languageTitleType: getLanguageIdByLanguageKey('al')
            };

            GetPopularUnitSentences(model)
                .then(result => {
                    if(result.status === 200)
                        setSentences(JSON.parse(result.content));
                })
                .catch()
        }
    }, [selectedDate, bookUnitId])

    return (
        sentences.length > 0 &&
            <>
                <div className="transparent-section mt-5">
                    <h5 className="text-secondary">{title}</h5>
                    {sentences.map((item, index) => (
                        <div key={index}>
                            <SentenceRow
                                data={item}
                                showHr={sentences.length !== index + 1}
                                handleClickedSentence={handleClickedSentence}
                            />
                        </div>
                    ))}
                    <div className="w-full my-1 flex justify-end">
                        <button
                            type="button"
                            className="text-primary hover:text-secondary font-bold"
                            onClick={handleNavigateAllSentences}
                        >
                            {strings.speaking_lesson.daily_lesson.sentence_bank.all_sentences}
                        </button>
                    </div>
                </div>
            </>
    )

}

export default PopularSentences;