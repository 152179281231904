import React, { useEffect, useRef, useState } from "react";
import { EventLogs } from "../../../../components/Constants";
import useAnalytics from "../../../../hooks/useAnalytics";
import useAuth from "../../../../hooks/useAuth";
import { useLocalization } from "../../../../hooks/useLocalization";
import { TargetReferenceStudentsCount } from "../../../../services/Reference/ReferenceService";

const Social = ({ referencePath, referenceDiscountRate, showCounter }) => {

    const strings = useLocalization();

    const { member } = useAuth();
    const analytics = useAnalytics();
    const memberId = member?.MemberId;

    const copiedRef = useRef();

    const [referenceTargetData, setReferenceTargetData] = useState("");

    const targetReferenceStudentsCount = async () => {
        const result = await TargetReferenceStudentsCount(memberId);
        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setReferenceTargetData(referenceTargetData => resultContent)
        }
    }

    const SHARE_TEXT_X_WHATSAPP = strings.reference.whatsapp_x_message_text
        .replace("#referenceDiscountRate#", referenceDiscountRate) + " " + referencePath + "&utm_content=WhatsappPaylasButon";

    const SHARE_TEXT_MAIL = strings.reference.mail_message_text
        .replace("#referenceDiscountRate#", referenceDiscountRate) + "\n\n" + referencePath + "&utm_content=MailPaylasButon";

    const sendEvent = (param) => {
        analytics.useAnalytics(
            EventLogs.REFERENCE_SHARE_CLICKED,
            { share_platform: param},
        );
    }

    const shareWithWhatsapp = () => {
        const shareLink = `${process.env.REACT_APP_WHATSAPP_API_URL}?text=${encodeURIComponent(SHARE_TEXT_X_WHATSAPP)}`;
        window.open(shareLink, "_blank");
        sendEvent("whatsapp");
    }

    const sendMail = () => {
        const body = encodeURIComponent(SHARE_TEXT_MAIL);
        const mailtoLink = `mailto:?&body=${body}`;
        window.location.href = mailtoLink;
        sendEvent("mail");
    }

    const copyLink = () => {
        handleCopy(referencePath + "&utm_content=LinkPaylasButon")
        sendEvent('link-copy');
    }

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);

        copiedRef.current.classList.remove("hidden");

        setTimeout(() => {
            copiedRef.current.classList.add("hidden");
        }, 1500);
    }

    const SOCIAL_LINKS = [
        {
            name: strings.social_button.whatsapp,
            action: shareWithWhatsapp
        },
        {
            name: strings.social_button.mail,
            action: sendMail
        },
        {
            name: strings.social_button.copy_link,
            action: copyLink
        },
    ]

    useEffect(() => {
        if (memberId && !showCounter) (
            targetReferenceStudentsCount()
        )
    }, [memberId])

    return (
        <>
            {!showCounter && (
                <p className="font-bold text-center">
                    {strings.reference.invite_activated?.replace("#activeReferenceStudentCount#", referenceTargetData.activeReferenceStudentCount).replace("#targetReferenceStudentCount#", referenceTargetData.targetReferenceStudentCount)}
                </p>
            )}

            <p>{strings.video_practice.word_card.share_text}</p>
            <div className="flex flex-col gap-3 my-4">
                {SOCIAL_LINKS.map((social, index) => (
                    <div key={index} className="relative group">
                        {social.name === strings.social_button.copy_link && (
                            <div ref={copiedRef} className="tooltip-top-content hidden -top-[34px] duration-500">{strings.reference.copied}</div>
                        )}
                        <button
                            className="button primary-button max-w-[330px] mx-auto w-full"
                            onClick={() => {
                                social.action();
                            }}
                        >
                            {social.name}

                        </button>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Social;