import React, {useEffect, useState} from "react";
import {useLocalization} from "../../hooks/useLocalization";
import {Check, Onboarding_1_1, Onboarding_1_2, Onboarding_1_3, Onboarding_1_4} from "../../assets/svg";
import {useLocation, useNavigate} from "react-router-dom";
import {url} from "../../routes/utility";
import {AddMemberLevelCollection} from "../../services/Auth/AuthService";
import useAuth from "../../hooks/useAuth";
import {DemoUnitType, LevelDefinitionType} from "../../components/Constants";
import {UpdateMemberDemoUnit} from "../../services/MemberDemoRight/MemberDemoRightServices";
import {getApplicationDirection} from "../../components/Utility";
import {useSelector} from "react-redux";

const DemoLevelSelection = () => {

    const strings = useLocalization();
    const { member } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const language = useSelector(state => state.localizationStore.language);

    const lesson = location?.state?.lesson;

    const [answer, setAnswer] = useState(-1);

    const handleContinue = () => {
        if(answer === -1) {
            navigate(url("demo.success"), { state: { "lesson": lesson }});
        } else {
            addMemberLevelCollection();
            updateDemoUnit(+member?.ReasonToLearnEnglishId, answer);
        }
    }

    const addMemberLevelCollection = () => {
        AddMemberLevelCollection(member?.MemberId, LevelDefinitionType.SELF, answer)
            .then(result => {
                if(result.status === 200) {
                    navigate(url("demo.success"), { state: { "lesson": lesson }});
                }
            })
            .catch()
    }

    const updateDemoUnit = (useCaseId, levelId) => {

        const model = {
            memberDemoUnitType: DemoUnitType.TRIAL,
            termId: lesson?.termId,
            memberId: member?.MemberId,
            useCaseId: useCaseId,
            levelId: levelId,
            lessonDate: lesson?.lessonDate
        };

        UpdateMemberDemoUnit(model)
            .then()
            .catch()
    }

    useEffect(() => {
        //eğer kullanıcı path'den gelmeye çalışırsa sayfa hata vermemesi için bir önceki sayfaya yönlendiriliyor.
        if (lesson === undefined || lesson.lessonDate === undefined) {
            navigate(-1);
        }
    }, []);

    return (
        <div className="main-content">
            <div className="transparent-section mt-5">
                <h5 className="text-secondary">{strings.demo.level_selection.title}</h5>

                <div className="onboarding-outer-div !mt-5">
                    <button
                        type='button'
                        className={`onboarding-button ${answer === 2 && "bg-onboarding-bg-select"} ${getApplicationDirection(language) ? 'pr-[70px] pl-7' : 'pl-[70px] pr-7'}`}
                        onClick={() => setAnswer(2)}
                    >
                        <div className={`onboarding-check ${answer === 2 ? "flex" : "hidden"} ${getApplicationDirection(language) ? 'ml-[5px] md:ml-[20px]' : 'mr-[5px] md:mr-[20px]'}`}>
                            <img src={Check} alt="" width="16" height="16"/>
                        </div>
                        <div className={`onboarding-button-left ${getApplicationDirection(language) ? 'border-l' : 'border-r'}`}>
                            <img src={Onboarding_1_1} alt="" width="32" height="32"/>
                        </div>
                        <p className="text-start">{strings.onboarding.first.level1}</p>
                    </button>

                    <button
                        type='button'
                        className={`onboarding-button ${answer === 3 && "bg-onboarding-bg-select"} ${getApplicationDirection(language) ? 'pr-[70px] pl-7' : 'pl-[70px] pr-7'}`}
                        onClick={() => setAnswer(3)}
                    >
                        <div className={`onboarding-check ${answer === 3 ? "flex" : "hidden"} ${getApplicationDirection(language) ? 'ml-[5px] md:ml-[20px]' : 'mr-[5px] md:mr-[20px]'}`}>
                            <img src={Check} alt="" width="16" height="16"/>
                        </div>
                        <div className={`onboarding-button-left ${getApplicationDirection(language) ? 'border-l' : 'border-r'}`}>
                            <img src={Onboarding_1_2} alt="" width="32" height="32"/>
                        </div>
                        <p className="text-start">{strings.onboarding.first.level2}</p>
                    </button>

                    <button
                        type='button'
                        className={`onboarding-button ${answer === 4 && "bg-onboarding-bg-select"} ${getApplicationDirection(language) ? 'pr-[70px] pl-7' : 'pl-[70px] pr-7'}`}
                        onClick={() => setAnswer(4)}
                    >
                        <div className={`onboarding-check ${answer === 4 ? "flex" : "hidden"} ${getApplicationDirection(language) ? 'ml-[5px] md:ml-[20px]' : 'mr-[5px] md:mr-[20px]'}`}>
                            <img src={Check} alt="" width="16" height="16"/>
                        </div>
                        <div className={`onboarding-button-left ${getApplicationDirection(language) ? 'border-l' : 'border-r'}`}>
                            <img src={Onboarding_1_3} alt="" width="32" height="32"/>
                        </div>
                        <p className="text-start">{strings.onboarding.first.level3}</p>
                    </button>

                    <button
                        type='button'
                        className={`onboarding-button ${answer === 5 && "bg-onboarding-bg-select"} ${getApplicationDirection(language) ? 'pr-[70px] pl-7' : 'pl-[70px] pr-7'}`}
                        onClick={() => setAnswer(5)}
                    >
                        <div className={`onboarding-check ${answer === 5 ? "flex" : "hidden"} ${getApplicationDirection(language) ? 'ml-[5px] md:ml-[20px]' : 'mr-[5px] md:mr-[20px]'}`}>
                            <img src={Check} alt="" width="16" height="16"/>
                        </div>
                        <div className={`onboarding-button-left ${getApplicationDirection(language) ? 'border-l' : 'border-r'}`}>
                            <img src={Onboarding_1_4} alt="" width="32" height="32"/>
                        </div>
                        <p className="text-start">{strings.onboarding.first.level4}</p>
                    </button>
                </div>
            </div>

            <button
                className="button primary-button max-w-[330px] mx-auto w-full mt-5"
                onClick={handleContinue}
            >
                {strings.speaking_lesson.quiz.continue}
            </button>
        </div>
    )
}

export default DemoLevelSelection;