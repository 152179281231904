import React from 'react'
import { useSelector } from 'react-redux'
import { getApplicationDirection } from '../../../components/Utility';

const StudentComment = (props) => {
    
    const language = useSelector(state => state.localizationStore.language);

    return (
        <div className="sm:w-[48%] max-w-[246px] relative">
            <img className="rounded-[10px]" src={props.imgUrl} alt="" />
            <p className="mt-2 font-bold text-sm leading-4" dir={`${getApplicationDirection(language) ? 'ltr' : 'rtl'}`}>{props.studentName} - {props.studentJob}</p>
            <button type="button" className="button secondary-button-outline inside-button group"
                onClick={props.videoClick}>
                <div className={`play-button h-3 w-3 ${getApplicationDirection(language) ? 'border-r-[10px] border-r-secondary group-hover:border-r-primary' : 'border-l-[10px] border-l-secondary group-hover:border-l-primary'}`}></div>
                {props.playVideo}
            </button>
        </div>
    )
}

export default StudentComment