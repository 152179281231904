import React, {useEffect, useState} from 'react';
import {Speaker} from '../../../assets/svg';
import {Button} from '../../../components';
import {useLocalization} from '../../../hooks/useLocalization';
import {EventLogs, OnboardingExercise, StepArray} from "../../../components/Constants";
import useSpeaker from "../../../hooks/useSpeaker";
import ExerciseQuestion from "../../Exercises/components/ExerciseQuestion";
import useAnalytics from "../../../hooks/useAnalytics";
import {useSelector} from "react-redux";
import classNames from "classnames";
import {getApplicationDirection} from "../../../components/Utility";
import ExerciseStep from "../../Exercises/components/Common/ExerciseStep";

const OnboardingExerciseWords = (props) => {

  const { setStep, data, day } = props;

  const strings = useLocalization();
  const analytics = useAnalytics();
  const language = useSelector(state => state.localizationStore.language);
  const { speakSentence, SpeakerComponent } = useSpeaker();
  const lang = 'al';

  //localStorage'dan gelen bir step değeri var ise component ilgili step'ten devam ettiriliyor
  let storageStep = JSON.parse(localStorage.getItem("step")) || false;
  const [wordStep, setWordStep] = useState(storageStep.words || 1);
  const [wordArrStep, setWordArrStep] = useState(0);
  const selectedData = data?.word[wordArrStep];

  const quizLogs = {
    leaveEmpty: {
      logDescription: EventLogs.ONBOARDING_EXERCISE_WORD_QUIZ_LEAVE_EMPTY,
      parameters: [{ name: 'day_count', data: day }]
    },
    continue: {
      logDescription: EventLogs.ONBOARDING_EXERCISE_WORD_QUIZ_CONTINUE,
      parameters: [{ name: 'day_count', data: day }]
    },
    check: {
      logDescription: EventLogs.ONBOARDING_EXERCISE_WORD_QUIZ_CHECK,
      parameters: [{ name: 'day_count', data: day }]
    },
  }

  const handleListenWord = () => {
    speakSentence(selectedData.title);
    //Kelimelerin dinlenmesi loglanıyor.
    analytics.useAnalytics(EventLogs.ONBOARDING_EXERCISE_WORD_LISTEN, { day_count: day });
  }

  /*
    if: Kelime kataloğunda gösterim bittiyse soru kısmına geçiliyor.
    else if: Hiç soru gelmemişse direkt cümle componentine geçiliyor.
    else: Kelime kataloğu değiştiriliyor.
  */
  const handleContinueButton = () => {
    if(wordArrStep === data.word.length - 1) {
      setWordStep(2);
    } else if(data?.questions.length === 0) {
      setStep(OnboardingExercise.SENTENCES);
    } else {
      setWordArrStep(wordArrStep => wordArrStep + 1);
    }

    //Devam Et butonuna basıldığında log atılıyor.
    analytics.useAnalytics(EventLogs.ONBOARDING_EXERCISE_WORD_NEXT, { day_count: day });
  }

  const handlePreviousButton = () => {
    if(wordArrStep === 0) {
      /*
        * Geri butonundan diyalog componentine dönülünce localStorage'a veri atılarak diyalog componentine
        başlaması gereken yer ve geri butonu ile gelindiği haber ediliyor.
      */
      let stepObject = {
        dialog: 2,
      };

      localStorage.setItem("step", JSON.stringify(stepObject));
      setStep(OnboardingExercise.DIALOG);
    } else {
      setWordArrStep(wordArrStep => wordArrStep - 1);
    }

    //Geri butonuna basıldığında log atılıyor.
    analytics.useAnalytics(EventLogs.ONBOARDING_EXERCISE_WORD_BACK, { day_count: day });
  }

  //localStorage'da tutulan bir step değerini okuduktan sonra ilgili veri localStorage'dan uçuruluyor.
  useEffect(() => {
    storageStep && localStorage.removeItem("step");
  }, []);

  return (
    <div className="main-content">
      <div className="left-page">
        <SpeakerComponent />

        <ExerciseStep step={2} data={StepArray} />

        {wordStep === 1 && (
          <div className="mt-10 gray-section px-[10px] xs:px-[10px] text-center">
            <h5 className="text-primary">
              {selectedData.title}
              <img
                className={classNames("inline-flex cursor-pointer", {
                    "mr-1.5" : getApplicationDirection(language),
                    "ml-1.5" : !getApplicationDirection(language)
                })}
                src={Speaker}
                onClick={handleListenWord}
                width="16"
                height="16"
                alt=""
              />
            </h5>
            <p className='mt-3'>{selectedData.translation["tr"]}</p>

            <img
                src={selectedData.content}
                className="rounded-[10px] mx-auto mt-5"
                width={175}
                height={175}
                alt="word-image"
            />

            <p dir="ltr" className="mt-5 font-bold">{selectedData.sentence.title}</p>
            <p className="mt-3">{selectedData.sentence.translation[lang]}</p>

            <div className="flex gap-8 my-5 justify-center">
              <Button
                  type="button"
                  classnames="button primary-button-outline max-w-[150px]"
                  action={handlePreviousButton}
                  text={strings.pagination.previous}
              />
              <Button
                  type="button"
                  classnames="button primary-button max-w-[150px]"
                  action={handleContinueButton}
                  text={strings.speaking_lesson.quiz.continue}
              />
            </div>
          </div>
        )}

        {wordStep === 2 && (
            <ExerciseQuestion
                questions={data?.questions}
                setInnerStep={() => setWordStep(1)}
                setComponentStep={() => setStep(OnboardingExercise.SENTENCES)}
                logs={quizLogs}
            />
        )}
      </div>
    </div>
  )
}

export default OnboardingExerciseWords