import Chart from "react-apexcharts";
import React from "react";
import {getColorForScore} from "./Utility";
import classNames from "classnames";

const RadialBar = (props) => {

    const {
        height,
        fontSize,
        score,
        offsetY,
        size,
        classnames = "",
        action = () => {}
    } = props;

    const chartState = {
        series: [score],
        options: {
            chart: {
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: `${size}`
                    },
                    dataLabels: {
                        show: true,
                        name: {
                            offsetY: `${offsetY}`,
                            show: true,
                            color: '#333',
                            fontSize: `${fontSize}px`
                        },
                        value: {
                            show: false,
                        }
                    },
                },
            },
            colors: [getColorForScore(score)],
            labels: [score === 0 ? '-' : score]
        },
    };

    return (
        <div className={classNames("", {
            [classnames] : !!classnames
        })}>
            <Chart
                type="radialBar"
                options={chartState.options}
                series={chartState.series}
                height={height}
                onClick={action}
            />
        </div>
    )
}
export default RadialBar;