import { getBrowserName, getOSId } from '../../components/Utility';
import { fetchApi } from '../index';

export const AddMemberFamily = async (model) => {

    const url = '/_services/member/v1/member_family/add_member_family/';

    const payload = {
        sourceType: 1, //web
        deviceName: getBrowserName(),
        deviceOs: getOSId(),
        ...model
    };

    return await fetchApi(url, payload);
};

export const RemoveMemberFamily = async (model) => {

    const url = '/_services/member/v1/member_family/remove_member_family/';

    const payload = {
        ...model,
    };

    return await fetchApi(url, payload);
};

export const GetFamilyMember = async (memberId) => {

    const url = '/_services/member/v1/member_family/get_family_members/';

    const payload = {
        memberId: memberId,
    };

    return await fetchApi(url, payload);
};

export const GetFamilyManagerNameByFamilyCode = async (familyCode) => {

    const url = '/_services/member/v1/member_family_code/get_family_manager_name_by_family_code/';

    const payload = {
        familyCode: familyCode,
    };

    return await fetchApi(url, payload);
};

export const AddMemberFamilyWithInvite = async (model) => {

    const url = '/_services/member/v1/member_family/add_member_family_with_invite/';

    const payload = {
        brandId: +process.env.REACT_APP_BRAND_ID,   
        sourceType: 1, //web
        deviceName: getBrowserName(),
        deviceOs: getOSId(),
        ...model,
    };

    return await fetchApi(url, payload);
};