import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getApplicationDirection } from '../../components/Utility';
import { useLocalization } from '../../hooks/useLocalization';
import { url } from '../../routes/utility';
import { GetResourcesByResourceType } from '../../services/Resources/ResourcesService';
import { ProgramBadge } from '../Market/components';
import useAnalytics from '../../hooks/useAnalytics';
import { EventLogs } from '../../components/Constants';
import { useEffect } from 'react';

const Resources = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const analytics = useAnalytics();
    const language = useSelector(state => state.localizationStore.language);

    const [buttonLoading, setButtonLoading] = useState(false);

    const resourcesData = [
        {resourceType: 1, title: strings.dashboard.student.grammar_videos},
        {resourceType: 2, title: strings.dashboard.student.business_english_videos},
    ];

    const ResourceType = Object.freeze({
        GRAMMAR_VIDEOS: 1,
        BUSINESS_ENGLISH_VIDEOS: 2,
    });

    const getResourcesByResourceType = async (resourceType) => {
        setButtonLoading(buttonLoading => true);

        const result = await GetResourcesByResourceType(resourceType);

        if (result) {
            if(resourceType === ResourceType.GRAMMAR_VIDEOS)
                analytics.useAnalytics(EventLogs.RESOURCES_GRAMMAR_VIDEOS_SCREEN_VIEWED);

            if(resourceType === ResourceType.BUSINESS_ENGLISH_VIDEOS)
                analytics.useAnalytics(EventLogs.RESOURCES_BUSINESS_ENGLISH_VIDEOS_SCREEN_VIEWED);

            navigate(url("resources.innercategory"), { state: { "data": result }});
        }
        setButtonLoading(buttonLoading => false);
    }

    const handleButton = (resourceType) => {
        !buttonLoading && getResourcesByResourceType(resourceType);
    }

    useEffect(() => {
        //LOGGED
        analytics.useAnalytics(EventLogs.RESOURCES_PAGE_VIEW);
    }, [])

    return (
        <div className={`main-content ${getApplicationDirection(language) ? 'md:ml-7' : 'md:mr-7'}`}>
            <div className="transparent-section mt-5">
                <h5 className="text-secondary">{strings.dashboard.student.all_sources}</h5>
                <p className="mt-3 text-center">{strings.dashboard.student.all_sources_description}</p>
                {resourcesData.map((resource, index) => (
                        <ProgramBadge
                            key={index}
                            title={resource.title}
                            onClick={() => handleButton(resource.resourceType)}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default Resources