import { createSlice } from "@reduxjs/toolkit";

export const ComponentRenderSlice = createSlice({
    name: 'ComponentRender',
    initialState: {
        DailyLesson: {
            Attended: false
        }
    },
    reducers: {
        updateRenderStatus: (state, action) => {
            const { group, key, value } = action.payload;
            if (state[group]) {
                state[group][key] = value;
            } else {
                state[group] = { [key]: value };
            }
        }
    }
});

export const { updateRenderStatus } = ComponentRenderSlice.actions;

export default ComponentRenderSlice.reducer;