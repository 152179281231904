import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom'
import {getCookie} from 'ko-basic-cookie';
import {cookieName} from '../../components/Constants';
import {url} from '../../routes/utility';
import {decodeToken} from '../../components';
import {GetCandidateSurveyService} from '../../services/Auth/AuthService';

const CheckAuth = () => {

    const navigate = useNavigate();
    //get token from cookies
    const token = getCookie(cookieName) || false;
    const member = token && decodeToken(token);
    const [surveyStatus, setSurveyStatus] = useState(false);

    useEffect(() => {
        if (token) {
            GetCandidateSurveyService(member?.MemberId)
                .then(result => {
                    if(result.status === 200){
                        let resultContent = JSON.parse(result.content);
                        setSurveyStatus(surveyStatus => resultContent);
                    }
                });
        }
        else {
            navigate(url("auth.login"));
        }
    }, [token])

    // Kullanıcı onboarding use case seçimi yapmadıysa onu seçim ekranına gönderiyoruz
    useEffect(() => {
        if(Object.keys(surveyStatus).length > 0) {
            let redirectUrl = 'dashboard';

            if (surveyStatus && surveyStatus?.first.length === 0) {
                redirectUrl = 'onboarding'
            }

            navigate(url(redirectUrl));
        }
    }, [surveyStatus])
}

export default CheckAuth