import classNames from "classnames";
import Lottie from "lottie-react";
import React from "react";
import { useSelector } from "react-redux";
import { BottomStickyBar } from ".";
import download from '../assets/animation/download.json';
import { Logo } from "../assets/svg";
import { useLocalization } from "../hooks/useLocalization";
import { getApplicationDirection } from "./Utility";

const DownloadMobileAppBar = () => {

    const language = useSelector(state => state.localizationStore.language);
    const strings = useLocalization();

    return (
        <BottomStickyBar
            theme="white"
            isOverlay={true}
            children={
                <>
                    <p className="text-center md:hidden pt-2">{strings.popup.download_app.content}</p>
                    <div className="flex justify-between items-center min-h-[64px]">
                        <div className="flex justify-center items-center gap-2.5">
                            <div className="flex items-center">
                                <img className="w-[28px] md:hidden" src={Logo} alt="" />
                                <div className="max-md:hidden">
                                    <Lottie
                                        animationData={download}
                                        loop
                                        autoplay
                                        style={{ height: '32px', width: '32px' }}
                                    />
                                </div>
                                <p className={classNames("font-bold text-[14px] md:hidden lg:text-lg", {
                                    "ml-1 lg:ml-2" : !getApplicationDirection(language),
                                    "mr-1 lg:mr-2" : getApplicationDirection(language),
                                })}>
                                    {strings.brand_name}
                                </p>
                                <p className={classNames("text-center max-md:hidden", {
                                    "mr-3" : getApplicationDirection(language),
                                    "ml-3" : !getApplicationDirection(language),
                                })}>{strings.popup.download_app.content}</p>
                            </div>

                        </div>
                        <a
                            className="button primary-button px-2 h-[40px] max-w-max min-w-[80px] text-[16px] mt-0 leading-[16px] flex justify-center items-center"
                            href={"https://onelink.to/3a8qtv"}
                        >
                            {strings.popup.download_app.button}
                        </a>
                    </div>
                </>
            }
        />
    );
}

export default DownloadMobileAppBar;