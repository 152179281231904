export const Local = {

    brand_name: 'Alingiliziah',

    errors: {
        no_records_found: 'لا توجد سجلات',
    },

    menu: {
        nav: {
            menu1: 'القائمة الرئيسية',
            menu2: 'درس التحدث',
            menu3: 'المدربين',
            menu4: 'سوبر ماركت',
            menu5: 'يدعم'
        },
        collapse: {
            profile: "حساب تعريفي",
            support: "مركز الدعم",
            reference: "أدع صديقا",
            demo: "احصل على الدرس التجريبي",
            logout: "الخروج",
            contact_us: "اتصل بنا",
            modal: {
                no_demo_description: "لا يحق لك الحصول على درس تجريبي.<br/><br/>تحتاج إلى الاتصال بمرشدك للحصول على حقوق الدرس التجريبي.",
                already_have_active_demo: "لديك درس تجريبي نشط.",
                contact_mentor: "اتصل بالمرشد"
            }
        },
        callbar: {
            need_permission: "تحتاج إلى إعطاء إذن الإشعار والميكروفون لحضور الفصول الدراسية من هذه الشاشة. ",
            allow_now: "منح الإذن",
            permission_error: "متصفح الإنترنت الخاص بك يمنع طلب إذن الإعلام من الظهور.  يمكنك الحصول على مساعدة حول كيفية إجراء هذه الإعدادات من خلال النقر فوق الارتباط أدناه.",
            join_your_lesson_button: "الانضمام للدرس",
            join_your_lesson_message: "بالنقر فوق الزر <b> الانضمام إلى الفصل </ b> ، يمكنك البدء في انتظار وصول معلمك إلى الدرس.",
            calling_you: "#teacher# يتصل بك", //Teacher Jasmine is calling you now.
            talking_to_now: "بدأ الدرس مع #teacher#", //Teacher is talking to you now.
            wait_while_connecting: "انتظر حتى يتم الاتصال.", //Please wait while connecting.
            an_error_occured: 'حدث خطأ أثناء العملية.', //An error occurred during operation.
            no_lesson_for_today: 'لا يوجد لديكم درس اليوم',//You do not have any lesson today.
            not_in_available_time_range: 'أنت لست في المنطقة الزمنية الصحيحة.  حاول مرة أخرى قبل 5 دقائق من موعد الدرس.', //You are not in available time range! You can join your lesson 5 minutes before.
            poor_connection: 'اتصالك بالإنترنت ضعيف. يرجى التحقق من اتصالك.',
            connection_lost: 'اتصال الإنترنت الخاص بك معطل. مدرسك سوف يتصل بك مرة أخرى.',
            general_error_message: 'حدث خطأ غير متوقع.  يرجى تقديم الطلب.',
            callbar_refresh_alert_message: 'ربما تكون قد أعطيت الإذن من قبل، يرجى تحديث صفحتك \n إذا كنت لا تزال ترى زر السماح بعد تحديث صفحتك، فيرجى منح الأذونات الخاصة بك.',
            refresh_page: 'قم بتحديث الصفحة',
            download_application: "اذهب إلى التطبيق",
            not_avaliable_text: "تحتاج إلى استخدام تطبيق الهاتف المحمول الخاص بنا لتلقي المكالمات.  تحتاج إلى استخدام تطبيقات الهاتف المتحرك لدينا."
        }
    },

    modal: {
        okey: "حسنا",
        approve: "اونايلا",
        dont_approve: "يستسلم",
        information_message: "رسالة إعلامية",
        transaction_information: "تأكيد المعاملة",
        required_field_error: "لا يمكنك ترك الحقول الاجبارية (*) فارغة . يرجى منكم تعبئة الحقول الضرورية"
    },

    //auth screens 
    auth: {
        layout: {
            right_subtitle_1:"نحن أكبر مدرسة لتعليم اللغة الإنجليزية على الإنترنت في #country# مع أكثر من 50000 طالب",
            right_subtitle_1_general: "تعلم اللغة الإنجليزية على طريقتك: دورات مخصصة للجميع",
            right_subtitle_2: "\”دورة لغة إنجليزية ممتازة. غيرت حياتي.” -أحمد جواد",
            logo_subtitle: "مرحباً بكم في نظام تعلم اللغة الإنجليزية الأكثر فعالية في #country#",
            logo_subtitle_general: "أطلق العنان لإمكانياتك في اللغة الإنجليزية من خلال نظام التعلم المثبت لدينا.",
        },
        form: {
            your_name: 'الاسم واللقب',
            create_your_name: 'أدخل معلومات الاسم واللقب',
            phone_number: 'رقم تليفونك',
            current_password: 'كلمة السر الحالية الخاصة بك',
            enter_current_password: 'أدخل كلمة المرور الحالية',
            your_password: 'كلمة السر خاصتك',
            your_password_again: 'كلمة السر خاصتك',
            create_your_password: 'إنشاء كلمة مرور',
            enter_your_password: 'إنشاء كلمة مرور',
            enter_your_password_again: 'أدخل كلمة المرور الخاصة بك مرة أخرى!',
            enter_your_phone_number: 'رقم هاتفك',
            enter_code: 'ادخل الرمز',
            enter_sms_code: 'أدخل الرمز المرسل عن طريق الرسائل القصيرة',
            sign_up_button: 'تسجيل',
            sign_in_button: 'تسجيل الدخول',
            forgot_password_button: 'هل نسيت كلمة السر',
            okay_button: 'حسنا',
            send_button: 'إرسال',
            change_button: 'يتغيرون',
            watch_video: 'شاهد الفيديو',
            verification_button: 'تحقق',
            privacy_and_terms_1: 'تعلم من خلال التحدث كعضو ',
            privacy_and_terms_2: 'اتفاق السرية ',
            privacy_and_terms_3: 'و ',
            privacy_and_terms_4: 'تعليمات الاستخدام ',
            privacy_and_terms_5: 'يعتبر أنك قد قبلت.'
        },
        information_messages: {
            forgot_password_sentence_1: 'الرجاء إدخال رقم هاتفك المسجل في نظامنا والنقر فوق الزر إرسال. ',
            forgot_password_sentence_2: 'سيتم إرسال كلمة المرور الخاصة بك إلى هاتفك عن طريق الرسائل القصيرة.',
            forgot_password_back: 'أريد تسجيل الوصول',
            forgot_password_verification_code_sentence_1: 'أدخل الرمز المرسل إلى هاتفك عبر الرسائل القصيرة واضغط على زر التحقق.',
            forgot_password_verification_code_sentence_2: 'إذا نجحت عملية التحقق ، يمكنك إجراء عملية تغيير كلمة المرور.',
            change_password_success_message: `\nلقد غيرت كلمة سرك بنجاح
            يمكنك تسجيل الدخول باستخدام رقم هاتفك وكلمة المرور الجديدة بالذهاب إلى شاشة تسجيل الدخول.`,
            code_not_expired: "لم تنتهي صلاحية الرمز الأخير الخاص بك بعد!",
            modal_title: 'رسالة إعلامية',
        },
        validation_messages: {
            //1
            invalid_name: 'الرجاء إدخال اسمك ولقبك.',
            invalid_phone: 'يرجى إدخال رقم هاتف صحيح!',
            invalid_password: 'على 4 أحرف على الأقل!',
            invalid_verification_code: 'أدخل رمز تحقق صالح!',
            invalid_change_password: 'يجب أن تحتوي كلمة مرورك على 4 أحرف على الأقل!',
            already_created_user: `'تم إيجاد حساب مسجل سابقا بهذا الرقم.\n\n ان كنت تعرف كلمة السر يمكنك تسجيل الدخول باستخدامها, ،ان كنت نسيت كلمة السر يمكنك الضغط على  قسم نسيت كلمة السر وإرسال طلب باستردادها`,
            invalid_username_or_password: 'لم يتم العثور على سجلات مطابقة للمعلومات التي أدخلتها. \n الرجاء التحقق من المعلومات الخاصة بك والمحاولة مرة أخرى.',
            you_entered_an_incorrect_verification_code: 'لقد أدخلت رمز تحقق غير صحيح. \n الرجاء التحقق من المعلومات الخاصة بك والمحاولة مرة أخرى.',
        },

    },

    //onboarding screens
    onboarding: {
        welcome_1: 'مرحبًا ،',
        welcome_2: 'لدينا بعض الأسئلة',
        welcome_2_single: 'لدينا سؤال',
        next_button: 'التالي',
        continue_button: 'استمر',
        first: {
            title: 'ما هو مستواك في اللغة الإنجليزية؟',
            button1: 'أنا لا أعرف الإنجليزية',
            button2: 'أفهم القليل',
            button3: 'أعرف القواعد ، لكني أجد صعوبة في التحدث',
            button4: 'أنا مرتاح جدًا للعرض.',
            button5: 'أنا لست مختلفا عن شكسبير',
            level1: "بداية (Starter)",
            level2: "اساسي (Beginner)",
            level3: "متوسط (Intermediate)",
            level4: "متقدم (Advanced)"
        },
        second: {
            title: 'لماذا تريد أن تتعلم اللغة الانجليزية؟',
            button1: 'لعملي / مهنتي',
            button2: 'للسفر إلى الخارج',
            button3: 'لتعليمي',
            button4: 'آخر',
            use_case1: "الاستخدام في الحياة العملية",
            use_case2: "الارتقاء في حياتك المهنية",
            use_case3: "الاستخدام في اجتماعات العمل ",
            use_case4: "مقابلة للمشاركة في مقابلة العمل ",
            use_case5: "رحلات العمل خارج البلاد ",
            use_case6: "عطلة خارج البلاد",
            use_case7: "السفر لأجل العمل خارج البلاد",
            use_case8: "التواصل مع قريب أو صديق ",
            use_case9: "الطلاب الذين سيستخدمونها في المدرسة",
        },
        third: {
            title: 'ما هو عملك؟',
            button1: 'أكاديمي',
            button2: 'محامي',
            button3: 'طبيب',
            button4: 'ضابط',
            button5: 'مهندس',
            button6: 'طالب',
            button7: 'معلم',
            button8: 'القطاع الخاص',
            button9: 'إدارة',
            button10: 'المالية',
            button11: 'السياحة',
            button12: 'آخر',
        },
        success: {
            congratulations: 'تهانينا!',
            profile_created: 'لقد نجحت في إنشاء ملف التعريف الخاص بك.',
            subtitle: "حان الوقت الآن للتعرف على نظام تعلم اللغة الإنجليزية الأكثر فعالية في #country#"
        },
        information_messages: {
            an_error_occured: 'حدث خطأ أثناء العملية. حاول مرة أخرى.',
            make_a_choice: 'يجب عليك الاختيار قبل المتابعة. الرجاء اختيار أحد الخيارات والضغط على زر "التالي".',
            max_choice_message: 'يمكنك إجراء ما يصل إلى #count# اختيارات.'
        },
    },

    onboarding_exercises: {
        title: 'عمل اليوم',
        dashboard_message_1: 'تم تحديد برنامج الدراسة المجاني الخاص بك والذي يتكون من الحوار والمفردات وبنية الجملة والقواعد.',
        dashboard_message_2: 'يمكنك مواصلة العمل بالضغط على الزر أدناه.',
        start_study_error_message: 'عذرًا، لقد انتهت فترة الـ 3 أيام الخاصة بك.',
        day_text: '#dayIndex#. يوم',
        start_study: 'إبدأ العمل',
        translate: 'عرض الترجمة',
        voice: 'تحدث بالحوار',
        record_dialog: 'حفظ الحوار',
        recording: 'يتم التسجيل',
        correct_answer: 'لقد أجبت بشكل صحيح.\nتهانينا.',
        wrong_answer: 'لقد أجبت خطأ.\nيجب عليك العمل بجدية أكبر.',
        congratulate: 'تهانينا',
        final_message: 'لقد تعلمت اليوم حوارًا وثلاث كلمات ونمط جملة وقاعدة نحوية.',
        final_message_2: 'نراكم غدا في العمل الجديد.',
        your_answer: "إجابتك",
        right_answer: "إجابة صحيحة",
        read_dialog: "اقرأ الحوار أدناه",
        complete_the_blank: "أكمل الفراغ بالكلمة المناسبة .",
        sentence_pattern: "هيكل الجملة",
        grammar_rule: "قواعد النحو",
        dialog_info: "لا تقرأ العناوين (أ،ب) أثناء قراءة الحوار."
    },

    mentor: {
        title: 'معلمك الشخصي',
        dashboard_message_1: 'المضي قدمًا في رحلتك الشخصية مع معلمك!',
        dashboard_message_2: 'اقترب من أهدافك خطوة بخطوة مع معلمك وعزز تطورك الشخصي من خلال التوجيه والتعليقات القيمة!',
        about_mentor: 'حول المرشد',
        mentor_informations: 'معلومات المرشد',
        name: 'الاسم',
        phone: 'رقم الهاتف',
        mail: 'عنوان البريد الإلكتروني',
        working_hour: 'ساعات العمل',
        what_is_mentor: 'ما هو المرشد؟',
        mentor_content1: 'يتتبع تقدمك مع معلمك.',
        mentor_content2: 'يقدم النصائح والإرشادات.',
        mentor_content3: 'يعقد اجتماعات التقييم المؤقتة.',
        write_whatsapp: "اكتب لي على الواتساب",
        default_mentor: "المرشد الافتراضي",
        weekday: "داخل الاسبوع",
        hello: "مرحبا",
    },

    dashboard: {
        title: "Al Ingiliziah",
        none_user: {
            title_1: 'مرحبًا',
            title_2: "مرحباً بكم في نظام تعلم اللغة الإنجليزية الأكثر فعالية في #country#",
            inactive_student_title_1: 'أهلا مرة أخرى,',
            inactive_student_title_2: 'انتهت صلاحية الباقة الخاصة بكم',
            inactive_student_title_3: "يمكنك مراجعة <strong class = \"text-primary \"> حزم التدريب </ strong> لمتابعة التطوير من حيث توقفت.",
            inactive_student_title_4: 'للوصول إلى دورات الفصل الدراسي السابقة<strong class=\"text-primary\">انقر هنا</strong>',
            advice_1: 'هذا هو الاستثمار لتطويرك.',
            advice_2: 'تعلم اللغة الإنجليزية!',
            more_info: 'معلومات مفصلة',
            view_packages: 'مراجعة الحقائب التدريبية',
            student_comments: 'تعليقات من طلابنا',
            comment_info_1: 'Gökhan Telkenar / مذيع_ ميكرفون ',
            comment_info_2: 'Esra Sönmezer / لاعب ',
            play_video: 'شاهد الفيديو',
        },
        how_to_learn: {
            play_video: 'شاهد الفيديو',
            most_curious_about: 'أكثر ما يثير فضولك',
        },
        demo_student: {
            title: "درس اليوم",
            try_demo_title: "استمتع بالتعلم من خلال التحدث من خلال درس تجريبي مجاني",
            try_demo_content: "تعرف على مدرسينا الأصليين للغة الإنجليزية واستمتع بتجربة التعلم عن طريق التحدث.",
            plan_demo_lesson: "جدولة الدرس التجريبي",
            demo_lesson_reserved: "لقد تم حجز الدرس التجريبي بنجاح!",
            no_permission_text: "<strong>الاستعداد للدرس التجريبي الخاص بك!</strong></br>لحضور الدرس التجريبي، تحتاج إلى تنزيل تطبيق الهاتف المحمول Alingliziah  ومنح الأذونات اللازمة للمشاركة في الدرس التجريبي.",
            permission_text: "<strong>كل شيء جاهز!</strong></brكل شيء جاهز لحضور الدرس التجريبي!  عندما يحين وقت الدرس، سيتصل بك معلمك عبر تطبيق الهاتف المحمول  Alingliziah ومنصة الويب.",
            change_demo_lesson_time: "يمكنك تغيير وقت الدرس #button#هنا#button#.",
            demo_lesson_completed: "لقد أكملت الدرس التجريبي الخاص بك",
            lesson_completed_content: "اكتمل درسك التجريبي مع #TEACHER#.  انقر #button#هنا#button# لرؤية تعليقات معلمك.",
            lesson_info: "مرحبًا <b>#name#</b>، يبدأ درس المحادثة التالي في #time# في #date#. لديك حوالي يوم واحد ، #counter# دقيقة على وقت الدرس. سيتصل بك معلمك عندما يحين وقت الدرس. من خلال تثبيت تطبيق الهاتف المحمول الخاص بنا، يمكنك تلقي تذكيرات ما قبل الفصل والحصول على تجربة درس أفضل.",
            microphone_permission: "إذا كنت ترغب في حضور دروسك عبر متصفح الويب الخاص بك، فيجب عليك منح أذونات الميكروفون والإشعارات.",
            download_mobil_app: "حمل تطبيق الهاتف",
            give_microphone_permission: "امنح اذن الميكروفون",
            give_notification_permission: "امنح الإذن للإشعارات",
        },
        student: {
            title: "درس المحادثة من اليوم",
            word_of_the_day: "كلمات اليوم",
            idioms_of_the_day: "التكبيرات الإصلاحية الإنجليزية اليوم",
            view_the_lesson_book: "مراجعة الكتاب المدرسي",
            click_for_previous_lessons: "انقر لأجل الدرس الماضي",
            want_to_postpone_class: "أريد تأجيل درسي",
            all_sources: "جميع المصادر",
            grammar_videos: "مقاطع فيديو نحوية",
            business_english_videos: "فيديوهات اللغة الإنجليزية للأعمال",
            all_sources_description: "طور نفسك من خلال مقاطع فيديو واختبارات تفاعلية ",
            video_duration: 'مدة الفيديو',
            no_lesson_info: "لا يمكن تحميل معلومات الدورة.",
            no_teacher_info: "فشل تحميل معلومات المدرس.",
            your_teacher: "المعلمين ",
            lesson_info: "مرحبًا <b>#name#</b>، يبدأ درس المحادثة التالي في #time# في #date#. لديك حوالي يوم واحد ، #counter# دقيقة على وقت الدرس. هل أعددت قبل الحصة؟",
            day: "يوم",
            hour: "ساعة",
            minute: "دقيقة",
            second: "ثانية",
            dashboard_passive_lesson_error: "الباقة المحددة تنتمي إلى الفترة الماضية",
            in_lesson_time_message: "مرحبًا <b>#name#</b>، بياناتك للدرس الأخير في انتظارك."
        },
        video_exercise: {
            title: "استمتع بتعلم اللغة الإنجليزية بالفيديو!",
            content: "ادخل إلى عالم من مقاطع الفيديو الغامرة والأنشطة التفاعلية لإتقان التحدث باللغة الإنجليزية والمفردات والكتابة والاستماع والنطق.",
            button_text: "ابدأ الاستكشاف"
        },
        yearly_summary: {
            card_title: 'ماذا فعلت في #year#؟',
            card_content: 'عرض ملخص نهاية العام',
            modal: {
              content: 'لقد حظيت بسنة رائعة! 🎉إليكم النجاحات التي حققتموها في رحلتكم لتعلم اللغة الإنجليزية. لا تنسى أن تصفق لنفسك! 👏',
              total_lesson_count: 'الدرس الذي حضرته',
              total_minute: 'إجمالي الدقائق',
              ai_session: 'الممارسة مع الذكاء الاصطناعي',
              total_word: 'الجملة التي نطقتها',
              total_sentence: 'الجملة التي نطقتها',
              close: 'أغلق'
            }
        } 
    },

    profile: {
        title: "حساب تعريفي",
        profile_settings: "إعدادات الملف الشخصي",
        save_button: "حفظ",
        success_message: "تم تحديث إعدادات ملفك الشخصي بنجاح.",
        dont_ask_personal_information: "من فضلك لا تسأل عن معلوماتي الشخصية",
        dont_ask_how_was_your_day: "من فضلك لا تسألني كيف كان يومك.",
        profile_visibility_open: "رؤية الملف الشخصي قيد التشغيل",
        profile_visibility_off: "رؤية الملف الشخصي معطلة",
        following: "يتبع",
        follower: "تابع",
        follow: "أتبع",
        you_are_following: "أنت تتابع",
        add_friend: "أضف صديق",
        join_date_text: "تم الانضمام بتاريخ #date#",
        unlock_profile: "فتح الملف الشخصي",
        go_to_profile: "انتقل إلى ملفي الشخصي",
        lesson_method: {
            title: "طريقة تقديم الدورة",
            title_one: "التقيد بموضوع الكتاب حصراً",
            title_two: "السماح بالمحادثة الحرة على أساس الكتاب"
        },
        feedback: {
            title: "نوع ردود فعل المعلم",
            title_one: "تصحيح جميع الأخطاء في نهاية الدرس",
            title_two: "تصحيح الأخطاء فورياً أثناء الدرس "
        },
        reason_to_learn_english: {
            title: "لماذا تريد أن تتعلم اللغة الانجليزية؟  (يمكن إجراء اختيارات متعددة)",
            error_message: "من الضروري اختيار سبب واحد على الأقل لتعلم اللغة الإنجليزية!",
            1: "استخدامها في الحياة العملية/التجارية",
            2: "التقدم في حياتك المهنية",
            3: "استخدامها في اجتماعات العمل",
            4: "حضور مقابلة عمل",
            5: "رحلات العمل الدولية",
            6: "عطلة في الخارج",
            7: "الذهاب إلى الخارج للعمل",
            8: "التواصل مع قريب أو صديق مقرب",
            9: "التواصل مع قريب أو صديق مقرب"
        },
        statistics: {
            title: "إحصائيات",
            weekly_streak: "سلسلة أسبوعية",
            total_lesson_duration_title: "التحدث الإنجليزية",
            total_lesson_duration: "#minutes# دقيقة",
        },
        recommendations: {
            title: "اقتراحات للأصدقاء",
            multiple_recommend_member_text: "#name# و#count# آخرين يتابعون",
            single_recommend_member_text: "#name# يتبع"
        },
        follow_list: {
            following: "#count# يتبع",
            follower: "#count# تابع",
            profile_click: "الانتقال إلى الملف الشخصي",
            following_empty_text: "سيتم عرض الأشخاص الذين تتابعهم في هذا المجال",
            follower_empty_text: "سيتم عرض الأشخاص الذين يتابعونك في هذا المجال"
        },
        profile_operations: {
            report_member: "بواسطة تقرير المستخدم",
            unfollow: "وقف التالية",
            report_member_approve_text: "هل أنت متأكد أنك تريد الإبلاغ عن <b>#name#</b>؟",
            report_member_success_text: "لقد أبلغت عن المستخدم <b>#name#</b>.",
            unfollow_member_approve_text: "هل أنت متأكد أنك تريد إلغاء متابعة <b>#name#</b>؟",
            unfollow_member_success_text: "لقد قمت بإلغاء متابعة المستخدم <b>#name#</b>."
        },
        leaderboard: {
            ranking: "الترتيب",
            weekly_streak: "سلسلة اسبوعية",
            total_lesson_duration: "#duration# دقيقة إجمالي مدة الدرس",
        },
        calendar: {
            calendar_title: "جدول السلسلة",
            disable_calendar_text: "سيتم عرض تقويم السلسلة الخاصة بك في هذه المنطقة",
            active_streak: "السلسلة المستمرة",
            end_streak: "اسبوع السلسلة المنتهي",
            start_streak: "بداية السلسلة"
        },
        awards: {
            all_awards_title: "كافة الجوائز",
            award_description: "يمكنك مواصلة السلسلة والفوز بجوائز مفاجئة من خلال حضور درس واحد على الأقل كل أسبوع.",
            all: "جميعها",
            awards_title: "الجوائز",
            reward_title: "#streakCount# سلسلة أسبوعية",
            reward_content: "أكمل سلسلة #streakCount# الاسبوع للفوز بالمكافأة.",
        }
    },

    demo: {
        title: "درس تجريبي",
        page_title: "اختر يوم وساعة الدرس",
        description: "باختياركم احد المدرسين الموجودين في القائمة في الأسفل يمكنكم اخد حصة تجريبية لمدة 10 دقائق",
        approve_message: "في #hour#الساعة #date# سيكون درسكم التجريبي\nهل توافق على هذا؟",
        success_message: "في #hour#الساعة #date# سيكون درسكم التجريبي <br/><br/>احتفظ بالصفحة Alingiliziah مفتوحة في متصفحك وقم بالرد على المكالمة الواردة.",
        term_not_found: "لم يتم العثور على الفصل الدراسي!",
        missed_call_not_found: "لم يتم العثور على درس نشط!",
        unit_selection: {
            title: "حدد محتوى الدورة التدريبية",
            level_test: "درس تقييم المستوى",
            free_talk: "درس محادثة حرة",
            trial: "دروس مصممة خصيصا لغرض التعلم الخاص بك",
            select_unit: "الرجاء تحديد محتوى الدورة!"
        },
        level_selection: {
            title: "اختر مستواك في اللغة الإنجليزية"
        },
        auto_teacher_selection: {
            available_hour: "اختيار الوقت الأنسب لك",
            available_teacher: "اختيار المدرب الأنسب لك",
            approve_demo_lesson: "قم بتأكيد الدرس التجريبي الخاص بك",
            ai_choosing_teacher: "يقوم ذكاءنا الاصطناعي بتحليل مستواك وأهدافك واختيار المدرب الأنسب لك.",
            ai_choosing_time: "وفي الوقت نفسه، نحدد وقت الدرس الأمثل وفقًا لتوافرك.",
            selected_auto_teacher_description: "إذا كان مناسبًا ليوم الدرس ووقته، قم بتأكيد الدرس، ويمكنك تغييره إذا كنت ترغب في ذلك.",
            approve_lesson_button: "تأكيد الدورة",
            choose_different_time: "اختر يومًا أو وقتًا مختلفًا",
            your_lesson_date: "تاريخ الدرس الخاص بك"
        },
        demo_progress: {
            demo_lesson: "الدرس التجريبي",
            demo_lesson_description: "احصل على درس تجريبي مجاني في الوقت الذي يناسبك.",
            demo_lesson_plan: "جدولة الدرس التجريبي",
            demo_lesson_prepare: "الاستعداد للدرس التجريبي",
            demo_lesson_attend: "انضم إلى الدرس التجريبي",
            demo_lesson_report: "مراجعة تقرير الدرس التجريبية",
            demo_faq: "F.A.Q",
            demo_progress_step: "#completedStepCount#/4 اكتمل"
        }
    },

    market:
    {
        title: 'سوبر ماركت',
        buy_button: 'اشتري',
        okay_button: 'حسنا',
        gifts: {
            title: "الهدايا"
        },
        additional_rights: {
            title: "حقوق اضافية",
            description: "يمكنك مواصلة مسيرتك التعليمية دون انقطاع عن طريق اختيار ما يناسبك من الخيارات أدناه.",
            postpone_description: "يمكنك اختيار الباقة المناسبة لتأجيل الدورة التي تناسبك.",
            postpone_right: "#count# عدد حقوق التأجيل",
            postpone_right_checkout: "انت تدفع #count# مقابل عدد حقوق تأجيل الدرس.",
            market_passive_term_message: "الباقة التي اخترتها هي باقة غير نشطة.  لا يمكن شراء حقوق إضافية للباقات غير النشطة.",
            market_future_term_message: "الباقة التي اخترتها هي باقة مستقبلية.  لا يمكن شراء حقوق إضافية للباقات المستقبلية.",
            market_pending_term_message: "لم يتم تأكيد الباقة التي اخترتها بعد.  بمجرد الموافقة على الباقة الخاصة بك، يمكنك شراء حقوق إضافية.",
        },
        speakingLesson: {
            title: 'حزم التدريب',
            title1: "كم يوما في الأسبوع يمكنك أن تتدرب؟",
            title2: "كم دقيقة في اليوم تريد أن تتدرب فيها؟",
            title3: "كم شهر ستستمر في تعليمك؟",
            day: "يوم",
            minute: "دقيقة",
            month: "شهر",
            month_single: "شهر",
            total_amount: "المبلغ الإجمالي",
            total_discount: "إجمالي الخصم",
            remove_discount: "إزالة الخصومات المطبقة",
            discount_content: "بخصم ",
            instead_of: " في مكانها",
            discount_code: "أدخل رمز الخصم",
            discount: "الخصم",
            apply: "تقدم",
            offer_title: "خاص لكم",
            price_by_per_lesson: "الرسوم لكل درس",
            total_lesson_price_with_gift: "إجمالي #totalLessonCount# درسًا ( #totalLessonCountWithoutGift# درسًا + #giftLessonCount# درسًا هدية)",
            total_lesson_price: "#totalLessonCount# درسًا",
            slogan: "بدأت الحملة الصيفية خصوم تصل إلى 35% . اغتنم الفرصة وابدأ معنا.العدد محدود.",
            selected_package: {
                title: 'الحزمة المختارة ومحتوياتها',
                package_display: 'شهر، أسبوعيا #D# أيام ومدة المحاضرة #LM# دقيقة ',
                // package_display: '#M# Ay haftada #D# gün günlük #LM# dakika #T#',
                postpone_right: 'امكانية تاجيل الدرس #number# مرات',
                change_teacher_and_time_right: 'امكانية تبديل الوقت والمدرس #number# مرات',
                missed_call_right: 'فاتني #number# دروس صحيحة',
                freeze_lesson_right: 'امكانيه شهر#number# تجميد',
                gift_month: 'تدريب هدية لمدة شهر #number#',
                monthly: "#monthCount# شهر",
                weekly: "#dayCount# أيام في الأسبوع",
                daily: "#minuteCount# دقيقة يوميا",
                extra_rights_one: "مقاطع الفيديو والمواد التدريبية والاختبارات",
                extra_rights_two: "دروس غير محدودة مع الذكاء الاصطناعي",
                extra_rights_three: "مرشد / مستشار خاص لك",
            },
            information_messages: {
                speaking_lesson_choose_all: 'يرجى تحديد جميع الاختيارات (الباقة / اليوم / الدقيقة / الشهر) والنقر على زر الشراء.',
                modal_title: "رسالة إعلامية",
                same_discount_code_error: 'لقد تم استخدام رمز الخصم هذا بالفعل.  لا يمكنك إعادة استخدام نفس رمز الخصم!',
                same_type_code_error: 'يمكن استخدام خصم واحد فقط من نفس النوع!',
            },

        },
        checkout: {
            price_without_discount: "المبلغ بدون خصم",
            price_without_tax: "المبلغ بدون ضريبة القيمة المضافة",
            tax_rate: "%#KDV# ضريبة",
            discount: "الخصم المطبق",
            sub_total: "المجموع الفرعي",
            click_for_pay_detail: "انقر لرؤية تفاصيل الدفع",
            total_amount: "المبلغ الإجمالي",
            package_amount: "مبلغ الباقة ",
            reference_discount: "الخصم المرجعي",
            fourty_eight_discount: "تخفيض ال 48 ساعه ",
            campaign_discount: "خصم العرض",
            family_package_discount: "خصم الباقة العائلية",
            second_purchase_discount: "تخفيض الاشتراك الثاني",
            moneybox_discount: "الخصومات المتراكمة",
            one_shot_discount: "خصم الدفعة الواحدة",
            other_installment_discount: "خيارات التقسيط الأخرى",
            discount_success_message: "لقد تم تطبيق رمز الخصم الخاص بك بنجاح.",
            discount_unsuccess_message: "لقد أدخلت رمز خصم غير صحيح.  حاول مرة اخرى.",
            discount_limit_exceeded: "لا يمكن إضافة كود خصم جديد لأن نسبة الخصم الحالية وصلت إلى 100%",
            cancel_discount: "إلغاء الخصم ",
            discounts_cleared: "تمت إعادة تعيين جميع الخصومات المطبقة",
            input_card_info: "أدخل معلومات البطاقة",
            card_number: "رقم البطاقة",
            card_name_surname: "الاسم على البطاقة",
            card_exp_date: "تاريخ الإنتهاء",
            month: "شهر",
            year: "سنة",
            bank: "بنك",
            cvv: "CVV هو آخر ثلاثة أرقام على ظهر بطاقتك الائتمانية.",
            installment_options: "خيارات الدفع",
            installment: 'الاقساط',
            complete_button: 'استمر',
            trying_to_pay: "الدفع قيد التقدم",
            pay_is_ok: "تم الدفع بنجاح",
            for_package: ' تدفعه مقابل الحزمة.',
            choose: "تحديد",
            one_shot: "ضربة واحدة",
            card_informations: "معلومات البطاقة",
            invoice_informations: "معلومات الفواتير",
            preliminary_information_form_title: "استمارة المعلومات الأولية",
            errors: {
                card_number: "لقد أدخلت رقم بطاقتك الائتمانية غير مكتمل / غير صحيح.\n",
                card_name: "لا يمكن ترك حقل الاسم على البطاقة فارغًا.\n",
                card_month: "لا يمكن ترك حقل الشهر فارغًا لتاريخ انتهاء الصلاحية.\n",
                card_year: "لا يمكن ترك حقل السنة فارغًا لتاريخ انتهاء الصلاحية.\n",
                card_cvv: "لقد أدخلت حقل CVV غير مكتمل / غير صحيح.\n",
                card_bank: "الرجاء اختيار بنك.\n",
                card_installment: "الرجاء تحديد عدد الأقساط.\n",
            }
        },
        successful:
        {
            title: "تم الدفع الخاص بك بنجاح.",
            content: "سيتصل بك مستشار التعليم الخاص بك في أقرب وقت ممكن لتحديد تفاصيل الدورة التدريبية الخاصة بك.",
            postpone_content: "يمكنك عرض حقوقك الإضافية على صفحة تقرير المشاركة.",
            button: "تمام",
            term_error: "سيتم إنشاء فترة التعليم الخاصة بك من قبل مستشار التعليم الخاص بك."
        },
        failed:
        {
            title: "لا يمكن إتمام الدفع الخاص بك.",
            content: "يرجى التحقق من المعلومات وحاول مرة أخرى.",
            error_title: "تفاصيل الخطأ",
            button: "حاول من جديد",
            bank_error: 'يوجد أدناه رد البنك الذي تتعامل معه بشأن خطأ الدفع.',
        },
        information: {
            title: "يمكنك متابعة معاملتك عن طريق إدخال معلوماتك عبر الشاشة أدناه.",
            name: "الاسم واللقب",
            email: "الايميل",
            address: "عنوان وصول الفواتير",
            button: "تابع",
            errors: {
                name: "أدخل معلومات الاسم واللقب.\n",
                email: "أدخل معلومات بريدك الإلكتروني.\n",
                address: "أدخل معلومات عنوان الفواتير.\n",
            }
        },
        completed:
        {
            title: "تهانينا",
            content: "تم حفظ المعلومات الخاصة بك بنجاح.",
            button: "تمام"
        },
        education_packages: {
            title: "برامج تعليمية",
            description: "اختر البرنامج التدريبي الذي يناسبك. قم بمراجعة إنجازاتك والمناهج الدراسية ونماذج الكتب.",
            total_lesson_count: "#count# درس",
            program_achievements: '"#title#" ماذا ستحقق في نهاية التعليم؟',
            word_count: "كلمة #count#",
            hint_count: "#count# اليومية",
            downloading: "إنديريليور..."
        }
    },

    speaking_lesson:
    {
        title: 'درس التحدث',
        click_for_lesson_detail: "انقر للحصول على تفاصيل الملاحظة",
        letter_note_informations: {
            speaking_grammar: {
                one: "The speaker has a very basic understanding of grammar and struggles to form coherent sentences. Errors in grammar usage are frequent and may interfere with understanding.\nMany errors in grammar usage, including frequent errors in subject-verb agreement, verb tense, and word order. The number of errors could be in the range of 10-20 per minute of speech.",
                two_three: "The speaker has a limited but functional understanding of grammar and can communicate basic information with some errors. Grammar mistakes may still be frequent and noticeable.\nSome errors in grammar usage, including errors in basic structures such as singular and plural forms, articles, and simple verb tenses. The number of errors could be in the range of 5-10 per minute of speech.",
                four_five: "The speaker has a good understanding of grammar and can communicate effectively, though with occasional errors. The speaker can use complex sentence structures and express ideas with relative ease.\nOccasional errors in grammar usage, including errors in complex structures such as subordinate clauses and verb tense consistency. The number of errors could be in the range of 2-5 per minute of speech.",
                six_seven: "The speaker has a near-native command of grammar and can communicate complex ideas with little difficulty. Errors in grammar usage are rare and do not interfere with understanding.\nFew errors in grammar usage, with occasional mistakes in complex structures such as passive voice and reported speech. The number of errors could be in the range of 1-2 per minute of speech.",
                eight_nine: "The speaker has a native-like mastery of grammar and can use language flexibly and effectively for a range of purposes. The speaker can understand and use complex structures, idioms, and other nuanced language features.\nRare errors in grammar usage, with occasional mistakes in subtle nuances such as idiomatic expressions and sentence-level discourse markers. The number of errors could be less than 1 per minute of speech.",
                ten: "The speaker has a mastery of grammar that exceeds that of most native speakers. The speaker can use language creatively and with a high degree of precision, using a wide range of registers and styles as appropriate to the context.\nVirtually no errors in grammar usage, with occasional slips in pronunciation or vocabulary choice. The number of errors could be less than 1 per several minutes of speech."
            },
            speaking_vocabulary_usage: {
                one: "The speaker has a very basic vocabulary and struggles to express ideas with limited words. They may rely on frequent repetition of the same words or phrases.\nFrequent errors in word choice, pronunciation, and usage. The speaker may rely on a small set of words and struggle to convey even basic ideas. The number of errors could be in the range of 10-20 per minute of speech.",
                two_three: "The speaker has a functional vocabulary and can communicate basic information with some errors. However, their vocabulary may be limited to everyday words and phrases, and they may struggle with more complex or specialized terminology.\nSome errors in word choice, pronunciation, and usage. The speaker may struggle with more advanced vocabulary and rely on basic words and phrases. The number of errors could be in the range of 5-10 per minute of speech.",
                four_five: "The speaker has a good vocabulary and can communicate effectively in most situations. They can use a range of vocabulary to express ideas, but may still struggle with more advanced terminology.\nOccasional errors in word choice, pronunciation, and usage. The speaker can use a range of vocabulary to express ideas, but may still struggle with more advanced or specialized terminology. The number of errors could be in the range of 2-5 per minute of speech.",
                six_seven: "The speaker has a broad vocabulary and can use language flexibly and precisely to express complex ideas. They can use specialized terminology and idiomatic expressions appropriately in a range of contexts.\nFew errors in word choice, pronunciation, and usage. The speaker can use language flexibly and precisely to express complex ideas, and can understand and use specialized terminology appropriately. The number of errors could be in the range of 1-2 per minute of speech.",
                eight_nine: "The speaker has an extensive and nuanced vocabulary, and can use language creatively to achieve a range of communicative goals. They can understand and use complex vocabulary in a variety of registers and styles.\nRare errors in word choice, pronunciation, and usage. The speaker has an extensive vocabulary and can use language creatively and effectively, even in specialized or formal contexts. The number of errors could be less than 1 per minute of speech.",
                ten: "The speaker has a near-native command of vocabulary, using language with an exceptional range of precision and nuance. They can understand and use rare or archaic vocabulary, as well as complex idiomatic expressions, with ease.\nVirtually no errors in word choice, pronunciation, and usage. The speaker has an exceptional range of vocabulary and can use language with an exceptional degree of precision and nuance. The number of errors could be less than 1 per several minutes of speech."
            },
            speaking_pronunciation: {
                one: "The speaker is difficult to understand, with poor articulation and significant errors in pronunciation, stress, and intonation. They may struggle to produce even basic sounds and may not be able to be understood by native speakers.\nThe speaker may make numerous mistakes in pronunciation, such as mispronouncing many sounds, omitting or adding sounds, and using incorrect stress and intonation patterns.",
                two_three: "The speaker can produce some sounds and words correctly, but may still have difficulty with more complex sounds and pronunciations. Their intonation and stress may be off, making it difficult to understand them in certain contexts.\nThe speaker may still make some noticeable errors in pronunciation, such as mispronouncing some sounds or having trouble with more complex sounds, but can generally be understood by others.",
                four_five: "The speaker can produce most sounds and words accurately and has a good grasp of basic intonation and stress patterns. They may still make some errors, particularly with less common sounds or words, but can generally be understood by native speakers.\nThe speaker may make occasional errors in pronunciation, such as mispronouncing some less common words or having a slight accent, but can generally communicate fluently and accurately.",
                six_seven: "The speaker can produce nearly all sounds and words accurately and has a good command of intonation and stress patterns, making them sound more natural and fluent. They may still have some slight accent or occasional errors, but overall can be easily understood by native speakers.\nThe speaker may make few errors in pronunciation, and can generally produce all English sounds with accuracy and natural intonation and stress. They may still have a slight accent, but it does not interfere with their communication.",
                eight_nine: "The speaker can produce all sounds and words accurately, with near-native pronunciation and intonation. They are able to make fine distinctions in sound and can adapt their pronunciation to different dialects and contexts. They may still have a slight accent, but are generally indistinguishable from native speakers.\nThe speaker may make very few errors in pronunciation, and can produce all English sounds with near-native accuracy and natural intonation and stress. They may still have a slight accent, but it is difficult to detect.",
                ten: "The speaker has native-level pronunciation and intonation, with no discernible accent. They are able to produce subtle distinctions in sound and can adapt their pronunciation to any dialect or context. They may also be able to imitate different accents or dialects with ease.\nThe speaker is unlikely to make any noticeable errors in pronunciation, and can produce all English sounds with native-like accuracy and natural intonation and stress. They may be mistaken for a native speaker."
            },
            listening_comprehension: {
                one: "The listener has difficulty understanding even basic words and phrases in the language. They may need to rely on visual cues or context to understand the meaning of spoken language.\nThe listener may make numerous mistakes, such as not being able to identify basic vocabulary or verb forms, and having difficulty understanding even simple sentences or phrases.",
                two_three: "The listener can understand simple words and phrases, but may struggle with more complex sentences or idiomatic expressions. They may need to ask for repetition or clarification in order to understand the meaning of spoken language.\nThe listener may make some mistakes, particularly with more complex language or idiomatic expressions, but can generally understand the meaning of spoken language with repetition or clarification.",
                four_five: "The listener can understand most spoken language in familiar contexts, but may still struggle with more complex or abstract language, particularly when it is spoken quickly or with an unfamiliar accent.\nThey may also miss some details or nuances in the language. The listener may make occasional mistakes, such as missing some details or nuances in the language, particularly when it is spoken quickly or with an unfamiliar accent.",
                six_seven: "The listener can understand most spoken language, including more complex and abstract language, spoken at a normal pace and with a variety of accents. They can also understand implied meanings and cultural references.\nThe listener may make few mistakes and can understand most spoken language, including more complex and abstract language, spoken at a normal pace and with a variety of accents.",
                eight_nine: "The listener can understand spoken language with a high degree of accuracy and fluency, even in complex or technical contexts. They can also understand nuances in tone, register, and intonation.\nThe listener may make very few mistakes, and can understand spoken language with a high degree of accuracy and fluency, even in complex or technical contexts.",
                ten: "Exceptional proficiency: The listener can understand spoken language with complete accuracy and fluency, even in the most challenging contexts, such as fast-paced conversations between multiple speakers or with strong regional accents. They can also understand nuances in meaning and cultural references at a level that is equivalent to that of a native speaker.\nThe listener is unlikely to make any mistakes and can understand spoken language with complete accuracy and fluency, even in the most challenging contexts."
            },
            reading_vocabulary_awareness: {
                one: "The reader has a very limited vocabulary and struggles to understand even basic words in the language. They may need to rely heavily on a dictionary or translation tool to read simple texts.\nThe reader may make numerous mistakes, such as not recognizing basic words or being unable to infer the meaning of unfamiliar words from context.",
                two_three: "The reader has a basic vocabulary and can understand simple texts with familiar vocabulary, but may struggle with more complex or technical vocabulary.\nThe reader may make some mistakes, particularly with less familiar vocabulary, but can generally understand most of the words in simple texts.",
                four_five: "The reader has a moderate vocabulary and can understand most texts with familiar vocabulary, but may struggle with more advanced or specialized vocabulary.\nThe reader may make occasional mistakes, such as misunderstanding the meaning of more complex or technical vocabulary, but can generally understand most of the words in moderately difficult texts.",
                six_seven: "The reader has an extensive vocabulary and can understand most texts with a high degree of accuracy, including those with advanced or specialized vocabulary.\nThe reader may make few mistakes, and can generally understand most of the words in difficult or specialized texts, but may occasionally need to look up unfamiliar vocabulary.",
                eight_nine: "The reader has an exceptional vocabulary and can understand texts with a high degree of precision, even in very specialized fields or contexts.\nThe reader may make very few mistakes, and can understand most texts with a high degree of accuracy, including those with advanced or technical vocabulary.",
                ten: "The reader has a mastery of vocabulary in the language equivalent to that of a native speaker, and can understand and use highly nuanced and idiomatic language with ease. They may also be able to accurately and precisely infer meaning from context, without needing to rely on a dictionary or other tools.\nThe reader is unlikely to make any mistakes, and can understand and use highly nuanced and idiomatic language with ease. They may also be able to accurately and precisely infer meaning from context, without needing to look up unfamiliar vocabulary."
            },
            reading_pronunciation: {
                one: "The reader reads slowly and may struggle with basic comprehension, needing to sound out words and reread frequently. Their reading may be halting and choppy, with little or no attention to expression.\nThe reader may make numerous mistakes, such as mispronouncing many words, misreading or skipping words, and struggling to understand basic vocabulary and sentence structure.",
                two_three: "The reader reads with some fluency and is able to understand simple texts with basic vocabulary. Their reading may still be slow and require occasional assistance.\nThe reader may make some mistakes, such as mispronouncing some words, stumbling over more complex vocabulary, and needing some guidance to understand the text.",
                four_five: "The reader reads with moderate fluency and is able to understand moderately complex texts with some unfamiliar vocabulary. They may still need to reread some sections for better comprehension.\nThe reader may make occasional mistakes, such as mispronouncing some specialized vocabulary, missing some nuances or idioms, and occasionally needing to reread sections for better comprehension.",
                six_seven: "The reader reads with good fluency and is able to understand difficult texts with specialized vocabulary. They can read smoothly and efficiently with little difficulty.\nThe reader may make few mistakes, and can generally understand and pronounce most words with accuracy, but may occasionally struggle with highly specialized or technical vocabulary.",
                eight_nine: "The reader reads with exceptional fluency and can easily understand even the most difficult texts with advanced vocabulary. They can read with excellent speed and accuracy and comprehend at a high level.\nThe reader may make very few mistakes, and can accurately and fluently read and understand texts with advanced or specialized vocabulary, without needing to look up unfamiliar words.",
                ten: "The reader reads with near-native fluency and can read and comprehend texts as well as or better than native speakers. They can easily read a wide range of texts with advanced vocabulary and high complexity.\nThe reader is unlikely to make any mistakes, and can accurately and fluently read and understand highly nuanced and idiomatic language with ease. They may also be able to infer meaning from context with a high degree of accuracy."
            }
        },
        speaking_lesson_user: {
            practice_with_ai: 'التطور بالحديث (AI)',
            lesson_of_the_day: 'درس اليوم',
            progress_report: "تتبع التقدم",
            quiz_test: 'مسابقة ومسابقة',
            daily_sentence: 'الجملة اليومية',
            participation_report: 'تقرير المشاركة',
            term_schedule: 'برنامج الدرس',
            education_packages: 'برامج تعليمية',
            today: 'اليوم',
            it_is_not_specified: 'غير محدد',
            audio_note: "سيتم حذف تسجيلاتك الصوتية من النظام بعد 5 أيام. نرجو أن تكون على علم."
        },
        speaking_lesson_non_user: {
            hello: 'مرحبًا',
            hello_title: "مرحباً بكم في نظام تعلم اللغة الإنجليزية الأكثر فعالية في #country#",
            sample_lesson: 'نموذج من الدروس في درس التحدث',
            view_packages: 'عرض حزم التدريب',
            plan_course_title: 'خطط لجدول الدورة مع مرشدك التعليمي الشخصي',
            plan_course: 'جدولة الدرس الخاص بي',
            modal_title: 'رسالة إعلامية',
            modal_message: 'سيتمكن مستشار التعليم الخاص بك من الوصول إليك في أقرب وقت ممكن عبر رقم هاتفك المسجل في النظام. حتى ذلك الحين ، يمكنك مراجعة المدربين لدينا',
            okay_button: 'مراجعة المدربين'
        },
        daily_sentence: {
            sample_sentences: 'عبارات توضيحيه',
            start_quiz: 'ابدأ الاختبار',
            message:'يمكنك رؤية الجملة واختبار اليوم لكتابك أدناه.',
            book_hint_not_found: 'لم يتم العثور على الجملة أو الاختبارات لكتاب اليوم.',
            friday_error_message:'نظرًا لأن كتاب اليوم هو درس محادثة حرة، فلا توجد أنماط واختبارات للجمل لهذا اليوم.',
            postponed: {
                content_1: "لقد أجلت درس التحدث اليوم لبرنامج المحادثة الذي اخترته",
                content_2: "يمكنك اختيار تاريخ آخر من التقويم لرؤية الجملة والاختبار.",
            },
            frozen: {
                content_1: "مرحبًا ، لقد جمدت فصولك الدراسية لبرنامج المحادثة هذا.",
                content_2: "يمكنك بسهولة تحديث معلمك ووقتك باتباع رسائل البريد الإلكتروني الخاصة بالمعلومات التي سيتم إرسالها قبل بدء الفصول الدراسية مرة أخرى.",
            },
            holiday: {
                content_1: "مرحبًا ،  اليوم عطلة ولا يوجد درس محادثة",
                content_2: "يمكنك اختيار تاريخ آخر من التقويم لرؤية الجملة والاختبار.",
            },
            inactive: {
                content_1: "ليس لديك دروس اليوم لأن الباقة الخاصة بك غير نشطة.  يمكنك الاطلاع على معلومات حول دوراتك القديمة بالانتقال إلى دوراتك السابقة في التقويم.",
                content_2: 'يمكنك مراجعة <strong class="text-primary">حزمنا التدريبية</strong> لمواصلة تطويرك من حيث توقفت.',
            },
        },
        daily_lesson: {
            choose_date: 'حدد تاريخ',
            daily_lesson_title: 'درس المحادثة من اليوم',
            daily_lesson_title_attended: 'تقييم الدورة',
            daily_lesson_information: 'مرحبًا ، يمكنك العثور أدناه على معلومات حول درس التحدث اليوم..',
            view_missed_lesson_book: 'عرض الكتاب المدرسي الفائت',
            your_teacher: 'معلمك ',
            lesson_time: 'وقت فصلك هو',
            lesson_content: 'يمكنك الوصول إلى محتوى الدورة التدريبية الخاصة بك من خلال الأقسام أدناه.',
            today_lesson_book: 'كتاب اليوم',
            book: 'الكتاب',
            presentation: 'عرض تقديمي',
            practice: 'يمارس',
            lesson_video: 'فيديو الدرس',
            book_voiceover: 'كتاب التعليق الصوتي',
            words: 'كلمات',
            lesson_evoluation: 'تقييم المحاضرة',
            your_voice_record: 'التسجيل الصوتي الخاص بكم للدرس',
            teacher_comment: 'تعليقات مدرسك حول الدورة',
            strong_points: 'نقاط القوة الخاصة بك',
            weak_points: 'المجالات التي تكون فيها منفتحًا على التحسين',
            teacher_suggestions: 'اقتراحات مدرسك للدورة',
            mispronunciation: 'خطأ في النطق/  الجمل المُكوَّنه بشكل غير صحيح',
            correct_pronunciation: 'النطق الصحيح/ الجمل المُكوَّنه بشكل صحيح',
            speaking_grammar_usage: 'استخدام القواعد النحوية',
            speaking_vocabulary_usage: 'استخدام المفردات',
            speaking_pronunciation: 'اللفظ',
            listening_comprehension: 'الإستماع والإستيعاب',
            reading_vocabulary_awareness: 'الإستيعاب بقراءة المفردات ',
            reading_pronunciation: 'قراءة النطق',
            lesson_information: "معلومات الدرس",
            lesson_study: "تدريبات ما بعد الدرس",
            lesson_audios: "التسجيلات الصوتية",
            correct_and_speak: "أصلح وتحدث (AI)",
            lesson_materials: "مواد الدرس",
            teacher_ranking_and_evaluation: "تقييمات ودرجات المدرس",
            lesson_ranking: "درجات الدرس",
            teacher_comments: "تعليق المدرس",
            teacher_recommendations: "اقتراحات المدرس",
            sentence_bank: {
                title: "بنك الجمل",
                all_sentences: "كل الجمل",
                added_list: "اضيف للقائمة",
                removed_list: "أُخرجَ من القائمة",
                load_more: "تحميل المزيد",
                sentence_learning_list: "قائمة تعلم الجمل"
            }
        },
        no_answer: {
            comment_title: "تعليقات مدرسك حول الدورة",
            comment_content: "لم تحضر درسك اليوم.",
            call_log_title: "أوقات استدعاء الدورة",
            missed_call_content: "الدرس الذي فاتك يوم #date# سيجري مع #new_date# .#teacher# في الساعة #time#.",
            missed_call_warning_one: "قم بمراجعة الكتاب المدرسي لليوم مرة أخرى بعد نتيجة الدرس البديل.",
            missed_call_warning_two: "التحقق من استبدال الكتاب المدرسي مرة أخرى بعد نتيجة اليوم."
        },
        components: {
            holiday: {
                content_1: "مرحبًا ، لا توجد عطلات ولا دروس في التحدث اليوم.",
                content_2: "يمكنك المتابعة باختيار موعد للتحضير لدرس المحادثة التالي.",
            },
            postponed: {
                content_1: "لقد قمت بتأجيل درس التحدث اليوم لبرنامج المحادثة الذي اخترته.",
                content_2: "يمكنك المتابعة باختيار موعد للتحضير لدرس المحادثة التالي.",
            },
            frozen: {
                content_1: "مرحبًا ، لقد جمدت فصولك الدراسية لهذا البرنامج الحواري.",
                content_2: "ستتم إعادة تشغيل دروسك في #date#. يمكنك بسهولة تحديث مدربك ووقتك باتباع رسائل البريد الإلكتروني الخاصة بالإشعارات التي سيتم إرسالها قبل هذا التاريخ.",
            },
            inactive: {
                content_1: "ليس لديك درس اليوم لأن حزمتك غير نشطة.",
                content_2: "يمكنك الاطلاع على معلومات حول دروسك السابقة بالذهاب إلى دروسك السابقة في التقويم.",
                content_3: 'يمكنك مراجعة <strong class="text-primary">حزمنا التدريبية</strong> لمواصلة تطويرك من حيث توقفت.',
            },
            study_subjects: {
                title: "المواد الدراسية",
                tab_title_1: "أسئلة الفصل",
                tab_title_2: "عبارات",
            },
            audio: {
                book_voiceover_title: "كتاب التعليق الصوتي",
                lesson_records_title: "التسجيلات الصوتية للدورة",
                lesson_records: "التسجيلات الصوتية للدورة",
            },
            video: {
                title: "فيديو الدرس",
            },
            words: {
                title: "كلمات",
            },
            reference_bar: {
                description: "ادع صديقك , تعلموا معا واربحوا الجوائز",
                button_text: "يدعو"
            }
        },
        progress_report: {
            you_are_a_candidate: 'لا يمكن عرض تقرير التقدم الخاص بك لأنه ليس لديك حزمة نشطة حتى الآن.',
            attendance_rate: "معدل المشاركة في الفصل",
            progress_chart: "مخطط التنمية او التطور",
            same_level_title: "من أين أتى أولئك الذين بدأوا بنفس مستواي خلال 12 شهرًا؟ (*)",
            same_level_content: "(*) يُعطى متوسط ​​المستوى الذي وصل إليه طلابنا الذين يأخذون دروسًا 5 أيام في الأسبوع إذا كانوا يحضرون 90٪ أو أكثر من فصولهم الدراسية."
        },
        attendance_report: {
            you_are_a_candidate: 'لا يمكن عرض تقرير التقدم الخاص بك لأنه ليس لديك حزمة نشطة حتى الآن.',
            not_use: 'لم يتم العثور على تاريخ.',
            date_content: ' درس مؤرخ',
            report_1: {
                title: 'تقرير حضور مفصل',
                begin_date: 'تاريخ البدء : ',
                total_lesson_count: 'العدد الإجمالي للدروس : ',
                attended_lesson_count: 'عدد الدورات التي حضرها : ',
                missed_lesson_count: 'عدد الدروس الفائتة : ',
                remaining_lesson_count: 'عدد الدروس المُتَبقية : ',
                postpone_right_count: 'حق "تأجيل الدرس" المتبقي : ',
                missed_call_right_count: 'الحق المتبقي "فاتني صفي" : ',
                change_teacher_time_right_count: 'استحقاق "المدرب / تغيير الوقت" المتبقي :',
                freeze_right_count: 'حق "تجميد الدورة التدريبية" المتبقي : ',
            },
            report_2: {
                title: 'الدورات التي لم تحضرها',
            },
            report_3: {
                title: 'الدروس باستخدام تأجيل الدرس',
            },
            report_4: {
                title: 'فاتني دروس الدرس المستخدمة',
            },
        },
        holidays: {
            information: 'لن تكون هناك فصول للتحدث المباشر في الأيام التالية. لديك وصول 24/7 إلى التطبيقات ومواد الدورة والاختبارات.',
            title: 'أيام الإجازة'
        },
        quiz: {
            title: "جميع الإختبارات",
            description: "يمكنك العثور على اختبارات حزمة المحادثة الخاصة بك وحلها أدناه. يمكنك العثور على اختبارات الحزمة الخاصة بك وحلها أدناه.",
            answered_quiz: "يتم عرض الوحدات التي تم الرد عليها مسبقًا بعلامة <span class=\"material-symbols-outlined text-primary\">check</span>.",
            view_book: "عرض الكتاب ",
            show_report: "إعلان النتائج",
            start: "إبدأ",
            remaining_time: "الوقت المتبقي",
            control: "افحص",
            continue: "تابع",
            quiz_completed: "لقد أكملت الاختبار",
            report_description: "يمكنك العثور على معلومات مفصلة حول هذا الاختبار أدناه.",
            solve_again: "إعادة تشغيل الاختبار",
            start_time: "البداية",
            end_time: "النهاية ",
            total_question: "سؤال إجمالي",
            right_answer: "جواب صحيح",
            wrong_answer: "جواب خاطئ",
            empty_answer: "تُرِكَ فارغاً",
            timer_end_description: "لقد انتهت فترة الاختبار الخاصة بك.  انقر على الزر أدناه لرؤية نتيجة الاختبار.",
            quit_modal_text: "إذا قمت بتسجيل الخروج، فسيتم ترك الاختبار الذي قمت بحله غير مكتمل.  هل أنت متأكد أنك تريد تسجيل الخروج؟",
            quit: "تسجيل خروج",
            want_continue: "أريد المتابعة",
            leave_empty: "اتركه فارغاً",
            last_lesson : "الدرس الأخير",
            show_result: "إعلان النتيجة",
            return_video: "فيديو عودة الدرس",
            no_book_found: "لم يتم العثور على اختبارات للكتب التابعة للحزمة التي حددتها."
        },
        education_packages: {
            title: "محتوى البرنامج",
            start_lessons: 'بدأ الدروس',
            units: "الوحدات",
            icons: {
                awsome: "مكمل/عظيم",
                knowledgeable: "معلومة",
                completed: "اكتمل الدرس",
                preparation: "تمهيد / تحضير",
                not_started: "لم يبدأ",
                quiz: "اختبار",
                awsome_description: "(عُمِلَ به بواسطة الذكاء الاصطناعي)",
                knowledgeable_description: "(تم حل الاختبار)",
                preparation_description: "(تمت مراجعة الكتاب)"
            }
        },
        speaking_lab: {
            title: "مختبر التحدث",
            description: "قم بتحسين مهاراتك في التحدث باللغة الإنجليزية مع مختبر التحدث! ممارسات النطق وإكمال الجملة والتركيب في انتظارك. دعونا نبدأ!",
            description_content: "طوّر مهارات التحدث باللغة الإنجليزية مع مختبر المحادثة! تنتظرك تمارين النطق وإكمال الجمل والتركيب.",
            go_lab: "الذهاب إلى المختبر",
            pronounce_sentence_title: "انطق الجملة التالية",
            fill_in_the_blank_title: "انطق الجملة التالية وأكمل الفراغ بالكلمة المناسبة",
            create_sentence_title: "قم بإنشاء الكلمة التي تستمع إليها عن طريق اختيار الكلمات التالية بالترتيب الصحيح",
            create_sentence_correct: "تهانينا! لقد قمت بتكوين الجملة بشكل صحيح. انطق الجملة التي قمت بإنشائها.",
            create_sentence_wrong: "لقد قمت بصياغة الجملة بشكل غير صحيح. يرجى المحاولة مرة أخرى.",
            drag_drop_wrong_message: "لقد قمت بصياغة الجملة بشكل غير صحيح.",
            drag_drop_correct_message: "لقد قمت بصياغة الجملة بنجاح.",
            sentence_hint: "فكرة",
            wrong_answer: "إجابة غير صحيحة",
            correct_answer: "إجابة صحيحة",
            try_again_button: "حاول ثانية",
            voice_again: "كرر التعليق الصوتي",
            results_loading: "جارٍ تحميل نتيجتك...",
            your_voice: "أنتم",
            sample_voice: "مثال ",
            first_card_counter_text: "يبدأ في غضون #second# ثانية...",
            second_card_counter_text: "يستمر في غضون #second# ثانية...",
            pronunce_error_text: "حدث خطأ أثناء تحليل نطقك.",
            motivation_text: "تهانينا! لقد أكملت #count# من التمارين!",
            exercise_completed_text: "تهانينا! لقد أكملت هذا التمرين بنجاح!",
            start_again_modal_text: "لديك تقدم سابق في هذا التمرين.\nهل تريد المتابعة من حيث توقفت؟",
            start_again: "البدء من جديد",
            exit_exercise_question_text: "هل أنت متأكد أنك تريد ترك التمرين؟",
            skip_this_question: "تخطي هذا السؤال",
            are_you_there: "هل أنت هناك؟  👋🏻 يمكن تسجيل ما يصل إلى 15 ثانية من الصوت."
        }
    },

    teachers:
    {
        title: 'المدربين',
        teacher_details: {
            birth_place: "مكان الميلاد",
            age: "سن",
            about: "عن",
            education: "التعليم",
            interest_fields: "مجالات الاهتمام",
            buy_lesson_from_teacher: "تعلم من المدرب",
            not_student_content: "يجب أن يكون لديك حزمة نشطة لتتمكن من أخذ دروس من المدرب. يمكنك شراء الحزم بالانتقال إلى صفحة الحزم التعليمية.",
            not_student_buy_button: "عرض حزم التدريب",
            not_student_cancel_button: "يستسلم",
            teacher_audio: "التسجيل الصوتي"
        },
        teachers_list: {
            page_title: 'تم إدراج إجمالي #currentCount#/#total# مدربًا.',
            current_teacher_text: 'المجموع #currentCount#/#total# مدرب',
            detail_information: "معلومات مفصلة",
            age: " العمر"
        },
        rating: {
            rate: "يقيم",
            rate_your_teacher: 'قيّم معلمك',
            rate_description: 'يمكنك مشاركة آرائك حول مدرسك #teacherName#، من خلال النقر على الزر أدناه.',
            your_comment: "تعليقك",
            your_comment_error_message: 'لا يمكن أن يتجاوز حقل التعليق 300 حرف.',
            rating_success_message: 'تم استلام تقييمكم لمعلمكم الخاص بنجاح. يتم توجيهكم إلى شاشة درس اليوم ...'
        },
        filter: {
            title: "قائمة  المعلمين",
            start_time: "وقت البدء",
            end_time: "وقت الانتهاء",
            lesson_time: 'وقت الدرس ',
            my_packages: "الحزم",
            and: 'مع',
            filter_teachers: 'تصفية المعلمين',
            teacher_feature: 'ميزة المعلم',
            choose_teacher_feature: 'حدد ميزة المعلم',
            choose_teacher_feature_number: 'ميزة المعلم المحددة (#count#)',
            avaliable_time_not_found: "لم يتم العثور على ساعات الدرس المناسبة للمعلم.",
            teacher_features_list: {
                beginner_friendly: "مستوى مناسب للمبتدئين",
                child_friendly: "مناسب للأطفال",
                grammar_focused: "الانتباه للقواعد"
            }
        }
    },

    support:
    {
        title: 'مركز الدعم',
        teacher_time_change_title: "مدرس / تغيير الوقت",
        buy: "اشتري",

        main_page: {
            send_request: "تقديم الطلب",
            ticket_history: "الطلبات السابقة",
            postpone_lesson: "تأجيل الدرس",
            teacher_lesson_time_change: "مدرس / تغيير الوقت",
            missed_call: "فاتني الدرس",
            frozen_lesson: "خاصية تجميد الكورس",
            change_password: "غير كلمة السر",
            not_student_content: "يجب أن يكون لديك حزمة نشطة لاستخدام هذه الميزات. يمكنك شراء الحزم بالانتقال إلى صفحة الحزم التعليمية.",
            not_student_buy_button: "عرض حزم التدريب",
            not_student_cancel_button: "يستسلم",
            delete_account: "احذف حسابي",
            holidays: 'أيام الإجازة',
            passive_term_message: "هذه الميزة غير متوفرة للباقة التي اخترتها .لديك باقة نشطة.لاستخدام هذه الميزة قم بتغير الباقة المحددة",
            future_term_message: "الباقة التي اخترتها هي باقة مستقبلية. لا يمكن تنفيذ المعاملة التي حددتها في الحزم المستقبلية. لتحديد حزمة نشطة، انقر فوق الزر \"تغيير الحزمة المحددة\".",
            pending_term_message: "الباقة التي حددتها لم يتم تأكيدها بعد.  بمجرد الموافقة على الحزمة الخاصة بك، يمكنك متابعة المعاملة التي تختارها.",
            choose_active_term: "تغير الباقة المحددة"
        },
        send_request: {
            title: "يمكنك مشاركة أسئلتك ومشاكلك وجميع اقتراحاتك معنا في هذا المجال.",
            placeholder: "اكتب طلبك في هذا المجال.",
            send: "إرسال",
            modal_content: "تم إرسال طلبك بنجاح إلى الإدارات ذات الصلة.",
            modal_content_error: "لا يمكن أن يكون الطلب المراد إرساله أقل من 10 أحرف. حاول مرة اخرى.",
            request_limit_reached: "لا يمكن إرسال طلبك نظرًا لتجاوز حد إرسال طلبك اليومي."
        },
        previous_request: {
            title: "يمكنك العثور أدناه على الطلبات والردود التي أرسلتها من قبل.",
            not_yet_answered: 'لم تجب بعد.',
        },
        postpone_lesson: {
            postponed_lesson_title: "الدورات المؤجلة سابقا",
            postponed_lesson_content: "لقد أجلت الدرس الخاص بك بتاريخ #date#.",
            postpone_right: "يحق لكم تأجيل #counter# درسا.",
            postpone_information: "لكي تنجح عملية تأجيل الدورة ، يجب أن تتم العملية قبل ساعتين على الأقل من الدورة.",
            choose_postpone_date: "اختر التاريخ الذي تريد تأجيله (لا يمكن الحضور)",
            second_modal_message: "تأجيل الدورة هو إجراء لا رجوع فيه. هل أنت متأكد أنك تريد التأجيل؟",
            third_modal_message: "تم تنفيذ عملية تأجيل الدورة التدريبية الخاصة بك بنجاح.",
            postpone: "تأجيل",
            you_have_frozen_your_courses: "لقد جمدت فصولك الدراسية حتى #date1#. يمكنك إعادة المعالجة من #date2#.",
            modal_1_content: "#program#. هل أنت متأكد أنك تريد تأجيل درس برنامجك بتاريخ #date#؟",
            buy_postpone_lesson: "شراء حق تأجيل الدورة",
            postpone_lesson_count_description: "اذا قمت بتأجيل #count# دروس متتالية ، يمكن أن يتم إجراء تعديل على الوقت والمعلم لإنشاء برنامج انسب لكم "
        },
        postpone_lesson_information: {
            information_title: 'الأشياء التي يجب تذكرها قبل استخدام قسم تأجيل الدرس',
            information_one: "إذا كان لديك أكثر من برنامج، يجب عليك تحديد البرنامج الذي تريد معالجته من القسم أعلاه.",
            information_two: "يعد تأجيل الدورة إجراءً دائمًا ولا يمكن التراجع عنه.",
            information_three: "يجب عليك تحديد تاريخ الدورة التي لا يمكنك حضورها من التقويم أدناه والضغط على زر التأجيل.",
            information_four: "بعد الضغط على الزر، سيُطلب منك التأكيد مرتين.  بعد إعطاء هذين التأكيدين، سيتم تأجيل الدورة.",
            information_five: "تتم إضافة الدورات المؤجلة إلى نهاية جدول الدورة التدريبية الخاصة بك.",
            information_six: "يجب عليك القيام بذلك قبل ساعتين على الأقل من الدرس.",
        },
        postpone_lesson_no_right: {
            no_postpone_right: "ليس لديك الحق في تأجيل الدروس للبرنامج الذي اخترته.",
            no_postpone_right_information: "إذا كان لديك برنامج مختلف ، يمكنك اختيار ذلك البرنامج أعلاه أو شراء حق تأجيل الدرس."
        },
        teacher_time_change: {
            previously_changed_courses: 'الدورات التي تم تغييرها سابقًا',
            you_changed_your_lesson: '#time# /#teacher#  لقد غيرت الدرس.',
            teacher_time_change_question: "حدد المعلم ونطاق الوقت الذي تريد تغييره.",
            list_hours: "قائمة الساعات",
            modal_content: "لم يتم العثور على مدرب مناسب خلال الفترة الزمنية التي حددتها. \ الرجاء المحاولة مرة أخرى في فترة زمنية مختلفة.",
            teacher_change_information: "سيتم تحديث وقت الدرس والمعلم مع المعلم / الساعة التي تختارها من القائمة أدناه.",
            process: "عملك  ",
            process_continue: "  سيتم تحديث البرنامج.",
            lesson_time_change_dialog: "هل أنت متأكد من أنك تريد تحديث دورتك إلى #lesson# من خلال عملية تغيير وقت المدرب؟",
            teacher_change_success: "تم إكمال تغيير المدرس / الساعة بنجاح.",
            with: "مع",
            esd_two_hour_error: "هناك درس نشط لهذا اليوم.  يمكنك تغيير الوقت بعد ساعتين من وقت الدرس.",
            esd_time_frame_error: "نظرًا لأنه لم يتبق سوى أقل من ساعتين لموعد الدرس، فلا يمكن اتخاذ أي إجراء."
        },
        teacher_time_change_information: {
            information_title: "أشياء يجب تذكرها قبل استخدام قسم 'المدرب / تغيير الوقت'",
            information_one: "إذا كان لديك أكثر من برنامج واحد ، فيجب عليك تحديد البرنامج الذي تريد معالجته من القسم أعلاه.",
            information_two: "تغيير المعلم/توقيت الدرس هو تغيير دائم",
            information_three: "وبعد التغيير ، ستتم معالجة جميع دروسك مع معلمك الجديد وفي وقتك الجديد.",
            information_four: "نظرًا لعدم حظر مدرسك القديم وساعتك ، يمكن لطالب آخر استخدامها.",
            information_five: "يجب إجراء هذا التغيير قبل ساعتين على الأقل من موعد الدرس.",
            select_teacher: "اختر المعلم"
        },
        teacher_time_change_frozen: {
            frozen: "لقد جمدت دروسك لهذا البرنامج",
        },
        teacher_time_change_not_now: {
            not_now: "لا يمكن تغيير وقت المدرب في الوقت الحالي",
            not_now_information: "يمكنك إما تأجيل الدرس لليوم أو محاولة هذه العملية مرة أخرى عندما تنتهي حالة الدرس لتغيير وقت المعلم."
        },
        teacher_time_change_no_right: {
            no_change_right: "ليس لديك الحق في تغيير المدرس / الساعة للبرنامج الذي اخترته.",
            no_change_right_information: "إذا كان لديك برنامج مختلف ، يمكنك اختيار ذلك البرنامج أعلاه أو شراء حق تأجيل الدرس."
        },
        teacher_list: {
            teacher_name: "اسم المدرب",
            lesson_date: "تاريخ",
            lesson_time: "ساعات الدرس",
            choose: "تحديد",
            date: "تاريخ"
        },
        missed_call: {
            buy_make_up_lesson: "خذ درس مكياج",
            previously_missedcall_courses: 'فاتني دروس دروسي المستخدمة بشكل صحيح',
            missedcall_lesson_content: " لقد استخدمتها في درسك القديم.",
            not_used: 'لم تستخدم "درس دسي الفائت" من قبل.',
            no_suitable_instructor: 'لم يتم العثور على مدرس مناسب لدرس التجميل. \ n \ n الرجاء المحاولة مرة أخرى لاحقًا.',
            lesson_time_not_suitable_error_message: "وقت درس التعويض الذي تم اختياره متزامن مع فترة زمنية أخرى لديك.",
            missed_lesson_conflicted: "يتعارض وقت الدرس الفائت المحدد مع دروسك النشطة الأخرى!"
        },
        missed_call_used: {
            used_call: "فاتني الدرس ، لقد استخدمت حقك من قبل.",
            used_content: "الدرس الذي فاتك يوم #date1# سيجري مع #date2##teacher#  في الساعة #time#."
        },
        missed_call_no_right: {
            no_right: "ليس لديك الحق في تفويت صفي للبرنامج الذي اخترته.",
            no_right_information: "إذا كان لديك برنامج مختلف ، يمكنك اختيار هذا البرنامج أعلاه أو شراء الحق في Miss My Class."
        },
        missed_call_out_of_time: {
            out_of_time: "لقد نفدت ساعات العمل لاستخدام قسم فاتني الدرس.",
            out_of_time_information: "إذا لم تتمكن من الاتصال بالإنترنت أثناء الدرس أو إذا كانت لديك مشكلات فنية ، فيمكنك أخذ درس مكياج من صفحة فاتني صفي."
        },
        missed_call_information: {
            information_title: "أشياء يجب تذكرها قبل الاستخدام فاتني الدرس",
            information_one: "إذا كان لديك أكثر من برنامج واحد ، فيجب عليك تحديد البرنامج الذي تريد معالجته من القسم أعلاه.",
            information_two: "أصبح قسم الدرس الذي فاتني نشطًا بعد 20 دقيقة من نهاية الدرس.",
            information_three: "يجب استخدامه في غضون 40 دقيقة بعد تنشيط قسم الدرس الذي فاتني.",
            information_four: "الساعات المدرجة بعد النقر فوق الزر \"أخذ درس مكياج\" هي أنسب الساعات في النظام.",
            information_five: "درس المكياج المأخوذ من قسم \"فاتني الدرس\" هو عملية يومية ولا يحل محل تغيير المدرب / الساعة."
        },
        missed_call_set_lesson: {
            title: "قائمة مدرس دروس المكياج",
            information: "يمكنك أن تأخذ درس المكياج عن طريق الاختيار من بين المدربين المذكورين أدناه.",
            set_lesson_success: "اكتملت عملية اختيار دورة المكياج بنجاح.",
            modal_transaction: "هل أنت متأكد أنك تريد ضبط درس المكياج على #lesson#؟",
            process_done: 'اكتملت عملية اختيار دورة المكياج بنجاح.',
            already_used: 'لقد استخدمت سابقًا حقك في "Missed My Class" لهذا الدرس.',
        },
        missed_call_no_lesson_result: {
            title: "لم يتم تحميل نتائج الدرس بعد",
            information: "سوف تظهر نتائج الدرس من خلال هذه الشاشة عندما يتم تحميلها . اعد المحاولة بعد عدة دقائق"
        },
        freeze_lesson: {
            freeze_lesson: "تجميد الدروس",
            modal_content: `يمكن استخدام تجميد الدورة مرة واحدة. سيؤدي الإجراء الذي ستتخذه إلى <b>تجميد الدورات التدريبية</b> الخاصة بك <b>لمدة شهرين</b>.\n هل تريد تجميد دروس برنامج #lesson#؟`,
            second_modal_content: "يمكن استخدام تجميد الدورة <b>مرة واحدة</b>. سيؤدي الإجراء الذي ستتخذه إلى <b>تجميد الدورات التدريبية</b> الخاصة بك <b>لمدة شهرين</b>. \n لا يمكن التراجع عن هذا الإجراء. هل أنت متأكد أنك تريد عمل آيس كريم للدرس؟",
            modal_success_message: "تم تجميد الدورة التدريبية الخاصة بك بنجاح."
        },
        freeze_lesson_success: {
            success_message: "تم تجميد دوراتك حتى #date#.",
        },
        freeze_lesson_information: {
            information_title: "أشياء يجب تذكرها قبل استخدام قسم التجميد",
            information_one: "إذا كان لديك أكثر من برنامج واحد ، فيجب عليك تحديد البرنامج الذي تريد معالجته من القسم أعلاه.",
            information_two: "لا يتم استخدام الحق في تجميد الدورة في أجزاء ، ولكن في كتلة من شهرين.",
            information_three: "لا يمكن إلغاء الدورة بعد التجميد.",
            information_four: "في نهاية شهرين ، يتم تعيين المدرب بواسطة النظام وفقًا للتوافر. لهذا السبب ، قد لا تتمكن من الاستمرار مع نفس المدرب.",
            information_five: "يمكنك بسهولة تغيير مدرسك الجديد من قسم المدرب / تغيير الوقت.",
            information_six: "ستكون عملية تجميد الدورة سارية من يوم المعاملة ولا يمكن إجراء تجميد في المستقبل.",
            information_seven: "سيُطلب منك التأكيد مرتين أثناء إجراء عملية تجميد الدورة."
        },
        freeze_lesson_no_right: {
            no_freeze_right: "ليس لديك الحق في تجميد الدورات للبرنامج الذي اخترته.",
            no_freeze_right_information: "إذا كان لديك برنامج مختلف ، يمكنك اختيار هذا البرنامج أعلاه أو طلب الدعم من قسم تقديم الطلب."
        },
        change_password: {
            title: "يمكنك تغيير كلمة المرور الخاصة بك عن طريق ملء المعلومات أدناه.",
            modal_change_password_success: "تم استكمال <b> تغيير كلمة المرور </ b> الخاصة بك بنجاح.",
            modal_current_password_error: "لقد أدخلت كلمة المرور الحالية بشكل غير صحيح. يرجى التحقق من المعلومات التي قمت بإدخالها وحاول مرة أخرى.",
            modal_current_password_same_error: "لا يمكن أن تكون كلمة مرورك الجديدة هي نفسها كلمة مرورك الحالية.  يرجى التحقق من المعلومات التي قمت بإدخالها وحاول مرة أخرى."
        },
        delete_account: {
            modal_delete_account_first_content: "هل انت متأكد انك تريد حذف حسابك؟",
            modal_delete_account_second_content: "عندما يتم حذف حسابك ، سيتم حذف جميع البيانات التي تنتمي إلى حسابك ، بما في ذلك بيانات الدورة التدريبية السابقة والمستقبلية.\nحذف الحساب إجراء لا رجوع فيه.",
            modal_delete_account_third_content: "تم استلام طلب حذف حسابك. سيتم الانتهاء من عملية حذف حسابك في أقرب وقت ممكن وسيتم إخبارك",
            title: "من المحزن أن نفقدكم...",
            description: "إذا كان لديك أي تعليقات ترغب في مشاركتها معنا، فنحن على استعداد للاستماع إليها وبذل قصارى جهدنا لتحسين تجربتك.",
            write_message_button: "اكتب رسالة"
        },
        error_list: {
            term_not_found: 'المصطلح غير موجود',
            lesson_date_must_be_equal_or_greater_than_today: 'يجب أن يكون تاريخ الدورة مساويًا لليوم أو أكبر منه',
            lesson_time_is_invalid: 'وقت الفصل غير صالح',
            active_or_postponed_lesson_not_found_with_specified_date: 'لم يتم العثور على دورات نشطة أو مؤجلة في التاريخ المحدد!',
            postpone_right_is_already_used_for_this_date: 'حق التأجيل مستخدم بالفعل لهذا التاريخ!',
            you_are_not_in_the_postponement_time_range: 'نظرًا لأن هناك أقل من ساعتين قبل موعد الدرس ، لا يمكننا تأجيل الدرس. من أجل تنفيذ تأجيل الدورة بنجاح ، يجب أن يتم ذلك قبل ساعتين على الأقل من الدورة. شكرا لتفهمك.',
            you_are_not_in_the_freeze_time_range: 'أنت لست في فترة التجميد!',
            you_do_not_have_enough_postpone_right: 'ليس لديك ما يكفي من التسويف!',
            freeze_right_already_used_by_specified_date: 'لقد قمت بالفعل بتجميد الدرس الخاص بك حتى التاريخ المحدد!',
            you_do_not_have_any_freeze_right: 'ليس لديك الحق في تجميد الدرس!',
            the_lesson_time_of_term_invalid: 'وقت الفصل الدراسي غير صالح!',
        },
        assign_term_teacher: {
            message_single: "نظرًا لغيابك، تم تحديث وقت الدرس إلى #teacherInformation#.",
            message_multiple: "نظرًا لغيابك، تم تحديث ساعات الدرس الخاصة بإشتراكك أدناه."
        }
    },

    general_information_messages: {
        prev: "< ",
        next: " >",
        an_error_occured: 'حدث خطأ أثناء العملية. حاول مرة أخرى.',
        minute: "دقيقة",
        loading: "جار التحميل...",
        processing: "جاري تنفيذ الصفقة...",
        under_construction_title: "خطة",
        under_construction_content: "لا تنس تحديث تطبيقك باستمرار.",
        error404_title: "الصفحة غير موجودة",
        error404_content: "الصفحة التي كنت تبحث عنها لا يمكن العثور عليها. يمكنك الوصول إلى الصفحة التي تريدها باستخدام القوائم.",
        error500_title: "عند إضافة المعطيات حدث خطأ. يرجى تحديث الصفحة مجدداً"
    },

    pagination: {
        next: "التالي",
        previous: "رجوع"
    },

    lesson_type: {
        active: "نشيط",
        future: "نشط (تاريخ مستقبلي)",
        inactive: "غير نشط",
        pending: "في انتظار الموافقة",
        attended: "انضم للدرس",
        missed: "مضاف",
        postponed: "الدرس الفائت",
        frozen: "المجمدة",
        holiday: "عطلة",
        weekend: "عطلة نهاية الاسبوع",
        no_lesson: "لا درس",
        system_postponed: "مؤجل من قبل النظام",
        deleted: "محذوف"
    },

    popup: {
        nps: {
            enter_your_comment: "ادخل تعليقك هنا",
            success_message: "تم استلام ردك. نشكركم",
            comment_error_message: 'لا يمكن أن يتجاوز حقل التعليق 250 حرف.',
        },
        download_app: {
            content: "قم بتحميل تطبيق الهاتف المحمول الخاص بنا لتلقي إشعارات ما قبل الفصل وتجربة درس أفضل.",
            button: "تحميل",
        }
    },

    more: {
        title: "اكثر",
    },

    ai_chatbot: {
        back: "للخلف، رجوع",
        start: "إبدأ التحدث",
        stop: "أوقف التحدث",
        write_here: "اكتب هنا",
        level_test_ai: "تحديد مستواك مع الذكاء الاصطناعي",
        ai_volume_up: "إلغاء كتم صوت الذكاء الاصطناعي",
        ai_volume_down: "كتم صوت الذكاء الاصطناعي",
        switch_write_mode: "قم بالتبديل إلى وضع التحدث",
        switch_speak_mode: "تحويل إلى وضع الكتابة",
        note: "لا",
        speak_end: "لانهاء المقابلة يكفي استعمال كلمة 'End'",
        write_end: "لانهاء المقابلة يكفي الضغط على زر 'End'.",
        give_microphone_permission_text: "تحتاج إلى السماح بالوصول إلى الميكروفون لاستخدام هذه الميزة. يمكنك الحصول على مساعدة من الفيديو الخاص بنا للسماح بالوصول إلى الميكروفون. يمكنك النقر فوق زر التبديل إلى وضع النص لمواصلة استخدام الذكاء الاصطناعي.",
        determine_your_level: "تحديد مستواك مع الذكاء الاصطناعي",
        errors: {
            correction_not_found_error: "لم يتم العثور على تصحيحات لهذه الفترة",
            microphone_not_allowed_error: "لاستخدام هذه الصفحة، يجب عليك منح إذن الميكروفون.",
            selected_term_not_found_error: "ليس لديك فترة محددة.  يمكنك اختيار فصل دراسي من صفحة درس اليوم."
        }
    },

    permissions: {
        title: "الأذونات",
        description: "يمكنك إدارة أذونات الاتصال الخاصة بك من هذه المنطقة.",
        whatsapp: "WhatsApp",
        lesson_record_title: "لأجل الاستماع للمقاطع الصوتية الخاصة بدروسكم يرجى منكم تفعيل الأذونات المطلوبة",
        approve_lesson_record: "أوافق على التسجيل الصوتي"
    },

    error404: {
        title: "خطأ",
    },

    learn_together: {
        learn_together: "نتعلم معا",
        family_package: "باقة عائلية",
        invite_friends: "ادع اصدقاءك",
        family_list: "قائمة العائلة",
        members: "أعضاء",
        edit: "ضبط",
        manager: "تنظيم/ تنفيذ",
        member: "عضو",
        add_new_member: "إضافة عضو جديد",
        remove_approve_message: "هل أنت متأكد أنك تريد إزالة #NAME#؟",
        remove_success_message: "لقد نجحت في إزالة #NAME# من حزمة العائلة.",
        add_member_title: "اللغة الإنجليزية للعائلة: متعة التعلم معًا، في الحزمة العائلية!",
        invite_message: "يدعوك #NAME# إلى الحزمة العائلية.  انضم إلى حساب العائلة على Alingliziah.  استفد من تمارين الفيديو الخالية من الإعلانات وأدوات الذكاء الاصطناعي ومئات المحتوى.",
        invited_title: "دعاك #NAME# للانضمام إلى حزمة عائلة Alingliziah!",
        invited_content: "لنبدأ في تعلم اللغة الإنجليزية.",
        join_family: "انضم إلى العائلة",
        join_success_message: "لقد تمت إضافتك إلى الحزمة العائلية لـ #NAME#.",
        already_registered_family: "أنت مسجل بالفعل مع عائلة!",
        already_manager: "أنت بالفعل مدير العائلة!",
        family_quota_full: "لقد تم الوصول إلى حصة العائلة التي تريد الانضمام إليها.",
        remaining_count: "بقي #COUNT# من الأعضاء",
        add_member_content: "ابدأ في تعلم اللغة الإنجليزية مع أفراد عائلتك أو أصدقائك.",
        add_member_content_title_2: "يعد تعلم اللغة الإنجليزية مع باقة عائلية أكثر ملاءمة.",
        add_member_content_2: "توفر لك هذه الحزمة الطريقة الأكثر ملاءمة لتعلم اللغة الإنجليزية. يمكنك البدء في تعلم اللغة الإنجليزية بأسعار مخفضة عن طريق دعوة شخص أو أكثر.",
        add_member_content_title_3: "خطة الحزمة العائلية",
        add_member_content_3: "يتم تطبيق خصم 10% على كل فرد من أفراد الأسرة.",
        share_text: "يمكنك استخدام الخيارات أدناه لدعوة الأصدقاء أو أفراد العائلة.",
        add_member_with_phone: "إضافة عضو عن طريق الهاتف",
        add_member_info: "أدخل بيانات الشخص المراد إضافته.",
        add_member: "إضافة",
        add_member_success_message: "تم إرسال رسالة الدعوة للعائلة بنجاح.",
        add_member_error_message: "حدث خطأ أثناء إرسال رسالة دعوة للعائلة."
    },


    reference: {
        give_reference_title: "هدية باللغة الإنجليزية لصديقك، وإرجاع الأموال إليك",
        give_reference_description: "قم بدعوة أصدقائك للتعلم عن طريق Alingliziah ، وامنح أصدقائك خصمًا بنسبة #referenceDiscountRate#% واحصل على #price#$ مرة أخرى.* آخر موعد للمشاركة: #lastDate#",
        give_reference_description_2: "*يجب على أصدقائك شراء باقة التدريب قبل الوقت #lastDate#.",
        invite_friend_title: "قم بدعوة أصدقائك للتعلم من خلال Alingliziah",
        invite_friend_description: "ادعُ، اربح!  سيحصل 5 أصدقاء على امتياز الحصول على تدريب مجاني للتعلم في Alingliziah  لمدة أسبوع واحد.*",
        invite_friend_information: "* دروس هدية مدتها 3 أيام. <br/> * سيحصل أصدقاؤك على #referenceDiscountRate#% وستحصل على خصم #moneyboxDiscountRate#% لكل صديق يشتري الباقة.",
        copy_link_and_share: "يمكنك نسخ ومشاركة الرابط.",
        invite_activated: "#activeReferenceStudentCount#/#targetReferenceStudentCount# الدعوة مفعلة /نشطة",
        copy: "نسخ",
        copied: "تم النسخ",
        whatsapp_x_message_text: "لقد اكتشفت منصة رائعة لتعلم اللغة الإنجليزية، تدعى Alingiliziah !  لقد استفدت منه كثيرا، وأعتقد أنك سوف تكون مهتماً به أيضا.  وهذا الرابط :",
        mail_message_text: "أصدقائي، لدي أخبار عظيمة لكم!  لقد بدأت تعلم اللغة الإنجليزية باستخدام تطبيق Alingliziah وهو تطبيق ممتع للغاية.  أنا أمارس اللغه أستمتع بها.  هل ترغب في تجربتها أيضًا؟  إذا استخدمت رمز الإحالة الخاص بي، يمكنك الحصول على خصم #referenceDiscountRate#%.  هيا، دعونا نتحدث الإنجليزية معا! #Alingliziah#",
        reference_message: "يدعوك <b>#NAME#</b> للتعلم من خلال التحدث مع مزايا مخفضة.  انضم إلى التعلم عن طريق التحدث الآن.  استفد من تمارين الفيديو الخالية من الإعلانات وأدوات الذكاء الاصطناعي ومئات المحتوى المجاني.",
        invite_reference_with_phone: "الدعوة عبر الهاتف",
        invite_reference_add_info: "أدخل بيانات الشخص المراد دعوته.",
        invite_reference_success_message: "تم إرسال رسالة التوصية بنجاح.",
        invite_reference_error_message: "حدث خطأ أثناء إرسال رسالة التوصية."
    },

    video_practice: {
        title: "تعلم من الفيديوهات",
        complete: "مكتمل",
        home: {
            video_duration: "مدة",
            current_video_count: 'المجموع #currentCount#/#total# فيديو',
        },
        video: {
            video_speed: "سرعة التشغيل",
            turkish_subtitle: "الترجمة العربية"
        },
        detail_search: {
            title: "تصفية الفيديوهات",
            list_videos: "قائمة مقاطع الفيديو",
            selected_data_title: "المحدد  (#count#) #selectedTagTitle#"
        },
        word_card: {
            synonyms: "المرادفات",
            phonetics: "علم الصوتيات:",
            english_description: "الوصف باللغة الإنجليزية",
            sample_sentence: "جملة مثال",
            sample_sentence_translation: "مثال عن الجملة باللغة العربية",
            add_member_word_store_success_message: "تمت إضافة كلمة <span class='font-bold'>#word#</span> لقائمة تعلم المفرادات بنجاح",
            add_member_word_store_error_message: "الكلمة <span class='font-bold'>#word#</span> موجودة بالفعل في قائمة تعلم المفردات الخاصة بك.",
            add_learn_word_list: "أضف إلى قائمتي",
            share: "يشارك",
            word_pronunciation: "انطق",
            word_origin: "اصل الكلمة",
            word_origin_translation: "اللغة العربية أصل الكلمة",
            writing_exercise: "تمارين الكتابة",
            today_english_word: "كلمة اليوم باللغة الإنجليزية",
            reset: "إعادة ضبط",
            use_keyboard: "يمكنك استخدام لوحة المفاتيح لملء الفراغات أدناه.",
            share_text: "يمكنك استخدام الخيارات التالية للمشاركة.",
            give_microphone_permission: "لاستخدام هذه الميزة، يجب عليك السماح بالوصول إلى الميكروفون.  يمكنك الحصول على مساعدة من الفيديو الخاص بنا للسماح بالوصول إلى الميكروفون."
        },
        pronunciation: {
            score_breakdown: "ملخص النقاط",
            pronunciation_score: "نقاط النطق",
            accuracy_score: "درجة الدقة",
            completeness_score: "نقاط الإنجاز",
            fluency_score: "درجة الطلاقة",
            prosody_score: "درجة العروض",
            pronunciation_description: "الدرجة الإجمالية التي تشير إلى جودة نطق الكلام المحدد.  يتم الحصول على هذه النتيجة من المجموع المرجح لنقاط الدقة ونقاط الطلاقة ونقاط الاكتمال (إن أمكن) ونقاط العروض (إن أمكن).",
            accuracy_description: "دقة نطق الكلام.  تشير الدقة إلى مدى تطابق المقاطع الصوتية مع نطق المتحدث الأصلي.  يتم جمع درجات دقة الكلمات والنص الكامل من درجة دقة مستوى الصوت.",
            completeness_description: "يتم حساب اكتمال الكلام من خلال نسبة الكلمات المنطوقة إلى النص المرجعي المُدخل.",
            fluency_description: "طلاقة الخطاب المعطى.  تشير الطلاقة إلى مدى تطابق الكلام مع استخدام المتحدث الأصلي للفواصل الصامتة بين الكلمات.",
            prosody_description: "قياس الكلام.  يشير المقياس إلى طبيعة الكلام، مثل الضغط والتنغيم ومعدل التحدث والإيقاع.",
            click_microphone_for_try_again: "يمكنك النقر فوق علامة الميكروفون مرة أخرى للمحاولة مرة أخرى.",
            omission: "لم ينطق",
            mispronunciation: "النطق غير الصحيح",
            none: "لا خطأ"
        },
        word: {
            title: "تعلم كلمة",
            description: "يمكنك اكتشاف #count# الكلمات الإنجليزية الهامة التي اخترناها."
        },
        dialog: {
            title: "اقرأ المحادثة",
            description: "يمكنك اكتشاف حوارات #count# الكلمات الإنجليزية المهمة التي اخترناها."
        },
        grammar: {
            title: "تعلم قاعدة",
        }
    },

    social_button: {
        whatsapp: "المشاركة عبر الواتساب",
        mail: "المشاركة عبر البريد الإلكتروني ( الايميل)",
        copy_link: "نسخ الرابط",
        download: "تحميل"
    }
}