import { getBrowserName, getOSId } from '../../components/Utility';
import {fetchApi} from '../index';

export const InviteFriendsService = async (memberId) => {

    const url = '/_services/member/v1/member_reference/invite_friends/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const TargetReferenceStudentsCount = async (memberId) => {

    const url = '/_services/member/v1/member_reference/target_reference_students_count/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const InviteReferenceWithPhone = async (model) => {

    const url = '/_services/member/v1/member_reference_invite/invite_reference_with_phone/';

    const payload = {
        sourceType: 1, //web
        deviceName: getBrowserName(),
        deviceOs: getOSId(),
        ...model,
    };

    return await fetchApi(url, payload);
}