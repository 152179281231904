import useMemberLevelCollection from "./useMemberLevelCollection";
import {LevelCollection} from "../components/Constants";

/*
    * Demo butonlarının görünme durumunu belirleyen hook.
*/
const useDemoAvailability = (memberId) => {

    const memberLevelCollection = useMemberLevelCollection(memberId);
    const todayDateIndex = new Date().getDay();
    const saturdayDayIndex = 6;

    // Eğer bugün cumartesi ise, demo butonu görünmemeli
    if (todayDateIndex === saturdayDayIndex) return false;
    
    // Eğer kullanıcının seviyesi STARTER ise, demo butonu görünmemeli
    return memberLevelCollection !== LevelCollection.STARTER;
}

export default useDemoAvailability;