import React, {useEffect, useState} from 'react';
import { Button } from '../../../components';
import { useLocalization } from '../../../hooks/useLocalization';
import {EventLogs, OnboardingExercise, StepArray} from "../../../components/Constants";
import ExerciseQuestion from "../../Exercises/components/ExerciseQuestion";
import useSpeaker from "../../../hooks/useSpeaker";
import {Speaker} from "../../../assets/svg";
import useAnalytics from "../../../hooks/useAnalytics";
import {useSelector} from "react-redux";
import classNames from "classnames";
import {getApplicationDirection} from "../../../components/Utility";
import ExerciseStep from "../../Exercises/components/Common/ExerciseStep";

const OnboardingExerciseSentence = (props) => {

  const { data, setStep, day } = props;

  const strings = useLocalization();
  const analytics = useAnalytics();
  const language = useSelector(state => state.localizationStore.language);
  const lang = "al";

  const { speakSentence, SpeakerComponent } = useSpeaker();

  let storageStep = JSON.parse(localStorage.getItem("step")) || false;
  const [sentenceStep, setSentenceStep] = useState(storageStep.sentences || 1);

  const quizLogs = {
      leaveEmpty: {
          logDescription: EventLogs.ONBOARDING_EXERCISE_SENTENCE_PATTERN_QUIZ_LEAVE_EMPTY,
          parameters: [{ name: 'day_count', data: day }]
      },
      continue: {
          logDescription: EventLogs.ONBOARDING_EXERCISE_SENTENCE_PATTERN_QUIZ_CONTINUE,
          parameters: [{ name: 'day_count', data: day }]
      },
      check: {
          logDescription: EventLogs.ONBOARDING_EXERCISE_SENTENCE_PATTERN_QUIZ_CHECK,
          parameters: [{ name: 'day_count', data: day }]
      },
  }

  /*
   * if: Hiç soru gelmemişse direkt "Grammar componentine geçiliyor"
   * else if: Soru geldiyse sorular gösteriliyor.
  */
  const handleNextButtonStepOne = () => {
      if(data?.questions.length === 0) {
          setStep(OnboardingExercise.GRAMMAR)
      } else {
        setSentenceStep(sentenceStep => 2)
      }

      //Devam et butonunda log atılıyor.
      analytics.useAnalytics(EventLogs.ONBOARDING_EXERCISE_SENTENCE_PATTERN_CONTINUE, { day_count: day });
  }

  const handlePreviousButtonStepOne = () => {
      /*
        * Geri butonundan kelime componentine dönülünce localStorage'a veri atılarak kelime componentine
        başlaması gereken yer ve geri butonu ile gelindiği haber ediliyor.
      */
      let stepObject = {
          words: 2,
          isClickedBackButton: true
      };
      localStorage.setItem("step", JSON.stringify(stepObject));

      //Geri butonunda log atılıyor.
      analytics.useAnalytics(EventLogs.ONBOARDING_EXERCISE_SENTENCE_PATTERN_BACK, { day_count: day });
      setStep(OnboardingExercise.WORDS);
  }

  //İkinci adım için ileri buton fonksiyonu
  const handleNextButtonStepTwo = () => {
      setSentenceStep(sentenceStep => 3);
      //Devam et butonunda log atılıyor.
      analytics.useAnalytics(EventLogs.ONBOARDING_EXERCISE_SENTENCE_PATTERN_CONTINUE, { day_count: day });
  }

  //İkinci adım için geri buton fonksiyonu
  const handlePreviousButtonStepTwo = () => {
      setSentenceStep(sentenceStep => 1);
      //Geri butonunda log atılıyor.
      analytics.useAnalytics(EventLogs.ONBOARDING_EXERCISE_SENTENCE_PATTERN_BACK, { day_count: day });
  }

  //localStorage'da tutulan bir step değerini okuduktan sonra ilgili veri localStorage'dan uçuruluyor.
  useEffect(() => {
      storageStep && localStorage.removeItem("step");
      //Component ilk render olduğunda log atılıyor.
      analytics.useAnalytics(EventLogs.ONBOARDING_EXERCISE_SENTENCE_PATTERN_OPENED, { day_count: day });
  }, []);

  return (
    <div className="main-content">
      <div className="left-page">
          <SpeakerComponent />

          <ExerciseStep step={3} data={StepArray} />

          {(sentenceStep === 1 || sentenceStep === 2) && (
            <div className="mt-10 gray-section px-[10px] xs:px-[10px] text-center">

              <h5>{strings.onboarding_exercises.sentence_pattern}</h5>

              {sentenceStep === 1 && (
                  <>
                      <div dir="ltr">
                          <p className='mt-3 px-[10px] xs:px-[10px] font-bold text-center'>{data?.title}</p>
                          <p className='mt-3 px-[10px] xs:px-[10px] text-center'>{data?.translation[lang]}</p>
                      </div>
                      <div className="flex gap-8 my-5 justify-center">
                          <Button
                              type="button"
                              classnames="button primary-button-outline max-w-[150px]"
                              action={handlePreviousButtonStepOne}
                              text={strings.pagination.previous}
                          />
                          <Button
                              type="button"
                              classnames="button primary-button max-w-[150px]"
                              action={handleNextButtonStepOne}
                              text={strings.speaking_lesson.quiz.continue}
                          />
                      </div>
                  </>
              )}

                {sentenceStep === 2 && (
                    <>
                    {data?.sentences.map((item, index) => (
                        <div
                            key={index}
                            className="flex flex-col my-5 px-[10px] xs:px-[10px]"
                        >
                          <div className="flex">
                            <p dir="ltr" className="font-bold text-start">{item?.sentence}</p>
                            <img
                                className={classNames("inline-flex cursor-pointer", {
                                    "mr-1.5": getApplicationDirection(language),
                                    "ml-1.5": !getApplicationDirection(language),
                                })}
                                src={Speaker}
                                onClick={() => speakSentence(item?.sentence)}
                                width="16"
                                height="16"
                                alt=""
                            />
                          </div>
                          <p className="text-start">{item?.translation[lang]}</p>
                        </div>
                    ))}

                    <div className="flex gap-8 my-5 justify-center">
                      <Button
                          type="button"
                          classnames="button primary-button-outline max-w-[150px]"
                          text={strings.pagination.previous}
                          action={handlePreviousButtonStepTwo}
                      />
                      <Button
                          type="button"
                          classnames="button primary-button max-w-[150px]"
                          text={strings.speaking_lesson.quiz.continue}
                          action={handleNextButtonStepTwo}
                      />
                    </div>
                  </>
              )}

            </div>
          )}

          {sentenceStep === 3 && (
              <ExerciseQuestion
                  questions={data?.questions}
                  setInnerStep={() => setSentenceStep(2)}
                  setComponentStep={() => setStep(OnboardingExercise.GRAMMAR)}
                  logs={quizLogs}
              />
          )}
      </div>
    </div>
  )
}

export default OnboardingExerciseSentence