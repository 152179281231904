import { fetchApi } from '../index';
import {Brands} from "../../components/Constants";

export const GetMemberUsername = async (memberId) => {
    const url = '/_services/member/v1/member_profile/get_member_username/';

    const payload = {
        memberId: memberId,
    };

    return await fetchApi(url, payload);
}

export const GetMemberStreak = async (memberId) => {
    const url = '/_services/member/v1/member_streak/get_member_streak/';

    const payload = {
        memberId: memberId,
    };

    return await fetchApi(url, payload);
}

export const GetMemberStreakRewards = async (memberId, languageTitleType) => {
    const url = '/_services/member/v1/streak_reward/get_all_streak_rewards_by_member/';

    const payload = {
        memberId: memberId,
        languageTitleType: languageTitleType
    };

    return await fetchApi(url, payload);
}

export const GetMemberProfile = async (memberId, username) => {
    const url = '/_services/member/v1/member_profile/get_member_profile/';

    const payload = {
        memberId: memberId,
        username: username
    };

    return await fetchApi(url, payload);
}

export const GetMemberLeaderBoard = async (username, memberId, languageTitleType) => {
    const url = '/_services/member/v1/member_streak_league/get_leaderboard_by_member/';

    const payload = {
        username: username,
        memberId: memberId,
        brandId: Brands.AL,
        languageTitleType: languageTitleType
    };

    return await fetchApi(url, payload);
}

export const GetMemberStreakCalendar = async (memberId) => {
    const url = '/_services/member/v1/member_streak/get_member_streak_calendar/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetPossibleWinRewards = async (memberId, languageTitleType) => {
    const url = '/_services/member/v1/streak_reward/get_possible_win_rewards_by_member/';

    const payload = {
        memberId: memberId,
        languageTitleType: languageTitleType
    };

    return await fetchApi(url, payload);
}

export const GetMemberStatistics = async (memberId) => {
    const url = '/_services/member/v1/member_profile/get_member_statistics/';

    const payload = {
        memberId: memberId
    }

    return await fetchApi(url, payload);
}

export const GetFollowRecommendations = async (memberId) => {
    const url = '/_services/member/v1/member_follow/get_follow_recommendations/';

    const payload = {
        memberId: memberId
    }

    return await fetchApi(url, payload);
}

export const UpdateMemberProfileStatus = async (memberId, profileStatus) => {
    const url = '/_services/member/v1/member_profile/update_member_profile_status/';

    const payload = {
        memberId: memberId,
        profileStatus: profileStatus
    };

    return await fetchApi(url, payload);
}


export const GetFollowersAndFollowings = async (memberId, username) => {
    const url = '/_services/member/v1/member_follow/get_followers_and_followings/';

    const payload = {
        memberId: memberId,
        username: username
    };

    return await fetchApi(url, payload);
}

export const FollowMember = async (followerMemberId, followingMemberId) => {
    const url = '/_services/member/v1/member_follow/follow/';

    const payload = {
        followerMemberId: followerMemberId,
        followingMemberId: followingMemberId
    }

    return await fetchApi(url, payload);
}


export const UnfollowMember = async (followerMemberId, followingMemberId) => {
    const url = '/_services/member/v1/member_follow/unfollow/';

    const payload = {
        followerMemberId: followerMemberId,
        followingMemberId: followingMemberId
    };

    return await fetchApi(url, payload);
}

export const AddMemberAbuseReport = async (reporterMemberId, reportedMemberId) => {
    const url = '/_services/member/v1/member_abuse_report/add_member_abuse_report/';

    const payload = {
        reporterMemberId: reporterMemberId,
        reportedMemberId: reportedMemberId
    }

    return await fetchApi(url, payload);
}