import React from "react";
import {useLocalization} from "../hooks/useLocalization";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {getApplicationDirection} from "./Utility";

const CallBarNotAvaliable = () => {

    const strings = useLocalization();
    const location = useLocation();
    const language = useSelector(state => state.localizationStore.language);

    const isOnboarding = location.pathname.includes("/onboarding/") || location.pathname.includes("/success");
    const isAuth = location.pathname.includes("/auth/");

    if (isOnboarding || isAuth)
        return null;

    return (
        <div
            className="bg-red transition-color duration-500 flex items-center justify-between fixed top-0 left-0 right-0 h-[50px] w-full z-20 px-2 sm:px-7 gap-2"
            dir={`${getApplicationDirection(language) ? 'rtl' : 'ltr'}`}
        >
            <div className='flex gap-2 my-auto items-center'>
                    <span className="material-symbols-outlined text-white">
                        warning
                    </span>
                <h1 className="text-white text-[11px] sm:text-[16px] font-bold">
                    {strings.menu.callbar.not_avaliable_text}
                </h1>
            </div>
            <a
                className="button bg-white text-red h-8 !leading-8 text-[11px] sm:text-[16px] hover:bg-[#f5f5f5] hover:text-[#333] max-w-max px-5 flex-shrink-0"
                href={process.env.REACT_APP_DOWNLOAD_APP_URL}
                target="_blank"
            >
                {strings.menu.callbar.download_application}
            </a>
        </div>
    )

}

export default CallBarNotAvaliable;