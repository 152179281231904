import React, {useEffect, useState} from "react";
import { useLocalization } from "../../../../hooks/useLocalization";
import {getDefaultPhotoByGender, getLanguageIdByLanguageKey} from "../../../../components/Utility"
import { useNavigate } from "react-router-dom";
import { url } from "../../../../routes/utility";
import {GetMemberLeaderBoard} from "../../../../services/Profile/ProfileService";
import {useSelector} from "react-redux";
import {Loading} from "../../../../components";
import classNames from "classnames";
import {MemberProfileStatus} from "../../../../components/Constants";

const Leaderboard = ({ memberUsername, memberId }) => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const language = useSelector(state => state.localizationStore.language);

    const [loading, setLoading] = useState(true);
    const [leaderBoardData, setLeaderBoardData] = useState({});
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if(memberUsername)
            GetMemberLeaderBoard(memberUsername, memberId, getLanguageIdByLanguageKey(language))
                .then(result => {
                    if (result.status === 200)
                        setLeaderBoardData(JSON.parse(result.content));
                    else
                        setIsError(true);

                    setLoading(false);
                })
                .catch()
    }, [memberUsername, language]);

    return (
        <div className="mt-4">
            {!isError &&
                <div className="flex justify-between">
                    <div className="flex items-center justify-center gap-1">
                        <p className="text-[16px] font-bold">{strings.profile.leaderboard.ranking}</p>
                        {(Object.keys(leaderBoardData).length > 0 && !loading) &&
                            <div className="flex items-center justify-center rounded-[10px] bg-secondary text-white w-full p-2 h-[15px] text-[11px]">
                                {leaderBoardData?.league.title}
                            </div>
                        }
                    </div>
                    <p className="text-[16px] font-bold text-primary">{strings.profile.leaderboard.weekly_streak}</p>
                </div>
            }

            {loading && <Loading />}

            {(Object.keys(leaderBoardData).length > 0 && !loading) &&
                <>
                    <hr className="h-[3px] bg-primary mt-0 mb-1"/>
                    {leaderBoardData.leaderboard.length > 0 &&
                        leaderBoardData.leaderboard.map((item, index) => (
                            <div key={index}>
                                <div className={classNames("flex justify-between items-center p-2", {
                                        "bg-box rounded-[10px] shadow": item.isSelfProfile
                                })}>
                                    <div className="flex gap-3">
                                        <img
                                            src={getDefaultPhotoByGender(item.gender, item.memberId) || ""}
                                            width="35"
                                            height="35"
                                            alt="profile-photo"
                                        />
                                        <div className="flex flex-col items-start justify-center">
                                            <p className="text-[14px] font-bold">
                                                {item.rank}. {" "}
                                                <button
                                                    type="button"
                                                    className={classNames("", {
                                                        "cursor-pointer text-primary hover:text-secondary": !item.isSelfProfile && item.profileStatus === MemberProfileStatus.PUBLIC
                                                    })}
                                                    disabled={item.isSelfProfile || item.profileStatus === MemberProfileStatus.PRIVATE}
                                                    onClick={() => navigate(url("profile.profilesub", {username: item.username}))}
                                                >
                                                    {item.memberName}
                                                </button>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-1">
                                        <p className="text-[14px] font-bold text-end text-primary">
                                            {item.streak}{" "}
                                            ({strings.profile.leaderboard.total_lesson_duration
                                            .replace("#duration#", item.totalLessonDuration)}
                                            )
                                        </p>
                                    </div>
                                </div>
                                {leaderBoardData.leaderboard.length - 1 !== index && <hr className="my-2"/>}
                            </div>
                        ))}
                    <hr className="my-2"/>
                </>
            }
        </div>
    )
}

export default Leaderboard