import React, {useEffect, useRef, useState} from "react";
import {useLocalization} from "../../hooks/useLocalization";
import {ModalOneButton, ModalOverlay, Radio} from "../../components";
import useAuth from "../../hooks/useAuth";
import useMemberLevelCollection from "../../hooks/useMemberLevelCollection";
import {useLocation, useNavigate} from "react-router-dom";
import {url} from "../../routes/utility";
import {UpdateMemberDemoUnit} from "../../services/MemberDemoRight/MemberDemoRightServices";
import {closeModal, openModal} from "../../components/Utility";
import {DemoUnitType} from "../../components/Constants";

const DemoUnitSelection = () => {

    const strings = useLocalization();
    const { member } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const modalOverlayRef = useRef();
    const modalRef = useRef();

    const lesson = location?.state?.lesson;
    const memberLevelCollectionId = useMemberLevelCollection(member?.MemberId);

    // Ünite seçenekleri
    const unitOptions = [
        {
            id: DemoUnitType.LEVEL_TEST,
            name: strings.demo.unit_selection.level_test
        },
        {
            id: DemoUnitType.FREE_TALK,
            name: strings.demo.unit_selection.free_talk
        },
        {
            id: DemoUnitType.TRIAL,
            name: strings.demo.unit_selection.trial
        },
    ];

    // Seçili seviyenin tutulduğu state
    const [unit, setUnit] = useState(DemoUnitType.LEVEL_TEST);

    /*
        * Kullanıcı LEVEL_TEST seçer ise zaten kitabı level test olduğu için success ekranına gönderiyoruz.
        * Kullanıcı FREE_TALK seçer ise ünitesini güncelleyip success ekranına gönderiyoruz.
        * Kullanıcı TRIAL seçer ise;
            * Bir seviyesi yoksa levelselection ekranına yönlendiriyoruz.
            * Seviyesi var ise kullanıcının ünitesini güncelliyoruz.
    */
    const handleContinue = () => {

        if(unit === null)
            openModal(modalOverlayRef, modalRef);
        else
            switch (unit) {
                case DemoUnitType.LEVEL_TEST:
                    navigate(url("demo.success"), { state: { "lesson": lesson }});
                    break;
                case DemoUnitType.FREE_TALK:
                    updateDemoUnit(DemoUnitType.FREE_TALK);
                    navigate(url("demo.success"), { state: { "lesson": lesson }});
                    break;
                case DemoUnitType.TRIAL:
                    handleSelectTrial();
                    break;
                default:
                    navigate(url("demo.success"), { state: { "lesson": lesson }});
            }
    }

    const updateDemoUnit = (demoUnitType, useCaseId = -1, levelId = -1) => {

        const model = {
            memberDemoUnitType: demoUnitType,
            termId: lesson?.termId,
            memberId: member?.MemberId,
            useCaseId: useCaseId,
            levelId: levelId,
            lessonDate: lesson?.lessonDate
        };

        UpdateMemberDemoUnit(model)
            .then()
            .catch()
    }

    const handleSelectTrial = () => {
        if(memberLevelCollectionId === -1) {
            navigate(url("demo.levelselection"), { state: { "lesson": lesson }});
        } else {
            updateDemoUnit(DemoUnitType.TRIAL, +member?.ReasonToLearnEnglishId, memberLevelCollectionId);
            navigate(url("demo.success"), { state: { "lesson": lesson }});
        }
    }

    useEffect(() => {
        //eğer kullanıcı path'den gelmeye çalışırsa sayfa hata vermemesi için bir önceki sayfaya yönlendiriliyor.
        if (lesson === undefined || lesson.lessonDate === undefined) {
            navigate(-1);
        }
    }, []);

    return (
        <>
            <div className="main-content">
                <div className="transparent-section mt-5">
                    <h5 className="text-secondary">{strings.demo.unit_selection.title}</h5>
                    <div className="flex flex-col mt-5 mb-3 gap-2">
                        {unitOptions.map((item, index) => (
                            <Radio
                                key={item.id}
                                id="level-test"
                                title={item.id === DemoUnitType.TRIAL && member?.ReasonToLearnEnglishId
                                    ? `${item.name} (${strings.profile.reason_to_learn_english[+member?.ReasonToLearnEnglishId]})`
                                    : item.name
                                }
                                state={unit === item.id}
                                action={() => setUnit(item.id)}
                            />
                        ))}
                    </div>
                </div>
                <button
                    className="button primary-button max-w-[330px] mx-auto w-full mt-5"
                    onClick={handleContinue}
                >
                    {strings.speaking_lesson.quiz.continue}
                </button>
            </div>

            <ModalOverlay ref={modalOverlayRef} />
            <ModalOneButton
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.demo.unit_selection.select_unit}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => {
                    closeModal(modalOverlayRef, modalRef);
                }}
            />
        </>
    )
}

export default DemoUnitSelection;