import React, { useEffect, useState } from 'react';
import useAnalytics from '../../../../hooks/useAnalytics';
import { useLocalization } from '../../../../hooks/useLocalization';
import PopularSentences from "./components/Common/PopularSentences";
import { LessonInfoPending as LessonInfo, LessonContentPending as LessonContent, SpeakingLabContent } from './components';
import { TermLessonAccordionType } from '../../../../components/Constants';

const Pending = ({ data, selectedDate }) => {

    const strings = useLocalization();
    const analytics = useAnalytics();

    const isViewLessonBookClicked = localStorage.getItem("isLessonBookClicked") ?? false;

    const [convertedData, setConvertedData] = useState();

    //we have 7 content type.
    useEffect(() => {
        let newData = {
            1: [], //StudySubjects-question
            2: [], //StudySubjects-sentence
            3: [], //DailyTextbook-book
            4: [], //DailyTextbook-presentation
            5: [], //DailyTextbook-practice
            6: [], //video
            7: [], //audio
        };

        data.book.contents.map(value => {
            for (let i = 1; i < 8; i++) {
                value.contentType == i && newData[i].push(value.content);
            }
        });

        setConvertedData(newData);
    }, [data, selectedDate]);

    useEffect(() => {
        analytics.useAnalytics('screen_view', { screen_name: 'Gunun_Dersi_Islenmemis' });
        window.customDataLayer.push({ event: 'screen_view', screen_name: 'Gunun_Dersi_Islenmemis' });
    }, []);

    return (
        <>
            {/* Ders Bilgisi */}
            <LessonInfo 
                data={data} 
                selectedDate={selectedDate}
                termLessonAccordionActivities={
                    data?.termLessonAccordionActivities?.filter(l => TermLessonAccordionType.LESSON_INFO === l.termLessonAccordionType) || []
                }
            />

            {/* Ders Materyalleri */}
            <LessonContent             
                data={data} 
                convertedData={convertedData}
                selectedDate={selectedDate}
                isViewLessonBookClicked={isViewLessonBookClicked}
                termLessonAccordionActivities={
                    data?.termLessonAccordionActivities?.filter(l => TermLessonAccordionType.LESSON_MATERIALS === l.termLessonAccordionType) || []
                }
            />

            {/* Speaking Lab */}
            {data?.book.isSpeakingLabAvailable && 
                <SpeakingLabContent  
                    bookUnitId={data?.book?.bookUnitId}
                    selectedDate={selectedDate}
                    termLessonAccordionActivities={
                        data?.termLessonAccordionActivities?.filter(l => TermLessonAccordionType.SPEAKING_LAB === l.termLessonAccordionType) || []
                    }
                />
            }

            {data?.book?.bookUnitId !== 0 &&
                <PopularSentences
                    title={strings.speaking_lesson.daily_lesson.sentence_bank.title}
                    bookUnitId={data?.book?.bookUnitId}
                    selectedDate={selectedDate}
                />
            }
        </>
    )
}

export default Pending