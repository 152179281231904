import React from 'react'
import { Video } from '../../../../../../components'
import useAnalytics from '../../../../../../hooks/useAnalytics'

const LessonVideo = ({ title, src, events, analyticsPass = false }) => {

    const analytics = useAnalytics();
    const videoSource = "https://www.youtube.com/embed/" + src;

    return (
        <div className="transparent-section mt-5">
            <h5 className="text-primary">{title}</h5>
            <Video src={videoSource} onclick={() => !analyticsPass && analytics.useAnalytics(events)} />
        </div>
    )
}

export default LessonVideo