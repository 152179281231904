import React from 'react'
import { useLocalization } from '../../../../hooks/useLocalization'
import { useSelector } from 'react-redux';
import { getApplicationDirection } from '../../../../components/Utility';

const Accordion = ({ data, title, tabindex, onclick }) => {

    const strings = useLocalization();
    const language = useSelector(state => state.localizationStore.language);

    const Content = () => {
        return data.length > 0 ? (
            data.map((item, index) => (
                <p key={index} className="mb-3">{item} {strings.speaking_lesson.attendance_report.date_content}</p>
            ))) : (
            <p className="mb-3">{strings.speaking_lesson.attendance_report.not_use}</p>
        )
    }

    return (
        <div className="group outline-none text-sm mt-5" tabIndex={tabindex} onClick={onclick}>
            <div className={`group accordion-div ${getApplicationDirection(language) ? 'pr-7' : 'pl-7'}`}>
                <div className={`accordion-header ${getApplicationDirection(language) ? 'right-0 mr-2 ml-auto rotate-180' : 'left-0 ml-2 mr-auto rotate-0'}`}>
                    <span className="material-symbols-outlined text-base-text"> arrow_forward_ios </span>
                </div>
                <div className={`accordion-text ${getApplicationDirection(language) ? 'mr-2' : 'ml-2'}`}>
                    {title}
                </div>

            </div>
            <div className="accordion-content">
                <Content />
            </div>
        </div>
    )
}

export default Accordion