import React, { useState, useEffect } from "react";
import { Bookmark, SelectedBookmark } from "../assets/svg";
import { UpsertMemberSentenceStore } from "../services/SentenceBank/SentenceBankService";
import classNames from "classnames";
import { SentenceStoreType } from "./Constants";
import useAuth from "../hooks/useAuth";
import useAnalytics from "../hooks/useAnalytics";
import { useLocalization } from "../hooks/useLocalization";

// Cümle yer işareti butonunu temsil eden bileşen
const SentenceBookmarkButton = ({ initialIsSaved, sentenceId, logEvent = {} }) => {

    const strings = useLocalization();
    const { member } = useAuth();
    const analytics = useAnalytics();

    // Cümlenin kaydedilip kaydedilmediğini takip eden state
    const [isSavedSentence, setIsSavedSentence] = useState(initialIsSaved);
    // Tooltip'in gösterilip gösterilmeyeceğini kontrol eden state
    const [showTooltip, setShowTooltip] = useState(false);

    // Cümleyi kaydetme/kaldırma işlemini gerçekleştiren fonksiyon
    const handleSaveSentence = () => {
        setShowTooltip(false);

        const model = {
            memberId: member?.MemberId,
            sentenceId: sentenceId,
            sentenceStoreType: SentenceStoreType.BOOK
        };

        // API çağrısı yaparak cümleyi kaydet/kaldır
        UpsertMemberSentenceStore(model)
            .then(result => {
                if (result.status === 200) {
                    const resultContent = JSON.parse(result.content);
                    setIsSavedSentence(resultContent);

                    // Analitik olayını tetikle (eğer varsa)
                    if(Object.keys(logEvent).length > 0)
                        resultContent 
                            ? analytics.useAnalytics(logEvent.true, undefined, false, true)
                            : analytics.useAnalytics(logEvent.false, undefined, false, true);

                    setShowTooltip(true);
                }
            })
            .catch();
    };

    // initialIsSaved prop'u değiştiğinde state'i güncelle
    useEffect(() => {
        setIsSavedSentence(initialIsSaved);
    }, [initialIsSaved]);

    // Tooltip'i belirli bir süre sonra gizle
    useEffect(() => {
        let timer;
        if (showTooltip) {
            timer = setTimeout(() => {
                setShowTooltip(false);
            }, 1500);
        }

        return () => clearTimeout(timer);
    }, [showTooltip]);

    return (
        <button type="button" onClick={handleSaveSentence}>
            {isSavedSentence
                ? <img src={SelectedBookmark} width={20} height={20} alt="bookmark" />
                : <img src={Bookmark} width={20} height={20} alt="selected-bookmark" />
            }

            {/* Tooltip */}
            <div className={classNames("has-tooltip z-0 relative", { "group-active": showTooltip })}>
                <div className="tooltip-top -top-[58px]">
                    {isSavedSentence
                        ? strings.speaking_lesson.daily_lesson.sentence_bank.added_list
                        : strings.speaking_lesson.daily_lesson.sentence_bank.removed_list
                    }
                </div>
            </div>
        </button>
    );
};

export default SentenceBookmarkButton;