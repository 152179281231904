import {useLocalization} from "../../hooks/useLocalization";
import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {AddMemberWithPhone, CountdownTimer, Social} from "./components";
import useAuth from "../../hooks/useAuth";
import {InviteFriendsService} from "../../services/Reference/ReferenceService";
import {Loading} from "../../components";
import {addTimeZone, formatDateWithTime, getApplicationDirection, getTimeZone} from "../../components/Utility";
import {useSelector} from "react-redux";
import { ReferenceCard } from "../../assets/svg";
import useAnalytics from "../../hooks/useAnalytics";
import {EventLogs} from "../../components/Constants";

const Reference = () => {

    const strings = useLocalization();

    const { member } = useAuth();
    const language = useSelector(state => state.localizationStore.language);
    const timeZone = getTimeZone();
    const analytics = useAnalytics();

    const AL_MAIN_PAGE = process.env.REACT_APP_SITE_BASE_URL + '/auth/register?utm_source=Reference&utm_medium=referans&ref=';

    const [showCounter, setShowCounter] = useState(false);
    const [referenceData, setReferenceData] = useState({});
    const [loading, setLoading] = useState(true);

    const getHourAndMinutes = (dt) => {
        const date = new Date(dt)
        const hour = date.getHours();
        const minute = date.getMinutes();
        
        return `${hour}:${minute}`;
    }

    useEffect(() => {
        if(member){
            InviteFriendsService(member?.MemberId)
                .then(result => {
                    if(result.status === 200){
                        let resultContent = JSON.parse(result.content);

                        setShowCounter(showCounter => resultContent.refundDueDate);
                        setReferenceData(referenceData => resultContent);
                    }

                    setLoading(loading => false);
                })
                .catch(e => {})
        }

        analytics.useAnalytics(EventLogs.REFERENCE_PAGE_VIEW);
    }, [])

    return (
        <div className={`main-content ${getApplicationDirection(language) ? 'md:ml-7' : 'md:mr-7'}`}>

            {loading && <Loading />}

            {!loading && (
                <>
                    {!showCounter && (
                        <img src={ReferenceCard} className='w-full max-w-[500px] mt-5 mx-auto' />
                    )}
                    {showCounter && (
                        <div className="mt-5 gray-section">
                            <h5 className="text-primary mb-2">{strings.reference.give_reference_title}</h5>
                            <div className="mt-4 pb-2 max-w-lg mx-auto">
                                <CountdownTimer endDate={referenceData.refundDueDate} />
                                <p className="mt-3">{strings.reference.give_reference_description
                                    .replace("#referenceDiscountRate#", referenceData.referenceDiscountRate)
                                    .replace("#price#", referenceData.refundPrice)
                                    .replace("#lastDate#", (formatDateWithTime(referenceData.refundDueDate, language, false, false)) + addTimeZone(getHourAndMinutes(referenceData.refundDueDate), timeZone))}
                                </p>
                                <p className="mt-2 text-[12px]">{strings.reference.give_reference_description_2
                                    .replace("#lastDate#", (formatDateWithTime(referenceData.refundDueDate, language, true, false)) + addTimeZone(getHourAndMinutes(referenceData.refundDueDate), timeZone))}</p>
                            </div>
                        </div>
                    )}
                    <div className="mt-5 transparent-section">
                        {!showCounter && <h5 className="text-secondary">{strings.reference.invite_friend_title}</h5>}
                        <div className="mt-4 pb-2 max-w-max mx-auto">
                            {!showCounter && (
                                <p>
                                    {strings.reference.invite_friend_description}
                                </p>
                            )}
                            <div
                                className={classNames("flex flex-col gap-2 mt-4", {
                                    "gray-section" : !showCounter
                                })}
                            >
                                <Social
                                    showCounter={showCounter}
                                    referencePath={AL_MAIN_PAGE + referenceData.referenceCode}
                                    referenceDiscountRate={referenceData.referenceDiscountRate}
                                />
                            </div>
                        </div>
                    </div>

                    {!showCounter && (
                        <p className="text-[12px] mt-3" dangerouslySetInnerHTML={{ __html: strings.reference.invite_friend_information.replace("#referenceDiscountRate#", referenceData.referenceDiscountRate).replace("#moneyboxDiscountRate#", referenceData.moneyboxDiscountRate) }} />
                    )}

                    <AddMemberWithPhone 
                        memberId={member?.MemberId} 
                        source="reference" 
                    />
                </>
            )}

            <div className="left-margin-bottom"></div>
        </div>
    )
}

export default Reference;