import React, {useEffect} from "react"
import { StreakDetail, StreakCalendar, Awards } from "./components"
import { useParams, useLocation } from "react-router-dom";
import useAnalytics from "../../hooks/useAnalytics";
import {EventLogs} from "../../components/Constants";

const Streak = () => {

    const location = useLocation();
    const { modalStatus, source } = location.state || {};
    const { memberId } = useParams();
    const analytics = useAnalytics();

    useEffect(() => {
        analytics
            .useAnalytics(EventLogs.STREAK_SCREEN_VIEWED, undefined, false, true);
    }, [])

    return (
        <div className="main-content">
            <div className="left-page">
                <StreakDetail
                    memberId={memberId}
                    modalStatus={modalStatus}
                    source={source}
                />
                {!modalStatus && (
                    <>
                        <StreakCalendar memberId={memberId} />
                        <Awards memberId={memberId} />
                    </>
                )}

                <div className="left-margin-bottom"></div>
            </div>
        </div>
    )
}

export default Streak