import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import useAuth from "./useAuth";
import {UpdateMemberTimeZone} from "../services/Dashboard/DashboardService";
import {decodeToken, getTimeZone} from "../components/Utility";
import {useDispatch, useSelector} from "react-redux";
import {
    ComponentRenderGroups,
    ComponentRenderKeys,
    EventLogs,
    TermStatusTypes,
    termStorageName
} from "../components/Constants";
import {AssignTermTeacher, AssignTermTeacherToSandy} from "../services/Teacher/TeacherService";
import useAnalytics from "./useAnalytics";
import {updateRenderStatus} from "../store/ComponentRenderSlice";

const useMember = () => {

    const location = useLocation();
    const isLoggedIn = useSelector(state => state.authStore.isLoggedIn);
    const analytics = useAnalytics();
    const dispatch = useDispatch();
    const attendedRenderStatus = useSelector(state => state.componentRenderStore.DailyLesson.Attended);
    const { member, token } = useAuth();
    const timeZone = getTimeZone();

    //Kullanıcının term'leri getiriliyor
    const storageTerms = token && (localStorage.getItem(termStorageName) || false);
    const terms = storageTerms ? decodeToken(storageTerms) : false;

    const resetTeacherFilter = () => {
        //Kullanıcı teacher dışında bir path'e gittiyse localStorage'dan teacher filtresi kaldırılıyor.
        if(!location.pathname.includes('/teacher') && localStorage.getItem("teacherFilter")) {
            localStorage.removeItem("teacherFilter");
        }

        //Kullanıcı eğitmen listeleme sayfasına ilk girdiğinde hepsini listeleme kontrolü yapılıyor.
        if(!location.pathname.includes('/teacher') && localStorage.getItem("getAll")) {
            localStorage.removeItem("getAll");
        }
    }

    const resetMarketSession = () => {
        const isLocationMarket = location.pathname.includes('/market') || location.pathname.includes('mobile_market');

        if(!isLocationMarket && sessionStorage.getItem("p"))
            sessionStorage.removeItem("p")

        if(!isLocationMarket && sessionStorage.getItem("m"))
            sessionStorage.removeItem("m")
    }

    const resetVideoFilter = () => {
        if(!location.pathname.includes("/videopractice")) {
            localStorage.removeItem("videoFilter");
            localStorage.removeItem("videoInfo");
        }
    }

    const resetDailyLesson = () => {
        if(!location.pathname.includes("/speakinglesson/daily") && attendedRenderStatus) {
            dispatch(updateRenderStatus({
                group: ComponentRenderGroups.DAILY_LESSON,
                key: ComponentRenderKeys.ATTENDED,
                value: false
            }));
        }
    }

    const resetSelectedDate = () => {
        if(!location.pathname.includes("/speakinglesson")) {
            localStorage.removeItem("selectedDate");
        }
    }

    const assignTermToTeacherSandy = () => {
        if(terms && isLoggedIn) {
            /*
                * Bu kodda öğrencinin dönemlerinden bir tanesi bile Sandy'de ise,
                * bu değer true'ya çekilip endpointe gidilmesi için bilgi veriliyor.
            */
            let isTeacherSandy = false;

            //Teacher Josiah kontrolü yapılıyor.
            let jsonTerms = JSON.parse(terms);
            jsonTerms.map((term) => {
                if(
                    term.TeacherId &&
                    term.TeacherId === +process.env.REACT_APP_TEACHER_SANDY_ID &&
                    term.Status === TermStatusTypes.Active
                ) {
                    isTeacherSandy = true;
                }
            })

            if(!isTeacherSandy) return;

            AssignTermTeacherToSandy(member?.MemberId)
                .then(result => {
                    if(result.status === 200) {
                        let resultContent = JSON.parse(result.content);
                        let modalInfo = {
                            open: resultContent.changedTermList?.length > 0,
                            info: resultContent.changedTermList
                        }

                        //Pencere açılması için localStorage'a bilgi kaydediliyor.
                        localStorage.setItem("openAssignTeacherSandyModal", JSON.stringify(modalInfo));
                    }
                })
                .catch()
        }
    }

    useEffect(() => {
        resetTeacherFilter();

        resetMarketSession();

        resetVideoFilter();

        resetDailyLesson();

        resetSelectedDate();

        const cancelScrollTo = ['/speakinglesson/educationpackages'];
        if(!cancelScrollTo.includes(location.pathname))
            //Sayfa ilk yüklendiğinde yukarıya scroll ettiriliyor.
            window.scrollTo({ top: 0, behavior: "smooth" });
    }, [location]);

    //Kullanıcının timeZone değeri değişirse DB'de güncelleme yapıyoruz
    useEffect(() => {
        if(token && +member?.Timezone !== timeZone && member?.MemberId) {
            UpdateMemberTimeZone(member?.MemberId, timeZone)
                .then(r => {})
                .catch(e => {})
        }
    }, []);

    //Bu useEffect sadece giriş yapıldığında ve öğrencinin term'ü var ise çalışıyor.
    useEffect(() => {
        if(terms && isLoggedIn){
            /*
                * Bu kodda öğrencinin dönemlerinden bir tanesi bile Josiah'ta ise,
                * bu değer true'ya çekilip endpointe gidilmesi için bilgi veriliyor.
            */
            let isTeacherJosiah = false;

            //Teacher Josiah kontrolü yapılıyor.
            let jsonTerms = JSON.parse(terms);
            jsonTerms.map((term) => {
                if(
                    term.TeacherId &&
                    term.TeacherId === +process.env.REACT_APP_TEACHER_JOSIAH_ID &&
                    term.Status === TermStatusTypes.Active
                ) {
                    isTeacherJosiah = true;
                }
            })

            if(!isTeacherJosiah) {
                assignTermToTeacherSandy();
                return;
            }

            AssignTermTeacher(member?.MemberId)
                .then(result => {
                    if(result.status === 200){
                        //LOGGED
                        analytics.useAnalytics(EventLogs.TEACHER_JOSIAH_MOVE);

                        let resultContent = JSON.parse(result.content);
                        let modalInfo = {
                            open: resultContent.changedTermList?.length > 0,
                            info: resultContent.changedTermList
                        }

                        //Pencere açılması için localStorage'a bilgi kaydediliyor.
                        localStorage.setItem("openAssignTeacherModal", JSON.stringify(modalInfo));

                        assignTermToTeacherSandy();
                    }
                })
        }
    }, [isLoggedIn, terms])
}

export default useMember;