import { Icon_Missed_Call } from "../../../assets/svg";
import {addTimeZone, getTimeZone, showDateWithFormat} from "../../../components/Utility";
import useAuth from "../../../hooks/useAuth";
import { useLocalization } from "../../../hooks/useLocalization";
import { Error } from "../components"

const MissedCallRightUsed = ({ data }) => {

    const strings = useLocalization();
    const { member } = useAuth();
    const timeZone = getTimeZone(member.Timezone);

    return (
        <Error
            icon={Icon_Missed_Call}
            title={strings.support.missed_call_used.used_call}
            content={strings.support.missed_call_used.used_content
                .replace(/#date1#/g, showDateWithFormat(data.missedDate))
                .replace(/#date2#/g, showDateWithFormat(data.lessonDate))
                .replace(/#time#/g, addTimeZone(data.lessonTime, timeZone))
                .replace(/#teacher#/g, data.teacherName)}
            hideButton="hidden"
        />
    )
}

export default MissedCallRightUsed