import ana_sayfa_ko_mobile from "../img/speaking_lesson/none_user/ana_sayfa_ko_mobile.png"
import nonpaid_user from "../img/speaking_lesson/none_user/nonpaid_user.png"
import most_curious_about from "../img/speaking_lesson/none_user/most_curious_about.png"
import play from "../img/speaking_lesson/speaking_lab/play.png"
import check_mark from "../img/speaking_lesson/speaking_lab/check_mark.png"
import wrong from "../img/speaking_lesson/speaking_lab/wrong.png"

import teacher_perry from "../img/speaking_lesson/daily_lesson/teacher-perry.png"
import workbook from "../img/speaking_lesson/daily_lesson/workbook.jpg"

import en_flag from "../img/speaking_lesson/daily_sentence/en.png"
import local_flag from "../img/speaking_lesson/daily_sentence/sa.png"

import schedule_calendar from "../img/speaking_lesson/calendar.png"

import teacher_claire from "../img/teachers/teacher-claire.png"

import play_store from "../img/stores/play_store.png"
import app_store from "../img/stores/app_store.png"
import app_gallery from "../img/stores/app_gallery.png"

import accept from "../img/accept.png"
import multiply from "../img/multiply.png"

import logo_square from "../img/logo-square.png"
import need_permission from "../img/need_permission.png"

import sa_flag from "../img/auth/sa_flag.png"
import usa_flag from "../img/auth/usa_flag.png"

import onboarding_2_2 from "../img/onboarding/second/2.png";
import onboarding_2_4 from "../img/onboarding/second/4.png";
import onboarding_2_5 from "../img/onboarding/second/5.png";
import onboarding_2_6 from "../img/onboarding/second/6.png";
import onboarding_2_7 from "../img/onboarding/second/7.png";
import onboarding_2_9 from "../img/onboarding/second/9.png";

import accept_call from "../img/call/acceptCall.png";
import end_call from "../img/call/endCall.png";

import default_video_thumbnail from "./video_practice/default-video-thumbnail.png";

import female from "./profile/female.png";
import male from "./profile/male.png";
import none_gender from "./profile/none_gender.png";
import check from "./profile/check.svg";
import default_profile_al from "./profile/default_profile_al.png";

export const Ana_Sayfa_Ko_Mobile = ana_sayfa_ko_mobile
export const Nonpaid_User = nonpaid_user
export const Most_Curious_About = most_curious_about
export const Play = play;
export const CheckMark = check_mark;
export const Wrong = wrong;

export const Teacher_Perry = teacher_perry
export const Workbook = workbook

export const EnFlag = en_flag
export const LocalFlag = local_flag

export const Schedule_Calendar = schedule_calendar

export const Teacher_Claire = teacher_claire

export const App_Store = app_store
export const Play_Store = play_store
export const App_Gallery = app_gallery

export const Accept = accept
export const Multiply = multiply

export const Logo_Square = logo_square
export const Need_Permission = need_permission

export const Sa_Flag = sa_flag;
export const Usa_Flag = usa_flag;

export const Onboarding_2_2 = onboarding_2_2;
export const Onboarding_2_4 = onboarding_2_4;
export const Onboarding_2_5 = onboarding_2_5;
export const Onboarding_2_6 = onboarding_2_6;
export const Onboarding_2_7 = onboarding_2_7;
export const Onboarding_2_9 = onboarding_2_9;

export const Accept_Call = accept_call;
export const End_Call = end_call;

export const Default_Video_Thumbnail = default_video_thumbnail;

export const Female = female;
export const Male = male;
export const NoneGender = none_gender;
export const Check = check;
export const DefaultProfileAl = default_profile_al;