import {useLocalization} from "../../../../hooks/useLocalization";
import {Card_Name, Card_Number} from "../../../../assets/svg";
import React, {useEffect, useState} from "react";
import {
    checkNumber,
    formatCreditCardNumber,
    getApplicationDirection,
    getCardMonths,
    getCardYears
} from "../../../../components/Utility";
import {Dropdown} from "../../../../components";
import classNames from "classnames";
import {useSelector} from "react-redux";

const CreditCard = (props) => {

    const { creditCardInfo, changeCreditCardInfo } = props;

    const strings = useLocalization();
    const language = useSelector(state => state.localizationStore.language);

    // Kart son kullanma ayının tutulduğu state
    const firstMonth = { id: -1, name: strings.market.checkout.month };
    const [cardMonth, setCardMonth] = useState(firstMonth);
    // Kart son kullanma yılının tutulduğu state
    const firstYear = { id: -1, name: strings.market.checkout.year };
    const [cardYear, setCardYear] = useState(firstYear);

    // Seçili ay değiştikçe üst componentteki kredi kartı bilgileri güncelleniyor
    useEffect(() => {
        changeCreditCardInfo({ cardMonth: cardMonth });
    }, [cardMonth])

    // Seçili yıl değiştikçe üst componentteki kredi kartı bilgileri güncelleniyor
    useEffect(() => {
        changeCreditCardInfo({ cardYear: cardYear });
    }, [cardYear])

    return (
        <div className="px-5 md:px-0 w-full md:w-[495px] mx-auto text-sm sm:text-base mt-5">
            <div className="font-bold w-full relative pb-2 text-sm mt-4 sm:mt-0">
                {strings.market.checkout.card_informations}
                <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-primary rounded"></div>
            </div>

            {/* Kart Adı */}
            <div className="flex flex-col mt-2">
                <label className="block text-start" htmlFor="card-name">
                    {strings.market.checkout.card_name_surname}
                </label>
                <div className="w-full relative group">
                    <input
                        id="card-name"
                        type="text"
                        className={classNames("market-input", {
                            "pr-[76px]": getApplicationDirection(language),
                            "pl-[76px]": !getApplicationDirection(language)
                        })}
                        onChange={(e) => changeCreditCardInfo({cardName: e.target.value})}
                    />
                    <div className={classNames("market-input-left", {
                        "border-l rounded-r-[10px]": getApplicationDirection(language),
                        "border-r rounded-l-[10px]": !getApplicationDirection(language)
                    })}
                    >
                        <img src={Card_Name} width="20" height="20" alt="card-name"/>
                    </div>
                </div>
            </div>

            {/* Kart Numarası */}
            <div className="flex flex-col mt-2">
                <label className="block text-start" htmlFor="card-number">
                    {strings.market.checkout.card_number}
                </label>
                <div className="w-full relative group">
                    <input
                        id="card-number"
                        type="text"
                        inputMode="numeric"
                        className={classNames("market-input", {
                            "pr-[76px]": getApplicationDirection(language),
                            "pl-[76px]": !getApplicationDirection(language)
                        })}
                        placeholder="---- ---- ---- ----" maxLength="19"
                        value={formatCreditCardNumber(creditCardInfo.cardNumber)}
                        onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Sadece sayılar izin verilir
                        }}
                        onChange={(e) => changeCreditCardInfo({cardNumber: e.target.value})}
                    />
                    <div className={classNames("market-input-left", {
                        "border-l rounded-r-[10px]": getApplicationDirection(language),
                        "border-r rounded-l-[10px]": !getApplicationDirection(language)
                    })}>
                        <img src={Card_Number} width="20" height="20" alt="card-number"/>
                    </div>
                </div>
            </div>

            {/* Kart Son Kullanma Tarihi */}
            <div className="flex gap-3 justify-between items-center w-full">
                <div className="flex flex-col mt-2">
                    <label className="block text-start">
                        {strings.market.checkout.card_exp_date}
                    </label>
                    <div className="w-full">
                        <div className="w-full flex justify-between items-center relative">
                            <Dropdown
                                data={[firstMonth, ...getCardMonths()]}
                                selected={cardMonth}
                                setSelected={setCardMonth}
                                classnames="min-w-[95px]"
                                innerClassnames="h-[40px] focus:border-primary"
                            />
                            <p className="px-1 text-center text-xl">/</p>
                            <Dropdown
                                data={[firstYear, ...getCardYears()]}
                                selected={cardYear}
                                setSelected={setCardYear}
                                classnames="min-w-[95px]"
                                innerClassnames="h-[40px] focus:border-primary"
                            />
                        </div>
                    </div>
                </div>
                {/* CVV */}
                <div className="flex flex-col mt-1.5 items-end">
                    <label className="flex w-full sm:w-[calc(50%-10px)]" htmlFor="card-cvv">
                        <p className={classNames("", {
                            "ml-1": getApplicationDirection(language),
                            "mr-1": !getApplicationDirection(language)
                        })}
                        >CVV</p>
                        <button type="button" className="group relative transition duration-150 ease-in-out has-tooltip">
                            <span className="material-symbols-outlined text-[20px]">info</span>
                            <div className="tooltip-top -top-[55px]">{strings.market.checkout.cvv}</div>
                        </button>
                    </label>
                    <input
                        id="card-cvv"
                        type="text"
                        inputMode="numeric"
                        className="market-input w-full sm:w-[calc(50%-10px)] pr-4"
                        maxLength="4"
                        onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                        onChange={(e) => changeCreditCardInfo({cvv: e.target.value})}
                    />
                </div>
            </div>

        </div>
    )
}

export default CreditCard;