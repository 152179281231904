import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import {useNavigate} from "react-router-dom";
import {getApplicationDirection} from "../../../../components/Utility";
import {url} from "../../../../routes/utility";

const ExerciseStep = (props) => {

    const {
        step,
        data,
        routingId = ""
    } = props;

  const navigate = useNavigate();
  const language = useSelector(state => state.localizationStore.language);

  const stepButtons = [];

  for (let i = 1; i <= data.length; i++) {
    const isActive = i === step;
    const isPrevious = i < step;
    const isAfterActive = i === step + 1;
    const matchingStep = data.find(item => item.id === i);
    const icon = matchingStep ? matchingStep.icon : '';
    const title = matchingStep ? language === "en" ? matchingStep.eng : matchingStep.name : '';
    const applicationDirection = getApplicationDirection(language);
    const pathName = matchingStep ? matchingStep.pathName : false;

    stepButtons.push(
        <button
            key={i}
            type='button'
            className={classNames("relative", {
                "cursor-pointer": pathName,
                "cursor-default": !pathName
            })}
            onClick={() => pathName && navigate(url(pathName, {videoId: routingId}))}
        >
            <div className={classNames({
                'line-between mt-[19px] left-[38px]': i !== 1 && applicationDirection && data.length === 4,
                'line-between mt-[19px] left-[30px]': i !== 1 && applicationDirection && data.length > 4,
                'line-between mt-[19px] right-[38px]': i !== 1 && !applicationDirection && data.length === 4,
                'line-between mt-[19px] right-[30px]': i !== 1 && !applicationDirection && data.length > 4,
                'line-between-active-before bg-gradient-to-l': isActive && applicationDirection,
                'line-between-active-before bg-gradient-to-r': isActive && !applicationDirection,
                'line-between-active-after bg-gradient-to-l': isAfterActive && applicationDirection,
                'line-between-active-after bg-gradient-to-r': isAfterActive && !applicationDirection,
                'bg-[#EEA265]': isPrevious
            })}></div>
            <div className={classNames('package-select-button', {
                'package-select-button-previous': isPrevious,
                'package-select-button-active': isActive,
            })}>{icon}</div>
            <span
                className={classNames('absolute text-[14px] -right-3 -left-3 xs:-right-10 xs:-left-10 text-center max-w-[50px] xs:max-w-[120px]', {
                    'text-[#EEA265]': isPrevious,
                    'text-secondary': isActive,
                })}>{title}</span>
        </button>
    );
  }
    ;

    return (
        <div className='px-2 mt-5'>
            <div className="flex justify-between gap-4 max-w-xl mx-auto">
                {stepButtons}
            </div>
        </div>
    )
};

export default ExerciseStep;