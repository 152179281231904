export const wordsAndIdioms = [
    {
        "idioms": [
            [
                "A little bird told me",
                "أخبرني العصفور"
            ],
            [
                "A penny for your thoughts",
                "ماذا تعتقد؟"
            ],
            [
                "Abide by a decision",
                "الإلتزام بالقرار"
            ]
        ],
        "word": [
            "have",
            "امتلاك – لديك",
            "I have an older brother",
            "لديَّ أخ"
        ]
    },
    {
        "idioms": [
            [
                "Add fuel to the fire",
                "مكافحة النار"
            ],
            [
                "Against the clock",
                "مع الزمن"
            ],
            [
                "Age before beauty",
                "العمر قبل الجمال"
            ]
        ],
        "word": [
            "some",
            "القليل- بعضاً من",
            "Ken went to the supermarket to buy some eggs.",
            "ذهب كان للسوبر ماركت لجلب بعضاً من البيض"
        ]
    },
    {
        "idioms": [
            [
                "All bets are off",
                "تم إلغاء كل شيء"
            ],
            [
                "All eyes on me",
                "كل العيون علي"
            ],
            [
                "All heart",
                "بإخلاص"
            ]
        ],
        "word": [
            "other",
            "الآخر",
            "We cannot see the other side of the moon.",
            "لا يمكننا رؤية الجانب الآخر من القمر"
        ]
    },
    {
        "idioms": [
            [
                "All over bar the shouting",
                "النتيجة النهائية"
            ],
            [
                "All over the place",
                "كل شيء في كل مكان"
            ],
            [
                "All over the shop",
                "فوضى عارمة"
            ]
        ],
        "word": [
            "all",
            "الكل",
            "All my friends went to the park.",
            "ذهب جميع أصدقائي إلى الحديقة"
        ]
    },
    {
        "idioms": [
            [
                "All talk and no trousers",
                "كلام كثير بدون عمل"
            ],
            [
                "All your eggs in one basket",
                "المخاطرة بكل شيء"
            ],
            [
                "And all that jazz",
                "والكثير من الهراء من هذا القبيل"
            ]
        ],
        "word": [
            "word",
            "كلمة",
            "I was remembering the words of my old friend.",
            "كنت أتذكر كلمات صديق قديم"
        ]
    },
    {
        "idioms": [
            [
                "Angry as a bear",
                "غاضب كالدب"
            ],
            [
                "Ants in your pants",
                "غير ملائم"
            ],
            [
                "Apple of your eye",
                "أن تكون قرة عين شخص ما"
            ]
        ],
        "word": [
            "each",
            "كل",
            "She kissed each of her children.",
            "قبل كل طفل"
        ]
    },
    {
        "idioms": [
            [
                "Apron strings",
                "الالتزام المفرط"
            ],
            [
                "Argue the toss",
                "قول نوح وعدم قول نبي"
            ],
            [
                "Arm and a leg",
                "خذها غالية الثمن"
            ]
        ],
        "word": [
            "kind",
            "نوع",
            "What kind of person is he?",
            "أي نوع من الأشخاص هو؟"
        ]
    },
    {
        "idioms": [
            [
                "Caught with your hand in the cookie jar",
                "ليتم التنصت عليه"
            ],
            [
                "Chalk and cheese",
                "يوجد فرق بينهم بقدر الجبال"
            ],
            [
                "Champ at the bit",
                "لا تناسب"
            ]
        ],
        "word": [
            "write",
            "يكتب",
            "An author writes stories.",
            "كاتب يكتب القصص"
        ]
    },
    {
        "idioms": [
            [
                "Change of heart",
                "تغيير العقل"
            ],
            [
                "Cheap shot",
                "لا تضرب من الخصر إلى أسفل"
            ],
            [
                "Cherry pick",
                "للتدقيق"
            ]
        ],
        "word": [
            "so",
            "حتي",
            "She is so beautiful.",
            "جميل جدا"
        ]
    },
    {
        "idioms": [
            [
                "Chew on a bone",
                "التفكير المكثف"
            ],
            [
                "Child`s play",
                "لعب طفل"
            ],
            [
                "Chip on your shoulder",
                "أن تكون في حالة من الغضب"
            ]
        ],
        "word": [
            "more",
            "أكثر",
            "Please sir, I want more.",
            "أرجوك سيدي ، أريد المزيد"
        ]
    },
    {
        "idioms": [
            [
                "Cigarette paper",
                "أن أكون مثل الأظافر بلحم"
            ],
            [
                "Circle the wagons",
                "خذ حذرك"
            ],
            [
                "Circling the drain",
                "قرب النهاية الحتمية"
            ]
        ],
        "word": [
            "day",
            "يوم",
            "Sunday is the last day of the week.",
            "الأحد هو آخر يوم في الأسبوع"
        ]
    },
    {
        "idioms": [
            [
                "Clean bill of health",
                "الشخص الذي يتلقى تقريرًا قويًا"
            ],
            [
                "Clean sheet",
                "سجل جديد / نظيف"
            ],
            [
                "Clean slate",
                "افتح صفحة بيضاء"
            ]
        ],
        "word": [
            "sound",
            "صوت بشري",
            "The sound of barking dogs bothers me while I am sleeping.",
            "يزعجني صوت نباح الكلاب أثناء نومي"
        ]
    },
    {
        "idioms": [
            [
                "Close but no cigar",
                "محاولة جيدة لكنها لم تنجح"
            ],
            [
                "Close to your heart",
                "مناشدة الشخص"
            ],
            [
                "Closed book to me",
                "صندوق مغلق"
            ]
        ],
        "word": [
            "number",
            "رقم",
            "Messi is the number one player in the world.",
            "ميسي هو اللاعب الأول في العالم"
        ]
    },
    {
        "idioms": [
            [
                "Cold feet",
                "لا تأكل العيون"
            ],
            [
                "Cold fish",
                "السرة الباردة"
            ],
            [
                "Cold light of day",
                "بهدوء"
            ]
        ],
        "word": [
            "call",
            "دعا",
            "A girl called her boyfriend at night.",
            "فتاة اتصلت بصديقها في الليل"
        ]
    },
    {
        "idioms": [
            [
                "Give a big hand",
                "نحيي"
            ],
            [
                "Give me five",
                "كفك"
            ],
            [
                "Give your eye teeth",
                "إعطاء كل ما تبذلونه / وضع كل ما تبذلونه على"
            ]
        ],
        "word": [
            "people",
            "شعب",
            "People sleep when they are tired.",
            "ينام الناس عندما يكونون متعبين "
        ]
    },
    {
        "idioms": [
            [
                "Glass ceiling",
                " معوقات النهوض بالمرأة والأقليات في مكان العمل"
            ],
            [
                "Go awry",
                "الذهاب في الاتجاه المعاكس"
            ],
            [
                "Go bananas",
                "خطف الماعز"
            ]
        ],
        "word": [
            "find",
            "وجد",
            "The police needs to find the evidence to put him into a jail.",
            "الشرطة بحاجة للعثور على دليل لوضعه في السجن"
        ]
    },
    {
        "idioms": [
            [
                "Go down like a lead balloon",
                "غير مرحب به"
            ],
            [
                "Go for the jugular",
                "ضرب نقطة الضعف"
            ],
            [
                "Go over like a lead balloon",
                "الفشل التام"
            ]
        ],
        "word": [
            "work",
            "عمل",
            "Many employees work at weekends.",
            "يعمل العديد من الموظفين في عطلات نهاية الأسبوع."
        ]
    },
    {
        "idioms": [
            [
                "Go play in traffic",
                "اذهب بعيدا حتى لا أراك"
            ],
            [
                "Go tell it to birds",
                "قل مخروط بلدي "
            ],
            [
                "Go the distance",
                "دفع حتى النهاية"
            ]
        ],
        "word": [
            "place",
            "مكان",
            "Keep your credit cards in a safe place.",
            "احتفظ ببطاقات الائتمان في مكان آمن"
        ]
    },
    {
        "idioms": [
            [
                "Golden rule",
                "قاعدة ذهبية"
            ],
            [
                "Going overboard",
                "المبالغة"
            ],
            [
                "Good egg",
                "رجل صالح"
            ]
        ],
        "word": [
            "attendance",
            "حضور",
            "Students whose attendance falls below 80% will receive a warning letter.",
            "الطلاب الذين تقل نسبة حضورهم عن 80٪ يتلقون رسالة تحذير"
        ]
    },
    {
        "idioms": [
            [
                "Gravy train",
                "در الركن"
            ],
            [
                "Grease monkey",
                "ميكانيكي / مهندس / ماجستير"
            ],
            [
                "Great guns",
                "تنفق كل قوتك وطاقتك"
            ]
        ],
        "word": [
            "authority",
            "السلطة",
            "We don`t have the authority to kick kids out of school unless they have been especially violent.",
            "ليس لدينا السلطة لطرد الأطفال من المدرسة ما لم يكونوا عنيفين بشكل خاص."
        ]
    },
    {
        "idioms": [
            [
                "Grey Cardinal",
                "استخدام قوتك وراء الكواليس"
            ],
            [
                "Grey matter",
                "العقل / الذكاء / الدماغ"
            ],
            [
                "Grey suits",
                "أولئك الذين يقفون خلف الستارة"
            ]
        ],
        "word": [
            "equipment",
            "معدات",
            "He is saving up to buy himself some new football equipment.",
            "الادخار لشراء معدات كرة قدم جديدة"
        ]
    },
    {
        "idioms": [
            [
                "Know a hawk from a handsaw",
                "لتعرف جيدا"
            ],
            [
                "Know full well",
                "ان تكون متاكد"
            ],
            [
                "Know the ropes",
                "شخص مطلع"
            ]
        ],
        "word": [
            "burst",
            "انفجار",
            "The fireworks burst in the air with brilliant colors.",
            "انفجرت الألعاب النارية في الهواء بألوان زاهية"
        ]
    },
    {
        "idioms": [
            [
                "Know where all the bodies are buried",
                "لمعرفة شيء بكل تفاصيله "
            ],
            [
                "Know which way the wind blows",
                "رفة الاتجاه الذي ستهب فيه الرياح"
            ],
            [
                "Know your place",
                "اعرف مكانك"
            ]
        ],
        "word": [
            "comparison",
            "قارن",
            "Country life is very peaceful in comparison with city life.",
            "الحياة الريفية أكثر هدوءًا مقارنة بحياة المدينة"
        ]
    },
    {
        "idioms": [
            [
                "Labor of love",
                "عمل تطوعي"
            ],
            [
                "Lame duck",
                "بطة عرجاء / مشلولة"
            ],
            [
                "Landslide victory",
                "النصر بأغلبية ساحقة"
            ]
        ],
        "word": [
            "dependable",
            "جدير بالثقة",
            "He is a good friend of mine, very helpful, and always dependable.",
            " إنه صديق جيد لي ، ومساعد للغاية وموثوق به."
        ]
    },
    {
        "idioms": [
            [
                "Lap of the gods",
                "الأمر متروك لله / لا تفعل شيئًا"
            ],
            [
                "Last hurrah",
                "العرض الأخير / آخر أداء"
            ],
            [
                "Last straw",
                "القشة الأخيرة"
            ]
        ],
        "word": [
            "patience",
            "الصبر",
            "Jean-Jacques Rousseau once observed that patience is bitter, but its fruit is sweet.",
            "قال جان جاك روسو:الصبر مر ، لكن ثمرته حلوة."
        ]
    },
    {
        "idioms": [
            [
                "Laugh to see a pudding crawl",
                "شخص يضحك على كل شيء"
            ],
            [
                "Laughing stock",
                "كن مضحكا"
            ],
            [
                "Laughter is the best medicine",
                "الضحك هو أفضل دواء"
            ]
        ],
        "word": [
            "poet",
            "شاعر",
            "I know a poet whose poems are widely read.",
            "أعرف شاعرًا تُقرأ قصائده على نطاق واسع."
        ]
    },
    {
        "idioms": [
            [
                "Law unto yourself",
                "اقرأ ما تعرفه "
            ],
            [
                "Lay down the law",
                "يرعى"
            ],
            [
                "Lean and mean",
                "ارفع يديك وانتظر "
            ]
        ],
        "word": [
            "impolite",
            "قلة الادب",
            "It would have been impolite to refuse.",
            "سيكون من الوقاحة أن ترفض."
        ]
    },
    {
        "idioms": [
            [
                "Learn the ropes",
                " تعلم كيفية القيام بالعمل "
            ],
            [
                "Leave well alone",
                "لا تتدخل في أي شيء"
            ],
            [
                "Left to your own devices",
                "يغادر بمفرده"
            ]
        ],
        "word": [
            "raise",
            "رفع؛ فائدة",
            "I just got a raise of over 50 cents an hour.",
            "حصلت على زيادة بنسبة 50 في الساعة."
        ]
    },
    {
        "idioms": [
            [
                "Lend an ear",
                "استمع"
            ],
            [
                "Let bygones be bygones",
                "إغلاق دفاتر الملاحظات القديمة"
            ],
            [
                "Let sleeping dogs lie",
                "الحية التي لا تلمسني ستعيش ألف سنة"
            ]
        ],
        "word": [
            "similarity",
            "تشابه",
            "Despite the twins`s physical similarity, their characters are totally different.",
            " على الرغم من التشابه الجسدي بين التوائم ، فإن شخصياتهما مختلفة تمامًا."
        ]
    },
    {
        "idioms": [
            [
                "Let the cat out of the bag",
                "بصق في فمك "
            ],
            [
                "Let the chips fall where they may",
                " اترك الوظيفة"
            ],
            [
                "Let the genie out of the bottle",
                "خرج السهم من القوس / حقيقة أن السهم قد خرج من القوس"
            ]
        ],
        "word": [
            "solution",
            "المحلول",
            "Love is the key to the solution of the problems of the world.",
            "الحب هو مفتاح حل المشاكل في العالم."
        ]
    },
    {
        "idioms": [
            [
                "Let your hair down",
                "استرخ / تنهد"
            ],
            [
                "Let`s call it a day",
                "وهذا يكفي لهذا اليوم"
            ],
            [
                "Letter of the law",
                "حكم القانون"
            ]
        ],
        "word": [
            "adult",
            "بالغ",
            "You cannot get a tattoo until you are an adult.",
            "لا يمكنك الحصول على وشم حتى تصبح بالغًا"
        ]
    }
]