import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { Speaker } from "../assets/svg";
import useAnalytics from "../hooks/useAnalytics";
import useTts from "../hooks/useTts";
import { EventLogs } from "./Constants";
import { SentenceBookmarkButton } from "./index";
import { getApplicationDirection } from "./Utility";

const SentenceRow = (props) => {

    const {
        data,
        showHr,
        handleClickedSentence = () => {}
    } = props;

    const { speakSentence, SpeakerComponent } = useTts();
    const analytics = useAnalytics();

    const language = useSelector(state => state.localizationStore.language);

    const handleClickSentence = () => {
        speakSentence(data.sentence, process.env.REACT_APP_LANG_EN_CODE, data.sentenceId)
            .then()
            .catch()

        analytics.useAnalytics(EventLogs.SENTENCE_BANK_PRONUNCIATION_LISTENED, undefined, false, true);
        handleClickedSentence(data.sentenceId);
    }

    return (
        <>
            <div className="flex justify-between items-center gap-2 mt-2">
                <div className="flex flex-col max-w-[calc(100%-40px)]">
                    <button
                        type="button"
                        className="flex gap-1 hover:text-base-text/80 cursor-pointer"
                        onClick={() => handleClickSentence(data)}
                    >
                        <p className="font-bold align-middle text-start">
                            <span dir="ltr">{data.sentence}</span>
                            <img
                                src={Speaker}
                                className={classNames("inline-flex", {
                                    "mr-1.5" : getApplicationDirection(language),
                                    "ml-1.5" : !getApplicationDirection(language),
                                })}
                                width={16}
                                height={16}
                                alt="speaker"
                            />
                        </p>
                    </button>
                    <p className="text-[12px] text-start">
                        {data.sentenceTranslation}
                    </p>
                </div>
                <SentenceBookmarkButton
                    initialIsSaved={data?.isSaved}
                    sentenceId={data?.sentenceId}
                    logEvent={{
                        true: EventLogs.SENTENCE_BANK_ADDED_TO_LEARNING_LIST,
                        false: EventLogs.SENTENCE_BANK_REMOVED_TO_LEARNING_LIST
                    }}
                />
            </div>

            {showHr && <hr className="my-2"/>}
            <SpeakerComponent/>
        </>
    )
}

export default SentenceRow;