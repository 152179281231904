import React from 'react';
import ReactStars from "react-rating-stars-component";

const Star = (props) => {

  const { text, onChange } = props;  

  return (
    <div className="flex justify-between items-center gap-x-2" >
        <label className="mb-0 max-w-[calc(100%-128px)]">
            {text} <span className='text-red'> *</span>
        </label>
        <ReactStars
            count={5}
            onChange={onChange}
            size={24}
            activeColor="#ffd700"
        />
    </div>
  )
}

export default Star