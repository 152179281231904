import classNames from "classnames";
import { getAnswerTitleByIndex, getApplicationDirection } from "../../../../components/Utility";
import { useSelector } from 'react-redux';

const AnswerButton = (props) => {

    const { index, item, userAnswer, quizReport, isClickedControlButton, action } = props;
    const language = useSelector(state => state.localizationStore.language);

    const correctAnswerId = item.isCorrectAnswer ? item.id : null

    // Kullanıcının hiçbir akisyon almadığı (cevap seçmediği) butonun koşuludur.
    let normalButton = userAnswer !== item.id;
    // Kullancının cevap seçtiğinde gördüğü butonun koşuludur.
    let focusedButton = !isClickedControlButton && userAnswer === item.id;
    // Kullanıcının cevap seçtikten sonra 'Kontrol Et' butonuna tıkladığında gördüğü doğru cevap butonun koşuludur.
    let rightAnswerButton = isClickedControlButton && item.isCorrectAnswer;
    // Kullanıcının cevap seçtikten sonra 'Kontrol Et' butonuna tıkladığında gördüğü yanlış cevap butonun koşuludur.
    let wrongAnswerButton = quizReport && isClickedControlButton && item.id === quizReport.userAnswer && quizReport.userAnswer !== correctAnswerId;

    return (
        <button
            type="button"
            className={classNames("w-full max-w-[330px] h-[50px] leading-[22px] relative overflow-hidden rounded-[10px] border border-onboarding-border mb-4 lg:mb-5 flex items-center", {
                "pl-[70px] pr-7": !getApplicationDirection(language),
                "pr-[70px] pl-7": getApplicationDirection(language),
                "bg-white": normalButton,
                "!bg-[#EBF6FF] !text-base": focusedButton,
                "bg-green hover:bg-green": rightAnswerButton,
                "!bg-red hover:bg-red": wrongAnswerButton,
                "hover:bg-onboarding-bg-select cursor-pointer": !isClickedControlButton,
                "cursor-default": isClickedControlButton,
            })}
            onClick={action}
        >
            <div
                dir="ltr"
                className={classNames("onboarding-button-left", {
                    "border-r": !getApplicationDirection(language),
                    "border-l": getApplicationDirection(language),
                    "bg-green border-white text-white": rightAnswerButton,
                    "bg-red border-white text-white": wrongAnswerButton,
                })}
            >
                <span className="font-bold">{getAnswerTitleByIndex(index)}</span>
            </div>
            <p
                className={classNames("text-start", {
                    "text-white": (rightAnswerButton || wrongAnswerButton),
                    "text-[14px] leading-[17px]": item.answer.content.length > 60,
                })}
            >
                {item.answer.content}
            </p>
        </button>
    );
};

export default AnswerButton;
